import { css } from 'styled-components';

const formCheck = css`
  .form-check {
    & input {
      & ~ span {
        background-color: var(--color-text);
        border-color: var(--button-primary) !important;
      }

      &:checked ~ span::after {
        background-color: var(--button-primary);
      }
    }

    & > label > div:hover > input ~ span {
      background-color: #eee;
    }
  }
`;

export default formCheck;
