import React, { useCallback, useMemo, useRef, useState } from 'react';

import debounce from 'just-debounce-it';
import useAppTranslation from '../hook/useAppTranslation';
import AsyncSelect from './AsyncSelect';

const AsyncSelectForm = ({
  name,
  value,
  isValid,
  isInvalid,
  onChange,
  onBlur,
  disabled,
  className,
  loadOptions,
  valueSelected,
}) => {
  const { t } = useAppTranslation();
  const refInput = useRef(null);
  const [valueSelectedInner, setValueSelectedInner] = useState(null);

  const defaultValue = useMemo(
    () => valueSelected ?? valueSelectedInner,
    [valueSelected, valueSelectedInner],
  );

  const setRef = useCallback(
    node => {
      if (refInput.current) {
        refInput.current.removeEventListener('change', onChange);
        refInput.current.removeEventListener('blur', onBlur);
      }

      if (node) {
        node.addEventListener('change', onChange);
        node.addEventListener('blur', onBlur);
      }

      refInput.current = node;
    },
    [onChange, onBlur],
  );

  const handleChange = newValue => {
    if (!refInput.current || value === newValue?.value) return;

    setValueSelectedInner(newValue);
    refInput.current.value = null;
    refInput.current.label = undefined;
    Object.entries(newValue ?? {}).forEach(([key, v]) => {
      refInput.current[key] = v ?? null;
    });

    refInput.current.dispatchEvent(new Event('change', { bubbles: true }));
  };

  const handleBlur = () => {
    if (!refInput.current) return;

    refInput.current.dispatchEvent(new Event('blur', { bubbles: true }));
  };

  const loadOptionsDebounce = useMemo(
    () =>
      debounce(async (inputValue, cb) => {
        if ((inputValue?.length ?? 0) < 3) {
          cb([]);
          return;
        }

        const arr = await loadOptions(inputValue);
        cb(arr);
      }, 300),
    [loadOptions],
  );

  return (
    <>
      <input
        type="hidden"
        ref={setRef}
        id={name}
        name={name}
        value={value ?? ''}
      />

      <AsyncSelect
        defaultValue={defaultValue}
        defaultOptions={defaultValue && [defaultValue]}
        value={defaultValue}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={`${t('Buscar')} ...`}
        loadOptions={loadOptionsDebounce}
        isClearable
        openMenuOnClick={false}
        isValid={isValid}
        isInvalid={isInvalid}
        isDisabled={disabled}
        className={className}
      />
    </>
  );
};

export default AsyncSelectForm;
