import { create } from 'zustand';

const store = set => ({
  message: undefined,
  count: 0,
  isShow: false,

  show: message =>
    set(
      state => {
        const count = state.count + 1;

        return { message, count, isShow: true };
      },
      false,
      'SHOW_LOADER',
    ),

  hide: () =>
    set(
      state => {
        if (state.count === 0) return state;

        const count = state.count - 1;

        return { message: undefined, count, isShow: count > 0 };
      },
      false,
      'HIDE_LOADER',
    ),
});

const useLoaderZustand = create(store);

export default useLoaderZustand;
