import React, { useState } from 'react';

import moment from 'moment';
import { Button, Col, Image, Modal, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import useAppTranslation from '../../hook/useAppTranslation';
import { encode } from '../../utils/uriComponent';
import CardStyled from '../Card';
import PriceFormat from '../PriceFormat';
import Text from '../Text';

const CardHotel = ({ item }) => {
  const { t, dateFormatMoment } = useAppTranslation();
  const history = useHistory();

  const [currency, setCurrency] = useState({});
  const [showCurrency, setShowCurrency] = useState(false);
  const handleCloseCurrency = () => setShowCurrency(false);

  const onHandleClickCurrency = () => {
    setCurrency(item.route);
    setShowCurrency(true);
  };

  const handleClick = () => {
    const itemEncode = encode(item);

    history.push(`/cruiseShips/detail/${itemEncode}`);
  };

  return (
    <>
      <Modal show={showCurrency} onHide={handleCloseCurrency} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('CRUISES_ITINERARY')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>{t('CRUISES_NAME')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{currency}</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      <WrapperHotel>
        <Col xs="12" className="tw-mx-0 tw-px-0">
          <CardHotelData>
            <Row className="tw-h-full tw-max-h-full tw-p-3">
              <Col xs={{ span: 4, offset: 4 }} lg={{ span: 4, offset: 0 }}>
                <Image
                  key={item.shipImage}
                  src={
                    item.shipImage ||
                    item.cruiseLineLogo ||
                    '/images/no-image.jpg'
                  }
                  width="120%"
                  height="120%"
                  className="img-thumbnail tw-cursor-pointer"
                  alt={item.title}
                  onClick={handleClick}
                />
              </Col>

              <Col>
                <Row
                  style={{
                    font: 'var(--unnamed-font-style-normal) normal var(--unnamed-tw-font-bold) var(--unnamed-font-size-30)/32px var(--unnamed-font-family-montserrat)',
                  }}
                >
                  <Text
                    size="22"
                    lineHeight="50"
                    color="#6B9AC3"
                    className="tw-cursor-pointer"
                    onClick={handleClick}
                  >
                    {t('CRUISES_NIGHTS', { count: item.duration })}
                  </Text>
                  <Text
                    size="22"
                    lineHeight="50"
                    color="#6B9AC3"
                    className="tw-cursor-pointer"
                    style={{
                      marginLeft: '10px',
                    }}
                    onClick={handleClick}
                  >
                    {`  ${item.title}`}
                  </Text>
                </Row>
                <Row>
                  {/*  <Col lg="6" style={{ backgroundColor: 'red' }}>  */}
                  <Col>
                    {item.duration > 4 ? (
                      <Row>
                        <Text size="20" lineHeight="40" color="#6B9AC3">
                          {t('CRUISES_ITINERARY')}:{' '}
                          {item.route
                            .split('-')
                            .splice(0, 4)
                            .map(it => it.trim())
                            .join(' - ')}
                          ...
                          <Button
                            variant="link"
                            onClick={onHandleClickCurrency}
                          >
                            {t('CRUISES_SEE_MORE')}
                          </Button>
                        </Text>
                      </Row>
                    ) : (
                      <Row>
                        <Text size="20" lineHeight="40" color="#6B9AC3">
                          {`${t('CRUISES_ITINERARY')}: ${item.route}`}
                        </Text>
                      </Row>
                    )}
                    <Row>
                      <Text size="20" lineHeight="40" color="#6B9AC3">
                        {item.masterCruiseID}
                      </Text>
                    </Row>
                    <Row>
                      <Text size="21" lineHeight="40" color="#6B9AC3">
                        {`Departure: ${moment(item.departure).format(
                          dateFormatMoment,
                        )}`}
                      </Text>
                    </Row>
                  </Col>

                  <Col>
                    <Row xs="1" className="tw-gap-4">
                      <Col>
                        <button
                          type="button"
                          className="tw-flex tw-flex-col tw-border-0 tw-bg-transparent tw-px-0 tw-font-montserrat"
                          onClick={handleClick}
                        >
                          <div className="tw-text-2xl tw-font-semibold">
                            {t('CRUISES_FROM')}:{' '}
                            <span className="tw-text-green-500">
                              <PriceFormat
                                value={item.price.total}
                                currency={item.price.currency}
                                prefix={item.price.prefix}
                              />
                            </span>
                          </div>

                          <span className="tw-font-bold tw-text-green-500">
                            {t('JustGoPoints', {
                              count: item.price.justGoPoints,
                            })}
                          </span>
                        </button>
                      </Col>

                      <Col>
                        <Button size="lg" onClick={handleClick}>
                          Siguiente
                        </Button>
                      </Col>

                      <Col>
                        <button
                          type="button"
                          className="tw-border-0 tw-bg-transparent tw-px-0 tw-font-montserrat tw-text-xl tw-text-cyan-600"
                          onClick={handleClick}
                        >
                          {t('CRUISES_UP_MONTHS')}
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardHotelData>
        </Col>

        {/* )}  */}
      </WrapperHotel>
    </>
  );
};

const CardHotelData = styled(CardStyled).attrs(() => ({
  className: 'tw-h-full tw-max-h-full',
}))`
  &&&& {
    ${({ header }) => {
      if (header) {
        return css`
          @media (max-width: 991px) {
            /* background-color: yellow !important; */
            margin-top: 1rem !important;
          }

          border-radius: 8px 8px 0px 0px !important;
          overflow-y: hidden;

          &:before {
            content: '${header}';
            height: 45px;
            padding-top: 0.3rem;
            width: 100%;
            background: ${'#A1C3E1' && '#0c6590'};
            border-radius: 8px 8px 0px 0px;
            text-align: center;
            font: normal normal bold 25px/36px Raleway;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
          }
        `;
      }

      return '';
    }}
  }
`;

const WrapperHotel = styled(Row).attrs(() => ({
  className: 'd-flex align-items-stretch',
}))``;

export default CardHotel;
