import React, { forwardRef } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import useAppTranslation from '../hook/useAppTranslation';
import useInputFormHidden from '../hook/useInputFormHidden';
import { toDate, toString } from '../utils/date';

const CustomInput = forwardRef(function CustomInput(
  { dateFormat, value, timeFormat, ...props },
  ref,
) {
  const [startDate, endDate = '-'] = (value ?? '').split(/\s+-\s+/).map(it => {
    return toString(it, { in: 'MM/DD/YYYY', out: dateFormat });
  });

  return (
    <input
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={`${startDate} - ${endDate}`}
    />
  );
});

const DateRangePicker = ({
  name,
  className,
  disabled,
  startDate,
  endDate,
  minDate,
  dateFormat,
  showMonthYearPicker,
}) => {
  const { language } = useAppTranslation();
  const start = useInputFormHidden(startDate);
  const end = useInputFormHidden(endDate);

  const handleChange = ([newStartDate, newEndDate]) => {
    let mStart = newStartDate;
    let mEnd = newEndDate;

    if (showMonthYearPicker) {
      if (mStart !== null && moment(mStart).isValid()) {
        mStart = moment(mStart).startOf('month');
      }

      if (mEnd !== null && moment(mEnd).isValid()) {
        mEnd = moment(mEnd).endOf('month');
      }
    }

    start.handleChange(toString(mStart));
    end.handleChange(toString(mEnd));
  };

  const handleBlur = () => {
    start.handleBlur();
    end.handleBlur();
  };

  return (
    <DatePicker
      name={name}
      id={name}
      className={className}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      minDate={minDate}
      startDate={toDate(start.value)}
      endDate={toDate(end.value)}
      selectsRange
      showMonthYearPicker={showMonthYearPicker}
      locale={language}
      monthClassName={() => 'tw-capitalize'}
      customInput={<CustomInput dateFormat={dateFormat} timeFormat="HH:mm" />}
    />
  );
};

DateRangePicker.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  startDate: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  endDate: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  minDate: PropTypes.instanceOf(Date),
  showMonthYearPicker: PropTypes.bool,
};

DateRangePicker.defaultProps = {
  disabled: false,
  minDate: undefined,
  showMonthYearPicker: false,
};

export default DateRangePicker;
