import React from 'react';

import { Card, Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import formatNumber from '../utils/formatNumber';

const ReservaCardTopWhiteEvent = ({ t, item }) => {
  return (
    <Card className="tw-shadow-none">
      <Card.Body>
        <Row>
          <Col xs="12" lg="12">
            <h5 className="tw-text-info">{t('YourReservationDetail')}</h5>
            <hr className="tw-bg-info" />
            <h6>{item.experience_name}</h6>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="6" lg="7">
            <label className="tw-lowercase">{t('precioUnitario')}</label>
          </Col>
          <Col xs="6" lg="5">
            <label>{formatNumber(item.unitPrice)} USD </label>
          </Col>
          <Col xs="6" lg="7">
            <label className="tw-lowercase">
              {item.nTickets} {t('FlightTickets', { count: item.nTickets })}
            </label>
          </Col>
          <Col xs="6" lg="5">
            <label>{formatNumber(item.total)} USD </label>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="6" lg="7">
            <h6 className="tw-text-success">{t('Total')}</h6>
          </Col>
          <Col xs="6" lg="5">
            <h6 className="tw-text-success">{formatNumber(item.total)} USD</h6>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default withTranslation()(ReservaCardTopWhiteEvent);
