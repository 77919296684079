import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { BsHouseFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const PromoCondos = ({ t }) => {
  return (
    <Row className="tw-justify-center">
      <Col xs="12" lg="10">
        <h2 className="ft-2-5rem line-before tw-text-6xl tw-font-light">
          {t('ViveMejorExperiencia')} <strong>{t('RentaCondominios')}</strong>
        </h2>
        <Link
          className="btn btn-primary btn-block tw-mt-4 tw-font-bold tw-uppercase"
          to="/condos"
        >
          <BsHouseFill className="tw-mb-1" /> {t('VerSemanasDisponibles')}
        </Link>
      </Col>
    </Row>
  );
};

export default withTranslation()(PromoCondos);
