import React from 'react';

import PropTypes from 'prop-types';
import './Counter.module.scss';

const Counter = ({ name, value, inc, min, max, onChange }) => {
  const disabledDec = value <= min;
  const disabledInc = value >= max;

  return (
    <div className="tw-relative tw-flex tw-h-10 tw-w-full tw-flex-row tw-rounded-lg tw-border tw-bg-transparent tw-p-1 focus-within:tw-border-primary">
      <button
        type="button"
        onClick={() => onChange(value - inc)}
        data-action="decrement"
        className={`tw-flex tw-aspect-square tw-w-20 tw-items-center tw-justify-center tw-rounded-l tw-border tw-bg-white tw-text-primary tw-outline-none ${
          disabledDec
            ? 'tw-cursor-not-allowed tw-opacity-50 tw-grayscale'
            : 'tw-cursor-pointer hover:tw-border-primary hover:tw-bg-primary hover:tw-text-white'
        }`}
        disabled={disabledDec}
      >
        <span className="tw-text-2xl tw-font-thin">−</span>
      </button>

      <input
        type="number"
        className="tw-flex tw-w-full tw-cursor-default tw-items-center tw-border-0 tw-text-center tw-text-base tw-font-semibold tw-text-gray-700 tw-outline-none md:tw-text-base"
        name={name}
        value={value}
        disabled
      />

      <button
        type="button"
        onClick={() => onChange(value + inc)}
        data-action="increment"
        className={`tw-flex tw-aspect-square tw-w-20 tw-items-center tw-justify-center tw-rounded-r tw-border tw-bg-white tw-text-primary tw-outline-none ${
          disabledInc
            ? 'tw-cursor-not-allowed tw-opacity-50 tw-grayscale'
            : 'tw-cursor-pointer hover:tw-border-primary hover:tw-bg-primary hover:tw-text-white'
        }`}
        disabled={disabledInc}
      >
        <span className="tw-text-2xl tw-font-thin">+</span>
      </button>
    </div>
  );
};

Counter.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  inc: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
};

Counter.defaultProps = {
  inc: 1,
  min: 1,
  max: 100,
  onChange: null,
};

export default Counter;
