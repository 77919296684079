import { withTranslation } from 'react-i18next';

function ErrorSunApi({ t, error }) {
  if (error.typeError === 'DATA') {
    return `${t('SunAPiDATA')}`;
  }
  if (error.typeError === 'CONNECTION') {
    return `${t('SunAPiCONNECTION')}`;
  }
  if (error.typeError === null && error.type === 'DATA') {
    return `${t('SunAPiDATE')}`;
  }
  return `${t('SunAPiError')}`;
}

export default withTranslation()(ErrorSunApi);
