import React, { useEffect } from 'react';

import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import SectionPromotionNY from '../../components/SectionPromotionNY';
import MainContainer from '../../shared/MainContainer';
import PriceBottom from '../../shared/PriceBottom';
import { scrollUp } from '../../utils/scroll';
import GroupTravelRequests from './GroupTravelRequests';

const Home = () => {
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  useEffect(() => {
    scrollUp();
  }, [pathname]);

  return (
    <MainContainer>
      <Switch>
        <Route exact path={path} component={GroupTravelRequests} />
      </Switch>

      <SectionPromotionNY />
      <PriceBottom />
    </MainContainer>
  );
};

export default Home;
