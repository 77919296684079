import { createGlobalStyle, css } from 'styled-components';
import a from './components/a';
import alert from './components/alert';
import asyncSelect from './components/asyncSelect';
import buttons from './components/buttons';
import checkbox from './components/checkbox';
import containers from './components/containers';
import dropdown from './components/dropdown';
import formCheck from './components/formCheck';
import inputRange from './components/inputRange';
import nav from './components/nav';
import select from './components/select';
import slider from './components/slider';

const GlobalStyles = createGlobalStyle`
  :root {
    ${({ theme }) => {
      const { background, buttons: btns } = theme;

      return css`
        --background-header: #${background.header};
        --background-header-translucent: #${background.header}${background.translucent};
        --background-primary: #${background.primary};
        --background-primary-translucent: #${background.primary}${background.translucent};
        --background-secondary: #${background.secondary};
        --background-secondary-translucent: #${background.secondary}${background.translucent};
        --background-footer: #${background.footer};
        --background-footer-translucent: #${background.footer}${background.translucent};
        --color-text: #${theme.text};
        --button-primary: #${btns.primary.default};
        --button-primary-translucent: #${btns.primary.default}${btns.primary
            .translucent};
        --button-primary-active: #${btns.primary.active};
        --button-primary-shadow: #${btns.primary.shadow};
      `;
    }}
  }

  .tw-text-primary {
    color: var(--button-primary) !important;
  }

  .react-datepicker-wrapper {
    margin-top: 3px;
  }

  .SingleDatePickerInput {
    margin-top: 7.93px;
  }

  ${a}
  ${alert}
  ${asyncSelect}
  ${buttons}
  ${checkbox}
  ${containers}
  ${dropdown}
  ${formCheck}
  ${inputRange}
  ${nav}
  ${select}
  ${slider}
`;

export default GlobalStyles;
