import React from 'react';

import { Card, Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { AiOutlinePlus } from 'react-icons/ai/index';
import MapSidebar from '../MapSidebar';

const CondoAmenities = ({
  t,
  amenities,
  positions,
  detail,
  items,
  position,
  setPosition,
  center,
  setCenter,
}) => {
  return (
    <Card>
      <Card.Header>{t('condoDescripcion')}</Card.Header>
      <Card.Body>
        <Row>
          <Col xs="12" lg="6">
            <Row>
              <Col xs="12" lg="12">
                <div className="tw-text-justify">{detail}</div>
                <br />
              </Col>
            </Row>

            <Row>
              <Col xs="12" lg="12">
                <h2>{t('Comodidades')}</h2>
              </Col>
            </Row>
            <Row>
              <br />
              {amenities.map((am, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Col xs="6" lg="6" key={i}>
                  <AiOutlinePlus />
                  {am.name}
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs="12" lg="6">
            <MapSidebar
              containerHeight="100%"
              items={items}
              position={position}
              setPosition={setPosition}
              positionsList={positions}
              center={center}
              setCenter={setCenter}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default withTranslation()(CondoAmenities);
