import React from 'react';

// import moment from 'moment';
import { withTranslation } from 'react-i18next';
import calcTotalHuespedes from './calcTotalHuespedes';

// eslint-disable-next-line no-unused-vars
const DetalleReserva = ({ t, rooms, fechaStar, fechaEnd }) => {
  // const f1 = moment(fechaStar, 'DD-MM-YYYY');
  // const f2 = moment(fechaEnd, 'DD-MM-YYYY');
  // const night = moment.duration(f2.diff(f1)).asDays();
  const hab = rooms.length;
  const adult = calcTotalHuespedes(rooms, 'adult');
  const child = calcTotalHuespedes(rooms, 'child');
  return (
    <span className="tw-lowercase">
      {`7 ${t('Noches')}, ${hab} ${
        hab > 1 ? t('HotelsRooms') : t('HotelsRooms_one')
      }, ${adult + child} ${
        adult + child > 1 ? t('HotelsTravelers') : t('HotelsTravelers_one')
      }`}
    </span>
  );
};

export default withTranslation()(DetalleReserva);
