import React, { useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ReservacionConcluida from '../components/ReservacionConcluida';
import SectionTop from '../components/SectionTop';
import CondoMap from '../components/condos/CondoMap';
import CondoReservaInformacion from '../components/condos/CondoReservaInformacion';
import CondosDatosReserva from '../components/condos/CondosDatosReserva';
import CondosReservaDatosMonetarios from '../components/condos/CondosReservaDatosMonetarios';
import '../css/condos/CondosStyles.css';
import useQuery from '../hook/useQuery';
import CargandoOpciones from '../loader/CargandoOpciones';
import Voucher from './Voucher';

const CondoReserva = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const { path } = useRouteMatch();
  const [loading] = useState(false);
  const [infoReserva, setInfoReserva] = useState(null);
  const condoInfo = JSON.parse(localStorage.getItem('condo'));
  const searching = JSON.parse(query.get('searching'));
  // eslint-disable-next-line camelcase
  const { destino_name: destination } = searching || '--';
  const {
    name,
    address: { city },
  } = condoInfo;

  return (
    <Switch>
      <Route exact path={path}>
        <SectionTop
          menu={t('Condominios')}
          destino={destination}
          nombre={name}
          direccion={city}
        />
        {loading ? (
          <CargandoOpciones />
        ) : (
          <Container fluid className="content mt-10rem ft-10rem">
            <Row>
              <Col xs="12" lg="3" className="section-container">
                <CondosDatosReserva condoInfo={condoInfo} />
                <CondosReservaDatosMonetarios condoInfo={condoInfo} />
                <CondoMap condoInfo={condoInfo} />
              </Col>
              <Col xs="12" lg="9">
                <CondoReservaInformacion
                  condoInfo={condoInfo}
                  setInfoReserva={setInfoReserva}
                />
              </Col>
            </Row>
          </Container>
        )}
      </Route>
      <Route path={`${path}/voucher`}>
        {condoInfo ? (
          <Voucher infoReserva={infoReserva} />
        ) : (
          <ReservacionConcluida />
        )}
      </Route>
    </Switch>
  );
};

export default CondoReserva;
