import React from 'react';

import ReactAsyncSelect from 'react-select/async';
import { useComponents } from './Select';

const AsyncSelect = ({
  className,
  styles,
  groupHeading,
  defaultValue,
  defaultOptions,
  value,
  onChange,
  onBlur,
  placeholder,
  loadOptions,
  isClearable,
  openMenuOnClick,
  isMulti,
  isValid,
  isInvalid,
  name,
  isDisabled,
}) => {
  const componentsWithClassNames = useComponents({ isValid, isInvalid });

  return (
    <ReactAsyncSelect
      name={name}
      className={className}
      styles={styles}
      groupHeading={groupHeading}
      defaultValue={defaultValue}
      defaultOptions={defaultOptions}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      components={componentsWithClassNames}
      placeholder={placeholder}
      loadOptions={loadOptions}
      isClearable={isClearable}
      openMenuOnClick={openMenuOnClick}
      isMulti={isMulti}
      isDisabled={isDisabled}
    />
  );
};

export default AsyncSelect;
