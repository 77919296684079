import React from 'react';

import moment from 'moment';
import { Form } from 'react-bootstrap';
import useAppField from '../../hook/useAppField';
import useAppTranslation from '../../hook/useAppTranslation';
import DateRangePicker from '../../shared/DateRangePicker';
import * as formikUtils from '../../utils/formik';

const FiltersDateRange = ({ disabled = false }) => {
  const { t, dateFormatMoment } = useAppTranslation();
  const startDate = useAppField('startDate');
  const endDate = useAppField('endDate');

  const error =
    formikUtils.getError(startDate.meta) ?? formikUtils.getError(endDate.meta);

  const isValid =
    formikUtils.isValid(startDate.meta) && formikUtils.isValid(endDate.meta);
  const isInvalid =
    formikUtils.isInvalid(startDate.meta) ||
    formikUtils.isInvalid(endDate.meta);

  return (
    <Form.Group controlId="date-range">
      <Form.Label className="tw-text-white">
        {t('Salida')} / {t('Regreso')}
      </Form.Label>

      <Form.Control
        as={DateRangePicker}
        name="date-range"
        disabled={disabled}
        minDate={moment().add(1, 'day').toDate()}
        startDate={startDate.field}
        endDate={endDate.field}
        dateFormat={dateFormatMoment}
        isValid={isValid}
        isInvalid={isInvalid}
      />

      {isInvalid && (
        <Form.Control.Feedback type="invalid">{t(error)}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default FiltersDateRange;
