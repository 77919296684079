import React, { Children, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import { compose, withProps } from 'recompose';

const { REACT_APP_GOOGLE_API_KEY } = process.env;
const calcCenter = ({ min = 0, max = 0 }) => (min + max) / 2;
const getPosition = ({ latLng }) => {
  return {
    lat: latLng.lat(),
    lng: latLng.lng(),
  };
};

const MapComponent = ({ markers, infoWindow, defaultZoom, defaultCenter }) => {
  const [position, setPosition] = useState(null);

  const center = useMemo(() => {
    if (!markers?.length) return null;

    const { latitude, longitude } = markers.reduce(
      (acc, marker) => {
        const lat = Number(marker.lat);
        const lng = Number(marker.lng);

        acc.latitude.min = Math.min(acc.latitude.min ?? lat, lat);
        acc.latitude.max = Math.max(acc.latitude.max ?? lat, lat);

        acc.longitude.min = Math.min(acc.longitude.min ?? lng, lng);
        acc.longitude.max = Math.max(acc.longitude.max ?? lng, lng);

        return acc;
      },
      {
        latitude: {},
        longitude: {},
      },
    );

    return {
      lat: calcCenter(latitude),
      lng: calcCenter(longitude),
    };
  }, [markers]);

  return (
    <GoogleMap
      defaultZoom={defaultZoom ?? 11}
      zoom={defaultZoom ?? 11}
      defaultCenter={defaultCenter ?? center}
      center={defaultCenter ?? center}
    >
      {markers
        .map((marker, index) => {
          const lat = Number(marker?.lat);
          const lng = Number(marker?.lng);
          const children = marker.children ?? marker.infoWindow;
          const show =
            children &&
            ((position?.lat === lat && position?.lng === lng) ||
              (position === null && marker.show));

          if (Number.isNaN(lat) || Number.isNaN(lng)) return null;

          return (
            <Marker
              key={`${lat},${lng}-${index}`}
              position={{ lat, lng }}
              onClick={evt => {
                if (infoWindow.showOnClick) {
                  let newPosition = getPosition(evt);
                  if (
                    position &&
                    newPosition.lat === position.lat &&
                    newPosition.lng === position.lng
                  )
                    newPosition = null;

                  setPosition(newPosition);
                }

                if (marker.onClick) {
                  marker.onClick(marker);
                }
              }}
              onMouseOver={evt => {
                if (infoWindow.showOnMouseHover) {
                  setPosition(getPosition(evt));
                }
              }}
              onMouseOut={() => {
                if (infoWindow.hideOnMouseOut) {
                  setPosition(null);
                }
              }}
            >
              {Boolean(show) && (
                <InfoWindow
                  onCloseClick={() => {
                    setPosition(null);
                  }}
                >
                  <div style={{ width: '14rem' }}>{children}</div>
                </InfoWindow>
              )}
            </Marker>
          );
        })
        .filter(Boolean)}
    </GoogleMap>
  );
};

const MapWithCompose = compose(
  withProps(props => {
    const markers = Children.map(
      props?.children,
      // eslint-disable-next-line no-unused-vars
      ({ props: propsMarker, type }) => {
        // const name = type?.name ?? type?.render?.name;
        // if (name !== 'MapMarker') return null;
        // console.log('🚀 ~ Map.jsx', { name });

        return propsMarker;
      },
    );

    markers.push(
      ...(props?.markers
        ? Array.isArray(props.markers)
          ? props.markers
          : [props.markers]
        : []),
    );

    if (props?.marker) {
      markers.push(props.marker);
    }

    return {
      googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: props?.height ?? '600px' }} />,
      mapElement: <div style={{ height: `100%` }} />,
      markers: markers.filter(Boolean),
      infoWindow: {
        showOnClick: props?.infoWindow?.showOnClick ?? false,
        showOnMouseHover: props?.infoWindow?.showOnMouseHover ?? true,
        hideOnMouseOut: props?.infoWindow?.hideOnMouseOut ?? true,
      },
    };
  }),

  withScriptjs,
  withGoogleMap,
)(MapComponent);

const marker = PropTypes.shape({
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  infoWindow: PropTypes.element.isRequired,
  onClick: PropTypes.func,
});

MapWithCompose.propTypes = {
  marker,
  markers: PropTypes.arrayOf(marker),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  infoWindow: PropTypes.shape({
    showOnClick: PropTypes.bool,
    showOnMouseHover: PropTypes.bool,
    hideOnMouseOut: PropTypes.bool,
  }),
  defaultZoom: PropTypes.number,
  defaultCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
};

export default MapWithCompose;
