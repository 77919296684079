import { toast } from 'react-toastify';
import { useShallow } from 'zustand/react/shallow';
import useMessageZustand from '../store/message';

const useMessage = () => {
  const { add, clear } = useMessageZustand(useShallow(state => state));

  return {
    info: message => {
      add({ message, type: 'info' });
      toast.info(message, {
        onClose: () => {
          clear();
        },
      });
    },
    success: message => {
      add({ message, type: 'success' });
      toast.success(message, {
        onClose: () => {
          clear();
        },
      });
    },
    warn: message => {
      add({ message, type: 'warning' });
      toast.warn(message, {
        onClose: () => {
          clear();
        },
      });
    },
    error: message => {
      add({ message, type: 'error' });
      toast.error(message, {
        onClose: () => {
          clear();
        },
      });
    },

    open: message => {
      add({ message, type: 'default' });
      toast(message, {
        onClose: () => {
          clear();
        },
      });
    },
  };
};

export default useMessage;
