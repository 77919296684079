import React from 'react';

import { Card, Col, Row } from 'react-bootstrap';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Rating from '../shared/Rating';
import CarouselTopDetail from './CarouselTopDetail';

const ReservaTop = ({
  hotelPhotos,
  hotelName,
  hotel_stars: hotelStars,
  hotel_direccion: hotelDireccion,
}) => {
  return (
    <Row>
      <Col xs="12" lg="12">
        <Card className="tw-mb-3 tw-shadow">
          <Card.Body className="tw-py-1">
            <Row>
              {hotelPhotos.length > 0 && (
                <Col xs="12" lg="3">
                  <CarouselTopDetail
                    photos={hotelPhotos}
                    numberCards={1}
                    imgHeight={180}
                  />
                </Col>
              )}

              <Col xs="12" lg={hotelPhotos.length > 0 ? '9' : '12'}>
                <h2>{hotelName}</h2>
                {hotelStars && <Rating rating={hotelStars} />}
                <p className="tw-text-secondary">
                  <FaMapMarkerAlt className="icon-20 tw-mb-1 tw-mr-2" />
                  {hotelDireccion}
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ReservaTop;
