import React from 'react';

import DatePicker from 'react-datepicker';
import useAppTranslation from '../hook/useAppTranslation';
import useInputFormHidden from '../hook/useInputFormHidden';
import { toDate, toString } from '../utils/date';

const TimePicker = ({ name, value, onChange, onBlur, className, disabled }) => {
  const { language, timeFormat } = useAppTranslation();
  const time = useInputFormHidden({ name, value, onChange, onBlur });

  const handleChange = newTime => {
    time.handleChange(toString(newTime));
  };

  const handleBlur = () => time.handleBlur();

  return (
    <DatePicker
      name={name}
      id={name}
      className={className}
      selected={toDate(value)}
      onChange={handleChange}
      onBlur={handleBlur}
      dateFormat={timeFormat}
      timeFormat={timeFormat}
      showTimeSelect
      showTimeSelectOnly
      disabled={disabled}
      locale={language}
    />
  );
};

TimePicker.propTypes = {};

TimePicker.defaultProps = {};

export default TimePicker;
