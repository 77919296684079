import React from 'react';

import CardItineraryBody from './CardItineraryBody';
import CardItineraryHeader from './CardItineraryHeader';

const CardItinerary = ({ isDeparture = false, itinerary }) => {
  return (
    !!itinerary && (
      <>
        <CardItineraryHeader isDeparture={isDeparture} itinerary={itinerary} />
        <CardItineraryBody itinerary={itinerary} />
      </>
    )
  );
};

export default CardItinerary;
