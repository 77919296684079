/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

const ReservaInformacionExp = ({
  t,
  onHandleClickReservacion,
  setClient,
  payment,
  setPayment,
  textoError,
  checkReserva,
  setCheckReserva,
  checkRJustG,
  setCheckRJustG,
}) => {
  useEffect(() => {
    if (textoError.length > 0) {
      window.scrollTo(0, 150);
    }
  }, [textoError]);

  const typeClient = (value, key) => {
    // console.log(value)
    if (key === 'name') {
      setClient(prevState => ({
        ...prevState,
        name: value,
      }));
    } else if (key === 'sname') {
      setClient(prevState => ({
        ...prevState,
        sName: value,
      }));
    } else if (key === 'lname') {
      setClient(prevState => ({
        ...prevState,
        lName: value,
      }));
    } else if (key === 'telClient') {
      setClient(prevState => ({
        ...prevState,
        telClient: value,
      }));
    } else if (key === 'eClient') {
      setClient(prevState => ({
        ...prevState,
        eClient: value,
      }));
    }
  };

  const typeCard = (value, key) => {
    if (key === 'titular') {
      // para donde va el titular
    } else if (key === 'type_card') {
      setPayment(previous => ({
        ...previous,
        paymentType: value,
      }));
    } else if (key === 'no_card') {
      const objCreditCard = payment.creditCard;
      objCreditCard.number = value;
      setPayment(previous => ({
        ...previous,
        creditCard: objCreditCard,
      }));
    } else if (key === 'expireMonth') {
      const objCreditCard = payment.creditCard;
      objCreditCard.expireMonth = value;
      setPayment(previous => ({
        ...previous,
        creditCard: objCreditCard,
      }));
    } else if (key === 'expireYear') {
      const objCreditCard = payment.creditCard;
      objCreditCard.expireYear = value;
      setPayment(previous => ({
        ...previous,
        creditCard: objCreditCard,
      }));
    }
  };

  const [dropInfo, setDropInfo] = useState(false);
  const [dropInfoPay, setDropInfoPay] = useState(true);

  return (
    <Card className="tw-shadow">
      <Card.Body>
        <Row className="tw-mb-3">
          <Col xs="12" lg="12">
            <h1>{t('EnterYourData')}</h1>
            <small className="tw-block tw-text-secondary">
              {t('IntroduceDatosParrafoGlobal')}
            </small>
          </Col>
        </Row>
        {textoError.length > 0 && (
          <Alert variant="danger" className="tw-py-2">
            <ul className="tw-mb-0">
              {textoError.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i}>{item}</li>
              ))}
            </ul>
          </Alert>
        )}
        <Row>
          <Col xs="12" lg="12">
            <Accordion defaultActiveKey="0">
              <Card className="bg-light card-reserva tw-border-0 tw-shadow-none">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  onClick={() => setDropInfo(!dropInfo)}
                >
                  <h6 className="tw-cursor-pointer">
                    {t('PrimaryInformation')}{' '}
                    {dropInfo ? <FaCaretDown /> : <FaCaretUp />}
                  </h6>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Row>
                      <Col xs="12" lg="4">
                        <Form.Group>
                          <Form.Label>{t('Nombre')}*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t('NombrePlaceholder')}
                            onChange={e => typeClient(e.target.value, 'name')}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs="12" lg="4">
                        <Form.Group>
                          <Form.Label>{t('SegundoNombre')}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t('SegundoNombrePlaceholder')}
                            onChange={e => typeClient(e.target.value, 'sname')}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs="12" lg="4">
                        <Form.Group>
                          <Form.Label>{t('Apellidos')}*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t('ApellidosPlaceholder')}
                            onChange={e => typeClient(e.target.value, 'lname')}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" lg="4">
                        <Form.Group>
                          <Form.Label>{t('CorreoElectronico')}*</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder={t('CorreoElectronicoPlaceholder')}
                            onChange={e =>
                              typeClient(e.target.value, 'eClient')
                            }
                          />
                          <Form.Text className="tw-text-gray-500">
                            {t('CorreoElectronicoParrafo')}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col xs="12" lg="4">
                        <Form.Group>
                          <Form.Label>{t('Telefono')}*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t('TelefonoPlaceholder')}
                            onChange={e =>
                              typeClient(e.target.value, 'telClient')
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
          <Col xs="12" lg="12">
            <Accordion>
              <Card className="bg-light card-reserva tw-border-0 tw-shadow-none">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  onClick={() => setDropInfoPay(!dropInfoPay)}
                >
                  <h6 className="tw-cursor-pointer">
                    {t('PaymentInformation')}{' '}
                    {dropInfoPay ? <FaCaretDown /> : <FaCaretUp />}
                  </h6>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Row>
                      <Col xs="12" lg="12">
                        <Alert variant="info">
                          {t('HotelsAccommodationRequires')}
                        </Alert>
                      </Col>
                      <Col xs="12" lg="6">
                        <Form.Group>
                          <Form.Label>{t('PaymentNameOnCard')}*</Form.Label>
                          <Form.Control
                            type="text"
                            onChange={e => typeCard(e.target.value, 'titular')}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs="12" lg="4">
                        <Form.Group>
                          <Form.Label>{t('PaymentCardType')}*</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={e =>
                              typeCard(e.target.value, 'type_card')
                            }
                          >
                            <option value="CREDIT_CARD">Crédito</option>
                            <option value="DEBIT_CARD">Débito</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" lg="6">
                        <Form.Group>
                          <Form.Label>
                            {t('PaymentCreditCardNumber')}*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            onChange={e => typeCard(e.target.value, 'no_card')}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs="12" lg="2">
                        <Form.Group>
                          <Form.Label>{t('Month')}*</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={e =>
                              typeCard(e.target.value, 'expireMonth')
                            }
                          >
                            {moment.months().map((nameMonth, index) => {
                              return (
                                <option
                                  key={index}
                                  value={`${index + 1}`.padStart(2, '0')}
                                >
                                  {nameMonth}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs="12" lg="2">
                        <Form.Group>
                          <Form.Label>{t('Year')}*</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={e =>
                              typeCard(e.target.value, 'expireYear')
                            }
                          >
                            {Array.from({ length: 10 })
                              .fill(moment().year())
                              .map((current, index) => {
                                const value = current + index;
                                return (
                                  <option key={value} value={`${value}`}>
                                    {value}
                                  </option>
                                );
                              })}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs="12" lg="2">
                        <Form.Group>
                          <Form.Label>{t('PaymentCardSecureCode')}*</Form.Label>
                          <Form.Control type="number" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" lg="12">
                        <Form.Check
                          type="checkbox"
                          id="check-1"
                          label={t('Politica1ReservaGlobal')}
                          className="tw-text-secondary"
                          checked={checkReserva}
                          onChange={e => setCheckReserva(e.target.checked)}
                        />
                        <Form.Check
                          type="checkbox"
                          id="check-2"
                          label={t('Politica2ReservaGlobal')}
                          className="tw-text-secondary"
                          checked={checkRJustG}
                          onChange={e => setCheckRJustG(e.target.checked)}
                        />
                      </Col>
                      <Col xs="12" lg="3">
                        <Button
                          variant="outline-primary"
                          className="tw-mt-4 tw-font-bold"
                          onClick={onHandleClickReservacion}
                        >
                          {t('CompleteReservation')}
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default withTranslation()(ReservaInformacionExp);
