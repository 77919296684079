import React, { useEffect, useState } from 'react';

import Footer from '../components/Footer';
import HeaderNav from '../components/HeaderNav';
import Loader from '../components/Loader';
import SectionPromotionNY from '../components/SectionPromotionNY';
import PriceBottom from '../shared/PriceBottom';

const Layout = ({ children }) => {
  const [navHeight, setNavHeight] = useState(0);

  useEffect(() => {
    const nav = window.document.querySelector('nav.navbar-home');

    if (nav) {
      const handleResize = () => {
        setNavHeight(nav.clientHeight);
      };

      handleResize();
      new ResizeObserver(handleResize).observe(nav);
    }
  }, []);

  return (
    <div className="tw-relative tw-h-screen tw-w-screen">
      <Loader />

      <HeaderNav />

      <main
        id="main"
        className="tw-absolute tw-bottom-0 tw-flex tw-w-screen tw-flex-col tw-overflow-y-scroll"
        style={{
          height: `calc(100vh - ${navHeight}px)`,
        }}
      >
        <section className="tw-flex-1">{children}</section>

        <SectionPromotionNY />
        <PriceBottom />
        <Footer />
      </main>
    </div>
  );
};

export default Layout;
