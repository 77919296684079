import React, { useEffect, useMemo, useState } from 'react';

import { Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { scrollUp } from '../../utils/scroll';
import CardFlightResume from './CardFlightResume';
import ListCardFlight from './ListCardFlight';

const StepperFlights = ({ flights, oneWay }) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [departingState, setDepartingState] = useState({
    currentPage: 1,
    isValid: false,
  });
  const [returningState, setReturningState] = useState({
    currentPage: 1,
    isValid: false,
  });

  useEffect(() => {
    scrollUp();
  }, [step, departingState.currentPage, returningState.currentPage]);

  const handleBackClick = () => {
    if (oneWay && step === 2) setStep(0);
    else setStep(step - 1);
  };

  const handleNextClick = () => {
    if (oneWay && step === 0) setStep(2);
    else setStep(step + 1);
  };

  const controller = isValid => {
    return (
      <div
        style={{ width: '100%', justifyContent: 'end', display: 'flex' }}
        className="tw-float-right"
      >
        <Button
          variant="outline-primary"
          style={{ marginRight: '1rem' }}
          disabled={step === 0}
          onClick={handleBackClick}
        >
          {t('Anterior')}
        </Button>

        <Button
          variant="outline-primary"
          disabled={!isValid || step === 2}
          onClick={handleNextClick}
        >
          {t('Siguiente')}
        </Button>
      </div>
    );
  };

  const itinerariesDeparting = useMemo(() => {
    return flights.map(({ departing }) => departing);
  }, [flights]);

  const pane = values => {
    const flight =
      values.departing &&
      flights.find(
        it =>
          it.departing.id === values.departing &&
          (!it.returning || it.returning.id === values.returning),
      );

    switch (step) {
      case 0: {
        return (
          <>
            <ListCardFlight
              name="departing"
              itineraries={itinerariesDeparting}
              state={departingState}
              setState={setDepartingState}
            />
            {controller(!!values.departing)}
          </>
        );
      }

      case 1: {
        return (
          <>
            <ListCardFlight
              name="returning"
              itineraries={flights
                .filter(it => it.departing.id === values.departing)
                .map(it => it.returning)}
              state={returningState}
              setState={setReturningState}
            />
            {controller(!!values.returning)}
          </>
        );
      }

      case 2:
        return (
          <CardFlightResume flight={flight} onCancelClick={handleBackClick} />
        );

      default:
        return <>Empty</>;
    }
  };

  return (
    <Formik
      initialValues={{
        departing: null,
        returning: null,
      }}
      validationSchema={Yup.object().shape({
        departing: Yup.string(),
        returning: Yup.string(),
      })}
    >
      {({ values }) => pane(values)}
    </Formik>
  );
};

export default StepperFlights;
