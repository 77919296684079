import * as yup from 'yup';
import { payment } from './payment';

const whenRequiredForPrimaryContact = (schema, context) => {
  const index = parseInt(
    context?.path?.match?.(/^.+?\[(\d+)\].+$/)?.[1] ?? -1,
    10,
  );

  if (index !== 0) return schema.nullable();

  return schema.required();
};

export const filterSearchSchema = yup.object().shape({
  origin: yup.number().nullable().required('CampoRequerido'),
  destination: yup.number().nullable().required('CampoRequerido'),
  startDate: yup.string().required().isDateMomentValid().default(''),
  endDate: yup.string().required().isDateMomentValid().isDateBeforeAtStartDate({
    startDateName: 'startDate',
    message: 'ErrorFechaRegreso',
  }),
  persons: yup
    .object()
    .shape({
      adults: yup.number().required(),
      children: yup.array().of(yup.number()).required(),
    })
    .required(),
  oneWay: yup.boolean().required(),
});

export const checkoutSchema = yup.object().shape({
  paxesInformation: yup
    .array()
    .of(
      yup.object().shape({
        type: yup.string().oneOf(['ADULT', 'CHILD']).required(),
        age: yup.number().nullable(),
        name: yup.string().required().max(20),
        lastName: yup.string().required().max(20),
        email: yup.string().email().when([], whenRequiredForPrimaryContact),
        phone: yup
          .object()
          .shape({
            type: yup.string().oneOf(['HOME']).required(),
            number: yup.string().required(),
          })
          .when([], whenRequiredForPrimaryContact),
      }),
    )
    .min(1)
    .required(),

  payment: payment.clone(),
});
