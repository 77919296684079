import React from 'react';

import VoucherHotel from '../../components/Hotels/Voucher2';
import useAppParams from '../../hook/useAppParams';
// import ReservacionConcluida from '../../components/ReservacionConcluida';

const Voucher = () => {
  const { booking } = useAppParams();
  // console.log('🚀 ~ Voucher.jsx', { booking });

  if (!booking || !booking.infoReserva || !booking.checkout) return <div />;

  // return <ReservacionConcluida />;

  return (
    <VoucherHotel
      infoReserva={booking.infoReserva}
      checkout={booking.checkout}
    />
  );
};

export default Voucher;
