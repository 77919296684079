import React, { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { nanoid } from 'nanoid';
import { Alert, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import BookingCardTopExp from '../../components/Experiences/BookingCardTopExp';
import BookingCardTopWhiteExp from '../../components/Experiences/BookingCardTopWhiteExp';
import ReservaInformacionExp from '../../components/ReservaInformacionExp';
import ReservaTop from '../../components/ReservaTop';
import ReservacionConcluida from '../../components/ReservacionConcluida';
import SectionTop from '../../components/SectionTop';
import useAppParams from '../../hook/useAppParams';
import useAppTranslation from '../../hook/useAppTranslation';
import useAuth from '../../hook/useAuth';
import LoaderReservar from '../../loader/LoaderReservar';
import addPoints from '../../utils/addPoints';
import Voucher from '../Voucher';

const Booking = () => {
  const { path, url } = useRouteMatch();
  const { data: auth } = useAuth();
  const { t, language } = useAppTranslation();

  const history = useHistory();
  const { key } = useAppParams();
  const item = useMemo(() => {
    const data = JSON.parse(window.localStorage.getItem(key));
    if (!data) return data;

    data.checkIn = moment(data.checkIn);

    return data;
  }, [key]);
  const [payment, setPayment] = useState(null);
  const [textoError, setTextoError] = useState([]);
  const [infoReserva, setInfoReserva] = useState(null);
  const [sendindReserva, setSendingReserva] = useState(false);
  const [show, setShow] = useState(false);
  const [client, setClient] = useState({
    name: '',
    sName: '',
    lName: '',
    telClient: '',
    eClient: '',
  });
  const [checkReserva, setCheckReserva] = useState(true);
  const [checkRJustG, setCheckRJustG] = useState(true);

  const handleClose = () => setShow(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (item !== null) {
      const paymentData = {
        currency: 'USD',
        source: item?.apiName ?? '',
        amount: item?.rate?.total ?? 0,
        paymentType: 'CREDIT_CARD',
        creditCard: {
          number: '',
          expireMonth: '01',
          expireYear: '2020',
          type: {
            name: 'VI',
          },
        },
      };

      setPayment(paymentData);
    }
  }, [item]);

  const onHandleClickReservacion = () => {
    const reservaBooking = {
      currency: item.currency,
      total: Math.round(item.rate.total),
      source: item.apiName,
      reference: 'CMOM', // fijo
      company: 'JustGo', // fij
      client: {
        phone: {
          number: client.telClient,
          type: 'HOME', // fijo
        },
        name: `${client.name}${client.sName === '' ? '' : ` ${client.sName}`}`,
        email: client.eClient,
        lastname: client.lName,
      },
      activity: {
        id: item.id,
        source: item.apiName,
        name: item.name,
        checkin: item.checkIn,
        paxes: {
          adults: item.adults,
          children: item.children,
        },
      },
      payment,
    };

    // console.log(reservaBooking);

    // vemos si tiene error el formulario
    // 1.cliente principal
    const arr = [];
    if (
      reservaBooking.client.name === '' ||
      reservaBooking.client.lastname === '' ||
      reservaBooking.client.email === '' ||
      reservaBooking.client.phone.number === ''
    ) {
      const txt = t('ValidacionHuespedPrincipal');
      arr.push(txt);
    }
    if (reservaBooking.payment.creditCard.number === '') {
      const txt = t('ValidacionPayment');
      arr.push(txt);
    }
    if (!checkReserva || !checkRJustG) {
      const txt = t('OkPolitica');
      arr.push(txt);
    }
    setTextoError(arr);

    if (arr.length === 0) {
      // console.log('ok');
      const dataVoucher = {
        client_name: `${reservaBooking.client.name} ${reservaBooking.client.lastname}`,
        client_phone: reservaBooking.client.phone.number,
        client_email: reservaBooking.client.email,
        place_name: reservaBooking.activity.name,
        reservationCode: nanoid(),
        checkin: reservaBooking.activity.checkin,
        checkout: null,
        paxes: reservaBooking.activity.paxes,
        currency: reservaBooking.currency,
        total: Math.round(reservaBooking.total),
      };
      // console.log({ dataVoucher, item });

      setInfoReserva(dataVoucher);

      addPoints({
        membership: auth.membership,
        describe: dataVoucher.place_name,
        points: Math.round(dataVoucher.total),
      });
      setSendingReserva(true);
      setTimeout(() => {
        setSendingReserva(false);
        history.push(`${url}/voucher`);
      }, 3000);
    }
  };

  return (
    <Switch>
      <Route exact path={path}>
        {item && (
          <>
            {sendindReserva && <LoaderReservar />}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton />
              <Modal.Body>
                <Row>
                  <Col xs="12" lg="12">
                    <h6>{t('LoSentimos')}!!!</h6>
                    <label>{t('NoEfectuarReservación')}</label>
                  </Col>
                </Row>
                {infoReserva && infoReserva.httpStatusCode && (
                  <Row>
                    <Col xs="12" lg="12">
                      <Alert variant="outline-primary">
                        {`${infoReserva.httpStatusCode}`}
                        <br />
                        {infoReserva.message}
                      </Alert>
                    </Col>
                  </Row>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={handleClose}>
                  {t('Aceptar')}
                </Button>
              </Modal.Footer>
            </Modal>

            <SectionTop
              menu={t('EXPERIENCES')}
              destino={item?.destino_name?.[language] ?? ''}
              nombre={t('DatosReserva')}
              direccion={item.name}
            />

            <Container fluid>
              <Row xs="1" className="tw-my-3">
                <Col lg="3">
                  <Row xs="1" className="tw-justify-center">
                    <Col>
                      <BookingCardTopExp item={item} />
                    </Col>
                    <Col>
                      <BookingCardTopWhiteExp item={item} />
                    </Col>
                  </Row>
                </Col>

                <Col lg="9">
                  <ReservaTop
                    hotelPhotos={item.photos}
                    hotelName={item.name}
                    hotel_stars={null}
                    hotel_direccion={`${item.address.stateCode} ${item.address.city} ${item.address.country}`}
                  />

                  <ReservaInformacionExp
                    onHandleClickReservacion={onHandleClickReservacion}
                    payment={payment}
                    setPayment={setPayment}
                    client={client}
                    setClient={setClient}
                    textoError={textoError}
                    checkReserva={checkReserva}
                    setCheckReserva={setCheckReserva}
                    checkRJustG={checkRJustG}
                    setCheckRJustG={setCheckRJustG}
                  />
                </Col>
              </Row>
            </Container>
          </>
        )}
      </Route>

      <Route path={`${path}/voucher`}>
        {item ? (
          <Voucher infoReserva={infoReserva} />
        ) : (
          <ReservacionConcluida />
        )}
      </Route>
    </Switch>
  );
};

export default Booking;
