import moment from 'moment';
import { prepareConvertCurrency } from '../utils/currency';
import setApplyDiscount from '../utils/setApplyDiscount';
import { TOUR_DETAIL, TOUR_LIST } from './Routes';
import instance from './instance';

const sources = process.env.REACT_APP_SUNAPI_API_NAME_TOUR;
const LANGUAGE_DEFAULT = 'es';
const CURRENCY_DEFAULT = 'USD';

export const getExperiences = async ({
  destiny,
  checkIn,
  paxes,
  language = LANGUAGE_DEFAULT,
  currency = CURRENCY_DEFAULT,
}) => {
  const body = {
    destiny,
    checkIn: moment(checkIn).format('DD-MM-YYYY'),
    paxes,
    language,
    currency,
  };

  const request = instance.post(TOUR_LIST, body, { params: { sources } });
  const response = await request
    .then(async res => {
      const { data } = res;

      if (data?.errors?.length) throw data?.errors;

      const convertCurrency = await prepareConvertCurrency();
      const activities = data.activities
        .filter(it => it && it.name !== null)
        .map(it => {
          const minRates = {
            adult: convertCurrency({
              total: setApplyDiscount(it.minRates.adult, 0),
              currency: data.currency,
            }),
            child: convertCurrency({
              total: setApplyDiscount(it.minRates.child, 0),
              currency: data.currency,
            }),
          };

          return {
            ...it,
            minRates,
            price: convertCurrency({
              total: it.price,
              currency: data.currency,
            }),
            rating: parseFloat(it.rating),
          };
        });

      return {
        errors: [],
        activities,
      };
    })
    .catch(err => {
      // console.log('🚀 ~ experiences.js', { err });
      return {
        errors: Array.isArray(err) ? err : [err],
        activities: [],
      };
    });

  return response;
};

export const getExperience = async ({
  id,
  apiName,
  destiny,
  checkIn,
  paxes,
  language = LANGUAGE_DEFAULT,
  currency = CURRENCY_DEFAULT,
}) => {
  const body = {
    id,
    apiName,
    checkIn: moment(checkIn).format('DD-MM-YYYY'),
    destiny,
    paxes,
    language,
    currency,
  };

  const convertCurrency = await prepareConvertCurrency();
  const request = instance.post(TOUR_DETAIL, body);
  const response = await request
    .then(res => {
      const { data } = res;
      // console.log('🚀 ~ experiences.js', { data });

      data.rateServices = data.rateServices.map(itRateService => {
        const { rateDetail } = itRateService;
        rateDetail.paxAmounts = rateDetail.paxAmounts.map(
          ({ amount, ...itRateDetail }) => {
            const price = convertCurrency({
              total: setApplyDiscount(amount, 0),
              currency,
            });

            return { ...itRateDetail, price };
          },
        );

        return { ...itRateService, rateDetail };
      });

      return data;
    })
    .catch(err => {
      throw err;
    });

  return response;
};
