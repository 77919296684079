import React, { useMemo, useState } from 'react';

import { Button, Col, Container, Row } from 'react-bootstrap';
import { ImListNumbered } from 'react-icons/im';
import useAppTranslation from '../../hook/useAppTranslation';
import useAuth from '../../hook/useAuth';
import Rating from '../../shared/Rating';
import PriceFormat from '../PriceFormat';
import ButtonOtherPrices from './ButtonOtherPrices';
import ModalAmenities from './ModalAmenities';

const MAX_AMENITIES = 5;

const Amenities = ({ hotel, onClick }) => {
  const { t } = useAppTranslation();

  const amenitiesToShow = hotel.amenities
    .filter(it => it?.image?.length)
    .slice(0, MAX_AMENITIES)
    .map((amenity, index) => (
      <div
        key={`${amenity.name}-${index}`}
        className="tw-flex tw-items-center tw-gap-2 tw-p-1"
      >
        <img
          src={amenity.image}
          alt={amenity.description}
          className="tw-aspect-square tw-h-5 md:tw-h-4"
        />
        <span className="tw-hidden tw-text-black lg:tw-block">
          {amenity.name}
        </span>
      </div>
    ));

  if (hotel.amenities.length > amenitiesToShow.length) {
    amenitiesToShow.push(
      <div
        key={`all-${amenitiesToShow.length}`}
        role="button"
        aria-hidden="true"
        className="tw-flex tw-items-center tw-gap-2 tw-p-1 tw-text-primary hover:tw-underline"
        onClick={evt => {
          evt.stopPropagation();

          onClick();
        }}
      >
        <ImListNumbered className="tw-box-content tw-h-5 tw-rounded-lg tw-border tw-px-1 tw-shadow md:tw-hidden" />
        <span className="tw-hidden md:tw-block">
          {t('HotelsShowAllAmenities')}
        </span>
      </div>,
    );
  }

  return amenitiesToShow;
};

const CardHotel = ({
  hotel,
  query,
  onSeeMoreClick,
  // onPriceGuaranteeClick,
  // onMouseOver,
  // onMouseOut,
}) => {
  const { isDemo } = useAuth();
  const { t } = useAppTranslation();

  // eslint-disable-next-line no-unused-vars
  const [minOffer, setMinOffer] = useState(Number.POSITIVE_INFINITY);
  const [maxOffer, setMaxOffer] = useState(Number.NEGATIVE_INFINITY);
  const [showAmenities, setShowAmenities] = useState(false);

  const handleSeeMoreClick = () => {
    if (typeof onSeeMoreClick === 'function') {
      onSeeMoreClick(hotel);
    }
  };

  const handleAmenityClick = () => {
    setShowAmenities(true);
  };

  const savingsPercent = useMemo(
    () => Math.trunc((1 - hotel.total / maxOffer) * 100),

    [hotel, maxOffer],
  );

  const buttonOtherPrices = (
    <ButtonOtherPrices
      hotel={hotel}
      query={query}
      setMinOffer={setMinOffer}
      setMaxOffer={setMaxOffer}
    />
  );

  return (
    <>
      <ModalAmenities
        show={showAmenities}
        onHide={() => {
          setShowAmenities(false);
        }}
        amenities={hotel.amenities}
      />

      <Container className="tw-m-0 tw-min-h-[theme(spacing.28)] tw-rounded-t-xl tw-border tw-border-solid tw-border-zinc-200 tw-p-0 md:tw-rounded-l-xl md:tw-rounded-tr-none">
        <Row className="tw-m-0 tw-h-full tw-w-full tw-rounded-t-xl tw-p-0 md:tw-rounded-l-xl md:tw-rounded-tr-none">
          <Col
            xs="12"
            md="4"
            className="tw-relative tw-m-0 tw-rounded-t-xl tw-p-0 md:tw-h-full md:tw-w-full md:tw-rounded-l-xl md:tw-rounded-tr-none"
          >
            <img
              src={hotel.image}
              alt={hotel.name}
              className="tw-h-full tw-w-full tw-rounded-t-xl md:tw-rounded-l-xl md:tw-rounded-tr-none"
            />

            {maxOffer > 0 && (
              <span className="tw-absolute tw-bottom-0 tw-right-0 tw-mb-2 tw-mr-2 tw-flex tw-h-7 tw-w-24 tw-items-center tw-justify-center tw-rounded tw-bg-primary-500 tw-px-2 tw-py-1 tw-text-white md:tw-hidden">
                {savingsPercent}% OFF
              </span>
            )}
          </Col>

          <Col xs="6" md="5" className="tw-m-0 tw-p-0">
            <div
              role="button"
              aria-hidden="true"
              className="tw-m-0 tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-between tw-gap-2 tw-border-0 tw-bg-transparent tw-p-3 hover:tw-bg-gray-50"
              onClick={handleSeeMoreClick}
            >
              {/* Header */}
              <header className="tw-flex tw-justify-between tw-gap-2 lg:tw-gap-12">
                <div className="tw-flex tw-flex-1 tw-flex-col">
                  <span className="tw-text-lg tw-font-medium tw-text-black">
                    {hotel.name}
                  </span>

                  <span>
                    {hotel.address} {maxOffer}
                  </span>
                </div>

                {maxOffer > 0 && (
                  <span className="tw-hidden tw-h-7 tw-items-center tw-justify-center tw-rounded tw-bg-primary-500 tw-px-2 tw-py-1 tw-text-white md:tw-flex lg:tw-w-24">
                    {savingsPercent}% OFF
                  </span>
                )}
              </header>

              {/* Amenities */}
              <div className="tw-hidden tw-grid-cols-3 md:tw-grid">
                <Amenities hotel={hotel} onClick={handleAmenityClick} />
              </div>

              {/* Rating */}
              <footer>
                <Rating rating={hotel.stars} />
              </footer>
            </div>
          </Col>

          <Col
            xs="6"
            md="3"
            className="tw-flex tw-flex-col tw-border-y-0 tw-border-l tw-border-r-0 tw-border-solid tw-border-zinc-200 tw-p-3"
          >
            <header className="tw-border-x-0 tw-border-b-4 tw-border-t-0 tw-border-dotted tw-border-zinc-200 tw-pb-3">
              <div className="tw-flex tw-justify-between tw-text-base tw-font-normal tw-text-black">
                <span>
                  {t(isDemo ? 'HotelsPublicPrice' : 'HotelsSunsetMembers')}:
                </span>
                <span>
                  <PriceFormat
                    currency={hotel.currency}
                    value={hotel.total}
                    decimalScale={0}
                  />
                </span>
              </div>

              {maxOffer > 0 && (
                <div className="tw-flex tw-justify-between tw-text-base tw-font-normal tw-text-green-500">
                  <span>{t('HotelsSavings')}:</span>
                  <span>
                    <PriceFormat
                      currency={hotel.currency}
                      value={maxOffer - hotel.total}
                      decimalScale={0}
                    />
                  </span>
                </div>
              )}
            </header>

            <main className="tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-start tw-pt-3">
              <span className="tw-flex tw-flex-col tw-items-center tw-text-lg tw-font-bold tw-text-black lg:tw-block">
                <span>{t('HotelsTotalTax')}: </span>
                <span>
                  <PriceFormat
                    currency={hotel.currency}
                    value={hotel.total}
                    decimalScale={0}
                  />
                </span>
              </span>

              <span className="tw-text-sm tw-text-black">
                <PriceFormat
                  currency={hotel.currency}
                  value={hotel.totalByNight}
                  decimalScale={0}
                >
                  {price => (
                    <span className="tw-text-black">
                      {t('HotelsPerNight', { price })}
                    </span>
                  )}
                </PriceFormat>
              </span>

              <div className="tw-hidden tw-flex-1 tw-items-center md:tw-flex">
                <Button
                  variant="primary"
                  onClick={handleSeeMoreClick}
                  className="tw-p-1 tw-text-xs lg:tw-px-4 lg:tw-py-3 lg:tw-text-base"
                >
                  {t('HotelsSeeMore')}
                </Button>
              </div>
            </main>

            <footer className="tw-hidden tw-self-center md:tw-block">
              {buttonOtherPrices}
            </footer>
          </Col>

          <Col
            xs="12"
            className="tw-flex tw-justify-between tw-border-x-0 tw-border-b-0 tw-border-t tw-border-solid tw-border-zinc-200 md:tw-hidden"
          >
            <div className="tw-flex">
              <Amenities hotel={hotel} onClick={handleAmenityClick} />
            </div>

            {buttonOtherPrices}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CardHotel;
