import React from 'react';

import useAppTranslation from '../hook/useAppTranslation';
import useLoader from '../hook/useLoader';

const Loader = () => {
  const { t } = useAppTranslation();
  const { isShow, message } = useLoader();

  return (
    <div
      className={`tw-fixed tw-z-[2147483002] tw-h-screen tw-w-screen ${
        !isShow
          ? 'tw-hidden tw-bg-black/100'
          : 'tw-flex tw-flex-col tw-items-center tw-bg-[#F9F8FE]'
      }`}
    >
      <div>
        <img
          src="/images/loader.gif"
          alt="Loader"
          className="tw-aspect-square tw-h-64"
        />
      </div>

      <h2 className="">{t('PorFavorEspere')}</h2>
      {message ?? t('EstamosBuscandoOpciones')}
    </div>
  );
};

export default Loader;
