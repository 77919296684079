import React from 'react';

import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useAuth from '../../hook/useAuth';
import useUser from '../../hook/useUser';
import black from '../../img/profile/black.jpg';
import platinum from '../../img/profile/platinum.jpg';
import ButtonEdit from './ButtonEdit';

const MyProfile = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useUser();
  const { isPlatinum } = useAuth();

  const handleEditClick = () => {
    history.push(`${history.location.pathname}/edit`);
  };

  return (
    <Row>
      <ColStyled xs="12" lg="3" className="tw-mb-3">
        <ImageStyled src={isPlatinum ? platinum : black} />
      </ColStyled>

      <ColStyled xs="12" lg="9">
        <Row>
          <Col xs="9" sm="4">
            <h2>
              {[user.name, user.lastName1]
                .map(it => it?.trim() ?? '')
                .filter(it => it.length)
                .join(' ')}
            </h2>
          </Col>

          <Col xs="3" sm="3" style={{ paddingLeft: '0' }}>
            <ButtonEdit onClick={handleEditClick}>{t('Edit')}</ButtonEdit>
          </Col>
        </Row>

        <Row>
          <Col xs="12" sm="3">
            <h3>{user.dateOfBirth}</h3>
          </Col>
        </Row>

        <Row>
          <ColFields xs="12" sm className="tw-mb-3">
            <span>
              <strong className="tw-uppercase">{t('ProfileEmail')}</strong>
            </span>
            <span>{user.email}</span>
          </ColFields>

          <ColFields xs="12" sm className="tw-mb-3">
            <span>
              <strong className="tw-uppercase">{t('ProfileTelephone')}</strong>
            </span>
            <span>{user.telephone}</span>
          </ColFields>

          <ColFields xs="12" sm className="tw-mb-3">
            <span>
              <strong className="tw-uppercase">{t('ProfileCountry')}</strong>
            </span>
            <span>{user.country}</span>
          </ColFields>

          <ColFields xs="12" sm className="tw-mb-3">
            <span>
              <strong className="tw-uppercase">
                {t('ProfileState')}/{t('ProfileCity')}
              </strong>
            </span>
            <span>{user.state}</span>
          </ColFields>
        </Row>
      </ColStyled>
    </Row>
  );
};

const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  & > img {
    align-self: center;
  }
`;

const ImageStyled = styled(Image)`
  width: 214px;
  aspect-ratio: 1;
  border-radius: 50rem;
`;

const ColFields = styled(Col)`
  display: flex;
  flex-direction: column;
`;

export default MyProfile;
