import React from 'react';

import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import formatNumber from '../utils/formatNumber';

const ReservaCardTopWhite = ({ item }) => {
  const { t } = useTranslation();

  return (
    <Card className="tw-shadow-none">
      <Card.Body>
        <Row>
          <Col xs="12" lg="12">
            <h5 className="tw-text-info">{t('YourReservationDetail')}</h5>
            <hr className="tw-bg-info" />
            <h6>{item.roomName}</h6>
            <small className="tw-block">
              {item.breakfast ? t('Desayuno') : ''}
            </small>
            <small className="tw-block">
              {item.parking ? t('Estacionamiento') : ''}
            </small>
          </Col>
        </Row>

        <Row>
          <Col xs="6" lg="7">
            <label>{t('FinalPorNoche')}</label>
          </Col>

          <Col xs="6" lg="5">
            <label>{formatNumber(item.totalByNight)} USD</label>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col xs="6" lg="7">
            <label className="tw-lowercase">
              {item.nights} {t('Nights', { count: item.nights })}, {item.guests}{' '}
              {t('HotelsTravelers', { count: item.guests })}
            </label>
          </Col>
          <Col xs="6" lg="5">
            <label>
              {formatNumber(item.total - (item.ServiceFee ?? 0))} USD
            </label>
          </Col>
        </Row>
        <Row>
          <Col xs="6" lg="7">
            <label className="lh-1">{t('ImpuestosTasasCargos')}</label>
          </Col>
          <Col xs="6" lg="5">
            <label>{formatNumber(item.ServiceFee ?? 0)} USD</label>
          </Col>
        </Row>
        <Row>
          <Col xs="6" lg="7">
            <label className="tw-text-info">{t('Descuento')}</label>
          </Col>
          <Col xs="6" lg="5">
            <label className="tw-text-info">{formatNumber(0)} USD</label>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="6" lg="7">
            <h6 className="tw-text-success">{t('Total')}</h6>
          </Col>
          <Col xs="6" lg="5">
            <h6 className="tw-text-success">{formatNumber(item.total)} USD</h6>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ReservaCardTopWhite;
