import React from 'react';

import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PriceFormat from '../PriceFormat';
import styles from './CardRate.module.scss';
import ListFeatures from './ListFeatures';

const FORMAT_DATE = 'DD-MM-YYYY hh:mm';

const CardRate = ({ car, rate, onClick }) => {
  // console.log('🚀 ~ CardRate.jsx', { car, rate });
  const { t } = useTranslation();

  const handleBookClick = () => {
    if (typeof onClick === 'function') {
      onClick(rate);
    }
  };

  return (
    <Card>
      <Card.Body>
        <Row xs="1" lg="2">
          <Col className={styles.containerImage}>
            <img
              className={`${styles.imageCar} img-thumbnail`}
              src={rate.picture}
              alt={rate.name}
            />

            <img
              className={styles.imageCompany}
              src={car.company.image}
              alt={car.company.name}
            />
          </Col>

          <Col>
            <Row xs="1">
              <Col>
                <h3 className={styles.description}>{rate.description}</h3>
              </Col>

              <Col className="tw-flex tw-flex-col">
                <h4 className="tw-m-0 tw-text-left tw-font-bold tw-text-green-500 lg:tw-text-right">
                  TOTAL
                </h4>

                <PriceFormat
                  value={parseFloat(rate.price.total)}
                  currency={rate.price.currency}
                  prefix={rate.price.prefix}
                >
                  {valueText => (
                    <h4 className="tw-m-0 tw-text-left tw-font-bold tw-text-green-500 lg:tw-text-right">
                      {valueText}
                    </h4>
                  )}
                </PriceFormat>

                <span className="tw-text-left tw-font-bold tw-text-green-500 lg:tw-text-right">
                  {t('JustGoPoints', {
                    count: rate.price.justGoPoints,
                  })}
                </span>
              </Col>
              <Col>
                <Button
                  variant="primary"
                  className="tw-float-right tw-font-bold"
                  onClick={handleBookClick}
                >
                  {t('CarsBook')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row xs="1" lg="2" className={styles.containerBelow}>
          <Col>
            <ListFeatures car={rate} />
          </Col>

          <Col>
            <h5 className="tw-font-bold">{t('CarsPickUpPlace')}</h5>
            <span>{rate.pickUp}</span>
            <h5 className="tw-font-bold">{t('CarsPickUpDate')}</h5>
            <span>{rate.pickUpDate.format(FORMAT_DATE)}</span>

            <h5 className="tw-font-bold">{t('CarsDropOffPlace')}</h5>
            <span>{rate.dropOff}</span>
            <h5 className="tw-font-bold">{t('CarsDropOffDate')}</h5>
            <span>{rate.dropOffDate.format(FORMAT_DATE)}</span>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

CardRate.propTypes = {
  car: PropTypes.shape().isRequired,
  rate: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CardRate;
