/* eslint-disable react/no-danger */
import React from 'react';
import useAppTranslation from '../../hook/useAppTranslation';
import ListItem from './ListItem';

const Cards = ({ onCardClick }) => {
  const { t } = useAppTranslation();

  return (
    <section className="sm:tw-px-0 md:tw-px-0 lg:tw-px-10 xl:tw-px-64 2xl:tw-px-80">
      <ul className="tw-flex tw-w-full tw-list-none tw-flex-col tw-gap-4 tw-p-4 md:tw-flex-row">
        <ListItem
          title={t('dwVOICE')}
          subtitle={t('dwRewardCredits')}
          prefixParagraph="dwRewardCreditsParagraph"
          btnTitle={t('dwDepositNow')}
          onClick={() => {
            onCardClick(true);
          }}
        />

        <ListItem
          title={t('dwExchange')}
          subtitle={t('dwExchangeCredits')}
          prefixParagraph="dwExchangeCreditsParagraph"
          btnTitle={t('dwExchangeNow')}
          onClick={() => {
            onCardClick(true);
          }}
        />

        <ListItem
          title={t('dwMarketplace')}
          subtitle={t('dwCash')}
          prefixParagraph="dwCashParagraph"
          btnTitle={t('dwListYourWeekNow')}
          onClick={() => {
            onCardClick(true);
          }}
        />
      </ul>
    </section>
  );
};

export default Cards;
