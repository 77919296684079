import React, { forwardRef } from 'react';
import { Card as CardBootstrap, Container } from 'react-bootstrap';
import styled from 'styled-components';

const Card = ({ children, className }, ref) => {
  return (
    <CardStyled className={['tw-m-2', className]} ref={ref}>
      <CardStyled.Body
        as={Container}
        className="tw-h-full tw-max-h-full tw-w-full tw-p-0"
      >
        {children}
      </CardStyled.Body>
    </CardStyled>
  );
};

const CardStyled = styled(CardBootstrap).attrs(() => ({
  className: 'card-hover',
}))`
  &&&& {
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: 2px 3px 6px #00000029 !important;
    /* border: 0.5px solid #707070 !important; */
    opacity: 1 !important;
  }
`;

export default forwardRef(Card);
