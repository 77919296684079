import React, { useCallback, useEffect, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import InputRange from 'react-input-range';
import useAppParams from '../../hook/useAppParams';
import useAppTranslation from '../../hook/useAppTranslation';
import CargandoOpciones from '../../loader/CargandoOpciones';
import { getCruises } from '../../services/cruises';
import FormSidebar from '../../shared/FormSidebar';
import { scrollUp } from '../../utils/scroll';
import sleep from '../../utils/sleep';
import Pagination from '../Pagination';
import SectionTop from '../SectionTop';
import Text from '../Text';
import CardCruise from './CardCruise';
import FormSearch from './FormSearch';
import ModalAmenities from './ModalAmenities';

const List = () => {
  const { t } = useAppTranslation();
  const { query } = useAppParams();

  const [loading, setLoading] = useState(false);
  const [cruises, setCruises] = useState([]);
  const [showAmenities, setShowAmenities] = useState(false);
  const [amenities] = useState([]);
  const [minPrecio, setMinPrecio] = useState(0);
  const [maxPrecio, setMaxPrecio] = useState(100);
  const [, setErrorServer] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [, setFilters] = useState(null);

  const options2 = { style: 'currency', currency: 'USD' };
  const numberFormat2 = new Intl.NumberFormat('en-US', options2);

  useEffect(() => {
    let didCancel = false;
    const get = async () => {
      await sleep(500);
      if (didCancel || !query) return;

      setLoading(true);
      setErrorServer(false);
      setCruises([]);
      setFilters(null);

      // console.log('🚀 ~ List.js', { query });
      const dataCruise = await getCruises({
        departure: query.departing,
        arrival: query.returning,
        area: query.area?.value,
        cruiseLine: query.line?.value,
        duration: query.duration?.value,
        ship: query.ship?.value,
      });

      if (dataCruise?.cruises?.length > 1) {
        setCruises(dataCruise.cruises);
        setMinPrecio(dataCruise.filter.price.min);
        setMaxPrecio(dataCruise.filter.price.max);
      }

      setLoading(false);
      scrollUp();
    };

    get();
    return () => {
      didCancel = true;
    };
  }, [query]);

  const handleAmenitiesClose = () => setShowAmenities(false);

  const renderCard = useCallback(
    cruise => <CardCruise item={cruise} />,

    [],
  );

  if (loading) return <CargandoOpciones marginTop />;

  if (!query) return <div />;

  return (
    <>
      <ModalAmenities
        show={showAmenities}
        onClose={handleAmenitiesClose}
        amenities={amenities}
      />

      <SectionTop
        menu={t('Cruceros', { count: cruises.length })}
        destino={query.area.label}
        total={cruises.length || undefined}
        product={t('Cruceros', { count: cruises.length })}
      />

      <Container fluid className="tw-my-3">
        <Row xs="1">
          <Col lg="4">
            <Row className="md:tw-justify-center">
              <FormSidebar>
                <FormSearch inline filters={query} />
              </FormSidebar>
            </Row>

            {cruises.length > 0 && (
              <Col>
                <br />
                <Row className="tw-mb-5 md:tw-justify-center">
                  <Col xs="12" lg="10">
                    <Text
                      size="20"
                      lineHeight="50"
                      color="#03B2E2"
                      className="tw-cursor-pointer"
                    >
                      {t('Xpersona')}
                    </Text>
                    <Col xs="12" lg="10">
                      <br />
                      <InputRange
                        maxValue={maxPrecio}
                        minValue={minPrecio}
                        value={maxPrecio / 2}
                        formatLabel={value => `${numberFormat2.format(value)} `}
                      />
                    </Col>
                  </Col>
                </Row>
              </Col>
            )}
          </Col>

          <Col lg="8" className="tw-flex tw-flex-col tw-gap-4">
            <Pagination
              items={cruises}
              render={renderCard}
              page={currentPage}
              onChange={setCurrentPage}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default List;
