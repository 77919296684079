import { encode } from './uriComponent';

const createAppSearchParams = params => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value === null || value === undefined) return;
    if (typeof value === 'string') searchParams.append(key, value);

    searchParams.append(key, encode(value));
  });

  return searchParams;
};

export default createAppSearchParams;
