import React from 'react';

import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';
import MapSidebar from '../../components/MapSidebar';
import SectionTop from '../../components/SectionTop';
import FormSearch from '../../components/SportingEvents/FormSearch';
import useAppSearchParams from '../../hook/useAppSearchParams';
import useAppTranslation from '../../hook/useAppTranslation';
import useDev from '../../hook/useDev';
import FormSidebar from '../../shared/FormSidebar';

const Warranty = () => {
  const { t, timeFormatMoment } = useAppTranslation();
  const { isDev } = useDev();
  const [query] = useAppSearchParams();
  const { sportingEvent, values } = query ?? {};
  const position = sportingEvent?.position
    ? {
        ...sportingEvent.position,
        place: sportingEvent.venue,
      }
    : null;

  return (
    <>
      <SectionTop
        className="tw-m-0"
        menu={t('SportingEvents.Title_one')}
        nombre={sportingEvent?.caption}
        direccion={
          <div className="tw-flex tw-flex-col tw-gap-4">
            <span>{sportingEvent?.venueAddress}</span>
            {sportingEvent?.date && (
              <span>
                {moment(sportingEvent.date).format(
                  `ddd, D MMM, ${timeFormatMoment}`,
                )}
              </span>
            )}
          </div>
        }
      />

      <Container fluid className="tw-my-3">
        <Row xs="1">
          <Col lg="3">
            <Row xs="1" className="tw-gap-4">
              <Col>
                <FormSidebar>
                  <FormSearch filters={values} inline disabled />
                </FormSidebar>
              </Col>

              {sportingEvent.venue_img && (
                <Col>
                  <img
                    className="tw-w-full"
                    src={sportingEvent.venue_img}
                    alt={sportingEvent.venue}
                  />
                </Col>
              )}

              {position && (
                <Col>
                  <MapSidebar
                    items={[sportingEvent]}
                    position={0}
                    positionsList={[position]}
                    center={position}
                  />
                </Col>
              )}
            </Row>
          </Col>

          <Col lg="9">
            <div className="tw-w-fit">
              <Formik
                initialValues={{
                  fullName: '',
                  phone: '',
                  email: '',
                  reservation: '',
                }}
                onSubmit={() => {}}
              >
                <Form>
                  <Col className="tw-mb-3">
                    <Row md={4}>
                      <label htmlFor="fullName">{t('Nombre')}</label>
                      <Col className="tw-mb-3">
                        <Field id="fullName" name="fullName" placeholder="" />
                      </Col>
                    </Row>
                    <Row md={4}>
                      <label htmlFor="phone">{t('Telefono')}</label>
                      <Col className="tw-mb-3">
                        <Field id="phone" name="phone" placeholder="" />
                      </Col>
                    </Row>
                    <Row md={4}>
                      <label htmlFor="email">{t('Correo')}</label>
                      <Col className="tw-mb-3">
                        <Field
                          id="email"
                          name="email"
                          placeholder=""
                          type="email"
                        />
                      </Col>
                    </Row>
                    <Row md={4}>
                      <label htmlFor="reservation">{t('Nboletos')}</label>
                      <Col className="tw-mb-3">
                        <Field
                          pattern="^[0-9]+"
                          type="number"
                          id="reservation"
                          name="reservation"
                          placeholder=""
                        />
                      </Col>
                    </Row>

                    <button
                      className="btn btn-primary btn-block tw-font-bold tw-uppercase "
                      type="submit"
                    >
                      {t('enviar')}
                    </button>
                  </Col>
                </Form>
              </Formik>
            </div>
          </Col>
        </Row>

        {isDev && (
          <Row xs="1" className="tw-mt-3">
            {Object.entries({
              query,
            })
              .filter(([, value]) => value)
              .map(([key, value], _, self) => {
                const lg = 12 / self.length;

                return (
                  <Col lg={lg}>
                    <pre>
                      {JSON.stringify(
                        {
                          [key]: value,
                        },
                        null,
                        2,
                      )}
                    </pre>
                  </Col>
                );
              })}
          </Row>
        )}
      </Container>
    </>
  );
};

export default Warranty;
