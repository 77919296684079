/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import moment from 'moment';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import '../css/Footer.css';
import socialInstagram from '../img/social/IG-icon-20x20px.png';
import socialFacebook from '../img/social/fb.svg';

const {
  REACT_APP_COMPANY_NAME,
  REACT_APP_COMPANY_URL,
  REACT_APP_COMPANY_FACEBOOK,
  REACT_APP_COMPANY_INSTAGRAM,
  REACT_APP_COMPANY_CONTACT_EMAIL,
  REACT_APP_COMPANY_CONTACT_TELEPHONE,
  REACT_APP_COMPANY_CONTACT_ADDRESS,
} = process.env;

const Footer = ({ t }) => {
  return (
    <>
      <section className="footer-1">
        <Container>
          <Row>
            <Col xs="12" lg="3">
              <Image
                src="/images/logo-without-background.webp"
                fluid
                className="tw-w-2/3 lg:tw-w-full"
              />

              <p className="tw-my-3">{t('FooterParrafo')}</p>
              <ul className="list-inline ft-0-9rem">
                <li className="list-inline-item">
                  <a
                    href={REACT_APP_COMPANY_FACEBOOK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image src={socialFacebook} fluid className="icon-20" />
                  </a>
                </li>

                <li className="list-inline-item tw-ml-1">
                  <a
                    href={REACT_APP_COMPANY_INSTAGRAM}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image src={socialInstagram} fluid className="icon-20" />
                  </a>
                </li>
              </ul>
            </Col>

            <Col xs="12" lg="3">
              <h5>{t('NuestrosServicios')}</h5>
              <ul className="tw-list-none tw-pl-0">
                <li>
                  <NavLink
                    to="/hotels"
                    className="tw-text-primary"
                    activeClassName="active"
                    exact
                  >
                    {t('Hoteles')}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/condos"
                    className="tw-text-primary"
                    activeClassName="active"
                    exact
                  >
                    {t('Condominios')}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/experiences"
                    className="tw-text-primary"
                    activeClassName="active"
                    exact
                  >
                    {t('EXPERIENCES')}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/cars"
                    className="tw-text-primary"
                    activeClassName="active"
                    exact
                  >
                    {t('Cars')}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/flights"
                    className="tw-text-primary"
                    activeClassName="active"
                    exact
                  >
                    {t('Vuelos')}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/group-travel-requests"
                    className="tw-text-primary"
                    activeClassName="active"
                    exact
                  >
                    {t('GroupTravelRequests')}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/price-guarantee"
                    className="tw-text-primary"
                    activeClassName="active"
                    exact
                  >
                    {t('PriceGuarantee')}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/deposit-week"
                    className="tw-text-primary"
                    activeClassName="active"
                    exact
                  >
                    {t('dwDepositWeek')}
                  </NavLink>
                </li>
              </ul>
            </Col>

            <Col xs="12" lg="3">
              <h5>{t('Privacidad')}</h5>
              <ul className="tw-list-none tw-pl-0">
                <li>
                  <a href="#" className="tw-text-primary">
                    {t('AvisoPrivacidad')}
                  </a>
                </li>
                <li>
                  <a href="#" className="tw-text-primary">
                    {t('AcercaNosotros')}
                  </a>
                </li>
                <li>
                  <a href="#" className="tw-text-primary">
                    {t('TérminosCondiciones')}
                  </a>
                </li>
                <li>
                  <a href="#" className="tw-text-primary">
                    {t('PreguntasFrecuentes')}
                  </a>
                </li>
              </ul>
            </Col>

            <Col xs="12" lg="3">
              <h5>{t('InformaciónContacto')}</h5>
              <ul className="tw-list-none tw-pl-0">
                <li>
                  <a href="#">
                    <FaEnvelope className="color-blue" />{' '}
                    <span className="ft-0-9rem">
                      {REACT_APP_COMPANY_CONTACT_EMAIL}
                    </span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FaPhoneAlt className="color-blue" />{' '}
                    <span className="ft-0-9rem">
                      {REACT_APP_COMPANY_CONTACT_TELEPHONE}
                    </span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FaMapMarkerAlt className="color-blue" />{' '}
                    <span className="ft-0-9rem">
                      {REACT_APP_COMPANY_CONTACT_ADDRESS}
                    </span>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="footer-2">
        <Container>
          <Row>
            <Col xs="12" lg="9">
              <small className="">
                Copyright © {moment().format('YYYY')}{' '}
                {t('FooterParrafoCopyright')}{' '}
                <a href={REACT_APP_COMPANY_URL} className="tw-text-primary">
                  {REACT_APP_COMPANY_NAME}
                </a>
              </small>
            </Col>
            <Col xs="12" lg="3">
              <small>
                <a href={REACT_APP_COMPANY_URL} className="tw-text-primary">
                  {REACT_APP_COMPANY_URL}
                </a>
              </small>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default withTranslation()(Footer);
