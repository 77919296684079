import React from 'react';

import { useTranslation } from 'react-i18next';
import FiltersForm from '../../components/Restaurants/FiltersForm';
import FiltersContainer from '../../shared/FiltersContainer';

const Restaurants = () => {
  const { t } = useTranslation();

  return (
    <FiltersContainer title={t('Restaurants')}>
      <FiltersForm />
    </FiltersContainer>
  );
};

export default Restaurants;
