import React from 'react';

import moment from 'moment';
import { Card, Col, Row } from 'react-bootstrap';
import { FaCalendarAlt } from 'react-icons/fa';
import useAppTranslation from '../../hook/useAppTranslation';

const BookingCardTopExp = ({ item }) => {
  const { t, language } = useAppTranslation();
  const checkIn = moment(item.checkIn);

  return (
    <Card className="tw-mb-2 tw-text-gray-500">
      <Card.Body>
        <Row>
          <Col xs="12" lg="12">
            <h4>{t('EXPERIENCES_RESERVATION_DETAIL')}</h4>
            <div className="tw-flex tw-flex-col">
              <label className="tw-mb-0 tw-block">
                <FaCalendarAlt className="icon-14 tw-mb-1 tw-mr-2" />
                {t('EXPERIENCES_DATE')}
              </label>

              <label className="ft-08rem tw-mb-0 tw-block">
                {checkIn.locale(language).format('ddd, DD MMM, YYYY')}
              </label>
            </div>
          </Col>
        </Row>

        <Row className="tw-mt-4">
          <Col>
            <label className="tw-mb-2 tw-block">
              {item?.destino_name?.[language] ??
                item?.destino_name?.label ??
                ''}
            </label>
            <label className="ft-08rem tw-mb-0  tw-block">{item.name}</label>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default BookingCardTopExp;
