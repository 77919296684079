import React, { useState } from 'react';

import { Col, Image, Row } from 'react-bootstrap';
import 'react-image-lightbox/style.css';
import Slider from 'react-slick';
import styled from 'styled-components';
import Lightbox from './Lightbox';

const CarouselTopDetail = ({ photos }) => {
  const [index, setIndex] = useState(null);

  return (
    <>
      <Lightbox images={photos} index={index} setIndex={setIndex} />

      <Row>
        <Col>
          <SliderComponent
            dots={false}
            infinite={false}
            speed={500}
            slidesToShow={4}
            slidesToScroll={1}
            initialSlide={0}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {photos.map((url, i) => {
              return (
                <div
                  style={{
                    height: 260,
                    background: '#EEE',
                    overflow: 'hidden',
                    padding: '0 10px',
                  }}
                  key={i}
                >
                  <Image
                    src={url}
                    onClick={() => {
                      setIndex(i);
                    }}
                    fluid
                    className="tw-h-52 tw-w-full tw-object-cover tw-px-1"
                  />
                </div>
              );
            })}
          </SliderComponent>
        </Col>
      </Row>
    </>
  );
};

const SliderComponent = styled(Slider)`
  & > button.slick-arrow {
    z-index: 9;
    display: flex !important;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 0 0 0.03rem black;

    &.slick-disabled {
      opacity: 0.25;

      &::before {
        opacity: 1 !important;
      }
    }

    &::before {
      position: absolute;
      top: 1px;
    }

    &.slick-prev {
      left: 0.5rem;
    }

    &.slick-next {
      right: 0.5rem;
    }
  }
`;

export default CarouselTopDetail;
