import { useEffect, useRef } from 'react';

const isChangeEvent = event => {
  return event;
};

const useInputFormHidden = ({ name, value, onChange, onBlur }) => {
  const input = useRef(null);
  useEffect(() => {
    const inputHidden = window.document.createElement('input');
    inputHidden.type = 'hidden';
    inputHidden.id = name;
    inputHidden.name = name;
    input.current = inputHidden;
    return () => {
      input.current = null;
    };
  }, [name]);

  useEffect(() => {
    if (input.current === null) return () => {};

    const handleChange = evt => {
      if (isChangeEvent(evt)) {
        onChange(evt);
      }
    };

    input.current.addEventListener('change', handleChange);
    input.current.addEventListener('blur', onBlur);

    return () => {
      if (input.current === null) return;

      input.current.removeEventListener('change', handleChange);
      input.current.removeEventListener('blur', onBlur);
    };
  }, [onChange, onBlur]);

  const handleChange = newValue => {
    if (input.current === null) return;
    input.current.value = newValue;
    input.current.dispatchEvent(new Event('change', { bubbles: true }));
  };

  const handleBlur = () => {
    if (input.current === null) return;
    input.current.dispatchEvent(new Event('blur', { bubbles: true }));
  };

  return { name, value, handleChange, handleBlur };
};

export default useInputFormHidden;
