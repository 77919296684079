import React from 'react';

import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useAuth from '../../hook/useAuth';
import black from '../../img/my-preferences/black.jpg';
import platinum from '../../img/my-preferences/platinum.jpg';

const MyButton = ({ name, active: activeProp = false }) => {
  const [active, setActive] = React.useState(activeProp);

  const variant = React.useMemo(
    () => (active === true ? 'primary' : 'outline-primary'),
    [active],
  );

  const handleClick = () => setActive(prev => !prev);

  return (
    <Button variant={variant} onClick={handleClick}>
      {name}
    </Button>
  );
};

const Travel = () => {
  const { t } = useTranslation();
  const { isPlatinum } = useAuth();

  return (
    <Container>
      <Row>
        <Col xs="12">
          <ImageStyled src={isPlatinum ? platinum : black} />
        </Col>

        <Col xs="12" className="tw-mt-3">
          <strong>{t('MyPreferencesHowTravel')}</strong>
        </Col>

        <Col xs="12">
          <Form.Check
            type="radio"
            inline
            name="temp1"
            label={t('MyPreferencesInSolitary')}
          />
          <Form.Check
            type="radio"
            inline
            name="temp1"
            checked
            label={t('MyPreferencesInCouple')}
          />
          <Form.Check
            type="radio"
            inline
            name="temp1"
            label={t('MyPreferencesFamilyUrGroup')}
          />
        </Col>
      </Row>

      <Row className="tw-mt-3">
        <Col xs="12">
          <strong>{t('MyPreferencesReasonsForTraveling')}</strong>
        </Col>
        <Col xs="12">
          <Form.Check
            type="radio"
            inline
            name="temp2"
            label={t('MyPreferencesJob')}
          />
          <Form.Check
            type="radio"
            inline
            name="temp2"
            checked
            label={t('MyPreferencesPleasure')}
          />
        </Col>
      </Row>

      <Row className="tw-mt-3">
        <Col xs="12">
          <strong>{t('MyPreferencesWhatWantNextTrip')}</strong>
        </Col>
        <Col xs="12">
          <Row>
            <Col>
              <WrapperButtons>
                {[
                  t('MyPreferencesAmusementParks'),
                  t('MyPreferencesExtremeSports'),
                  t('MyPreferencesLuxuryHotels'),
                  t('MyPreferencesShoppingMalls'),
                  t('MyPreferencesGastronomy'),
                  t('MyPreferencesRest'),
                  t('MyPreferencesCustoms'),
                  t('MyPreferencesShopping'),
                  t('MyPreferencesExplore'),
                  t('MyPreferencesAdventure'),
                  t('MyPreferencesBars'),
                  t('MyPreferencesEvents'),
                ].map((name, index) => (
                  <MyButton
                    name={name}
                    active={[1, 2, 9, 10].includes(index)}
                  />
                ))}
              </WrapperButtons>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="tw-mt-3">
        <Col xs="12">
          <strong>{t('MyPreferencesHowManyTravel')}</strong>
        </Col>
        <Col xs="12">
          <Form.Check type="radio" inline name="temp3" label="0-10" />
          <Form.Check type="radio" inline name="temp3" checked label="11-20" />
          <Form.Check type="radio" inline name="temp3" label="+20" />
        </Col>
      </Row>

      <Row className="tw-mt-3">
        <Col xs="12">
          <strong>{t('MyPreferencesWhatWeather')}</strong>
        </Col>
        <Col xs="12">
          <Form.Check
            type="radio"
            inline
            name="temp4"
            label={t('MyPreferencesHeat')}
          />
          <Form.Check
            type="radio"
            inline
            name="temp4"
            label={t('MyPreferencesCold')}
          />
          <Form.Check
            type="radio"
            inline
            name="temp4"
            checked
            label={t('MyPreferencesBoth')}
          />
        </Col>
      </Row>

      <Row className="tw-mt-3">
        <Col xs="12">
          <strong>{t('MyPreferencesWhichPlaces')}</strong>
        </Col>
        <Col xs="12">
          <Form.Check
            type="checkbox"
            inline
            name="temp5"
            checked
            label={t('MyPreferencesCity')}
          />
          <Form.Check
            type="checkbox"
            inline
            name="temp5"
            label={t('MyPreferencesDesert')}
          />
          <Form.Check
            type="checkbox"
            inline
            name="temp5"
            checked
            label={t('MyPreferencesBeach')}
          />
          <Form.Check
            type="checkbox"
            inline
            name="temp5"
            checked
            label={t('MyPreferencesForest')}
          />
          <Form.Check
            type="checkbox"
            inline
            name="temp5"
            checked
            label={t('MyPreferencesMountain')}
          />
        </Col>
      </Row>

      <Row className="tw-mt-3">
        <Col xs={{ offset: 8 }} md={{ offset: 10 }}>
          <Button variant="outline-primary">{t('MyPreferencesSave')}</Button>
        </Col>
      </Row>
    </Container>
  );
};

const ImageStyled = styled(Image)`
  margin-top: -2rem;
  margin-left: -3rem;
  width: 120%;
  aspect-ratio: 4 / 2;

  @media only screen and (max-width: 520px) {
    width: 135%;
  }

  @media only screen and (max-width: 350px) {
    width: 145%;
  }
`;

const WrapperButtons = styled.div`
  & > button {
    margin-block: 0.5rem;
  }

  & > button + button {
    margin-left: 1rem;
  }
`;

export default Travel;
