import { css } from 'styled-components';

const btnPrimary = css`
  .btn {
    &.btn-primary {
      color: var(--color-text);
      background-color: var(--button-primary);
      border-color: var(--button-primary);

      &:disabled,
      &.disabled {
        color: var(--color-text);
        background-color: var(--button-primary);
        border-color: var(--button-primary);
      }

      &:hover:not(:disabled):not(.disabled) {
        color: var(--color-text);
        background-color: var(--button-primary-active);
        border-color: var(--button-primary-active);
      }

      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      .show > &.dropdown-toggle {
        color: var(--color-text);
        background-color: var(--button-primary-active);
        border-color: var(--button-primary-active);

        &:focus,
        &:focus,
        .show > &.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem var(--button-primary-shadow);
        }
      }

      &.focus,
      &:focus {
        color: var(--color-text);
        background-color: var(--button-primary);
        border-color: var(--button-primary);
        box-shadow: 0 0 0 0.2rem var(--button-primary-shadow);
      }
    }

    &.btn-outline-primary {
      color: var(--button-primary);
      background-color: var(--color-text);
      border-color: var(--button-primary);

      &:hover {
        color: var(--color-text);
        background-color: var(--button-primary);
        border-color: var(--button-primary);
      }

      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      .show > &.dropdown-toggle {
        color: var(--color-text);
        background-color: var(--button-primary);
        border-color: var(--button-primary);

        &:focus,
        &:focus,
        .show > &.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem var(--button-primary-shadow);
        }
      }

      &.focus,
      &:focus {
        box-shadow: 0 0 0 0.2rem var(--button-primary-shadow);
      }
    }

    &.btn-link {
      color: var(--button-primary);

      &.focus,
      &:focus {
        box-shadow: 0 0 0 0.2rem var(--button-primary-shadow);
      }

      &:focus-visible {
        outline-color: var(--button-primary-shadow);
      }
    }
  }
`;

const buttons = css`
  .btn {
    padding: 10px 15px;
    display: inline-block;
  }

  ${btnPrimary}
`;

export default buttons;
