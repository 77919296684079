import moment from 'moment';
import { SPORTING_EVENTS, SPORTING_EVENTS_LIST } from './Routes';
import instance from './instance';

const sources = process.env.REACT_APP_SUNAPI_API_NAME_SPORTING_EVENTS;

const getParams = async params => {
  const request = instance.get(SPORTING_EVENTS, {
    params: {
      sources,
      ...params,
    },
  });

  const response = await request.then(res => {
    if (res?.data?.errors?.length) throw res.data.errors;

    return res.data.all.data.map(({ id, caption }) => ({
      value: id,
      label: caption,
    }));
  });

  return response;
};

export const getSports = async () => getParams({ typeQuery: 'GET_SPORTS' });

export const getCountries = async () =>
  getParams({ typeQuery: 'GET_COUNTRIES' });

export const getCities = async countryId =>
  getParams({
    typeQuery: 'GET_CITIES',
    id: countryId?.trim().length ? countryId.trim() : undefined,
  });

export const getSportingEvents = async ({
  startDate,
  endDate,
  sportTypeId,
  countryId,
  cityId,
  page,
  perPage = 50,
}) => {
  const request = instance.get(SPORTING_EVENTS_LIST, {
    params: {
      sources,
      from: startDate && moment(startDate).format('DD/MM/YYYY'),
      until: endDate && moment(endDate).format('DD/MM/YYYY'),
      sportTypeId: sportTypeId?.trim().length ? sportTypeId.trim() : undefined,
      competitorId: null,
      countryId: countryId?.trim().length ? countryId.trim() : undefined,
      cityId: cityId?.trim().length ? cityId.trim() : undefined,
      textToSearch: null,
      page,
      perPage,
    },
  });

  const response = await request
    .then(res => {
      const { all } = res.data;
      all.data = all.data.map(
        ({
          venue,
          venue_address: venueAddress,
          venue_lat: latitude,
          venue_lon: longitude,
          date,
          time_of_event: timeOfEvent,
          ...rest
        }) => {
          const sportingEvent = { ...rest, venue };
          sportingEvent.venueAddress = [
            venue,
            venueAddress.replace(/(\\r|<br>)/gi, ''),
          ]
            .filter(str => str?.trim().length)
            .map(str => str.trim())
            .join(' - ');

          sportingEvent.position =
            `${latitude}`.trim().length && `${longitude}`.trim().length
              ? { latitude, longitude }
              : null;

          sportingEvent.date = moment(
            `${date} ${timeOfEvent}`,
            'YYYY-MM-DD HH:mm',
          );

          sportingEvent.ticketdata = sportingEvent.ticketdata.map(it => ({
            ItemID: it.ItemID,
            Section: it.Section,
            Price: it.Price,
            ServiceFee: it.ServiceFee,
            Currency: it.Currency,
            available_selling_quantities: it.available_selling_quantities,
            immediate_confirmation: it.immediate_confirmation === '1',
          }));

          return sportingEvent;
        },
      );

      return all;
    })
    .catch(err => {
      const tmp = [{ message: err.response.data.developerMessage }];
      throw tmp;
    });

  // console.log('🚀 ~ sportingEvents.js', { response });
  return response;
};
