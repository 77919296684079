import React, { useEffect, useMemo, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import { getImageForDestination } from '../services/roadGoat';
import sleep from '../utils/sleep';

const IMAGE_DEFAULT = '/images/section-top.jpg';

const SectionTop = ({
  menu,
  destino: destination,
  nombre: name,
  total,
  product,
  direccion,
  imageDefault = IMAGE_DEFAULT,
  className,
}) => {
  const [imageUrl, setImageUrl] = useState(IMAGE_DEFAULT);

  useEffect(() => {
    let didCancel = false;
    const fetch = async () => {
      await sleep(500);
      if (didCancel) return;

      const image = await getImageForDestination({
        destination: name ?? destination,
      });
      setImageUrl(image ?? imageDefault);
    };

    fetch();
    return () => {
      didCancel = true;
    };
  }, [name, destination, imageDefault]);

  const content = useMemo(() => {
    // If they contain name is the detail
    if (name?.length) {
      return (
        <>
          <label className="tw-text-2xl tw-leading-none tw-text-white">
            {menu}
          </label>
          <h1 className="tw-text-white">{name}</h1>
          <span className="tw-text-2xl tw-leading-none tw-text-white">
            {direccion}
          </span>
        </>
      );
    }

    // List
    return (
      <>
        <label className="tw-text-2xl tw-leading-none tw-text-white">
          {menu}
        </label>
        <h1 className="tw-text-white">{destination}</h1>
        <span className="tw-bg-[#003c74] tw-p-1 tw-text-white">
          {total} {product}
        </span>
      </>
    );
  }, [destination, name, total, product, direccion, menu]);

  return (
    <Wrapper className={className} imageUrl={imageUrl}>
      <Container className="tw-pt-32">
        <Row>
          <Col>{content}</Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 40vh;
  margin-top: 90px;
  position: relative;

  ${({ imageUrl }) => {
    return css`
      background-image: url(${imageUrl}) !important;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    `;
  }}
`;

export default SectionTop;
