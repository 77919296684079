import React from 'react';

import Pagination from '../../shared/Pagination';
import EmptyItems from '../EmptyItems';
import CardSportingEvent from './CardSportingEvent';

const List = ({
  control,
  sportingEvents = [],
  onSportingEventClick,
  page,
  setPage,
}) => {
  if (!sportingEvents.length) return <EmptyItems />;

  return (
    <>
      <ul className="tw-flex tw-flex-col tw-gap-4">
        {sportingEvents
          // .filter(({ startPrice }) => startPrice)
          .map(sportingEvent => (
            <li key={sportingEvent.id}>
              <CardSportingEvent
                sportingEvent={sportingEvent}
                onClick={onSportingEventClick}
              />
            </li>
          ))}
      </ul>

      <Pagination
        current={page}
        onChange={setPage}
        total={control?.totalrecords ?? 0}
        pageSize={Number(control?.perpage ?? 20)}
      />
    </>
  );
};

export default List;
