import React, { useEffect, useState } from 'react';

import { Col, Container, Jumbotron, Row } from 'react-bootstrap';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import MapSidebar from '../../components/MapSidebar';
import FiltersForm from '../../components/Restaurants/FiltersForm';
import RestaurantBooking from '../../components/Restaurants/RestaurantBooking';
import RestaurantDetail from '../../components/Restaurants/RestaurantDetail';
import SectionTop from '../../components/SectionTop';
import useAppTranslation from '../../hook/useAppTranslation';
import CargandoOpciones from '../../loader/CargandoOpciones';
import { getLabelByDestinyId } from '../../services/destinations';
import { getRestaurantById } from '../../services/restaurants';
import FormSidebar from '../../shared/FormSidebar';
import PriceBottom from '../../shared/PriceBottom';
import sleep from '../../utils/sleep';
import { decode } from '../../utils/uriComponent';
import styles from './Detail.module.css';

const Detail = () => {
  const { t, language } = useAppTranslation();
  const { id, query } = useParams();
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState(null);
  const [destinyLabel, setDestinyLabel] = useState(null);
  const [restaurant, setRestaurant] = useState({});

  useEffect(() => {
    let didCancel = false;
    const set = async () => {
      await sleep(500);
      if (didCancel) return;

      setLoading(true);

      const restaurantId = id;
      const queryDecode = decode(query);

      const [_destinyLabel, _restaurant] = await Promise.all([
        getLabelByDestinyId({
          destinyId: queryDecode.destiny,
          language,
        }),

        getRestaurantById({
          restaurantId,
          language,
        }),
      ]);

      setFilters(queryDecode);
      setDestinyLabel(_destinyLabel);
      setRestaurant(_restaurant);
      setLoading(false);
    };

    set();
    return () => {
      didCancel = true;
    };
  }, [id, query, language]);

  const handleBookingClick = () => {
    history.push(`${url}/booking`);
  };

  if (loading) return <CargandoOpciones />;
  if (!restaurant)
    return (
      <Container fluid className="tw-mt-3">
        <Row className="tw-mt-4">
          <Col xs="12" lg="12">
            <h2>{t('Restaurants_one')}</h2>

            <Jumbotron>
              <p>{t('NoInformacionMostrar')}</p>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    );

  return (
    <>
      <SectionTop
        menu={t('Restaurants_one')}
        destino={destinyLabel}
        nombre={restaurant.name}
        direccion={restaurant.address}
      />

      <Container fluid className="tw-mt-3">
        <Row>
          <Col xs="12" lg="3">
            <Row>
              <Col xs="12" lg="12">
                <FormSidebar>
                  <FiltersForm inline filters={filters} />
                </FormSidebar>
              </Col>

              <Col xs="12" lg="12" className="tw-mt-3">
                <MapSidebar
                  items={[restaurant.map]}
                  position={0}
                  setPosition={() => {}}
                  positionsList={[restaurant.map]}
                  center={restaurant.map}
                />
              </Col>
            </Row>
          </Col>

          <Col xs="12" lg="9" className={styles['detail-content']}>
            <Switch>
              <Route exact path={path}>
                <RestaurantDetail
                  restaurant={restaurant}
                  onBookingClick={handleBookingClick}
                />
              </Route>

              <Route path={`${path}/booking`}>
                <RestaurantBooking />
              </Route>
            </Switch>
          </Col>
        </Row>
      </Container>

      <PriceBottom />
    </>
  );
};

export default Detail;
