import React from 'react';

import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Container, Row } from 'react-bootstrap';
import withFormikSearch from '../../hoc/withFormikSearch';
import useAppTranslation from '../../hook/useAppTranslation';
import useDev from '../../hook/useDev';
import useLoader from '../../hook/useLoader';
import {
  getCities,
  getCountries,
  getSports,
} from '../../services/sportingEvents';
import ColInline from '../../shared/ColInline';
import createAppSearchParams from '../../utils/createAppSearchParams';
import { filterSearchSchema } from '../../validations/sportingEvents';
import FiltersDateRange from './FiltersDateRange';
import FiltersSelect from './FiltersSelect';

const FormSearch = ({ formikBag, inline, disabled }) => {
  const { t } = useAppTranslation();
  const { isDev } = useDev();
  const { sportTypeId, countryId, cityId } = formikBag.values;

  const resultSports = useQuery({
    queryKey: ['sports'],
    queryFn: getSports,
  });

  const resultCountries = useQuery({
    queryKey: ['countries'],
    queryFn: getCountries,
  });

  const resultCities = useQuery({
    queryKey: ['cities', countryId],
    queryFn: ({ queryKey }) => {
      return getCities(queryKey[1]);
    },
    enabled: countryId !== null && countryId?.trim()?.length > 0,
  });

  useLoader({
    isLoading: resultSports.isPending || resultCountries.isPending,

    message: t('LoadingOptions', { keyPrefix: 'SportingEvents' }),
  });

  return (
    <Container className="tw-flex tw-flex-col tw-gap-4">
      <Row xs="1">
        <ColInline inline={inline} lg="4">
          <FiltersDateRange disabled={disabled} />
        </ColInline>

        {(!disabled || sportTypeId) && (
          <ColInline inline={inline} lg="4">
            <FiltersSelect
              name="sportTypeId"
              label={t('Sport', { keyPrefix: 'SportingEvents' })}
              options={resultSports.data ?? []}
              disabled={disabled}
            />
          </ColInline>
        )}

        {(!disabled || countryId) && (
          <ColInline inline={inline} lg="4">
            <FiltersSelect
              name="countryId"
              label={t('Country', { keyPrefix: 'SportingEvents' })}
              options={resultCountries.data ?? []}
              disabled={disabled}
            />
          </ColInline>
        )}

        {(!disabled || cityId) && (
          <ColInline inline={inline} lg="4">
            <FiltersSelect
              name="cityId"
              label={t('City', { keyPrefix: 'SportingEvents' })}
              options={resultCities.data ?? []}
              disabled={disabled}
            />
          </ColInline>
        )}
      </Row>

      {!disabled && (
        <Row xs="1">
          <ColInline inline={inline} lg={{ span: 2, offset: 10 }}>
            <Button
              type="submit"
              block
              variant="outline-primary"
              className="tw-font-bold"
            >
              {t('Buscar')}
            </Button>
          </ColInline>
        </Row>
      )}

      {isDev && (
        <Row>
          <ColInline xs="12">
            <pre style={{ color: '#fff' }}>
              {JSON.stringify(
                {
                  values: formikBag.values,
                  touched: formikBag.touched,
                  errors: formikBag.errors,
                },
                null,
                2,
              )}
            </pre>
          </ColInline>
        </Row>
      )}
    </Container>
  );
};

FormSearch.propTypes = {
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
};

FormSearch.defaultProps = {
  inline: false,
  disabled: false,
};

const FormikSearch = withFormikSearch(
  {
    initValues: {
      startDate: moment().add(2, 'day').startOf('day').hour(12).format(),
      endDate: moment().add(4, 'day').startOf('day').hour(12).format(),
    },
    schema: filterSearchSchema,
    onSubmit: ({ values, history }, { query, setQuery }) => {
      const newQuery = {
        values,
      };

      if (query === undefined) {
        history.push(
          `/sportingEvents/list?${createAppSearchParams(newQuery).toString()}`,
        );
      }

      setQuery?.(newQuery);
    },
  },

  FormSearch,
);

export default FormikSearch;
