import React, { useState } from 'react';

import { Field } from 'formik';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { MdPeople } from 'react-icons/md';
import useAppTranslation from '../../hook/useAppTranslation';
import Counter from '../../shared/Counter';

const Travelers = ({ inline, name, value, disabled }) => {
  const { t } = useAppTranslation();
  const [show, setShow] = useState(false);
  const adults = value?.adults ?? 0;
  const children = value?.children ?? 0;
  const travelers = adults + children;
  const description = t('EXPERIENCES_TRAVELERS', {
    count: travelers,
  });

  return (
    <Dropdown
      as={ButtonGroup}
      className="filter-traveler tw-m-0 tw-w-full tw-p-0"
      size="lg"
      show={show}
      onToggle={() => {
        setShow(!show);
      }}
    >
      <Dropdown.Toggle
        className="tw-flex tw-items-center tw-gap-1 tw-text-base"
        disabled={disabled}
      >
        <MdPeople /> {description}
      </Dropdown.Toggle>

      <Dropdown.Menu
        className={`tw-w-full tw-p-0 ${
          inline ? '' : 'lg:tw-w-[130%] xl:tw-w-[110%] 2xl:tw-w-full'
        }`}
      >
        <div className="tw-z-10 tw-w-full tw-origin-top-right tw-divide-y tw-rounded-md tw-bg-white tw-text-black tw-shadow-lg tw-ring-1 tw-ring-black/5 focus:tw-outline-none">
          <section className="tw-flex tw-max-h-60 tw-w-full tw-flex-col tw-gap-4 tw-divide-y tw-overflow-y-scroll tw-p-4">
            <div className="tw-grid tw-w-full tw-grid-cols-2">
              <span className="tw-flex tw-items-center">
                {t('EXPERIENCES_ADULTS', { count: adults })}
              </span>

              <Field name={`${name}.adults`}>
                {({ field, form }) => (
                  <Counter
                    name={field.name}
                    value={field.value}
                    min={1}
                    max={10}
                    onChange={newAdults => {
                      form.setFieldValue(field.name, newAdults);
                    }}
                  />
                )}
              </Field>
            </div>

            <div className="tw-grid tw-w-full tw-grid-cols-2">
              <span className="tw-flex tw-items-center">
                {t('EXPERIENCES_CHILDREN', { count: children.length })}
              </span>

              <Field name={`${name}.children`}>
                {({ field, form }) => (
                  <Counter
                    name={field.name}
                    value={field.value}
                    min={0}
                    max={10}
                    onChange={newChildren => {
                      form.setFieldValue(field.name, newChildren);
                    }}
                  />
                )}
              </Field>
            </div>
          </section>

          <section className="tw-flex tw-items-center tw-justify-center tw-border-x-0 tw-border-b-0 tw-border-t-2 tw-border-solid tw-border-gray-300 tw-py-2">
            <Button
              variant="primary"
              className="tw-flex tw-flex-col"
              onClick={() => {
                setShow(false);
              }}
            >
              <span className="tw-block">{t('EXPERIENCES_DONE')}</span>
              <small className="tw-block">{description}</small>
            </Button>
          </section>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Travelers;
