import React from 'react';

import { Card as CardBoostrap, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import useAppTranslation from '../../hook/useAppTranslation';
import PriceFormat from '../PriceFormat';
import RadioButton from '../RadioButton';
import FlightTimeDetail from './FlightTimeDetail';

const CardFlight = ({ itinerary, name }) => {
  const { t } = useAppTranslation();

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xs="12" lg="3" className="tw-px-0">
            <RadioButton name={name} value={itinerary.id}>
              <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-start tw-justify-center">
                <img
                  src={itinerary.airline.image}
                  alt={itinerary.airline.name}
                  height={35}
                />
                <span>{itinerary.airline.name}</span>
              </div>
            </RadioButton>
          </Col>

          <Col>
            <FlightTimeDetail itinerary={itinerary} />
          </Col>

          {itinerary.price && (
            <Col xs="12" lg="3" className="tw-flex tw-flex-col tw-items-center">
              <h4 className="tw-font-bold tw-text-primary">TOTAL</h4>

              <PriceFormat
                value={parseFloat(itinerary.price.total)}
                currency={itinerary.price.currency}
                prefix={itinerary.price.prefix}
              >
                {valueText => <h4 className="tw-font-bold">{valueText}</h4>}
              </PriceFormat>

              <span className="tw-font-bold tw-text-green-500">
                {t('JustGoPoints', {
                  count: itinerary.price.justGoPoints,
                })}
              </span>
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

const Card = styled(CardBoostrap)`
  border: 0px !important;
  border-bottom: 1px solid var(--button-primary) !important;

  &:first-of-type {
    border-top: 1px solid var(--button-primary) !important;
  }
`;

export default CardFlight;
