import axios from 'axios';
import { ROAD_GOAT_AUTOCOMPLETE } from './Routes';

const { REACT_APP_ROADGOAT_API, REACT_APP_ROADGOAT_ACCESS_KEY } = process.env;

export const getImageForDestination = async ({ destination = '' }) => {
  if (!destination?.trim?.().length) return null;

  const result = await axios
    .get(`${REACT_APP_ROADGOAT_API}${ROAD_GOAT_AUTOCOMPLETE}`, {
      params: {
        q: destination.replace(', null', ''),
      },

      headers: {
        Authorization: `Basic ${REACT_APP_ROADGOAT_ACCESS_KEY}`,
      },
    })
    .then(response => {
      const included = Array.isArray(response?.data?.included)
        ? response?.data?.included
        : [];

      const photo = included.find(({ type }) => type === 'photo');
      const { full, large, medium } = photo?.attributes?.image ?? {};

      return full ?? large ?? medium ?? null;
    })
    .catch(() => null);

  return result;
};

export default getImageForDestination;
