import React, { useEffect } from 'react';

import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import SectionPromotionNY from '../../components/SectionPromotionNY';
import MainContainer from '../../shared/MainContainer';
import PriceBottom from '../../shared/PriceBottom';
import { scrollUp } from '../../utils/scroll';
import Booking from './Booking';
import Cars from './Cars';
import Detail from './Detail';
import List from './List';

const Home = () => {
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  useEffect(() => {
    scrollUp();
  }, [pathname]);

  return (
    <MainContainer>
      <Switch>
        <Route exact path={path} component={Cars} />
        <Route exact path={`${path}/list/:query`} component={List} />
        <Route exact path={`${path}/list/:query/:office`} component={List} />
        <Route exact path={`${path}/detail/:query/:car`} component={Detail} />
        <Route
          exact
          path={`${path}/detail/:query/:office/:car`}
          component={Detail}
        />
        <Route exact path={`${path}/booking/:rate`} component={Booking} />
      </Switch>

      <SectionPromotionNY />
      <PriceBottom />
    </MainContainer>
  );
};

export default Home;
