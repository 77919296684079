import React from 'react';

import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { MdTimer, MdTimerOff } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Rating from '../../shared/Rating';
import Tooltip from '../../shared/Tooltip';
import { encode } from '../../utils/uriComponent';
import styles from './CardRestaurant.module.css';

const CardRestaurant = ({ restaurant, filters }) => {
  // console.log('🚀 ~ CardRestaurant.jsx', { restaurant });
  const { id, image, name, isOpen, rating, price, cuisine } = restaurant;
  const history = useHistory();

  const handleClick = () => {
    const query = encode(filters);

    history.push(`/restaurants/detail/${query}/${id}`);
  };

  return (
    <Card className={`${styles.card} tw-mb-2`}>
      <Card.Body onClick={handleClick}>
        <Row>
          <Col xs="12" lg="2">
            <Image
              src={image ?? '/images/no-image.jpg'}
              className={styles.image}
            />
          </Col>

          <Col xs="12" lg="10">
            <Container fluid className={styles.container}>
              <Row>
                <Col xs="12" md="6">
                  {name}
                </Col>

                <Col xs="12" md="6">
                  <div
                    className={`${styles.timer} ${
                      isOpen ? styles.open : styles.close
                    }`}
                  >
                    {isOpen ? (
                      <>
                        <MdTimer />
                        <span>Abierto</span>
                      </>
                    ) : (
                      <>
                        <MdTimerOff />
                        <span>Cerrado</span>
                      </>
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs="12" md="4">
                  <Rating rating={rating} />
                </Col>

                <Col xs="12" md="4">
                  <span className={styles.price}>{price}</span>
                </Col>

                <Col xs="12" md="4">
                  <Tooltip message={cuisine}>
                    <span className={styles.cuisine}>{cuisine}</span>
                  </Tooltip>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CardRestaurant;
