import moment from 'moment';

export const isFlightSegmentsValid = ({ flightSegments }) => {
  if (!Array.isArray(flightSegments)) return false;

  return flightSegments.every(
    segment =>
      segment?.airline?.name?.length &&
      segment?.airline?.codeIata?.trim?.().toLocaleUpperCase?.() !== 'ZZ',
  );
};

export const isItinerariesValid = ({ itineraries }) => {
  if (!Array.isArray(itineraries)) return false;

  return itineraries.every(itinerary => {
    if (!isFlightSegmentsValid(itinerary)) return false;

    return true;
  });
};

export const isFlightValid = ({ flight }) => {
  if (!flight) return false;
  if (!isItinerariesValid(flight)) return false;

  return true;
};

const toMinutes = input => moment.duration(input).asMinutes();

const mapSegments = ({ flightSegments }) =>
  flightSegments.map((segment, index, self) => {
    if (!segment) return null;

    // console.log('🚀 ~ flights.js', { duration: segment.duration });
    const lastSegment = self?.[index - 1];

    return {
      id: segment.id,
      number: segment.departure?.number ?? segment.arrival?.number,
      duration: toMinutes({ seconds: segment.duration }),
      layover:
        lastSegment &&
        toMinutes({
          minutes: Math.abs(
            moment
              .utc(segment.departingAt)
              .diff(moment.utc(lastSegment.arrivingAt), 'minutes'),
          ),
        }),

      airline: {
        code: segment.airline.codeIata,
        name: segment.airline.name,
      },
      departure: {
        terminal: {
          name: segment.departure?.terminal?.name,
          codeCity: segment.departure?.terminal?.codeCity,
        },
        date: moment.utc(segment.departingAt),
      },
      arrival: {
        terminal: {
          name: segment.arrival.terminal.name,
          codeCity: segment.arrival.terminal.codeCity,
        },
        date: moment.utc(segment.arrivingAt),
      },
      flightNumber: segment.departure.number,
    };
  });

export const mapItineraries = ({ price, itineraries }) => {
  const [departing, returning] = itineraries.map((it, i) => {
    if (!it) return undefined;

    const airline = it.flightSegments?.[0]?.airline ?? {};
    const segments = mapSegments(it);

    return {
      id: it.id,
      price: i === 0 ? price : undefined,
      departingAt: segments[0].departure.date,
      arrivingAt: segments[segments.length - 1].arrival.date,
      duration: segments.reduce(
        (acc, { duration, layover }) => acc + duration + (layover ?? 0),
        0,
      ),
      airline: {
        id: airline.id,
        codeIata: airline.codeIata,
        image: airline.image?.url,
        name: airline.name,
      },

      segments,
    };
  });

  return { departing, returning };
};
