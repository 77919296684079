import React, { useState } from 'react';
import { Button, Modal, ModalFooter } from 'react-bootstrap';
import Cards from '../../components/DepositWeek/Cards';
import ExchangeForm from '../../components/DepositWeek/ExchangeForm';
import useAppTranslation from '../../hook/useAppTranslation';

const DepositWeek = () => {
  const { t } = useAppTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Cards onCardClick={handleOpen} />

      <Modal size="xl" show={showModal} onHide={handleClose}>
        <Modal.Body className="tw-h-[80vh] tw-overflow-y-scroll tw-text-gray-600 [&_a]:tw-text-primary [&_a]:tw-underline">
          <ExchangeForm />
        </Modal.Body>

        <ModalFooter className="">
          <Button variant="primary" onClick={handleClose}>
            {t('dwAccept')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DepositWeek;
