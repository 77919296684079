import debounce from 'just-debounce-it';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { getCurrentRate } from '../services/currencyApi';
import useCurrencyZustand from '../store/currency';
import { convertToDollar } from '../utils/currency';
import { calcJustGoPoints } from '../utils/justGoPoints';
import sleep from '../utils/sleep';

const getCurrentRateDebounce = debounce(async ({ baseCurrency, cb }) => {
  const rates = await getCurrentRate({ baseCurrency });

  cb(rates);
}, 300);

const useCurrency = ({ currency: toCurrency = 'USD' } = {}) => {
  const { lastCheck, rates, save } = useCurrencyZustand(
    useShallow(state => state),
  );

  const isFresh = useMemo(() => {
    if (!lastCheck || !rates) return false;
    const lastCheckEndOfDay = moment(lastCheck).endOf('day');
    const today = moment();

    if (today.isAfter(lastCheckEndOfDay)) return false;

    return true;
  }, [lastCheck, rates]);

  useEffect(() => {
    if (isFresh) return undefined;

    let didCancel = false;
    const fetch = async () => {
      await sleep(500);
      if (didCancel) return;

      getCurrentRateDebounce({
        baseCurrency: 'USD',
        cb: save,
      });
    };

    fetch();
    return () => {
      didCancel = true;
    };
  }, [isFresh, save]);

  const convertTo = useCallback(
    ({ value, currency: fromCurrency }) => {
      const fromRate = rates?.[fromCurrency];
      const price = {
        value,
        currency: fromCurrency,
        prefix: fromRate?.prefix,
        rate: fromRate,
        justGoPoints: 0,
      };

      const toRate = rates?.[toCurrency];
      if (fromCurrency !== toCurrency && toRate) {
        const dollar = convertToDollar({ rates, price });
        price.value = Math.ceil(dollar * toRate.value);
        price.currency = toCurrency;
        price.prefix = toRate.prefix;
      }

      price.justGoPoints = calcJustGoPoints({ total: price.value });

      return price;
    },

    [rates, toCurrency],
  );

  return { rates, convertTo };
};

export default useCurrency;
