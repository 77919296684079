import React from 'react';
import useAppTranslation from '../../hook/useAppTranslation';
import FiltersContainer from '../../shared/FiltersContainer';
import FormSearch from '../../components/Experiences/FormSearch';

const Experiences = () => {
  const { t } = useAppTranslation();

  return (
    <FiltersContainer title={t('EXPERIENCES')}>
      <FormSearch />
    </FiltersContainer>
  );
};

export default Experiences;
