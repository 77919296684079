import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useAppSearchParams from '../../hook/useAppSearchParams';
import imgWarranty from '../../img/garantia.png';
import ErrorServer from '../ErrorServer';
import PromoCondos from '../PromoCondos';
import SectionTop from '../SectionTop';
import EmptyData from './EmptyData';

const Warranty = () => {
  const { t, i18n } = useTranslation();
  const [tmp] = useAppSearchParams();
  const { sportingEvent: query } = tmp;

  const [, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [, setPosition] = useState({});
  const [, setCenter] = useState([]);
  const [errorServer, setErrorServer] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [errorStatus] = useState(null);
  const handleSubmit = async () => {
    // alert(JSON.stringify(dataWarranty, null, 2));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const source = axios.CancelToken.source();
    setLoading(false);
    setErrorServer(false);
    setItem(null);

    const fetch = async () => {
      if (query) {
        try {
          // console.log('🚀 ~ Warranty.js ~ Init');
          setLoading(true);

          if (query?.caption) {
            setItem(query);

            if (query.address?.geolocation) {
              setPosition([
                {
                  id: query.id,
                  place: query.name,
                  raiting: query.stars,
                  latitude: query.address.geolocation.latitude || null,
                  longitude: query.address.geolocation.longitude || null,
                },
              ]);

              setCenter({
                latitude: query.address.geolocation.latitude || null,
                longitude: query.address.geolocation.longitude || null,
              });
            }
          } else if (query?.vname) {
            // if (res.data.errors?.length) throw res.data.errors;
            const data = query;
            setItem(data);

            if (data.address?.geolocation) {
              setPosition([
                {
                  id: data.id,
                  place: data.name,
                  raiting: data.stars,
                  latitude: data.address.geolocation.latitude || null,
                  longitude: data.address.geolocation.longitude || null,
                },
              ]);

              setCenter({
                latitude: data.address.geolocation.latitude || null,
                longitude: data.address.geolocation.longitude || null,
              });
            }
          }
        } catch (err) {
          // console.log('🚀 ~ Warranty.js', { err });

          setItem(null);
          setErrorData(Array.isArray(err) ? err : [err]);
          setErrorServer(true);
        } finally {
          setLoading(false);
        }
      }
    };

    fetch();

    return () => {
      // console.log('🚀 ~ Warranty.js ~ Cancel');

      source.cancel();
    };
  }, [query, i18n]);

  if (!query) return <div />;

  return (
    <>
      <SectionTop
        menu={query?.sport}
        destino={query?.venue || ''}
        nombre={query?.caption || query.vname}
        direccion={query?.direccion || query.country}
      />
      {/* eslint-disable-next-line no-console */}
      {console.log('ITEM', item)}

      {!item ? (
        <EmptyData />
      ) : (
        <Container fluid className="tw-my-3">
          <Row>
            <Col xs="12" lg="3">
              <Row>
                <Col xs="12" lg="10">
                  <h1 className="text-go ft-6em tw-text-right">GO!</h1>
                </Col>
                <Col xs="12" lg="12">
                  <PromoCondos />
                </Col>
              </Row>
            </Col>
            {errorServer ? (
              <ErrorServer errorData={errorData} errorStatus={errorStatus} />
            ) : (
              <>
                <Col xs="12" lg="3">
                  <Row>
                    <img src={imgWarranty} alt="Garantia" />
                  </Row>
                </Col>

                <Row>
                  <Formik
                    initialValues={{
                      fullName: '',
                      phone: '',
                      email: '',
                      reservation: '',
                    }}
                    onSubmit={handleSubmit}
                  >
                    <Form>
                      <Col className="tw-mb-3">
                        <Row md={4}>
                          <label htmlFor="fullName">{t('Nombre')}</label>
                          <Col className="tw-mb-3">
                            <Field
                              id="fullName"
                              name="fullName"
                              placeholder=""
                            />
                          </Col>
                        </Row>
                        <Row md={4}>
                          <label htmlFor="phone">{t('Telefono')}</label>
                          <Col className="tw-mb-3">
                            <Field id="phone" name="phone" placeholder="" />
                          </Col>
                        </Row>
                        <Row md={4}>
                          <label htmlFor="email">{t('Correo')}</label>
                          <Col className="tw-mb-3">
                            <Field
                              id="email"
                              name="email"
                              placeholder=""
                              type="email"
                            />
                          </Col>
                        </Row>
                        <Row md={4}>
                          <label htmlFor="reservation">{t('Nboletos')}</label>
                          <Col className="tw-mb-3">
                            <Field
                              pattern="^[0-9]+"
                              type="number"
                              id="reservation"
                              name="reservation"
                              placeholder=""
                            />
                          </Col>
                        </Row>

                        <button
                          className="btn btn-primary btn-block tw-font-bold tw-uppercase "
                          type="submit"
                        >
                          {t('enviar')}
                        </button>
                      </Col>
                    </Form>
                  </Formik>
                </Row>
              </>
            )}
          </Row>
        </Container>
      )}
    </>
  );
};

export default Warranty;
