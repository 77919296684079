import React, { useCallback, useEffect, useState } from 'react';

import { useFormikContext } from 'formik';
import { getHotelDestination } from '../../services/hotels';
import AsyncSelectForm from '../../shared/AsyncSelectForm';
import sleep from '../../utils/sleep';

const SelectHotel = ({
  name,
  value,
  isValid,
  isInvalid,
  onChange,
  onBlur,
  disabled,
  className,
}) => {
  const formikBag = useFormikContext();
  const [valueSelected, setValueSelected] = useState(null);

  useEffect(() => {
    let didCancel = false;
    const updateValue = async () => {
      await sleep(500);
      if (didCancel) return;

      setValueSelected(() => {
        if (!formikBag?.values?.hotelId) return null;

        return {
          value: formikBag.values.hotelId,
          label: formikBag.values.hotel,
        };
      });
    };

    updateValue();
    return () => {
      didCancel = true;
    };
  }, [formikBag]);

  const loadOptions = useCallback(
    async searchString =>
      getHotelDestination({
        searchString,
      })
        .then(data => {
          // console.log('🚀 ~ SelectDestination.jsx', { data });

          return data;
        })
        .catch(() => []),

    [],
  );

  const handleChange = evt => {
    let destiny = null;
    let hotel = null;

    if (evt?.target?.value) {
      destiny = evt?.target?.destiny ?? null;
      hotel = evt?.target?.label ?? null;
    }

    formikBag.setFieldValue('destiny', destiny);
    formikBag.setFieldValue('hotel', hotel);

    onChange(evt);
  };

  return (
    <AsyncSelectForm
      name={name}
      value={value}
      isValid={isValid}
      isInvalid={isInvalid}
      onChange={handleChange}
      onBlur={onBlur}
      disabled={disabled}
      className={className}
      loadOptions={loadOptions}
      valueSelected={valueSelected}
    />
  );
};

export default SelectHotel;
