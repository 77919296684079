import moment from 'moment';

const getDuration = minutes => {
  if (!minutes) return null;

  const nMinutes = parseInt(minutes, 10);
  const dur = moment.duration({ minutes: Math.abs(nMinutes) });

  const obj = {
    d: dur.days(),
    h: dur.hours(),
    m: dur.minutes(),
  };

  return obj;
};

const prettyTime = ({ format, lng, key, time }) => {
  if (format === 'shortDuration') {
    if (lng === 'en') {
      return `${time}${key}`; // {hours}h {minutes}m
    }

    if (lng === 'es') {
      const KEYS = {
        d: 'd',
        h: 'h',
        m: 'min',
      };
      return `${time} ${KEYS[key]}`; // {hours} h {minutes} min
    }
  }

  if (format === 'longDuration') {
    if (lng === 'en') {
      const KEYS = {
        d: 'Day',
        h: 'Hour',
        m: 'Minute',
      };

      return `${time} ${KEYS[key]}${time > 1 ? 's' : ''}`; // {hours} Hours {minutes} Minutes
    }

    if (lng === 'es') {
      const KEYS = {
        d: 'Día',
        h: 'Hora',
        m: 'Minuto',
      };

      return `${time} ${KEYS[key]}${time > 1 ? 's' : ''}`; // {hours} Horas {minutes} Minutos
    }
  }

  return null;
};

export default (value, format, lng) => {
  try {
    if (format === 'shortDuration' || format === 'longDuration') {
      const newValue = parseInt(value, 10);

      const duration = getDuration(newValue);
      const arr = Object.entries(duration)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([key, time]) => {
          if ((time ?? 0) === 0) return null;

          return prettyTime({ format, lng, key, time });
        })
        .filter(Boolean);

      // console.log('🚀 ~ formatDuration.js', { arr });
      return arr.join(' ');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('🚀 ~ formatDuration.js', { err });
  }

  return value;
};
