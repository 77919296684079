import React from 'react';

import { useTranslation } from 'react-i18next';
import FormSearch from '../../components/Flights/FormSearch';
import FiltersContainer from '../../shared/FiltersContainer';

const Flights = () => {
  const { t } = useTranslation();

  return (
    <FiltersContainer title={t('Vuelos')}>
      <FormSearch />
    </FiltersContainer>
  );
};

export default Flights;
