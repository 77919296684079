import React from 'react';

import { Route, Switch } from 'react-router-dom';
import Footer from '../components/Footer';
import HeaderNav from '../components/HeaderNav';
import Cars from './Cars';
import Condos from './Condos';
import CruiseShips from './CruiseShips';
import Dashboard from './Dashboard';
import DepositWeek from './DepositWeek';
import Exchange from './Exchange';
import Experiences from './Experiences';
import Flights from './Flights';
import GroupTravelRequests from './GroupTravelRequests';
import Hotels from './Hotels';
import MyPreferences from './MyPreferences';
import MyProfile from './MyProfile';
import PriceGuarantee from './PriceGuarantee';
import Restaurants from './Restaurants';
import SportingEvents from './SportingEvents';
import Layout from '../layouts/Layout';

const AppRoute = ({ exact = false, path, component }) => {
  return (
    <>
      <HeaderNav />
      <Route exact={exact} path={path} component={component} />
      <Footer />
    </>
  );
};

const NewAppRoute = ({ exact = false, path, component: Component }) => {
  return (
    <Route exact={exact} path={path}>
      <Layout>
        <Component />
      </Layout>
    </Route>
  );
};

const Home = () => {
  return (
    <Switch>
      <AppRoute exact path="/" component={Dashboard} />
      <AppRoute path="/price-guarantee" component={PriceGuarantee} />
      <AppRoute path="/deposit-week" component={DepositWeek} />
      <AppRoute path="/group-travel-requests" component={GroupTravelRequests} />
      <AppRoute path="/my-profile" component={MyProfile} />
      <AppRoute path="/my-preferences" component={MyPreferences} />
      <AppRoute path="/restaurants" component={Restaurants} />
      <NewAppRoute path="/hotels" component={Hotels} />
      <AppRoute path="/experiences" component={Experiences} />
      <AppRoute path="/cars" component={Cars} />
      <AppRoute path="/condos" component={Condos} />
      <AppRoute path="/flights" component={Flights} />
      <NewAppRoute path="/sportingEvents" component={SportingEvents} />
      <AppRoute path="/cruiseShips" component={CruiseShips} />

      <AppRoute exact path="/exchange">
        <Exchange />
      </AppRoute>
      {/*  <Route path="/ritzCarlton" component={ritzCarlton} /> */}
    </Switch>
  );
};

export default Home;
