import React from 'react';

import { Image } from 'react-bootstrap';
import styled from 'styled-components';

const LoadingTranslations = () => {
  return (
    <Wrapper>
      <ImageStyled src="/images/loader.gif" />
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs(() => ({ className: 'jumbotron' }))`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483002;
  width: 100vw;
  height: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #fcfcfc !important;
`;

const ImageStyled = styled(Image)`
  height: 16rem;
  aspect-ratio: 2/2;
`;

export default LoadingTranslations;
