import { payment } from './payment';
import yup from './yup';

export const filterSearchSchema = yup.object().shape({
  pickUp: yup.number().nullable().required('CampoRequerido').default(null),
  pickUpDate: yup
    .string()
    .required('ErrorFieldRequired')
    .isDateMomentValid()
    .default(''),
  pickUpTime: yup
    .string()
    .required('ErrorFieldRequired')
    .isDateMomentValid()
    .default(''),

  dropOff: yup.number().nullable().required('CampoRequerido').default(null),
  dropOffDate: yup
    .string()
    .required('ErrorFieldRequired')
    .isDateMomentValid()
    .isDateBeforeAtStartDate({
      startDateName: 'pickUpDate',
      message: 'ErrorFechaRegreso',
    })
    .default(''),
  dropOffTime: yup
    .string()
    .required('ErrorFieldRequired')
    .isDateMomentValid()
    .default(''),
});

export const bookingSchema = yup.object().shape({
  name: yup.string().required('ErrorFieldRequired').default(''),
  middleName: yup.string().required('ErrorFieldRequired').default(''),
  lastName: yup.string().required('ErrorFieldRequired').default(''),
  email: yup.string().required('ErrorFieldRequired').default(''),
  phone: yup.string().required('ErrorFieldRequired').default(''),
  policy: yup
    .boolean()
    .oneOf([true], 'CarsBookPolicyAccept')
    .required('ErrorFieldRequired')
    .default(false),
  payment: payment.clone(),
});
