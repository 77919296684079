export const decode = paramEncoded => {
  try {
    if (!paramEncoded?.trim?.()?.length) return null;

    const paramDecoded = decodeURIComponent(paramEncoded.trim());
    const param = JSON.parse(paramDecoded);

    return param;
  } catch {
    // console.log('🚀 ~ uriComponent.js', { err });
  }

  return paramEncoded;
};

export const encode = param => {
  const paramDecoded = JSON.stringify(param);
  const paramEncoded = encodeURIComponent(paramDecoded);

  return paramEncoded;
};
