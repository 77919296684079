import React, { useEffect, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import ErrorServer from '../../components/ErrorServer';
import CardRestaurant from '../../components/Restaurants/CardRestaurant';
import FiltersForm from '../../components/Restaurants/FiltersForm';
import SectionTop from '../../components/SectionTop';
import useAppParams from '../../hook/useAppParams';
import useAppTranslation from '../../hook/useAppTranslation';
import CargandoOpciones from '../../loader/CargandoOpciones';
import { getLabelByDestinyId } from '../../services/destinations';
import { getRestaurantsByDestiny } from '../../services/restaurants';
import FormSidebar from '../../shared/FormSidebar';
import Pagination from '../../shared/Pagination';
import sleep from '../../utils/sleep';

const errorServer = false;
const List = () => {
  const { t, language } = useAppTranslation();
  const { query } = useAppParams();

  const [loading, setLoading] = useState(true);
  const [destiny, setDestiny] = useState(null);
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let didCancel = false;
    const loadDestiny = async () => {
      await sleep(500);
      if (didCancel || !query) return;

      setDestiny(null);

      const label = await getLabelByDestinyId({
        destinyId: query.destiny,
        language,
      });

      // console.log('🚀 ~ List.jsx', { label });
      setDestiny(label);
    };

    loadDestiny();
    return () => {
      didCancel = true;
    };
  }, [language, query]);

  useEffect(() => {
    let didCancel = false;
    const fetch = async () => {
      await sleep(500);
      if (didCancel || !query) return;

      setLoading(true);
      const restaurants = await getRestaurantsByDestiny({
        destiny: query.destiny,
        offset: (current - 1) * 20,
        language,
      });

      // console.log('🚀 ~ List.jsx', { restaurants });
      setData(restaurants.data);
      setTotal(restaurants.total);
      setLoading(false);
    };

    fetch();
    return () => {
      didCancel = true;
    };
  }, [query, current, language]);

  if (loading) return <CargandoOpciones />;

  return (
    <>
      <SectionTop
        menu={t('Restaurants', { count: total })}
        destino={destiny}
        total={total}
        product={t('Restaurants', { count: total })}
      />

      <Container fluid className="tw-my-3">
        <Row>
          <Col xs="12" lg="4">
            <Row className="md:tw-justify-center">
              <FormSidebar>
                <FiltersForm inline filters={query} />
              </FormSidebar>
            </Row>
          </Col>

          <Col xs="12" lg="8">
            {errorServer ? (
              <ErrorServer />
            ) : (
              <>
                {data.map(item => {
                  return <CardRestaurant restaurant={item} filters={query} />;
                })}

                <Pagination
                  current={current}
                  onChange={setCurrent}
                  total={total}
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default List;
