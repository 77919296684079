import React from 'react';

import { ThemeProvider } from 'styled-components';
import GlobalStyles from '../theme/GlobalStyles';
import sunsetWorld from '../theme/sunsetWorld.json';

const withTheme = Component => () => {
  return (
    <ThemeProvider theme={sunsetWorld}>
      <GlobalStyles />

      <Component />
    </ThemeProvider>
  );
};

export default withTheme;
