import React, { useState } from 'react';

import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './Stops.module.css';
import MyTimeline from './Timeline';

const Stops = ({ itinerary }) => {
  const { t } = useTranslation();

  // console.log('🚀 ~ Stops.js', { itinerary });
  const stops = itinerary.segments.length - 1;
  const [show, setShow] = useState(false);
  const toggle = () => stops > 0 && setShow(!show);

  return (
    <>
      <Button
        variant="link"
        className={styles.btnStops}
        onClick={toggle}
        disabled={stops === 0}
      >
        {t('Escalas', { count: stops })}
      </Button>

      <Modal
        size="lg"
        centered
        show={show}
        onHide={toggle}
        contentClassName={styles.modalContent}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <Row>
              <Col>
                <h1 className="tw-font-bold tw-text-black">{t('Detalles')}</h1>
              </Col>
            </Row>

            <Row className="tw-mt-3">
              <Col>
                <h2 className="tw-text-primary">
                  {t('DuracionLarga', { duration: itinerary.duration })}
                </h2>
              </Col>
            </Row>

            <Row>
              <Col>
                <h2 className="tw-font-bold tw-text-primary">
                  {t('Conectado')}
                </h2>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {itinerary.segments.map(stop => {
            return (
              <div key={stop.id}>
                {stop.layover > 0 && (
                  <Row className="tw-mt-2">
                    <Col>
                      <Alert variant="primary">
                        {t('FlightStopover', {
                          duration: t('DuracionCorta', {
                            duration: stop.layover,
                          }),
                          terminal: stop.departure.terminal.name,
                        })}
                      </Alert>
                    </Col>
                  </Row>
                )}

                <MyTimeline>
                  <Row xs="1">
                    <Col>
                      <Row xs="1">
                        <Col lg="4">
                          <span className="tw-font-bold tw-text-black">
                            {stop.departure.date.format(
                              'ddd, D MMM YYYY, kk:mm',
                            )}
                          </span>
                        </Col>

                        <Col lg="8">
                          <span className="tw-text-black">
                            {stop.departure.terminal.name} (
                            {stop.departure.terminal.codeCity})
                          </span>
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <span>{t('FlightDuration')}:</span>
                      <span className="tw-ml-2">
                        {t('DuracionCorta', { duration: stop.duration })}
                      </span>
                    </Col>
                  </Row>

                  <Row xs="1">
                    <Col>
                      <Row xs="1">
                        <Col lg="4">
                          <span className="tw-font-bold tw-text-black">
                            {stop.arrival.date.format('ddd, D MMM YYYY, kk:mm')}
                          </span>
                        </Col>

                        <Col lg="8">
                          <span className="tw-text-black">
                            {stop.arrival.terminal.name} (
                            {stop.arrival.terminal.codeCity})
                          </span>
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <Row xs="1">
                        <Col lg="4">
                          <span>{t('FlightOperatedBy')}:</span>
                          <span className="tw-ml-2">{stop.airline.name}</span>
                        </Col>

                        <Col lg="4">
                          <span>
                            {stop.airline.code}
                            {stop.flightNumber}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </MyTimeline>
              </div>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Stops;
