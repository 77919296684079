/* eslint-disable react/no-danger */
import { Formik } from 'formik';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import useAppTranslation from '../../hook/useAppTranslation';
import InputForm from '../../shared/InputForm';

const ExchangeForm = () => {
  const { t } = useAppTranslation();

  return (
    <Formik>
      <>
        <Container className="tw-pb-4">
          <Row xs="1">
            <Col>
              <h1 className="">{t('dwExchangeOwnerInformation')}</h1>
            </Col>

            <Col className="[&>div.form-group>select]:lg:tw-w-1/3 [&>div.form-group]:tw-flex [&>div.form-group]:tw-flex-col [&>div.form-group]:tw-gap-4 [&>div.form-group]:lg:tw-flex-row">
              <InputForm
                as="select"
                name="traveler"
                label={t('dwExchangeAutofillTraveler')}
                required={false}
              >
                <option value={-1}>Demo Account</option>
              </InputForm>
            </Col>
          </Row>

          <Row xs="1" md="2" lg="3">
            <Col>
              <InputForm
                name="firstName"
                label={t('dwExchangeFirstName')}
                required={false}
              />
            </Col>

            <Col>
              <InputForm
                name="lastName"
                label={t('dwExchangeLastName')}
                required={false}
              />
            </Col>

            <Col>
              <InputForm
                name="coOwnersFirstName"
                label={t('dwExchangeCoOwnersFirstName')}
                required={false}
              />
            </Col>

            <Col>
              <InputForm
                name="coOwnersLastName"
                label={t('dwExchangeCoOwnersLastName')}
                required={false}
              />
            </Col>

            <Col>
              <InputForm name="email" label={t('dwExchangeEmail')} required />
            </Col>

            <Col>
              <InputForm name="phone" label={t('dwExchangePhone')} required />
            </Col>

            <Col>
              <InputForm
                name="secondaryPhone"
                label={t('dwExchangeSecondaryPhone')}
                required={false}
              />
            </Col>

            <Col>
              <InputForm
                name="address"
                label={t('dwExchangeAddress')}
                required
              />
            </Col>

            <Col>
              <InputForm name="city" label={t('dwExchangeCity')} required />
            </Col>

            <Col>
              <InputForm
                name="stateOrProvince"
                label={t('dwExchangeStateProvince')}
                required
              />
            </Col>

            <Col>
              <InputForm
                name="zipPostalCode"
                label={t('dwExchangePostalCode')}
                required={false}
              />
            </Col>

            <Col>
              <InputForm
                name="country"
                label={t('dwExchangeCountry')}
                required={false}
              />
            </Col>
          </Row>
        </Container>

        <Container className="tw-border-x-0 tw-border-b-0 tw-border-t tw-border-solid tw-py-4">
          <Row xs="1">
            <Col>
              <h1 className="">{t('dwExchangePromotionCode')}</h1>
              <span>{t('dwExchangePromotionCodeMessage')}</span>
            </Col>

            <Col className="tw-flex tw-flex-col tw-gap-4 lg:tw-flex-row [&>div.form-group>*]:tw-m-0 [&>div.form-group>input]:lg:tw-w-auto [&>div.form-group]:tw-m-0 [&>div.form-group]:tw-flex [&>div.form-group]:tw-w-auto [&>div.form-group]:tw-flex-col [&>div.form-group]:tw-gap-4 [&>div.form-group]:lg:tw-flex-row [&>div.form-group]:lg:tw-items-center">
              <InputForm
                name="code"
                label={t('dwExchangeEnterCode')}
                required={false}
              />

              <Button
                variant="primary"
                size="sm"
                className="tw-w-1/3 lg:tw-w-auto"
              >
                {t('dwExchangeApplyPromoCode')}
              </Button>
            </Col>
          </Row>
        </Container>

        <Container className="tw-border-x-0 tw-border-b-0 tw-border-t tw-border-solid tw-pt-4 tw-text-gray-600">
          <Row xs="1">
            <Col>
              {[
                'dwExchangeAgree1',
                'dwExchangeAgree2',
                'dwExchangeAgree3',
                'dwExchangeAgree4',
              ].map(it => (
                <p dangerouslySetInnerHTML={{ __html: t(it) }} />
              ))}

              <Row className="" xs="1" lg="3">
                <Col>
                  <InputForm
                    name="firstName"
                    label={t('dwExchangeNameOwner')}
                    required={false}
                  />
                </Col>

                <Col>
                  <InputForm
                    name="lastName"
                    label={t('dwExchangeNameCoOwner')}
                    required={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    </Formik>
  );
};

export default ExchangeForm;
