import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useCruise from '../../hook/useCruise';
import CargandoOpciones from '../../loader/CargandoOpciones';
import FormSidebar from '../../shared/FormSidebar';
import CarouselTopDetail from '../CarouselTopDetail';
import DescripcionDetail from '../DescripcionDetail';
import ErrorServer from '../ErrorServer';
import CabinsDetail from '../RitzDetail';
import SectionTop from '../SectionTop';
import EmptyData from './EmptyData';
import FormSearch from './FormSearch';

const DetailCarlton = () => {
  const { query, makeBook } = useCruise();
  const { t, i18n } = useTranslation();
  const [cruises, setCruises] = useState({});
  const [showCurrency, setShowCurrency] = useState(false);
  const handleCloseCurrency = () => setShowCurrency(false);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [text] = useState(null);
  const [errorServer, setErrorServer] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [errorStatus] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const source = axios.CancelToken.source();
    setLoading(false);
    setErrorServer(false);
    setItem(null);

    const fetch = async () => {
      if (query) {
        try {
          // console.log('🚀 ~ DetailCarlton.js ~ Init');
          setLoading(true);
          const dataC = {
            id: query.yachtId,
            title: query.disembarkPort,
            embar: query.disembarkPort,
            arrival: query.embarkDate,
            departure: query.disembarkDate,
            duration: query.nights,
            excluded: query.excluded,
            included: query.included,
            route: query.itinerary,
            description: query.voyage_long_description,
            images: [{ url: query.voyage_image }, { url: query.map_image }],
            region: query.region,
            price: query.portFee_USD,
            categories: query.inventory.suite,
          };
          setCruises(dataC);
          setItem(dataC);

          /*       const dataCruise = await getDetails(masterCruiseID);

          const dataC = {
            masterCruiseId: dataCruise.masterCruiseId,
            title: dataCruise.title,
            arrival: dataCruise.arrival,
            departure: dataCruise.departure,
            duration: dataCruise.duration,
            excluded: dataCruise.excluded,
            included: dataCruise.included,
            route: dataCruise.route,
            description: dataCruise.ship.description,
            images: dataCruise.ship.images,
            features: dataCruise.ship.features,
            categories: assignPrice(
              dataCruise.ship.categories,
              dataCruise.priceCat,
            ),
          };

          setCruises(dataC.categories);
 */
          /*           if (query.count >= 1) {
            const data = dataC;

            setItem(data);

            if (data.route?.points[0]) {
              setPosition([
                {
                  id: data.masterCruiseId,
                  place: data.title,
                  raiting: data.stars,
                  latitude: data.route.points[0].latitude || null,
                  longitude: data.route.points[0].longitude || null,
                },
              ]);

              setCenter({
                latitude: data.route.points[0].latitude || null,
                longitude: data.route.points[0].longitude || null,
              });
            }
          } else {
            const data = dataCruise;
            setItem(data);

            if (data.route?.points[0]) {
              setPosition([
                {
                  id: data.masterCruiseId,
                  place: data.title,
                  raiting: data.stars,
                  latitude: data.route.points[0].latitude || null,
                  longitude: data.route.points[0].longitude || null,
                },
              ]);

              setCenter({
                latitude: data.route.points[0].latitude || null,
                longitude: data.route.points[0].longitude || null,
              });
            }
          } */
        } catch (err) {
          // console.log('🚀 ~ DetailCarlton.js', { err });

          setItem(null);
          setErrorData(Array.isArray(err) ? err : [err]);
          setErrorServer(true);
        } finally {
          setLoading(false);
        }
      }
    };

    fetch();

    return () => {
      // console.log('🚀 ~ DetailCarlton.js ~ Cancel');
      source.cancel();
    };
  }, [query, i18n]);

  const handleBookClick = rate => makeBook(item, rate);

  if (loading) return <CargandoOpciones hideLooking />;

  return (
    <>
      <Modal show={showCurrency} onHide={handleCloseCurrency} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('itinerario')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>{t('Nombre')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{text}</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      <SectionTop
        menu={t('Cruceros')}
        destino={query.arrival}
        nombre={item?.title}
        direccion={item?.region || ''}
      />

      {!item ? (
        <EmptyData />
      ) : (
        <Container fluid className="tw-my-3">
          <Row>
            <Col xs="12" lg="3">
              <Row>
                <Col xs="12" lg="12">
                  <FormSidebar>
                    <FormSearch inline showDestiny={false} />
                  </FormSidebar>
                </Col>
                {/*   <Col xs="12" lg="12">
                  <Row className="tw-justify-center tw-my-4">
                    <Col xs="12" lg="10">
                      {item && (
                        <MapSidebar
                          items={[item]}
                          position={position}
                          setPosition={setPosition}
                          positionsList={[position]}
                          center={center}
                          setCenter={setCenter}
                        />
                      )}
                    </Col>
                  </Row>
                </Col> */}
              </Row>
            </Col>
            <Col xs="12" lg="9">
              {errorServer ? (
                <ErrorServer errorData={errorData} errorStatus={errorStatus} />
              ) : (
                <>
                  <CarouselTopDetail
                    photos={item.images}
                    numberCards={2}
                    imgHeight={260}
                  />
                  <DescripcionDetail description={item.description} />
                  {/*  <Col xs="12" className="tw-px-0 tw-mx-0">
                    <h1>
                      <Cabins>
                        <Text
                          size="30"
                          lineHeight="50"
                          color="#FFFFFF"
                          className="tw-cursor-pointer"
                          onClick={handleClick}
                        >
                          Escoge el tipo de cabina
                        </Text>
                      </Cabins>
                    </h1>
                  </Col> */}
                  <CabinsDetail
                    cabinas={cruises.categories}
                    onHandleClickReservar={handleBookClick}
                  />
                </>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default DetailCarlton;
