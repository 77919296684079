import React from 'react';

import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsWhatsapp } from 'react-icons/bs';
import { FiMessageSquare } from 'react-icons/fi';
import { IoIosCall } from 'react-icons/io';
import styled from 'styled-components';
import Travel from './Travel';

const { REACT_APP_WHATS_APP, REACT_APP_WEB_CHAT } = process.env;

const Home = () => {
  const { t } = useTranslation();

  return (
    <Container fluid style={{ 'margin-block': '7rem' }}>
      <Row>
        <Col xs="12">
          <Row className="md:tw-justify-center">
            <Col xs="12" xl="9">
              <h4 className="tw-text-primary">{t('MyPreferencesTitle')}</h4>
            </Col>

            <Col xs="12" md="8" xl="6" className="tw-mb-3">
              <CardStyled>
                <Card.Body>
                  <Travel />
                </Card.Body>
              </CardStyled>
            </Col>

            <Col xs="12" md="4" xl="3">
              <CardRightStyled>
                <Card.Body>
                  <h4 className="tw-text-white">
                    {t('DashboardBestOptionTravel')}
                  </h4>
                  <p
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: t('DashboardBestOptionTravelDescription'),
                    }}
                  />
                  <div className="tw-flex tw-w-full tw-justify-around">
                    {REACT_APP_WHATS_APP?.length && (
                      <WrapperIcon
                        href={REACT_APP_WHATS_APP}
                        target="_blank"
                        className="tw-cursor-pointer tw-text-primary"
                      >
                        <BsWhatsapp />
                      </WrapperIcon>
                    )}
                    {REACT_APP_WEB_CHAT?.length && (
                      <WrapperIcon
                        href={REACT_APP_WEB_CHAT}
                        target="_blank"
                        className="tw-cursor-pointer tw-text-primary"
                      >
                        <FiMessageSquare />
                      </WrapperIcon>
                    )}
                    <WrapperIcon className="tw-cursor-pointer tw-text-primary">
                      <IoIosCall />
                    </WrapperIcon>
                  </div>
                </Card.Body>
              </CardRightStyled>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const CardStyled = styled(Card)`
  box-shadow: 0px 1px 15px #00000014 !important;
  border-radius: 22px !important;
  overflow: hidden;
`;

const CardRightStyled = styled(CardStyled)`
  background-color: #003c74 !important;
  color: white !important;
`;

const WrapperIcon = styled.a`
  font-size: 30px;
  height: 66px;
  aspect-ratio: 1;
  border-radius: 100%;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Home;
