import React from 'react';

import Pagination from '../Pagination';
import CardTicket from './CardTicket';

const ListTickets = ({ ticketdata = [], setTicket }) => {
  return (
    <ul className="tw-flex tw-flex-col tw-gap-4">
      <Pagination items={ticketdata}>
        {ticket => {
          return (
            <li>
              <CardTicket ticket={ticket} setTicket={setTicket} />
            </li>
          );
        }}
      </Pagination>
    </ul>
  );
};

export default ListTickets;
