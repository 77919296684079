import React, { useEffect } from 'react';
import { useLocation, useRouteMatch, Switch, Route } from 'react-router-dom';
import { scrollUp } from '../../utils/scroll';
import MainContainer from '../../shared/MainContainer';
import DepositWeek from './DepositWeek';
import SectionPromotionNY from '../../components/SectionPromotionNY';
import PriceBottom from '../../shared/PriceBottom';

const Home = () => {
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  useEffect(() => {
    scrollUp();
  }, [pathname]);

  return (
    <MainContainer>
      <div className="tw-flex tw-h-full tw-min-h-[inherit] tw-w-full tw-flex-col">
        <div className="tw-flex-1">
          <Switch>
            <Route exact path={path} component={DepositWeek} />
          </Switch>
        </div>

        <SectionPromotionNY />
        <PriceBottom />
      </div>
    </MainContainer>
  );
};

export default Home;
