import { useCallback } from 'react';
import { useNumericFormat } from 'react-number-format';

const usePriceFormat = ({ decimalScale = 2, prefix = '$' }) => {
  const { format } = useNumericFormat({
    thousandSeparator: true,
    prefix,
    decimalScale,
    fixedDecimalScale: true,
  });

  const formatPrice = useCallback(
    ({ value, currency }) => {
      const currencyText = currency?.trim?.()?.length
        ? `${currency.trim()} `
        : '';

      const formattedPrice = `${currencyText}${format(`${value ?? 0}`)}`;

      return formattedPrice;
    },
    [format],
  );

  return formatPrice;
};

export default usePriceFormat;
