import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import CardCar from '../../components/Cars/CardCar';
import FilterSidebar from '../../components/Cars/FilterSidebar';
import FormSearch from '../../components/Cars/FormSearch';
import MapOffices from '../../components/Cars/MapOffices';
import EmptyItems from '../../components/EmptyItems';
import ErrorServer from '../../components/ErrorServer';
import Pagination from '../../components/Pagination';
import SectionTop from '../../components/SectionTop';
import useAppParams from '../../hook/useAppParams';
import useAppTranslation from '../../hook/useAppTranslation';
import CargandoOpciones from '../../loader/CargandoOpciones';
import { getCars, getOffices } from '../../services/cars';
import { getLabelByDestinyId } from '../../services/destinations';
import FormSidebar from '../../shared/FormSidebar';
import { scrollUp } from '../../utils/scroll';
import sleep from '../../utils/sleep';
import { encode } from '../../utils/uriComponent';
import styles from './List.module.css';

const errorServer = false;
const List = () => {
  const { t, language } = useAppTranslation();
  const { query, office } = useAppParams();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dropOff, setDropOff] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [switchFilters, setSwitchFilters] = useState(null);
  const [filters, setFilters] = useState(null);
  const [officesPickUp, setOfficesPickUp] = useState([]);

  useEffect(() => {
    let didCancel = false;
    const fetch = async () => {
      await sleep(500);
      if (didCancel || !query) return;

      setLoading(true);
      setDropOff(null);
      setData([]);
      setSwitchFilters(null);
      setFilters(null);
      setOfficesPickUp([]);

      const [labelDropOff, cars, officesPickUpByDestiny] = await Promise.all([
        getLabelByDestinyId({
          destinyId: query.pickUp,
          language,
        }),

        getCars({
          pickUpDate: query?.pickUpDate,
          pickUpTime: query?.pickUpTime,
          pickUpLocation: query?.pickUp,
          pickUpOffice: office?.officeCode,
          dropOffDate: query?.dropOffDate,
          dropOffTime: query?.dropOffTime,
          dropOffLocation: query?.dropOff,
          dropOffOffice: office?.officeCode,
          language,
          currency: null,
        }),

        getOffices({
          id: query.pickUp,
        }),
      ]);

      if (didCancel) return;

      setDropOff(labelDropOff);
      setData(cars.vehicles);
      setSwitchFilters(cars.filters);
      setFilters(null);
      setOfficesPickUp(officesPickUpByDestiny);
      setLoading(false);
      scrollUp();
    };

    fetch();
    return () => {
      didCancel = true;
    };
  }, [query, office, language]);

  const handleOfficeMarkerClick = useCallback(
    ({ show, ...officeRest }) => {
      if (show) {
        window.location.reload();
        return;
      }

      const encodedQuery = encode(query);
      const encodedOffice = encode(officeRest);

      history.push(`/cars/list/${encodedQuery}/${encodedOffice}`);
    },

    [history, query],
  );

  const handleCarClick = useCallback(
    ({
      name,
      sippCode,
      ccrc,
      company,
      pickUpLocation,
      dropOffLocation,
      price,
    }) => {
      const encodedQuery = encode(query);
      const encodedOffice = office && encode(office);
      const encodedCar = encode({
        name,
        pickUpLocation,
        dropOffLocation: dropOffLocation?.length
          ? dropOffLocation
          : pickUpLocation,
        currency: price?.currency,
        sippCode,
        ccrc,
        company,
      });

      const params = [encodedQuery, encodedOffice, encodedCar]
        .filter(Boolean)
        .join('/');

      window.open(`/cars/detail/${params}`, '_blank');
    },

    [query, office],
  );

  const cars = useMemo(() => {
    if (!filters || !Object.keys(filters).length) return data;

    return (data ?? []).filter(it => {
      if (filters?.price) {
        let min = parseFloat(filters.price.min);
        min = !Number.isNaN(min) ? min : Number.NEGATIVE_INFINITY;

        let max = parseFloat(filters.price.max);
        max = !Number.isNaN(max) ? max : Number.POSITIVE_INFINITY;

        if (it.price.total < min || it.price.total > max) {
          return false;
        }
      }

      if (filters.isManual !== filters.isAutomatic) {
        if (
          it.isManual !== (filters.isManual ?? false) &&
          it.isAutomatic !== (filters.isAutomatic ?? false)
        ) {
          return false;
        }
      }

      if (filters.capacity?.length > 0) {
        if (
          filters.capacity.every(passengers => {
            let min = parseFloat(passengers.min);
            min = !Number.isNaN(min) ? min : Number.NEGATIVE_INFINITY;

            let max = parseFloat(passengers.max);
            max = !Number.isNaN(max) ? max : Number.POSITIVE_INFINITY;

            return it.passengers < min || it.passengers > max;
          })
        ) {
          return false;
        }
      }

      if (typeof filters.airConditioning === 'boolean') {
        if (it.airConditioning !== filters.airConditioning) {
          return false;
        }
      }

      return true;
    });
  }, [data, filters]);

  const renderCard = useCallback(
    car => <CardCar car={car} onClick={handleCarClick} />,
    [handleCarClick],
  );

  if (loading) return <CargandoOpciones />;

  if (errorServer)
    return (
      <Container>
        <ErrorServer />
      </Container>
    );

  return (
    <>
      <SectionTop
        menu={t('Cars', { count: data?.length })}
        destino={dropOff}
        total={data?.length || 0}
        product={t('Cars', {
          count: data?.length,
        })}
      />

      <Container fluid className="tw-my-3">
        <Row>
          <Col xs="12" lg="4">
            <Row xs="1" className={styles.containerFilters}>
              <Col>
                <FormSidebar>
                  <FormSearch inline filters={query} />
                </FormSidebar>
              </Col>

              <Col>
                <MapOffices
                  onMarkerClick={handleOfficeMarkerClick}
                  offices={officesPickUp}
                  current={office}
                />
              </Col>

              <Col>
                <FilterSidebar config={switchFilters} onChange={setFilters} />
              </Col>
            </Row>
          </Col>

          <Col xs="12" lg="8" className={styles.containerCars}>
            {!cars?.length ? (
              <EmptyItems />
            ) : (
              <Pagination
                items={cars}
                render={renderCard}
                page={currentPage}
                onChange={setCurrentPage}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default List;
