import React from 'react';

import { Formik } from 'formik';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import useAppTranslation from '../../hook/useAppTranslation';
import InputForm from '../../shared/InputForm';
import styles from './PriceGuarantee.module.scss';

const PriceGuarantee = () => {
  const { t } = useAppTranslation();

  return (
    <Formik
      initialValues={{
        traveler: null,
        reservation: null,
        competitorWebsiteURL: null,
        competitorWebsitePrice: null,
      }}
    >
      <Form inline>
        <Container className="tw-py-5">
          <Row xs="1" lg="2">
            <Col>
              <img
                className={styles.image}
                src="https://img.freepik.com/premium-photo/search-information-internet-young-adult-girl-is-sitting-with-laptop-her-lap-holding-magnifying-glass-near-her-eye-blue-background_182793-170.jpg"
                alt="temp"
              />
            </Col>

            <Col>
              <Row xs="1">
                <Col>
                  <span className={styles.title}>{t('PGTitle')}</span>
                </Col>

                <Col>
                  <span className={styles.subTitle}>{t('PGSubTitle')}</span>
                </Col>

                <Col>
                  <span className={styles.submitYourClaim}>
                    {t('PGSubmitYourClaim')}
                  </span>
                </Col>

                <Col>
                  <Row xs="1" className={styles.containerForm}>
                    <Col className={styles.input}>
                      <InputForm
                        as="select"
                        name="traveler"
                        label={t('PGTraveler')}
                        required={false}
                      >
                        <option value={-1}>Demo Account</option>
                      </InputForm>
                    </Col>

                    <Col className={styles.input}>
                      <InputForm
                        as="select"
                        name="reservation"
                        label={t('PGReservation')}
                        required={false}
                      >
                        <option value={-1}>-- Elegir Reservación --</option>
                        <option value={-2}>
                          1136820 - Vehículo - Hertz - Cancun International
                          Airport
                        </option>
                        <option value={-3}>
                          1074473 - Vehículo - Alamo - Cancun International
                          Airport
                        </option>
                      </InputForm>
                    </Col>

                    <Col className={styles.input}>
                      <InputForm
                        name="competitorWebsiteURL"
                        label={t('PGCompetitorWebsiteURL')}
                        required={false}
                      />
                    </Col>

                    <Col className={styles.input}>
                      <InputForm
                        name="competitorWebsitePrice"
                        label={t('PGCompetitorWebsitePrice')}
                        required={false}
                      />
                    </Col>

                    <Col>
                      <Button variant="link" className="tw-m-0 tw-p-0">
                        {t('PGTermsConditions')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </Formik>
  );
};

export default PriceGuarantee;
