import React, { useState } from 'react';

import { Field, FieldArray } from 'formik';
import { Button, ButtonGroup, Dropdown, Form } from 'react-bootstrap';
import { MdPeople } from 'react-icons/md';
import useAppTranslation from '../../hook/useAppTranslation';
import Counter from '../../shared/Counter';

const Travelers = ({ inline, disabled, name, value }) => {
  const { t } = useAppTranslation();
  const [show, setShow] = useState(false);
  const adults = value?.adults ?? 0;
  const children = value?.children ?? [];
  const travelers = adults + children.length;
  const description = t('FlightsTravelers', {
    count: travelers,
  });

  return (
    <Dropdown
      as={ButtonGroup}
      className="filter-traveler tw-m-0 tw-w-full tw-p-0"
      size="lg"
      show={show}
      onToggle={() => {
        setShow(!show);
      }}
    >
      <Dropdown.Toggle
        className="tw-flex tw-items-center tw-justify-between tw-text-base"
        disabled={disabled}
      >
        <span>
          <MdPeople /> {description}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className={`tw-w-full tw-p-0 ${
          !inline && 'lg:tw-w-[255%] xl:tw-w-[220%] 2xl:tw-w-[165%]'
        }`}
      >
        <div className="tw-z-10 tw-w-full tw-origin-top-right tw-divide-y tw-rounded-md tw-bg-white tw-text-black tw-shadow-lg tw-ring-1 tw-ring-black/5 focus:tw-outline-none">
          <section className="tw-flex tw-max-h-60 tw-w-full tw-flex-col tw-gap-4 tw-divide-y tw-overflow-y-scroll tw-p-4">
            <div className="tw-grid tw-w-full tw-grid-cols-2">
              <span className="tw-flex tw-items-center">
                {t('FlightsAdults', { count: adults })}
              </span>

              <Field name={`${name}.adults`}>
                {({ field, form }) => (
                  <Counter
                    name={field.name}
                    value={field.value}
                    min={1}
                    max={6}
                    onChange={newAdults => {
                      form.setFieldValue(field.name, newAdults);
                      if (newAdults < children.length) {
                        form.setFieldValue(
                          `${name}.children`,
                          children.slice(0, newAdults),
                        );
                      }
                    }}
                  />
                )}
              </Field>
            </div>

            <div className="tw-grid tw-w-full tw-grid-cols-2">
              <span className="tw-flex tw-items-center">
                {t('FlightsChildren', { count: children.length })}
              </span>

              <FieldArray name={`${name}.children`}>
                {arrayHelpers => {
                  return (
                    <Counter
                      name={arrayHelpers.name}
                      value={children.length}
                      min={0}
                      max={adults}
                      onChange={newChildren => {
                        if (children.length > newChildren) {
                          arrayHelpers.remove(children.length - 1);
                        } else {
                          arrayHelpers.push(0);
                        }
                      }}
                    />
                  );
                }}
              </FieldArray>
            </div>

            {(children ?? []).map((age, childIndex) => {
              const fieldChildren = `${name}.children.${childIndex}`;

              return (
                <Field key={fieldChildren} name={fieldChildren}>
                  {({ field }) => {
                    // console.log('🚀 ~ Travelers.jsx', { args });

                    return (
                      <div className="tw-grid tw-w-full tw-grid-cols-2">
                        <span className="tw-flex tw-items-center">
                          {t('FlightsChildAge')} {childIndex + 1}
                        </span>

                        <Form.Control
                          as="select"
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                        >
                          {Array.from({ length: 13 }).map((_, year) => (
                            <option
                              key={`${fieldChildren}-${year}`}
                              value={year}
                            >
                              {t('FlightsChildAgeYears', {
                                count: year,
                              })}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    );
                  }}
                </Field>
              );
            })}
          </section>

          <section className="tw-flex tw-items-center tw-justify-center tw-border-x-0 tw-border-b-0 tw-border-t-2 tw-border-solid tw-border-gray-300 tw-py-2">
            <Button
              variant="primary"
              className="tw-flex tw-flex-col"
              onClick={() => {
                setShow(false);
              }}
            >
              <span className="tw-block">{t('FlightsDone')}</span>
              <small className="tw-block">{description}</small>
            </Button>
          </section>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Travelers;
