import React, { useState } from 'react';

import moment from 'moment';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FormSidebar from '../../shared/FormSidebar';
import SelectDestination from '../../shared/SelectDestination';
import FiltersDateRange from './FiltersDateRange';

const CondosFormSideBar = ({ searching, sendData }) => {
  const { t, i18n } = useTranslation();
  const [destino, setDestino] = useState(searching.destiny);
  const [startDate, setStartDate] = useState(
    moment(searching.checkIn, 'DD/MM/YYYY').format(),
  );
  const [endDate, setEndDate] = useState(
    moment(searching.checkOut, 'DD/MM/YYYY').format(),
  );
  const [errorDestino, setErrorDestino] = useState(false);
  const history = useHistory();

  const onHandleClickBuscar = () => {
    if (destino === null) setErrorDestino(true);
    else {
      setErrorDestino(false);
      const data = {
        destiny: destino,
        destino_name: null,
        checkIn: moment(startDate).startOf('month').format('DD-MM-YYYY'),
        checkOut: moment(endDate).endOf('month').format('DD-MM-YYYY'),
        currency: 'USD',
        language: `${i18n.language === 'es' ? 'CAS' : 'EN'}`,
        rooms: [
          {
            adults: 1,
            children: {
              ages: [],
            },
          },
        ],
      };

      sendData(data);

      history.push(
        `/condos?search=${encodeURIComponent(JSON.stringify(data))}`,
      );
    }
  };

  return (
    <FormSidebar>
      <Row className="tw-mb-2">
        <Col xs="12" sm="12" md="12" lg="12" xl="12">
          <SelectDestination
            value={destino}
            onChange={evt => {
              const { value } = evt?.target ?? {};
              if (value === null) setErrorDestino(true);
              else setErrorDestino(false);

              setDestino(value);
            }}
          />

          {errorDestino && (
            <Form.Control.Feedback type="invalid">
              {t('CampoRequerido')}
            </Form.Control.Feedback>
          )}
        </Col>

        <Col xs="12" sm="12" md="12" lg="12" xl="12" className="tw-mt-4">
          <FiltersDateRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Col>

        <Col xs="12" sm="12" md="12" lg="12" xl="12">
          <Button
            block
            variant="light"
            onClick={onHandleClickBuscar}
            className="tw-font-bold"
          >
            {t('Buscar')}
          </Button>
        </Col>
      </Row>
    </FormSidebar>
  );
};

export default CondosFormSideBar;
