import React, { Suspense, lazy, useEffect, useState } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const ReactQueryDevtoolsProduction = lazy(() =>
  // eslint-disable-next-line import/extensions
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    d => ({
      default: d.ReactQueryDevtools,
    }),
  ),
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 3 * (60 * 1000),
    },
  },
});

const withQueryClient = Component => () => {
  const [showDevTools, setShowDevTools] = useState(false);

  useEffect(() => {
    window.toggleDevtools = () => {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ withQueryClient.jsx ~ toggleDevtools');
      setShowDevTools(old => !old);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Component />

      <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
      {showDevTools && (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </Suspense>
      )}
    </QueryClientProvider>
  );
};

export default withQueryClient;
