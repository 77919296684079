import moment from 'moment';
import { prepareConvertCurrency } from '../utils/currency';
import { CRUISE_BY_ID, CRUISE_LIST, CRUISE_SEARCH_PARAMS } from './Routes';
import instance from './instance';

const sources = process.env.REACT_APP_SUNAPI_API_NAME_CRUISES;
const MARK_UP = 0.31;

export const getCruises = async ({
  departure,
  arrival,
  area,
  cruiseLine,
  duration,
  ship,
}) => {
  // console.log('🚀 ~ Cruises.js', { area, cruiseLine, duration, ship });
  const request = instance.get(CRUISE_LIST, {
    params: {
      sources,
      departure: moment(departure).format('YYYY-MM-DD'),
      arrival: moment(arrival).format('YYYY-MM-DD'),
      area,
      cruiseline: cruiseLine,
      duration,
      ship,
    },
  });

  const response = request
    .then(async res => {
      // console.log('🚀 ~ Cruises.js', { res });
      if (res?.data?.errors?.length) throw res.data.errors;

      const convertCurrency = await prepareConvertCurrency();
      const { data } = res;
      data.cruises = await Promise.all(
        data.cruises.map(
          async ({
            currency,
            currencySymbol,
            priceUnformatted,
            price,
            ...rest
          }) => {
            const newCruise = { ...rest };
            newCruise.departure = moment(rest.departure, 'M/D/YYYY');
            newCruise.departureRaw = moment(rest.departureRaw, 'YYYY-MM-DD');
            newCruise.departureCruise = moment(
              rest.departureCruise,
              'M/D/YYYY',
            );

            newCruise.arrival = moment(newCruise.arrival, 'M/D/YYYY');
            newCruise.arrivalRaw = moment(newCruise.arrivalRaw, 'YYYY-MM-DD');
            newCruise.arrivalCruise = moment(
              newCruise.arrivalCruise,
              'M/D/YYYY',
            );

            newCruise.duration = parseInt(newCruise.duration, 10);

            newCruise.price = convertCurrency({
              total: parseFloat(priceUnformatted),
              currency,
              prefix: currencySymbol,
            });

            if (MARK_UP > 0) {
              newCruise.price.total = Math.ceil(
                newCruise.price.total - newCruise.price.total * MARK_UP,
              );
            }

            return newCruise;
          },
        ),
      );

      return res.data;
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ cruises.js', { err });

      return [];
    });

  return response;
};

export const getParams = async ({ area, cruiseLine } = {}) => {
  // console.log('🚀 ~ Cruises.js', { area, cruiseline: cruiseLine });
  const request = instance.get(CRUISE_SEARCH_PARAMS, {
    params: { sources, area, cruiseline: cruiseLine },
  });

  const response = request.then(res => res?.data).catch(() => ({}));

  return response;
};

export const getCruise = async ({ masterCruiseID }) => {
  const request = instance.get(CRUISE_BY_ID({ masterCruiseID }));
  const response = await request
    .then(async res => {
      if (res?.data?.errors?.length) throw res.data.errors;

      const { data } = res;
      const { currency, currencySymbol: prefix } = data;
      const convertCurrency = await prepareConvertCurrency();

      data.priceCat = data.priceCat.map(it => {
        const value = it.price
          .replace(new RegExp(`\\s*${prefix}\\s*`, 'ig'), '')
          .replace('.', '');

        return {
          ...it,
          price: convertCurrency({
            total: parseFloat(value),
            currency,
            prefix,
          }),
        };
      });

      data.ship.categories = data.ship.categories
        .map(category => {
          const priceCat = data.priceCat.find(
            ({ text }) => text === category.text,
          );

          if (category.price === '' && !priceCat) return null;

          const price =
            category.price !== ''
              ? convertCurrency({
                  total: category.price,
                  currency,
                  prefix,
                })
              : priceCat.price;

          return {
            ...category,
            price,
            children: category.children.map(child => {
              return { ...child, price };
            }),
          };
        })
        .filter(it => it);

      // console.log('🚀 ~ cruises.js', JSON.parse(JSON.stringify({ data })));
      return data;
    })
    .catch(err => {
      // console.log('🚀 ~ cruises.js', { err });
      throw err;
    });

  return response;
};
