import React, { useState } from 'react';

import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';
import MapSidebar from '../../components/MapSidebar';
import SectionTop from '../../components/SectionTop';
import CardPreOrder from '../../components/SportingEvents/CardPreOrder';
import FormSearch from '../../components/SportingEvents/FormSearch';
import ListTickets from '../../components/SportingEvents/ListTickets';
import useAppSearchParams from '../../hook/useAppSearchParams';
import useAppTranslation from '../../hook/useAppTranslation';
import useDev from '../../hook/useDev';
import FormSidebar from '../../shared/FormSidebar';

const Detail = () => {
  const { t, timeFormatMoment } = useAppTranslation({
    keyPrefix: 'SportingEvents',
  });
  const { isDev } = useDev();
  const [query] = useAppSearchParams();
  const { sportingEvent, values } = query ?? {};
  const position = sportingEvent?.position
    ? {
        ...sportingEvent.position,
        place: sportingEvent.venue,
      }
    : null;
  const [ticket, setTicket] = useState(null);
  const [quantity, setQuantity] = useState(0);

  return (
    <>
      <SectionTop
        className="tw-m-0"
        menu={t('Title_one')}
        nombre={sportingEvent?.caption}
        direccion={
          <div className="tw-flex tw-flex-col tw-gap-4">
            <span>{sportingEvent?.venueAddress}</span>
            {sportingEvent?.date && (
              <span>
                {moment(sportingEvent.date).format(
                  `ddd, D MMM, ${timeFormatMoment}`,
                )}
              </span>
            )}
          </div>
        }
      />

      <Container fluid className="tw-my-3">
        <Row xs="1">
          <Col lg="3">
            <Row xs="1" className="tw-gap-4">
              <Col>
                <FormSidebar>
                  <FormSearch filters={values} inline disabled />
                </FormSidebar>
              </Col>

              {sportingEvent.venue_img && (
                <Col>
                  <img
                    className="tw-w-full"
                    src={sportingEvent.venue_img}
                    alt={sportingEvent.venue}
                  />
                </Col>
              )}

              {position && (
                <Col>
                  <MapSidebar
                    items={[sportingEvent]}
                    position={0}
                    positionsList={[position]}
                    center={position}
                  />
                </Col>
              )}
            </Row>
          </Col>

          <Col lg="9">
            <ListTickets
              ticketdata={sportingEvent?.ticketdata}
              setTicket={setTicket}
            />
          </Col>
        </Row>

        {isDev && (
          <Row xs="1" className="tw-mt-3">
            {Object.entries({
              query,
            })
              .filter(([, value]) => value)
              .map(([key, value], _, self) => {
                const lg = 12 / self.length;

                return (
                  <Col lg={lg}>
                    <pre>
                      {JSON.stringify(
                        {
                          [key]: value,
                        },
                        null,
                        2,
                      )}
                    </pre>
                  </Col>
                );
              })}
          </Row>
        )}
      </Container>

      <CardPreOrder
        ticket={ticket}
        setTicket={setTicket}
        quantity={quantity}
        setQuantity={setQuantity}
      />
    </>
  );
};

export default Detail;
