import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useAppTranslation from '../hook/useAppTranslation';
import { getDestinies, getLabelsByDestinyId } from '../services/destinations';
import sleep from '../utils/sleep';
import AsyncSelectForm from './AsyncSelectForm';

const SelectDestination = ({
  name,
  value,
  isValid,
  isInvalid,
  onChange,
  onBlur,
  disabled,
  className,
  typeDestination,
  service = 'DESTINATION',
}) => {
  const { t, language } = useAppTranslation();
  const [labels, setLabels] = useState(null);

  useEffect(() => {
    let didCancel = false;
    const fetch = async () => {
      await sleep(500);
      if (didCancel || !value) return;

      const { es, en } = await getLabelsByDestinyId({ destinyId: value });
      setLabels(prev => {
        if (prev && prev.es !== es && prev.es !== en) return prev;

        return {
          es: es ?? prev?.es ?? null,
          en: en ?? prev?.en ?? null,
        };
      });
    };

    fetch();
    return () => {
      didCancel = true;
    };
  }, [value]);

  const valueSelected = useMemo(() => {
    if (!value) return null;

    return {
      value,
      label: labels?.[language] ?? `${t('Loading')}...`,
    };
  }, [t, language, value, labels]);

  const loadOptions = useCallback(
    async searchString =>
      getDestinies({
        searchString,
        language,
        typeDestination,
        service,
      })
        .then(data => {
          // console.log('🚀 ~ SelectDestination.jsx', { data });

          return data;
        })
        .catch(() => []),

    [language, typeDestination, service],
  );

  const handleChange = evt => {
    const label = evt?.target?.label ?? '';

    setLabels({
      es: label,
      en: label,
    });

    onChange(evt);
  };

  return (
    <AsyncSelectForm
      name={name}
      value={value}
      isValid={isValid}
      isInvalid={isInvalid}
      onChange={handleChange}
      onBlur={onBlur}
      disabled={disabled}
      className={className}
      loadOptions={loadOptions}
      valueSelected={valueSelected}
    />
  );
};

export default SelectDestination;
