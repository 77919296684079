import React, { useEffect } from 'react';

import HomeMap from '../components/HomeMap';
import OfertasUMinuto from '../components/welcome/OfertasUMinuto';
import WelcomeFooter from '../components/welcome/WelcomeFooter';
import WelcomeSectionTop from '../components/welcome/WelcomeSectionTop';
import PriceBottom from '../shared/PriceBottom';

import '../css/Welcome.css';

const Welcome = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WelcomeSectionTop />
      <OfertasUMinuto />
      <HomeMap />
      <hr />
      <PriceBottom />
      <WelcomeFooter />
    </>
  );
};

export default Welcome;
