/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';

import { Button, Modal, Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { BsEyeFill } from 'react-icons/bs';

const TableRitz = ({ t, rates, onHandleClickReservar }) => {
  const [showTaxes, setShowTaxes] = useState(false);
  const [taxes, setTaxes] = useState([]);

  const handleCloseTaxes = () => setShowTaxes(false);
  const [countShow, setCountShow] = useState(3);
  const [texto, setTexto] = useState(t('VerMas'));

  const onHandleClickTaxes = price => {
    // eslint-disable-next-line no-console
    console.log('🚀 ~ TableRitz.js', { price });
    setTaxes(price);
    setShowTaxes(true);
  };

  const showAll = () => {
    if (countShow === 1000) {
      setTexto(t('VerMas'));
      setCountShow(3);
    } else {
      setTexto(t('VerMenos'));
      setCountShow(1000);
    }
  };

  return (
    <>
      {/*     <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('detalles')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>{t('descripcion')}</th>
              </tr>
            </thead>
            <tbody>
              {politicas.map((pol, i) => (
                <tr key={i}>
                  <td>{pol}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal> */}
      <Modal show={showTaxes} onHide={handleCloseTaxes} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('Impuestos')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>{t('Precio')}</th>
                {/* port Fee USD */}
                <th className="tw-text-center">{t('Impuestos')}</th>
                <th className="tw-text-center">{t('Total')}</th>
              </tr>
            </thead>
            <tbody>
              <tr key="ṕrice" className="tw-text-success">
                <td>{taxes.fareFrom_USD_PortFeesExcluded}</td>
                <td className="tw-text-center">
                  {taxes.fareFrom_USD - taxes.fareFrom_USD_PortFeesExcluded}
                </td>
                <td className="tw-text-center">{taxes.fareFrom_USD} USD</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Table hover striped bordered responsive className="table-rates tw-mt-3">
        <thead>
          <tr>
            <th>{t('Detalle')}</th>
            <th className="tw-text-center">{t('Precio')}</th>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th />
          </tr>
        </thead>
        <tbody>
          {rates.map(
            (item, i) =>
              i < countShow && (
                <tr key={i}>
                  <td width="50%">
                    <label className="tw-mb-0 tw-block tw-font-bold tw-text-secondary">
                      {item.suiteName}
                    </label>
                    <label className="tw-text-secondary">
                      {item.suiteCategory}
                    </label>
                    {/* 
                    <AmenitiesRoomDetalle
                      amenities={item.roomDescription[0].amenities}
                    /> */}
                  </td>
                  <td className="tw-text-center" width="20%">
                    <div className="tw-font-bold tw-text-success">
                      {`${item.fareFrom_USD} USD`}

                      {/* <FaInfoCircle
                        className="tw-text-info icon-18 tw-mb-1 tw-ml-2"
                        onClick={() => onHandleClickTaxes(item)}
                      /> */}
                    </div>
                  </td>
                  <td width="30%" className="tw-text-center">
                    <div>
                      <Button
                        variant="outline-primary"
                        className="m-auto tw-block tw-px-5 tw-font-bold"
                        onClick={() => onHandleClickReservar(item)}
                      >
                        {t('Reservar')}
                      </Button>
                      <small>
                        <Button
                          variant="link"
                          size="sm"
                          className="tw-font-bold  tw-text-danger"
                          onClick={() => onHandleClickTaxes(item)}
                        >
                          <BsEyeFill /> {t('VerDetalles')}
                        </Button>
                      </small>
                    </div>
                  </td>
                </tr>
              ),
          )}
        </tbody>
        {rates.length > 3 && (
          <tfoot>
            <tr>
              <td colSpan="4" className="tw-text-center">
                <Button
                  variant="light"
                  size="sm"
                  className="tw-font-bold"
                  onClick={showAll}
                >
                  {texto}
                </Button>
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
    </>
  );
};

export default withTranslation()(TableRitz);
