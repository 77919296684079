import React, { useState } from 'react';

import moment from 'moment';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SelectDestination from '../shared/SelectDestination';

import 'react-datepicker/dist/react-datepicker.css';
import '../css/DatePicker.css';
import FiltersDateRange from './condos/FiltersDateRange';

const SearchCondominios = ({ searching, sendData }) => {
  const { t, i18n } = useTranslation();
  const [destino, setDestino] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().add(1, 'month').startOf('month').format(),
  );
  const [endDate, setEndDate] = useState(
    moment().add(1, 'month').endOf('month').format(),
  );
  const [errorDestino, setErrordestino] = useState(false);
  const history = useHistory();

  // Cargar la información previamente cargada en el filtro
  if (searching) {
    setDestino({ value: searching.destiny, label: searching.destino_name });
    setStartDate(moment(searching.checkIn, 'DD/MM/YYYY').format());
    setEndDate(moment(searching.checkOut, 'DD/MM/YYYY').format());
  }

  const onHandleClickBuscar = () => {
    if (destino == null) setErrordestino(true);
    else {
      const data = {
        destiny: destino,
        destino_name: null,
        checkIn: moment(startDate).startOf('month').format('DD-MM-YYYY'),
        checkOut: moment(endDate).endOf('month').format('DD-MM-YYYY'),
        currency: 'USD',
        language: `${i18n.language === 'es' ? 'CAS' : 'EN'}`,
        rooms: [
          {
            adults: 1,
            children: {
              ages: [],
            },
          },
        ],
      };

      if (sendData != null) sendData(data);

      history.push(
        `/condos?search=${encodeURIComponent(JSON.stringify(data))}`,
      );
    }
  };

  return (
    <Container className="form-padding">
      <Row className="tw-items-center">
        <Col xs="12" lg="5">
          <Form.Group>
            <Form.Label className="tw-text-slate-50">{t('Destino')}</Form.Label>

            <SelectDestination
              value={destino}
              onChange={evt => {
                const { value } = evt?.target ?? {};
                if (value === null) setErrordestino(true);
                else setErrordestino(false);

                setDestino(value);
              }}
            />

            {errorDestino && (
              <Form.Control.Feedback type="invalid">
                {t('CampoRequerido')}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>

        <Col xs="12" lg="5">
          <FiltersDateRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Col>

        <Col xs="12" lg="2">
          <Button
            block
            variant="outline-primary"
            onClick={onHandleClickBuscar}
            className="tw-font-bold"
          >
            {t('Buscar')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SearchCondominios;
