import React from 'react';

import PaginationRc from 'rc-pagination';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import styles from './Pagination.module.scss';

const PAGE_SIZE = 20;

const Pagination = ({ current, onChange, total, pageSize = PAGE_SIZE }) => {
  return (
    <PaginationRc
      onChange={onChange}
      current={current}
      total={total}
      showLessItems
      showTitle
      pageSize={pageSize}
      className={styles.container}
      prevIcon={<BsChevronLeft />}
      nextIcon={<BsChevronRight />}
      jumpPrevIcon={<BiDotsHorizontalRounded />}
      jumpNextIcon={<BiDotsHorizontalRounded />}
    />
  );
};

export default Pagination;
