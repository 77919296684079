import { css } from 'styled-components';

const slider = css`
  .slick-slider {
    outline: none;

    & > button.slick-arrow {
      z-index: 9;
      display: flex !important;
      background-color: white;
      border-radius: 1rem;
      border-color: var(--button-primary);
      box-shadow: 0 0 0 0.03rem var(--button-primary);

      &.slick-disabled {
        opacity: 0.25;

        &::before {
          opacity: 1 !important;
        }
      }

      &::before {
        position: absolute;
        top: 1px;
        color: var(--button-primary);
      }

      &.slick-prev {
        left: 0.5rem;
      }

      &.slick-next {
        right: 0.5rem;
      }
    }

    & h3 {
      background: #5f9ea0;
      color: #fff;
      font-size: 36px;
      line-height: 100px;
      margin: 10px;
      padding: 2%;
      position: relative;
      text-align: center;
    }
  }
`;

export default slider;
