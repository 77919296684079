import { css } from 'styled-components';

const alert = css`
  .alert.alert-primary {
    color: var(--gray-dark);
    background-color: var(--button-primary-translucent);
    border-color: var(--button-primary);
  }
`;

export default alert;
