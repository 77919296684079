import React from 'react';

import useAppTranslation from '../../hook/useAppTranslation';
import LayoutHome from '../../layouts/LayoutHome';
import FormSearch from '../../components/SportingEvents/FormSearch';

const SportingEvents = () => {
  const { t } = useAppTranslation({
    keyPrefix: 'SportingEvents',
  });

  return (
    <LayoutHome src="images/sporting-events/banner.webp" title={t('Title')}>
      <FormSearch />
    </LayoutHome>
  );
};

export default SportingEvents;
