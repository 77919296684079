import React from 'react';

import moment from 'moment';
import { Form } from 'react-bootstrap';
import useAppField from '../../hook/useAppField';
import useAppTranslation from '../../hook/useAppTranslation';
import DateRangePicker from '../../shared/DateRangePicker';
import * as formikUtils from '../../utils/formik';

const FiltersDateRange = ({ disabled = false }) => {
  const { t, dateFormatMoment } = useAppTranslation();
  const checkIn = useAppField('checkIn');
  const checkOut = useAppField('checkOut');

  const error =
    formikUtils.getError(checkIn.meta) ?? formikUtils.getError(checkOut.meta);

  const isValid =
    formikUtils.isValid(checkIn.meta) && formikUtils.isValid(checkOut.meta);
  const isInvalid =
    formikUtils.isInvalid(checkIn.meta) || formikUtils.isInvalid(checkOut.meta);

  return (
    <Form.Group controlId="date-range">
      <Form.Label className={`tw-text-white ${disabled || 'required'}`}>
        {t('HotelsCheckIn')} / {t('HotelsCheckOut')}
      </Form.Label>

      <Form.Control
        as={DateRangePicker}
        name="date-range"
        disabled={disabled}
        minDate={moment().add(1, 'day').toDate()}
        startDate={checkIn.field}
        endDate={checkOut.field}
        dateFormat={dateFormatMoment}
        isValid={isValid}
        isInvalid={isInvalid}
      />

      {isInvalid && (
        <Form.Control.Feedback type="invalid">{t(error)}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default FiltersDateRange;
