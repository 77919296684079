import React, { useMemo } from 'react';

import { Button } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import styled, { css } from 'styled-components';

const ButtonTypeFlight = ({ children, disabled, onClick }) => {
  const value = useMemo(() => {
    const style = {
      marginRight: '0.45rem',
      border: '0.1px solid var(--button-primary)',
      borderRadius: '50%',
      padding: 3,

      color: 'var(--color-text)',
      background: 'var(--button-primary)',
    };

    if (disabled) {
      style.color = 'var(--button-primary)';
      style.background = 'var(--color-text)';
    }

    return { size: '1.35rem', style };
  }, [disabled]);

  return (
    <IconContext.Provider value={value}>
      <ButtonStyled
        block
        variant="primary"
        onClick={onClick}
        className="tw-font-bold"
        disabled={disabled}
      >
        {children}
      </ButtonStyled>
    </IconContext.Provider>
  );
};

const ButtonStyled = styled(Button)`
  &&&& {
    display: flex;
    align-items: center;
    border-radius: 20px;

    &:hover:not(:disabled):not(.disabled) {
      background-color: var(--button-primary);
      border-color: var(--button-primary);

      & > svg {
        background-color: var(--color-text) !important;
        color: var(--button-primary) !important;
      }
    }

    ${({ disabled }) => {
      if (disabled) {
        return css`
          opacity: 1;
        `;
      }

      return css`
        background-color: var(--color-text);
        color: var(--button-primary);
        opacity: 0.65;
      `;
    }}
  }
`;

export default ButtonTypeFlight;
