import { nanoid } from 'nanoid';
import { useCallback } from 'react';

const useExtraItems = tagBooking => {
  const save = useCallback(
    data => {
      try {
        // console.log('🚀 ~ useExtraItems.js ~ save', { data });

        if (!data) return null;

        const id = nanoid();
        localStorage.setItem(`${tagBooking}-${id}`, JSON.stringify(data));

        return id;
      } catch {
        return null;
      }
    },
    [tagBooking],
  );

  const get = useCallback(
    id => {
      try {
        // console.log('🚀 ~ useExtraItems.js ~ get', { id });

        if (!id) return null;

        const key = `${tagBooking}-${id}`;
        const str = localStorage.getItem(key) || 'null';
        const data = JSON.parse(str);

        return data;
      } catch {
        return null;
      }
    },
    [tagBooking],
  );

  const clear = useCallback(() => {
    try {
      // console.log('🚀 ~ useExtraItems.js ~ clear');

      for (let i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i).startsWith(tagBooking))
          localStorage.removeItem(localStorage.key(i));
      }

      return true;
    } catch {
      return false;
    }
  }, [tagBooking]);

  return { save, get, clear };
};

export default useExtraItems;
