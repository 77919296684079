import { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import sleep from '../utils/sleep';
import { decode } from '../utils/uriComponent';

const useAppParams = () => {
  const params = useParams();

  const [paramsDecode, setParamsDecode] = useState({});

  const strParams = useMemo(() => JSON.stringify(params ?? {}), [params]);

  useEffect(() => {
    let didCancel = false;
    const set = async () => {
      await sleep(500);
      if (didCancel) return;

      const entries = Object.entries(JSON.parse(strParams)).map(
        ([key, value]) => [key, decode(value)],
      );
      const newParamsDecode = Object.fromEntries(entries);
      setParamsDecode(newParamsDecode);
    };

    set();
    return () => {
      didCancel = true;
    };
  }, [strParams]);

  return paramsDecode;
};

export default useAppParams;
