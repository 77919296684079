import React, { useEffect, useState } from 'react';

import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { Col, Container, Row } from 'react-bootstrap';
import FormSearch from '../../components/SportingEvents/FormSearch';
import ListComponent from '../../components/SportingEvents/List';
import useAppSearchParams from '../../hook/useAppSearchParams';
import useDev from '../../hook/useDev';
import useLoader from '../../hook/useLoader';
import { getSportingEvents } from '../../services/sportingEvents';
import Error from '../../shared/Error';
import createAppSearchParams from '../../utils/createAppSearchParams';
import sleep from '../../utils/sleep';

const PAGE_SIZE = 20;

const List = () => {
  const { isDev } = useDev();
  const queryClient = useQueryClient();
  const [query, setQuery] = useAppSearchParams();
  const [page, setPage] = useState(null);
  const [values, setValues] = useState(null);

  useEffect(() => {
    let didCancel = false;
    const updateValues = async () => {
      await sleep(50);
      if (didCancel) return;

      if (!query?.values || (page ?? null) === null) {
        setValues(null);
        return;
      }

      const valuesInner = JSON.parse(JSON.stringify(query.values));
      valuesInner.page = page;
      setValues(valuesInner);
    };

    updateValues();
    return () => {
      didCancel = true;
    };
  }, [query, page]);

  useEffect(() => {
    let didCancel = false;
    const reset = async () => {
      setPage(null);

      await sleep(500);
      if (didCancel) return;

      setPage(1);
      queryClient.removeQueries({ queryKey: ['sportingEvents'] });
    };

    reset();
    return () => {
      didCancel = true;
    };
  }, [query, queryClient]);

  const { isLoading, isPending, isFetching, isSuccess, isError, data, error } =
    useQuery({
      queryKey: ['sportingEvents', values],
      queryFn: async () => {
        return getSportingEvents({
          startDate: values.startDate,
          endDate: values.endDate,
          sportTypeId: values.sportTypeId,
          countryId: values.countryId,
          cityId: values.cityId,
          page: values.page,
          perPage: PAGE_SIZE,
        });
      },
      placeholderData: keepPreviousData,
      enabled: values !== null,
    });

  useLoader({ isLoading: isLoading || isPending || isFetching });

  const handleSportingEventClick = sportingEvent => {
    const { ticketdata } = sportingEvent;

    const newQuery = createAppSearchParams({
      ...query,
      sportingEvent: {
        caption: sportingEvent.caption,
        venue: sportingEvent.venue,
        venueAddress: sportingEvent.venueAddress,
        venue_img: sportingEvent.venue_img,
        position: sportingEvent.position,
        date: sportingEvent.date,
        ticketdata: sportingEvent.ticketdata,
      },
    });

    let nextStep = 'detail';
    if (!ticketdata.length) {
      nextStep = 'warranty';
    }

    const to = `/sportingEvents/${nextStep}?${newQuery.toString()}`;
    window.open(to, '_blank');
  };

  return (
    <section>
      <header className="tw-bg-gray-400 tw-p-3">
        <FormSearch filters={query?.values} query={query} setQuery={setQuery} />
      </header>

      <main className="tw-my-4">
        <Container>
          <Row xs="1">
            {isError && (
              <Col>
                <Error errors={error} />
              </Col>
            )}

            {isSuccess && (
              <Col className="tw-flex tw-flex-col tw-gap-4">
                <ListComponent
                  control={data?.control}
                  sportingEvents={data?.data}
                  onSportingEventClick={handleSportingEventClick}
                  page={page}
                  setPage={setPage}
                />
              </Col>
            )}
          </Row>

          {isDev && (
            <Row xs="1" className="tw-mt-3">
              {Object.entries({
                query,
              })
                .filter(([, value]) => value)
                .map(([key, value], _, self) => {
                  const lg = 12 / self.length;

                  return (
                    <Col key={key} lg={lg}>
                      <pre>
                        {JSON.stringify(
                          {
                            [key]: value,
                          },
                          null,
                          2,
                        )}
                      </pre>
                    </Col>
                  );
                })}
            </Row>
          )}
        </Container>
      </main>
    </section>
  );
};

export default List;
