import React from 'react';
import { Button } from 'react-bootstrap';
import { GrEdit } from 'react-icons/gr';
import styled from 'styled-components';

const ButtonEdit = ({ children, onClick }) => {
  const handleClick = (...args) => {
    if (onClick) onClick(...args);
  };

  return (
    <ButtonStyled onClick={handleClick}>
      <GrEdit />
      {children}
    </ButtonStyled>
  );
};

const ButtonStyled = styled(Button).attrs(() => ({
  variant: 'outline-warning',
}))`
  display: flex !important;
  justify-content: center;

  & * {
    align-self: center !important;

    &:first-child {
      margin-right: 0.5rem;

      & > path {
        stroke: #ffc107;
      }
    }
  }

  &:hover *:first-child > path {
    stroke: #212529;
  }
`;

export default ButtonEdit;
