import React from 'react';

import moment from 'moment';
import { Form } from 'react-bootstrap';
import useAppTranslation from '../../hook/useAppTranslation';
import DateRangePicker from '../../shared/DateRangePicker';

const FiltersDateRange = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  hideLabel = false,
}) => {
  const { t } = useAppTranslation();

  return (
    <Form.Group controlId="date-range">
      {hideLabel === false && (
        <Form.Label className="tw-text-white">
          {t('desde')} / {t('hasta')}
        </Form.Label>
      )}

      <Form.Control
        as={DateRangePicker}
        name="date-range"
        minDate={moment().add(1, 'month').startOf('month').toDate()}
        startDate={{
          value: startDate,
          name: null,
          multiple: null,
          checked: null,
          onChange: evt => {
            setStartDate(evt.target.value);
          },
          onBlur: null,
        }}
        endDate={{
          value: endDate,
          name: null,
          multiple: null,
          checked: null,
          onChange: evt => {
            setEndDate(evt.target.value);
          },
          onBlur: null,
        }}
        dateFormat="MMMM-yyyy"
        showMonthYearPicker
      />
    </Form.Group>
  );
};

export default FiltersDateRange;
