import React from 'react';

import { Card, Container } from 'react-bootstrap';
import styles from './FormSidebar.module.scss';

const FormSidebar = ({ children, className }) => {
  return (
    <Container className={`${className ?? ''}`}>
      <Card className={styles.card}>
        <Card.Body>{children}</Card.Body>
      </Card>
    </Container>
  );
};

export default FormSidebar;
