import React from 'react';

import moment from 'moment';
import { Form } from 'react-bootstrap';
import useAppField from '../../hook/useAppField';
import useAppTranslation from '../../hook/useAppTranslation';
import DateRangePicker from '../../shared/DateRangePicker';
import * as formikUtils from '../../utils/formik';

const FiltersDateRange = ({ disabled = false }) => {
  const { t } = useAppTranslation();
  const departing = useAppField('departing');
  const returning = useAppField('returning');

  const error =
    formikUtils.getError(departing.meta) ??
    formikUtils.getError(returning.meta);

  const isValid =
    formikUtils.isValid(departing.meta) && formikUtils.isValid(returning.meta);
  const isInvalid =
    formikUtils.isInvalid(departing.meta) ||
    formikUtils.isInvalid(returning.meta);

  return (
    <Form.Group controlId="date-range">
      <Form.Label className={`tw-text-white ${disabled || 'required'}`}>
        {t('CRUISES_DEPARTING')} / {t('CRUISES_RETURNING')}
      </Form.Label>

      <Form.Control
        as={DateRangePicker}
        name="date-range"
        disabled={disabled}
        minDate={moment().add(1, 'month').startOf('month').toDate()}
        startDate={departing.field}
        endDate={returning.field}
        dateFormat="MMMM-yyyy"
        showMonthYearPicker
        isValid={isValid}
        isInvalid={isInvalid}
      />

      {isInvalid && (
        <Form.Control.Feedback type="invalid">{t(error)}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default FiltersDateRange;
