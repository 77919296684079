import { css } from 'styled-components';

const text = css`
  p,
  span,
  small,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--color-text) !important;
  }

  a {
    & svg > path {
      stroke: var(--button-primary);
      color: var(--button-primary);
    }

    &:hover svg > path {
      stroke: var(--button-primary-active);
      color: var(--button-primary-active);
    }
  }
`;

export default text;
