import yup from './yup';

// eslint-disable-next-line import/prefer-default-export
export const filterSearchSchema = yup.object().shape({
  startDate: yup.string().isDateMomentValid().default(''),
  endDate: yup.string().isDateMomentValid().isDateBeforeAtStartDate({
    startDateName: 'startDate',
    message: 'ErrorFechaRegreso',
  }),
  sportTypeId: yup.number().nullable().default(null),
  countryId: yup.number().nullable().default(null),
  cityId: yup.number().nullable().default(null),
});
