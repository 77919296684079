import { create } from 'zustand';

const { REACT_APP_COMPANY_NAME } = process.env;

const store = set => ({
  loading: true,
  isPlatinum: false,
  isDemo: false,
  data: null,

  setAuthData: data => {
    const isPlatinum = data?.bin === 44879067;
    const isDemo =
      REACT_APP_COMPANY_NAME === 'Sunset World' &&
      data?.email === 'demo@demo.com';

    set({ loading: false, isPlatinum, isDemo, data }, false, 'SET_AUTH_DATA');
  },
});

const useAuthZustand = create(store);

export default useAuthZustand;
