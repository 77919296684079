import React from 'react';

import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const CheckBox = ({
  children,
  name = 'custom-switch',
  onChange,
  checked,
  disabled,
}) => {
  return (
    <Wrapper>
      <Form.Check
        type="switch"
        name={name}
        id={name}
        label={children}
        checked={checked}
        disabled={disabled}
        onChange={data => {
          onChange(data.target.checked);
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & * {
    line-height: 24px;
  }

  & input {
    display: none;
  }

  & label {
    padding-left: 2.3rem;

    &::before {
      left: 0 !important;
    }

    &::after {
      left: 2px !important;
    }
  }
`;

export default CheckBox;
