import React, { useState } from 'react';

import { Field, Formik, useField } from 'formik';
import moment from 'moment';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsArrowLeftRight } from 'react-icons/bs';
import { IoArrowForward } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import useAppTranslation from '../../hook/useAppTranslation';
import useDev from '../../hook/useDev';
import SelectDestination from '../../shared/SelectDestination';
import { encode } from '../../utils/uriComponent';
import { filterSearchSchema } from '../../validations/flights';
import ButtonTypeFlight from './ButtonTypeFlight';
import FiltersDateRange from './FiltersDateRange';
import Travelers from './Travelers';

const FormGroupDestination = ({ name, label }) => {
  const { t } = useTranslation();
  const [field, meta] = useField(name);
  const { touched, error } = meta;

  return (
    <Form.Group controlId={field.name}>
      <Form.Label className="tw-text-slate-50">{label}</Form.Label>

      <SelectDestination
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        isValid={touched && !error}
        isInvalid={touched && !!error}
        typeDestination="AIRPORT"
      />

      {touched && error && (
        <Form.Control.Feedback type="invalid">{t(error)}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

const FormSearch = ({ inline = false, filters, disabled = false }) => {
  const { isDev } = useDev();
  const { t } = useAppTranslation();
  const history = useHistory();

  const [initialValues] = useState(() => {
    if (filters) return filters;

    return {
      origin: null,
      destination: null,
      startDate: moment().add(1, 'day').startOf('day').hour(12).format(),
      endDate: moment().add(4, 'day').startOf('day').hour(12).format(),
      persons: { adults: 1, children: [] },
      oneWay: true,
    };
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={filterSearchSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);

        const newQuery = encode(values);

        history.push(`/flights/list/${newQuery}`);
      }}
    >
      {({ values, errors, touched, setFieldValue, handleSubmit }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Container className="form-padding">
              <Row>
                <Col xs="12" lg={inline ? 12 : 3}>
                  <FormGroupDestination
                    name="origin"
                    label={t('FlightLeavingFrom')}
                  />
                </Col>

                <Col xs="12" lg={inline ? 12 : 3}>
                  <FormGroupDestination
                    name="destination"
                    label={t('FlightGoingTo')}
                  />
                </Col>

                <Col xs="12" lg={inline ? 12 : 4}>
                  <FiltersDateRange disabled={disabled} />
                </Col>

                <Col xs="12" lg={inline ? 12 : 2}>
                  <Field name="persons">
                    {({ field }) => {
                      return (
                        <Form.Group controlId="travelers">
                          <Form.Label
                            className={`tw-text-slate-50 ${
                              disabled || 'required'
                            }`}
                          >
                            {t('FlightsTravelers_zero')}
                          </Form.Label>

                          <Form.Control
                            as={Travelers}
                            inline={inline}
                            name={field.name}
                            value={field.value}
                            disabled={disabled}
                          />

                          {/* <DropdownTravelers
                      value={values.persons}
                      onChange={persons => setFieldValue('persons', persons)}
                    /> */}
                        </Form.Group>
                      );
                    }}
                  </Field>
                </Col>
              </Row>

              <Row className="tw-items-center">
                <Col xs="12" lg={inline ? 12 : 5}>
                  <Row>
                    <Col xs="12" lg={inline ? 12 : 6}>
                      <Form.Group>
                        <ButtonTypeFlight
                          disabled={!values.oneWay}
                          onClick={() =>
                            setFieldValue('oneWay', !values.oneWay)
                          }
                        >
                          <BsArrowLeftRight />

                          {t('ViajeRedondo')}
                        </ButtonTypeFlight>
                      </Form.Group>
                    </Col>
                    <Col xs="12" lg={inline ? 12 : 6}>
                      <Form.Group>
                        <ButtonTypeFlight
                          disabled={values.oneWay}
                          onClick={() =>
                            setFieldValue('oneWay', !values.oneWay)
                          }
                        >
                          <IoArrowForward />
                          {t('ViajeSencillo')}
                        </ButtonTypeFlight>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg={inline ? 12 : { span: 2, offset: 5 }}>
                  <Button
                    type="submit"
                    block
                    variant="outline-primary"
                    className="tw-font-bold"
                  >
                    {t('Buscar')}
                  </Button>
                </Col>
              </Row>

              {isDev && (
                <Row className="tw-mt-3">
                  <Col xs="12">
                    <pre style={{ color: '#fff' }}>
                      {JSON.stringify(
                        {
                          values,
                          touched,
                          errors,
                        },
                        null,
                        2,
                      )}
                    </pre>
                  </Col>
                </Row>
              )}
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormSearch;
