import React from 'react';

import { Badge, Button, Col, Container, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import useAppTranslation from '../../hook/useAppTranslation';
import useCurrency from '../../hook/useCurrency';
import PriceFormat from '../PriceFormat';

const CardSportingEvent = ({ sportingEvent, onClick }) => {
  const { convertTo } = useCurrency();
  const { t, timeFormatMoment } = useAppTranslation({
    keyPrefix: 'SportingEvents',
  });
  const ticketsAvailable = sportingEvent.ticketdata.length > 0;
  const minTicket = sportingEvent.ticketdata
    .map(it => {
      const oldValue = { value: it.Price, currency: it.Currency };
      const newValue = convertTo(oldValue);

      return {
        ...it,
        conversion: newValue,
      };
    })
    .reduce((min, it) => {
      if (min === null || it.Price < min.Price) {
        return it;
      }

      return min;
    }, null);

  return (
    <Container className="tw-rounded-lg tw-border-[1px] tw-border-solid tw-border-black/20 tw-p-4 tw-shadow-lg">
      <Row xs="1">
        <Col lg="3">
          <img
            src={sportingEvent.venue_img}
            alt={sportingEvent.vname}
            className="tw-aspect-video tw-w-full"
          />
        </Col>

        <Col lg="6" className="tw-flex tw-flex-col tw-gap-2">
          <h1 className="tw-m-0 tw-text-3xl tw-font-bold tw-text-[#6b9ac3]">
            {sportingEvent.caption}
          </h1>
          <h2 className="tw-m-0 tw-text-lg">{sportingEvent.venueAddress}</h2>

          <div className="tw-m-0 tw-flex tw-gap-2">
            <Badge pill variant="primary">
              {sportingEvent.sport}
            </Badge>

            <Badge pill variant="secondary">
              {sportingEvent.tournament}
            </Badge>
          </div>

          <h3 className="tw-m-0">
            {sportingEvent.date.format(`ddd, D MMM, ${timeFormatMoment}`)}
          </h3>
        </Col>

        <Col lg="3" className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
          {ticketsAvailable ? (
            <PriceFormat
              value={minTicket.conversion.value}
              currency={minTicket.conversion.currency}
              prefix={minTicket.conversion.prefix}
            >
              {valueText => (
                <h4 className="tw-m-0 tw-text-lg tw-font-bold tw-leading-5">
                  <Trans
                    t={t}
                    i18nKey="From"
                    values={{
                      price: valueText,
                    }}
                    components={[
                      <strong className="tw-text-xl tw-text-green-500" />,
                    ]}
                  />
                </h4>
              )}
            </PriceFormat>
          ) : (
            <h4 className="tw-text-lg tw-uppercase tw-leading-5 tw-text-black">
              {t('ComingSoon')}
            </h4>
          )}

          <Button
            variant="outline-primary"
            className="tw-rounded-full"
            onClick={() => {
              onClick?.(sportingEvent);
            }}
          >
            {t('Tickets')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CardSportingEvent;
