import React, { useMemo } from 'react';

import ReactSelect, { components } from 'react-select';

const CustomComponent = ({ name, isValid, isInvalid }) => {
  const className = [
    'async-select',

    name
      .match(/[A-Z][a-z]+/g)
      .join('-')
      .toLocaleLowerCase(),
  ];

  if (isValid) {
    className.push('is-valid');
  }

  if (isInvalid) {
    className.push('is-invalid');
  }

  return ({ children, ...props }) => {
    const Component = components[name];

    return (
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className={[
          ...className,
          props?.className,
          props?.isDisabled === true ? 'disabled' : '',
        ]
          .filter(Boolean)
          .join(' ')}
      >
        {children}
      </Component>
    );
  };
};

export const useComponents = ({ isValid, isInvalid } = {}) =>
  useMemo(() => {
    return Object.fromEntries(
      Object.keys(components).map(componentName => {
        return [
          componentName,
          CustomComponent({ name: componentName, isValid, isInvalid }),
        ];
      }),
    );
  }, [isValid, isInvalid]);

const Select = ({
  value,
  defaultValue,
  options,
  className,
  placeholder,
  onInputChange,
  onChange,
  onBlur,
  isValid,
  isInvalid,
  isDisabled,
  groupHeading,
  defaultOptions,
  isMulti,
  isClearable,
}) => {
  const componentsWithClassNames = useComponents({ isValid, isInvalid });

  return (
    <ReactSelect
      components={componentsWithClassNames}
      value={value}
      defaultValue={defaultValue}
      options={options}
      className={className}
      placeholder={placeholder}
      onInputChange={onInputChange}
      onChange={onChange}
      onBlur={onBlur}
      isDisabled={isDisabled}
      groupHeading={groupHeading}
      defaultOptions={defaultOptions}
      isMulti={isMulti}
      isClearable={isClearable}
    />
  );
};

export default Select;
