import React from 'react';

import { Col, Container, Image, Row } from 'react-bootstrap';
import useAppTranslation from '../../hook/useAppTranslation';

const OfertasUMinuto = () => {
  const { t } = useAppTranslation();

  return (
    <section className="bg-welcome-u-minuto tw-py-5">
      <Container>
        <Row className="tw-items-start">
          <Col xs="5" lg="4">
            <Image src="/images/word-circle.png" fluid />
          </Col>

          <Col xs="7" lg={{ span: 7, offset: 1 }}>
            <h1 className="tw-uppercase tw-tracking-[4px] tw-text-white">
              {t('OFERTASULTIMOMINUTO')}
            </h1>
            <label className="tw-text-2xl tw-text-white">
              {t('ReservaMenosTiempoPrecios')}
            </label>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OfertasUMinuto;
