import { css } from 'styled-components';

const checkbox = css`
  input[type='checkbox']:checked ~ label::before {
    background-color: var(--button-primary) !important;
    border-color: var(--button-primary) !important;
  }

  input[type='radio'],
  input[type='checkbox'] {
    accent-color: #d27745 !important;
    border-color: red;
  }
`;

export default checkbox;
