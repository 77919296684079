import moment from 'moment';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const store = set => ({
  lastCheck: null,
  rates: null,
  save: rates => {
    set({ lastCheck: moment().format(), rates }, false, 'SAVE_RATES');
  },
});

const useCurrencyZustand = create(persist(store, { name: 'currency' }));

export default useCurrencyZustand;
