import React, { useState } from 'react';

import { Button, Col, Image, Modal, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import useCruise from '../../hook/useCruise';
import CardStyled from '../Card';
import Text from '../Text';

const CardRizCarlton = ({ item }) => {
  const { t } = useTranslation();

  const { searchByCarlton } = useCruise();
  const [currency, setCurrency] = useState({});
  const [showCurrency, setShowCurrency] = useState(false);
  const handleCloseCurrency = () => setShowCurrency(false);

  const Burbuja = price => {
    const lista = price;
    let n = [];
    let i = 0;
    let k = 0;
    let aux = [];
    n = lista.length;
    for (k = 1; k < n; k++) {
      for (i = 0; i < n - k; i++) {
        if (lista[i] > lista[i + 1]) {
          aux = lista[i];
          lista[i] = lista[i + 1];
          lista[i + 1] = aux;
        }
      }
    }
  };
  Burbuja(item.inventory.suite);

  const onHandleClickCurrency = () => {
    const destinos = item.itinerary.port;
    if (destinos.length >= 1) {
      setCurrency(destinos);
    }
    setShowCurrency(true);
  };
  const handleClick = () => {
    searchByCarlton(item);
  };

  return (
    <>
      <Modal show={showCurrency} onHide={handleCloseCurrency} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="tw-text-center">
            {t('itinerario')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr className="tw-text-center">
                <th>{t('Fecha')}</th>
                <th>{t('País')}</th>
                <th>{t('Nombre')}</th>
                <th>{`No - ${t('Destino')}`}</th>
              </tr>
            </thead>
            <tbody>
              {currency.length >= 1 ? (
                currency.map(element => (
                  <tr className="tw-text-center">
                    <td>{element.depart || element.arrive}</td>
                    <td>{element.portCountry || element.portName}</td>
                    <td>{element.portName}</td>
                    <td>{element.portNumber}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>loading</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      <WrapperHotel>
        <Col xs="12" className="tw-mx-0 tw-px-0">
          <WrapperCard>
            <CardHotelData>
              <Row className="tw-h-full tw-max-h-full tw-p-3">
                <Col xs={{ span: 4, offset: 4 }} lg={{ span: 4, offset: 0 }}>
                  <Image
                    key={item.embarkPort}
                    src={
                      item.voyage_image ||
                      item.map_image ||
                      '/images/no-image.jpg'
                    }
                    width="120%"
                    height="120%"
                    className="img-thumbnail tw-cursor-pointer"
                    alt={item.embarkPort}
                    onClick={handleClick}
                  />
                </Col>
                <Col>
                  <Row
                    style={{
                      font: 'var(--unnamed-font-style-normal) normal var(--unnamed-tw-font-bold) var(--unnamed-font-size-30)/32px var(--unnamed-font-family-montserrat)',
                    }}
                  >
                    <Text
                      size="22"
                      lineHeight="50"
                      color="#6B9AC3"
                      onClick={handleClick}
                    >
                      {`${item.nights} ${t('Noches')}  `}
                    </Text>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Text size="29" lineHeight="40" color="#6B9AC3">
                          {item.embarkPort}
                        </Text>
                      </Row>

                      <Row onClick={onHandleClickCurrency}>
                        <Text
                          size="21"
                          lineHeight="40"
                          color="#6B9AC3"
                          className="tw-cursor-pointer"
                        >
                          {t('itinerario')}
                        </Text>
                      </Row>
                      <Row>
                        <Text size="21" lineHeight="40" color="#6B9AC3">
                          {`Departure: ${item.disembarkDate}`}
                        </Text>
                      </Row>
                    </Col>
                    <Col>
                      <Text
                        size="20"
                        lineHeight="50"
                        color="#636060"
                        className="tw-cursor-pointer"
                        onClick={handleClick}
                      >
                        {`${t('desde')}: `}
                      </Text>
                      <Text
                        size="20"
                        lineHeight="50"
                        color="#51BC65"
                        className="tw-cursor-pointer"
                        onClick={handleClick}
                      >
                        {`${item.inventory.suite[0].fareFrom_USD} USD`}
                      </Text>

                      <Row>
                        <Col>
                          <Button size="lg" onClick={handleClick}>
                            Siguiente
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Text
                            size="20"
                            lineHeight="50"
                            color="#3A9EC7"
                            className="tw-cursor-pointer"
                            onClick={handleClick}
                          >
                            Hasta 18 meses sin intereses
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardHotelData>
          </WrapperCard>
        </Col>

        {/* )}  */}
      </WrapperHotel>
    </>
  );
};

const CardHotelData = styled(CardStyled).attrs(() => ({
  className: 'tw-h-full tw-max-h-full',
}))`
  &&&& {
    ${({ header }) => {
      if (header) {
        return css`
          @media (max-width: 991px) {
            /* background-color: yellow !important; */
            margin-top: 1rem !important;
          }

          border-radius: 8px 8px 0px 0px !important;
          overflow-y: hidden;

          &:before {
            content: '${header}';
            height: 45px;
            padding-top: 0.3rem;
            width: 100%;
            background: ${'#A1C3E1' && '#0c6590'};
            border-radius: 8px 8px 0px 0px;
            text-align: center;
            font: normal normal bold 25px/36px Raleway;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
          }
        `;
      }

      return '';
    }}
  }
`;

const WrapperCard = styled.div.attrs(() => ({ className: 'tw-h-full' }))`
  &&&& {
    ${props => {
      if (!props?.children?.props?.header) {
        return css`
          @media (min-width: 992px) {
            /* background-color: yellow; */
            padding-top: 41px;
          }
        `;
      }

      return '';
    }}
  }
`;

const WrapperHotel = styled(Row).attrs(() => ({
  className: 'tw-flex align-items-stretch tw-py-2',
}))``;

export default CardRizCarlton;
