/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const API_KEY = 'hNvBQfqRvCqMni4l11ESb9dv9qQgnkzY3AV6a7dz';
const URL = `https://api.currencyapi.com/v3/latest`;
const CURRENCIES = {
  EUR: {
    value: 0.9428401686,
    currency: 'EUR',
    prefix: '\u20AC',
  },
  MXN: {
    value: 17.9258332177,
    currency: 'MXN',
    prefix: '$',
  },
  USD: {
    value: 1,
    currency: 'USD',
    prefix: '$',
  },
};

export const getCurrentRate = async ({ baseCurrency }) => {
  const request = axios.get(URL, {
    params: {
      apikey: API_KEY,
      base_currency: baseCurrency,
      currencies: ['USD', 'MXN', 'EUR'],
    },
  });

  const response = await request
    .then(res => {
      const { data } = res.data;

      return Object.fromEntries(
        Object.values(data).map(({ code, value }) => {
          const dataCurrency = CURRENCIES[code] ?? {};
          dataCurrency.value = value;
          dataCurrency.currency = code;

          return [code, dataCurrency];
        }),
      );
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ currencyApi.jsx', { err });

      return CURRENCIES;
    });

  return response;
};
