import moment from 'moment';

export const toString = (date, format = {}) => {
  const mDate = moment(date, format.in);
  const newValue = mDate.isValid() ? mDate.format(format.out) : '';
  return newValue;
};

export const toMoment = (date, format) => {
  const mDate = moment(date, format);
  return mDate.isValid() ? mDate : undefined;
};

export const toDate = date => {
  const mDate = moment(date);
  return mDate.isValid() ? mDate.toDate() : null;
};

export const toDateTime = (date, time, out) => {
  const dateOnly = toString(date, { out: 'DDMMYYYY' });
  const timeOnly = toString(time, { out: 'HHmm' });
  return toString(`${dateOnly}${timeOnly}`, { in: 'DDMMYYYYHHmm', out });
};
