import React from 'react';

import { useField } from 'formik';
import { Trans } from 'react-i18next';
import Pagination from '../Pagination';
import Text from '../Text';
import CardFlight from './CardFlight';

const ListCardFlight = ({
  itineraries = [],
  state,
  setState,
  name = 'card-flight',
}) => {
  const [, , helpers] = useField(name);

  return (
    <>
      <Text bold size="55" lineHeight="70" color="#000">
        <Trans
          i18nKey={
            name === 'departing'
              ? 'ElijeVueloSalida'
              : name === 'returning'
              ? 'ElijeVueloRegreso'
              : ''
          }
          components={[<span className="tw-text-primary" />]}
        />
      </Text>

      <Pagination
        items={itineraries}
        render={itinerary => <CardFlight itinerary={itinerary} name={name} />}
        page={state.currentPage}
        onChange={page => {
          setState({ ...state, currentPage: page });
          helpers.setValue(null);
        }}
      />
    </>
  );
};

export default ListCardFlight;
