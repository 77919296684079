import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import List from './List';
import Detail from './Detail';
import DetailOld from '../../components/sportingEvents.old/Detail';
import WarrantyOld from '../../components/sportingEvents.old/Warranty';
import BookingOld from '../../components/sportingEvents.old/Booking';
import CheckoutOld from '../../components/sportingEvents.old/Checkout';
import Warranty from './Warranty';
import SportingEvents from './SportingEvents';

const Home = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={SportingEvents} />
      <Route exact path={`${path}/list`} component={List} />
      <Route exact path={`${path}/detail`} component={Detail} />
      <Route exact path={`${path}/warranty`} component={Warranty} />

      <Route exact path={`${path}/old/detail`} component={DetailOld} />
      <Route exact path={`${path}/old/warranty`} component={WarrantyOld} />
      <Route exact path={`${path}/old/booking/:id`} component={BookingOld} />
      <Route
        exact
        path={`${path}/old/checkout/:query/:id`}
        component={CheckoutOld}
      />
    </Switch>
  );
};

export default Home;
