import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import CardRate from '../../components/Cars/CardRate';
import FormSearch from '../../components/Cars/FormSearch';
import MapOffices from '../../components/Cars/MapOffices';
import EmptyItems from '../../components/EmptyItems';
import SectionTop from '../../components/SectionTop';
import useAppParams from '../../hook/useAppParams';
import useAppTranslation from '../../hook/useAppTranslation';
import useDev from '../../hook/useDev';
import CargandoOpciones from '../../loader/CargandoOpciones';
import { getCar } from '../../services/cars';
import Error from '../../shared/Error';
import FormSidebar from '../../shared/FormSidebar';
import { scrollUp } from '../../utils/scroll';
import sleep from '../../utils/sleep';
import { encode } from '../../utils/uriComponent';
import styles from './Detail.module.scss';

const Detail = () => {
  const { t, language } = useAppTranslation();
  const { isDev } = useDev();
  const { query, car, office } = useAppParams();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [rates, setRates] = useState([]);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    let didCancel = false;
    const fetch = async () => {
      await sleep(500);
      if (didCancel || !query || !car) return;

      setLoading(true);
      setRates([]);
      setErrors(null);

      const data = await getCar({
        pickUpDate: query.pickUpDate,
        pickUpTime: query.pickUpTime,
        pickUpAddress: car.pickUpLocation,
        pickUpLocation: query.pickUp,
        pickUpOffice: office?.officeCode,
        dropOffDate: query.dropOffDate,
        dropOffTime: query.dropOffTime,
        dropOffAddress: car.dropOffLocation,
        dropOffLocation: query.dropOff,
        dropOffOffice: office?.officeCode,
        sippCode: car.sippCode,
        ccrc: car.ccrc,
        companyCode: car.company.code,
        language,
        currency: car.currency,
      });

      if (Array.isArray(data)) {
        setRates(data);
      } else if (data.errors) {
        setErrors(data.errors);
      }

      setLoading(false);
      scrollUp();
    };

    fetch();
    return () => {
      didCancel = true;
    };
  }, [query, car, office, language]);

  const handleCardRateClick = useCallback(
    rateSelected => {
      // console.log('🚀 ~ Detail.jsx', { rate: rateSelected, query, car, office });
      const rate = {
        ...rateSelected,

        car: {
          name: car.name,
          company: car.company.name,
        },
      };
      const encodedRate = encode(rate);

      history.push(`/cars/booking/${encodedRate}`);
    },

    [car, history],
  );
  const content = useMemo(() => {
    if (loading) return null;

    if (errors) {
      return <Error errors={errors} />;
    }

    if (!rates.length) {
      return <EmptyItems />;
    }

    return rates.map(rate => (
      <CardRate
        key={rate.id}
        car={car}
        rate={rate}
        onClick={handleCardRateClick}
      />
    ));
  }, [loading, errors, rates, car, handleCardRateClick]);

  if (loading) return <CargandoOpciones />;

  return (
    <>
      <SectionTop
        menu={t('Cars', { count: rates?.length ?? 0 })}
        destino="destino"
        nombre={car?.name}
        direccion={car?.company?.name}
      />

      <Container fluid className="tw-my-3">
        <Row xs="1">
          <Col lg="4">
            <Row xs="1" className={styles.containerFilters}>
              <Col>
                <FormSidebar>
                  <FormSearch inline disabled filters={query} />
                </FormSidebar>
              </Col>

              {office && (
                <Col>
                  <MapOffices offices={[office]} current={office} />
                </Col>
              )}
            </Row>
          </Col>

          <Col lg="8" className={styles.containerCars}>
            {content}
          </Col>
        </Row>

        {isDev && (
          <Row xs="1" className="tw-mt-3">
            {Object.entries({
              query,
              car,
              office,
            })
              .filter(([, value]) => value)
              .map(([key, value], _, self) => {
                const lg = 12 / self.length;

                return (
                  <Col key={key} lg={lg}>
                    <pre>
                      {JSON.stringify(
                        {
                          [key]: value,
                        },
                        null,
                        2,
                      )}
                    </pre>
                  </Col>
                );
              })}
          </Row>
        )}
      </Container>
    </>
  );
};

export default Detail;
