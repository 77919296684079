import React, { useMemo, useState } from 'react';

import { Field, useField } from 'formik';
import { Accordion, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  BookingAccordionCollapse,
  BookingAccordionToggle,
} from '../../booking/BookingAccordion';
import styles from './CardTraveler.module.css';

const CardTraveler = ({ name, index }) => {
  const { t } = useTranslation();
  const prefix = useMemo(() => `${name}[${index}]`, [name, index]);
  const [{ value }, { touched, error }] = useField(prefix);
  const [isOpen, setIsOpen] = useState(false);

  const headerText = useMemo(() => {
    const text = t('FlightTravelerDescription', {
      count: index + 1,
      type:
        t('FlightTravelerType_interval', {
          postProcess: 'interval',
          count: value.age ?? 18,
        })?.trim?.() ?? '',
    });

    if (!isOpen) {
      const fullName = [value.name, value.lastName]
        .filter(str => str?.trim?.()?.length)
        .join(' ');

      if (fullName?.length) {
        return `${text} (${fullName})`;
      }
    }

    return text;
  }, [t, isOpen, index, value]);

  const borderColor = useMemo(() => {
    if (!touched || isOpen) return '';

    return error ? styles.isInvalid : styles.isValid;
  }, [isOpen, touched, error]);

  return (
    <Accordion>
      <Card className={borderColor}>
        <BookingAccordionToggle
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
        >
          <h6>{headerText}</h6>
        </BookingAccordionToggle>

        <BookingAccordionCollapse>
          <Row xs="1" lg="2">
            <Col>
              <Field name={`${prefix}.name`}>
                {({ field, meta }) => (
                  <Form.Group controlId={field.name}>
                    <Form.Label className="required">{t('Nombre')}</Form.Label>

                    <Form.Control
                      placeholder={t('NombrePlaceholder')}
                      type="text"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      isValid={meta.touched && !meta.error}
                      isInvalid={meta.touched && !!meta.error}
                    />
                  </Form.Group>
                )}
              </Field>
            </Col>

            <Col>
              <Field name={`${prefix}.lastName`}>
                {({ field, meta }) => (
                  <Form.Group controlId={field.name}>
                    <Form.Label className="required">
                      {t('Apellidos')}
                    </Form.Label>

                    <Form.Control
                      placeholder={t('ApellidosPlaceholder')}
                      type="text"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      isValid={meta.touched && !meta.error}
                      isInvalid={meta.touched && !!meta.error}
                    />
                  </Form.Group>
                )}
              </Field>
            </Col>

            {index === 0 && (
              <>
                <Col>
                  <Field name={`${prefix}.email`}>
                    {({ field, meta }) => (
                      <Form.Group controlId={field.name}>
                        <Form.Label className="required">
                          {t('CorreoElectronico')}
                        </Form.Label>

                        <Form.Control
                          placeholder={t('CorreoElectronicoPlaceholder')}
                          type="text"
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          isValid={meta.touched && !meta.error}
                          isInvalid={meta.touched && !!meta.error}
                        />
                      </Form.Group>
                    )}
                  </Field>
                </Col>

                <Col>
                  <Field name={`${prefix}.phone.number`}>
                    {({ field, meta }) => (
                      <Form.Group controlId={field.name}>
                        <Form.Label className="required">
                          {t('Telefono')}
                        </Form.Label>

                        <Form.Control
                          placeholder={t('TelefonoPlaceholder')}
                          type="text"
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          isValid={meta.touched && !meta.error}
                          isInvalid={meta.touched && !!meta.error}
                        />
                      </Form.Group>
                    )}
                  </Field>
                </Col>
              </>
            )}
          </Row>
        </BookingAccordionCollapse>
      </Card>
    </Accordion>
  );
};

export default CardTraveler;
