import React from 'react';

import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import styled from 'styled-components';

const {
  REACT_APP_COMPANY_NAME,
  REACT_APP_COMPANY_INSTAGRAM,
  REACT_APP_COMPANY_FACEBOOK,
  REACT_APP_COMPANY_TWITTER,
} = process.env;

const WelcomeFooter = ({ t }) => {
  return (
    <section className="footer-welcome tw-pb-5 tw-pt-3">
      <Container>
        <Row>
          <Col xs="12" lg="5">
            <p>{t('WelcomeFooter1')}</p>
            <p>{t('WelcomeFooter2')}</p>
            <p>{t('WelcomeFooter3')}</p>
          </Col>

          <Col xs="12" lg="4">
            <h4>{t('NecesitaAyuda')}</h4>
            <ul className="tw-list-none tw-pl-0">
              <li>
                <a href="!#" className="tw-text-primary">
                  00 000 000 0000
                </a>
              </li>

              <li>
                <a href="!#" className="tw-text-primary">
                  (+52) 000-000-0000
                </a>
              </li>
            </ul>

            <h4>{t('ContactenosOpinion')}</h4>
            <ul className="tw-list-none tw-pl-0">
              <li>
                <a href="!#" className="tw-text-primary">
                  info@JustgoNow.com
                </a>
              </li>
            </ul>
          </Col>

          <Col xs="12" lg="3">
            <h4>{t('SeaPrimerosofertas')}</h4>

            {REACT_APP_COMPANY_INSTAGRAM?.length > 0 && (
              <Link
                href={REACT_APP_COMPANY_INSTAGRAM}
                className="btn btn-dark btn-block btn-sm tw-text-left tw-text-slate-50"
              >
                <FaInstagram className="tw-mb-1" /> {REACT_APP_COMPANY_NAME}
              </Link>
            )}

            {REACT_APP_COMPANY_FACEBOOK?.length > 0 && (
              <Link
                href={REACT_APP_COMPANY_FACEBOOK}
                className="btn btn-dark btn-block btn-sm tw-text-left tw-text-slate-50"
              >
                <FaFacebookF className="tw-mb-1" /> {REACT_APP_COMPANY_NAME}
              </Link>
            )}

            {REACT_APP_COMPANY_TWITTER?.length > 0 && (
              <Link
                href={REACT_APP_COMPANY_TWITTER}
                className="btn btn-dark btn-block btn-sm tw-text-left tw-text-slate-50"
              >
                <FaTwitter className="tw-mb-1" /> {REACT_APP_COMPANY_NAME}
              </Link>
            )}
          </Col>
        </Row>

        <hr className="tw-border-slate-50" />

        <Row>
          <Col className="md:tw-text-right">
            <small>
              {REACT_APP_COMPANY_NAME} © {moment().format('YYYY')}
            </small>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const Link = styled.a`
  width: 100%;
`;

export default withTranslation()(WelcomeFooter);
