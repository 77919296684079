import { getCurrentRate } from '../services/currencyApi';
import { calcJustGoPoints } from './justGoPoints';

export const convertToDollar = ({ rates, price } = {}) => {
  const { value: valuePrice, currency } = price;
  const { value: valueRate } = rates?.[currency] ?? {};

  return valuePrice / valueRate;
};

export const prepareConvertCurrency = async ({ currency = 'USD' } = {}) => {
  const rates = await getCurrentRate({ baseCurrency: 'USD' });

  return price => {
    let justGoPoints = calcJustGoPoints({ total: price.total });
    if (price.currency === currency) return { ...price, justGoPoints };

    const dollar = convertToDollar({
      rates,
      price: { value: price.total, currency: price.currency },
    });

    const { value, ...restRate } = rates?.[currency] ?? {};
    const total = Math.ceil(dollar * value);
    justGoPoints = calcJustGoPoints({ total });

    return { ...restRate, total, justGoPoints };
  };
};
