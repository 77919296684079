import React, { useMemo } from 'react';

import { Col, Container, Image, Row } from 'react-bootstrap';
import useAppTranslation from '../hook/useAppTranslation';

const PriceBottom = () => {
  const { language } = useAppTranslation();

  const name = useMemo(() => {
    if (language === 'en') return 'info-bottom-en.png';

    return 'info-bottom.jpg';
  }, [language]);

  return (
    <section className="tw-py-5">
      <Container>
        <Row>
          <Col>
            <div className="tw-text-center">
              <Image src={`/images/${name}`} fluid className="mx-height-120" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PriceBottom;
