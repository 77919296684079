import React, { useCallback, useMemo } from 'react';

import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Map from '../../shared/Map';
import MapMarker from '../../shared/MapMarker';
import styles from './MapOffices.module.css';

const MapOffices = ({ offices, current, onMarkerClick }) => {
  const { t } = useTranslation();

  const showOffice = useCallback(
    ({ code, lat, lng }) =>
      current &&
      code === current.code &&
      lat === current.lat &&
      lng === current.lng,

    [current],
  );

  const defaultCenter = useMemo(
    () => offices.find(showOffice),
    [offices, showOffice],
  );

  return (
    <Map
      infoWindow={{
        showOnClick: true,
        showOnMouseHover: false,
        hideOnMouseOut: false,
      }}
      defaultZoom={defaultCenter ? 15 : 8}
      defaultCenter={defaultCenter}
    >
      {offices.map(office => {
        const show = showOffice(office);
        const { lat, lng, company, address } = office;

        return (
          <MapMarker
            key={`${company?.name ?? 'S/C'}-${address}`}
            lat={lat}
            lng={lng}
            show={show}
          >
            <Container className="tw-my-1">
              <Row xs="1" className={styles.containerMarkerOffice}>
                <Col>
                  <img src={company?.logo} alt={company?.name} />
                </Col>

                <Col>
                  <span className="tw-font-bold tw-text-black">
                    {t('CarsAddress')}:
                  </span>
                  <span className="tw-ml-2 tw-text-black">{address}</span>
                </Col>

                {typeof onMarkerClick === 'function' && (
                  <Col>
                    <Button
                      variant="primary"
                      className="tw-font-bold"
                      block
                      onClick={() => onMarkerClick({ ...office, show })}
                    >
                      {show ? t('CarsSearchAgain') : t('CarsSelectOffice')}
                    </Button>
                  </Col>
                )}
              </Row>
            </Container>
          </MapMarker>
        );
      })}
    </Map>
  );
};

export default MapOffices;
