import instance from './instance';

const post = async props => {
  const { data } = props;
  const cancelToken = props?.token || undefined;

  const config = {
    cancelToken,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return instance.post(props.url, data, config);
};

export default post;
