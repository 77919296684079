import React, { useMemo } from 'react';

import moment from 'moment';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { IoAirplane } from 'react-icons/io5';
import useAppTranslation from '../../../hook/useAppTranslation';
import PriceFormat from '../../PriceFormat';
import styles from './CardBookingInfo.module.css';

const getDaysDiff = ({ departure, arrival }) => {
  // console.log('🚀 ~ CardBookingInfo.jsx', { departure, arrival });
  const start = moment.utc(departure);
  const end = moment.utc(arrival);

  if (!start.isValid() || !end.isValid()) return 0;

  return end.diff(start, 'days');
};

const CardBookingInfo = ({
  origin: lblOrigin,
  destination: lblDestination,
  query,
  checkout,
}) => {
  const { t, language } = useAppTranslation();

  const tickets = useMemo(() => {
    const { adults = 0, children = [] } = query?.persons ?? {};
    const numTickets = adults + children.length;

    if (numTickets === 0) return '';

    const lblTicketsAdults =
      adults > 0 ? `${adults} ${t('Adults', { count: adults })}` : null;

    const lblTicketsChildren =
      children.length > 0
        ? `${children.length} ${t('Children', { count: children.length })}`
        : null;

    const ticketsDescription = [lblTicketsAdults, lblTicketsChildren]
      .filter(Boolean)
      .join(', ');

    return `${numTickets} ${t('FlightTickets', {
      count: numTickets,
    })}: ${ticketsDescription}`;
  }, [query, language]); // eslint-disable-line react-hooks/exhaustive-deps

  const itineraries = [checkout.departing, checkout.returning].filter(Boolean);

  return (
    <Card className={`${styles.card} tw-text-white`}>
      <Card.Header className="tw-pl-5">
        <h5 className="tw-font-bold tw-text-white">
          {t(query?.oneWay ? 'ViajeSencillo' : 'ViajeRedondo')}
        </h5>
        <h6 className="tw-text-white">{tickets}</h6>
      </Card.Header>

      <Card.Body className="tw-pl-2">
        {itineraries.map((it, index) => {
          const [{ departure }] = it.segments;
          const { arrival } = it.segments[it.segments.length - 1];
          const isDeparting = index === 0;
          const diffDays = getDaysDiff({
            departure: departure.date,
            arrival: arrival.date,
          });

          let origin = isDeparting ? lblOrigin : lblDestination;
          origin = `${departure?.terminal?.name ?? origin} (${
            departure.terminal.codeCity
          })`;

          let destination = isDeparting ? lblDestination : lblOrigin;
          destination = `${arrival?.terminal?.name ?? destination} (${
            arrival.terminal.codeCity
          })`;

          return (
            <Container key={it.id} className={styles.itineraryDescription}>
              <Row xs="1">
                <Col>
                  <IoAirplane
                    className={`${
                      isDeparting ? '' : styles.rotate
                    } icon-14 tw-mb-1 tw-mr-2`}
                  />
                  <span>
                    {t(isDeparting ? 'FlightLeavingFrom' : 'FlightGoingTo')}
                  </span>
                </Col>

                <Col>
                  <span>
                    {t('FlightItineraryDescription', { origin, destination })}
                  </span>
                </Col>

                <Col>
                  <span className="tw-font-bold">
                    {t('FlightLongDate', {
                      date: moment.utc(departure.date).format('DDMMYYYY'),
                    })}
                  </span>
                </Col>

                <Col>
                  <span className="tw-flex">
                    <Trans
                      t={t}
                      i18nKey="FlightItineraryDescriptionDuration"
                      values={{
                        departure: moment.utc(departure.date).format('HHmm'),
                        arrival: moment.utc(arrival.date).format('HHmm'),
                        diffDays,
                        duration: it.duration,
                      }}
                      components={{
                        diffDays: (
                          <span
                            className={`${
                              diffDays === 0
                                ? styles.sameDepartureDate
                                : styles.differingDepartureDate
                            } tw-mr-1`}
                          />
                        ),
                      }}
                    />
                  </span>
                </Col>

                {diffDays > 0 && (
                  <Col>
                    <span className={styles.flightArrives}>
                      {t('FlightArrives', {
                        date: moment.utc(arrival.date).format('DDMMYYYY'),
                      })}
                    </span>
                  </Col>
                )}

                <Col>
                  {it.segments
                    .filter(({ layover }) => layover)
                    .map(seg => {
                      const airlineName =
                        seg?.airline?.name ?? seg?.airline?.codeIata ?? '';

                      return (
                        <Row key={seg.id} xs="1" className="tw-m-0 tw-p-0">
                          <Col className="tw-m-0 tw-p-0">
                            <span>
                              {t('FlightStopInfo', {
                                duration: seg.layover,
                                terminal: seg.departure.terminal.name,
                              })}
                            </span>
                          </Col>

                          <Col className="tw-m-0 tw-p-0">
                            <span>
                              {[
                                airlineName,
                                seg?.number ? `${seg?.number}` : '',
                              ]
                                .filter(str => str?.length)
                                .join(' ')}
                            </span>
                          </Col>
                        </Row>
                      );
                    })}
                </Col>
              </Row>
            </Container>
          );
        })}
      </Card.Body>

      <Card.Footer className="tw-pl-5">
        <Row>
          <Col>Total</Col>

          <Col>
            <PriceFormat currency="MXN" value={checkout.price.total}>
              {valueText => valueText}
            </PriceFormat>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default CardBookingInfo;
