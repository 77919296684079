import React, { useEffect } from 'react';

import useAuth from '../hook/useAuth';
import useUser from '../hook/useUser';
import sleep from '../utils/sleep';

const withAuth = Component => () => {
  const { isPlatinum } = useAuth();
  const { setBlack, setPlatinum } = useUser();

  useEffect(() => {
    let didCancel = false;
    const set = async () => {
      await sleep(300);
      if (didCancel) return;

      if (isPlatinum) {
        setPlatinum();
        return;
      }

      setBlack();
    };

    set();
    return () => {
      didCancel = true;
    };
  }, [setBlack, setPlatinum, isPlatinum]);

  return <Component />;
};

export default withAuth;
