// eslint-disable-next-line import/prefer-default-export
export const scrollUp = () => {
  const main = window.document.querySelector('#main');
  if (main) {
    main.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    return;
  }

  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};
