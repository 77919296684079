/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import useAuth from '../hook/useAuth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { data: auth, loading } = useAuth();

  if (loading) {
    return (
      <Route
        {...rest}
        render={() => {
          return <p>Loading...</p>;
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={routeProps =>
        auth ? <Component {...routeProps} /> : <Redirect to="/welcome" />
      }
    />
  );
};

export default PrivateRoute;
