const compare = ({ valueA, valueB, amenity }) => {
  if (valueA === amenity && valueB === amenity) return 0;

  if (valueA === amenity) return -1;

  if (valueB === amenity) return 1;

  return 0;
};

const normalize = value => value.replace(/\u0421/, 'C');

const getImgByName = amenity => {
  const name = normalize(amenity.name.toLocaleLowerCase().trim());

  switch (name) {
    case 'cajero automático':
    case 'atm':
      return 'atm.svg';

    case 'tiendas en las instalaciones':
    case 'shopping on si-te':
      return 'Flat.svg';

    case 'aire acondicionado':
    case 'air conditioning':
      return null;

    case 'cambio de divisas':
    case 'currency exchange':
      return 'currency-exchange.svg';

    case 'alojamiento para no fumadores':
    case 'habitaciones para no fumadores':
      return 'no-smokers.svg';

    case 'calefacción':
      return null;

    case 'guardia de seguridad':
    case 'security guard':
      return 'shield-account.svg';

    case 'periódicos':
    case 'newspapers':
      return 'newspaper.svg';

    case 'asistencia para reservas':
    case 'tour assistance':
      return 'tour-assistance.svg';

    case 'tienda de regalos':
    case 'gift shop':
      return 'gift-open-outline.svg';

    case 'check-in/check-out rápido':
    case 'express check-in/check-out':
      return 'check-decagram.svg';

    case 'jardín':
    case 'garden':
      return 'tree.svg';

    case 'televisión en el vestíbulo':
    case 'tv':
      return 'television.svg';

    case 'terraza':
    case 'terrace':
      return 'balcony.svg';

    case 'portero':
    case 'doorman':
      return 'doorman.svg';

    case 'mostrador de recepción':
    case 'reception desk':
      return 'reception-desk.svg';

    case 'servicio de habitaciones':
    case 'room service':
      return 'room-service-outline.svg';

    case 'nevera':
    case 'fridge':
      return 'fridge.svg';

    case 'habitación familiar':
    case 'family room':
      return 'human-male-female-child.svg';

    case 'detector de humos':
    case 'smoke detector':
      return 'smoke-detector-alert.svg';

    case 'televisión por cable':
    case 'cable tv':
      return 'television-box.svg';

    case 'minibar':
      return null;

    case 'secador':
    case 'hairdryer':
      return 'hair-dryer-outline.svg';

    case 'ducha/bañera':
    case 'shower/bathtub':
      return 'shower.svg';

    case 'ducha':
    case 'shower':
      return 'shower-head.svg';

    case 'armario':
    case 'wardrobe/closet':
      return 'wardrobe-outline.svg';

    case 'despertador':
    case 'alarm clock':
      return 'clock-digital.svg';

    case 'caja fuerte':
    case 'safe (in room)':
      return null;

    case 'artículos de aseo':
      return 'spray-bottle.svg';

    case 'ascensor':
    case 'elevator/lift':
      return 'elevator.svg';

    case 'accesibilidad':
    case 'accessibility features':
      return 'accesible.svg';

    case 'accesible para sillas de ruedas':
    case 'wheelchair accessible':
      return 'wheelchair-accessibility.svg';

    case 'sala de planchado':
    case 'iron and board':
      return 'iron-board.svg';

    case 'servicio de planchado':
    case 'ironing':
      return 'iron-outline (1).svg';

    case 'consigna de equipajes':
      return 'bag-suitcase-outline.svg';

    case 'lavandería':
    case 'laundry':
      return null;

    case 'servicio de conserjería':
    case 'concierge services':
      return 'vacuum-outline.svg';

    case 'tintorería':
      return null;

    case 'masajes':
      return null;

    case 'teléfono':
    case 'telephone':
      return 'phone.svg';

    case 'plancha':
    case 'iron':
      return 'iron-outline (1).svg';

    case 'bar':
      return 'glass-cocktail.svg';

    case 'desayuno':
    case 'breakfast':
      return 'food-apple-outline.svg';

    case 'buffet desayuno':
    case 'buffet breakfast':
      return 'food-variant.svg';

    case 'café':
    case 'cafe':
      return 'coffee-outline.svg';

    case 'restaurante':
    case 'restaurant':
      return 'table-chair.svg';

    case 'wi-fi gratuito':
    case 'free wi-fi':
      return 'wifi-check.svg';

    case 'wi-fi':
    case 'internet access':
      return 'wifi.svg';

    case 'acceso a internet en la habitación':
      return 'wifi-strength-4.svg';

    // case 'piscina':
    // case 'swimming pool':
    //   return noImage;

    case 'gimnasio':
    case 'gym':
      return 'dumbbells.svg';

    default:
      return null;
  }
};

const sortAmenitiesHotel = amenities =>
  amenities
    .map(it => {
      const amenity = JSON.parse(JSON.stringify(it));
      const image = getImgByName(amenity);
      amenity.image = image && `/images/hotels/amenities/${image}`;
      amenity.name = normalize(amenity.name);

      return amenity;
    })
    .sort((amenityA, amenityB) => {
      const valueA = amenityA.name.toLocaleLowerCase();
      const valueB = amenityB.name.toLocaleLowerCase();

      const result = [
        'wi-fi gratuito',
        'free wi-fi',
        'aire acondicionado',
        'air conditioning',
        'restaurante',
        'restaurant',
        'piscina',
        'swimming pool',
        'check-in/check-out rápido',
        'express check-in/check-out',
      ].reduce((acc, amenity, index, arr) => {
        if (acc !== 0) {
          arr.splice(1);
          return acc;
        }

        const resultCompare = compare({ valueA, valueB, amenity });

        return resultCompare;
      }, 0);

      if (result !== 0) return result;

      const sortByImg = compare({
        valueA: amenityB.image,
        valueB: amenityA.image,
        amenity: null,
      });

      if (sortByImg !== 0) return sortByImg;

      return valueA.localeCompare(valueB);
    });

export default sortAmenitiesHotel;
