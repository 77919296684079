import React, { useEffect } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import SectionPromotionNY from '../../components/SectionPromotionNY';
import MainContainer from '../../shared/MainContainer';
import PriceBottom from '../../shared/PriceBottom';
import { scrollUp } from '../../utils/scroll';
import Booking from './Booking';
import Detail from './Detail';
import Experiences from './Experiences';
import List from './List';

const Home = () => {
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  useEffect(() => {
    scrollUp();
  }, [pathname]);

  return (
    <MainContainer>
      <div className="tw-flex tw-h-full tw-min-h-[inherit] tw-w-full tw-flex-col">
        <div className="tw-flex-1">
          <Switch>
            <Route exact path={path} component={Experiences} />
            <Route exact path={`${path}/list/:query`} component={List} />
            <Route
              exact
              path={`${path}/detail/:query/:activityId`}
              component={Detail}
            />
            <Route path={`${path}/reservation/:key`} component={Booking} />
          </Switch>
        </div>

        <SectionPromotionNY />
        <PriceBottom />
      </div>
    </MainContainer>
  );
};

export default Home;
