const { REACT_APP_SUN_API_STORAGE_KEY } = process.env;

const storage = {
  get: () => {
    const item =
      window.localStorage.getItem(REACT_APP_SUN_API_STORAGE_KEY) ?? 'null';

    return JSON.parse(item);
  },

  set: data =>
    window.localStorage.setItem(
      REACT_APP_SUN_API_STORAGE_KEY,
      JSON.stringify(data),
    ),
};

export default storage;
