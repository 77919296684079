import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import MapSidebar from '../MapSidebar';

const CondoMap = ({ condoInfo }) => {
  const [items, setItems] = useState();
  const [positions, setPositions] = useState([]);
  const [position, setPosition] = useState(-1);
  const [center, setCenter] = useState(null);

  useEffect(() => {
    if (condoInfo.address != null && condoInfo.address.geolocation != null) {
      const arrP = [
        {
          id: condoInfo.id,
          place: condoInfo.name,
          raiting: condoInfo.stars,
          latitude: condoInfo.address.geolocation.latitude,
          longitude: condoInfo.address.geolocation.longitude,
        },
      ];
      setPositions(arrP);
      setCenter({
        latitude: condoInfo.address.geolocation.latitude,
        longitude: condoInfo.address.geolocation.longitude,
      });
    }

    setItems(condoInfo);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card className="container-info condo-map-container">
      <MapSidebar
        items={items}
        position={position}
        setPosition={setPosition}
        positionsList={positions}
        center={center}
        setCenter={setCenter}
      />
    </Card>
  );
};

export default withTranslation()(CondoMap);
