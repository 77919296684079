/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';

import { Card, Col, Jumbotron, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TableCabins from './TableCabins';

const CabinsDetail = ({ cabinas, onHandleClickReservar }) => {
  const { t } = useTranslation();
  const items = cabinas;

  return (
    <Row className="tw-mt-4">
      <Col xs="12" lg="12">
        <h2 className="tw-capitalize">{t('Cabinas')}</h2>
      </Col>
      <Col>
        {items.length === 0 || undefined ? (
          <Jumbotron>
            <h4>{t('LoSentimos')}</h4>
            <p>{t('NoHabitacionesDisponibles')}</p>
          </Jumbotron>
        ) : (
          items.map((item, i) => (
            <Card key={i} className="tw-mb-4">
              <Card.Body>
                <Row>
                  <Col xs="12" lg="12" className="tw-mb-2">
                    <span className="tw-rounded tw-bg-black tw-text-slate-50">
                      {item.text || 'Suite'}
                    </span>
                  </Col>

                  <Col xs="12" lg="12">
                    <TableCabins
                      rates={item.description}
                      onHandleClickReservar={onHandleClickReservar}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ))
        )}
      </Col>
    </Row>
  );
};

export default CabinsDetail;
