import React from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormMaps from './FormMaps';

const MapSidebar = ({
  center,
  containerHeight = '600px',
  items,
  onClick,
  onMarkerClick,
  position,
  positionsList,
  setCenter,
  setPosition,
}) => {
  const { t } = useTranslation();

  return positionsList.length > 0 ? (
    <Wrapper>
      <FormMaps
        items={items}
        center={center}
        setCenter={setCenter}
        positions={positionsList}
        position={position}
        setPosition={setPosition}
        isMarkerShown
        onClick={onClick}
        onMarkerClick={onMarkerClick}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: containerHeight }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </Wrapper>
  ) : (
    <div className="jumbotron" style={{ height: 'auto' }}>
      <p className="tw-text-center tw-text-xl tw-font-light">
        {t('NoDisponibleVistaMapa')}
      </p>
    </div>
  );
};

const Wrapper = styled.div`
  &&&& .gm-style-iw > button {
    display: none !important;
  }
`;

export default MapSidebar;
