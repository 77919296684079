import React from 'react';

import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsWhatsapp } from 'react-icons/bs';
import { FiMessageSquare } from 'react-icons/fi';
import { IoIosCall } from 'react-icons/io';
import styled from 'styled-components';
import SliderHome from '../../components/SliderHome';
import useAuth from '../../hook/useAuth';
import artGalleryBlack from '../../img/dashboard/art-gallery-black.jpg';
import flightsBlack from '../../img/dashboard/flights-black.jpg';
import hotelsBlack from '../../img/dashboard/pexels-donald-tong-189296.png';
import transportationBlack from '../../img/dashboard/transportation-black.jpg';
import yatchBlack from '../../img/dashboard/yate-black.jpg';
import FloatingButton from './FloatingButton';
import Slider from './Slider';
import Title from './Title';

const { REACT_APP_WHATS_APP, REACT_APP_WEB_CHAT } = process.env;

const Home = () => {
  const { t } = useTranslation();
  const { isPlatinum } = useAuth();

  return (
    <>
      <SliderHome />

      <ContainerStyled>
        {!isPlatinum && (
          <Row>
            <Col xs="12">
              <Title title={t('DashboardExclusiveBenefitsBlack')} />
            </Col>

            <Col xs="12" md="4">
              <CardStyled>
                <Card.Img variant="top" src={yatchBlack} />

                <Body>
                  <Card.Title>{t('DashboardYateBlack')}</Card.Title>
                  <Card.Text className="tw-flex tw-flex-col">
                    <span className="tw-mb-3">
                      {t('DashboardYateDescriptionBlack')}
                    </span>
                    <Dates>{t('DashboardYateDatesBlack')}</Dates>
                  </Card.Text>
                  <FloatingButton code="SUNSET-WORLD-YATCH">
                    -30%
                  </FloatingButton>
                </Body>
              </CardStyled>
            </Col>

            <Col xs="12" md="8">
              <Row>
                <Col xs="12" md="6">
                  <CardStyled>
                    <Card.Img
                      className="tw-h-[150px]"
                      variant="top"
                      src={flightsBlack}
                    />
                    <Body>
                      <Card.Title>{t('DashboardFlightsBlack')}</Card.Title>
                      <Card.Text className="tw-flex tw-flex-col">
                        <span className="tw-mb-3">
                          {t('DashboardFlightsDescriptionBlack')}
                        </span>
                        <Dates>{t('DashboardFlightsDatesBlack')}</Dates>
                      </Card.Text>
                      <FloatingButton code="SUNSET-WORLD-FLIGHT">
                        -15%
                      </FloatingButton>
                    </Body>
                  </CardStyled>
                </Col>

                <Col xs="12" md="6">
                  <CardStyled>
                    <Card.Img
                      className="tw-h-[150px]"
                      variant="top"
                      src={hotelsBlack}
                    />

                    <Body>
                      <Card.Title>{t('DashboardHotelsBlack')}</Card.Title>
                      <Card.Text className="tw-flex tw-flex-col">
                        <span className="tw-mb-3">
                          {t('DashboardHotelsDescriptionBlack')}
                        </span>
                        <Dates>{t('DashboardHotelsDatesBlack')}</Dates>
                      </Card.Text>
                      <FloatingButton code="SUNSET-WORLD-HOTEL">
                        -15%
                      </FloatingButton>
                    </Body>
                  </CardStyled>
                </Col>

                <Col xs="12" md="6">
                  <CardStyled>
                    <Card.Img
                      className="tw-h-[150px]"
                      variant="top"
                      src={transportationBlack}
                    />

                    <Body>
                      <Card.Title>
                        {t('DashboardTransportationBlack')}
                      </Card.Title>
                      <Card.Text className="tw-flex tw-flex-col">
                        <span className="tw-mb-3">
                          {t('DashboardTransportationDescriptionBlack')}
                        </span>
                        <Dates>{t('DashboardTransportationDatesBlack')}</Dates>
                      </Card.Text>
                      <FloatingButton code="SUNSET-WORLD-TRANSPORTATION">
                        -40%
                      </FloatingButton>
                    </Body>
                  </CardStyled>
                </Col>

                <Col xs="12" md="6">
                  <CardStyled>
                    <Card.Img
                      className="tw-h-[150px]"
                      variant="top"
                      src={artGalleryBlack}
                    />

                    <Body>
                      <Card.Title>{t('DashboardArtGalleryBlack')}</Card.Title>
                      <Card.Text className="tw-flex tw-flex-col">
                        <span className="tw-mb-3">
                          {t('DashboardArtGalleryDescriptionBlack')}
                        </span>
                        <Dates>{t('DashboardArtGalleryDatesBlack')}</Dates>
                      </Card.Text>
                      <FloatingButton code="SUNSET-WORLD-ART-GALLERY">
                        -40%
                      </FloatingButton>
                    </Body>
                  </CardStyled>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <Row className={isPlatinum && 'tw-mt-3'}>
          <Col>
            <CardOptionTravelStyled className="tw-text-primary">
              <Body>
                <h3>{t('DashboardBestOptionTravel')}</h3>
                <p
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: t('DashboardBestOptionTravelDescription'),
                  }}
                />
                <div className="tw-flex tw-w-full tw-justify-around">
                  {REACT_APP_WHATS_APP?.length && (
                    <WrapperIcon
                      href={REACT_APP_WHATS_APP}
                      target="_blank"
                      className="tw-text-primary"
                    >
                      <BsWhatsapp />
                    </WrapperIcon>
                  )}

                  {REACT_APP_WEB_CHAT?.length && (
                    <WrapperIcon
                      href={REACT_APP_WEB_CHAT}
                      target="_blank"
                      className="tw-text-primary"
                    >
                      <FiMessageSquare />
                    </WrapperIcon>
                  )}

                  <WrapperIcon className="tw-text-primary">
                    <IoIosCall />
                  </WrapperIcon>
                </div>
              </Body>
            </CardOptionTravelStyled>
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <Title title={t('DashboardBenefitsOurFavoriteDestinations')} />
          </Col>

          <Col xs="12">
            <Slider />
          </Col>
        </Row>
      </ContainerStyled>
    </>
  );
};

const ContainerStyled = styled(Container)`
  [class*='col-'] {
    display: flex;
  }
`;

const CardStyled = styled(Card).attrs(() => ({ className: 'tw-mb-3' }))`
  &&&& {
    border-radius: 1rem !important;
    width: 100%;
  }
`;

const Body = styled(Card.Body)`
  position: relative;
  border-top: 1px solid #88888826;
`;

const CardOptionTravelStyled = styled(CardStyled)`
  text-align: center;
  background-color: #003c74 !important;
  color: white !important;

  & h3 {
    color: white !important;
  }

  & p {
    /* font-size: 10px; */
  }
`;

const Dates = styled.span`
  font-weight: 600;
  color: var(--button-primary);
`;

const WrapperIcon = styled.a`
  font-size: 30px;
  height: 66px;
  aspect-ratio: 1;
  border-radius: 100%;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Home;
