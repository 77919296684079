import { css } from 'styled-components';

const inputRange = css`
  .input-range__track.input-range__track--background {
    background-color: var(--button-primary-translucent) !important;
  }

  .input-range__track.input-range__track--active,
  .input-range__slider {
    background-color: var(--button-primary) !important;
    border-color: var(--button-primary) !important;
  }
`;

export default inputRange;
