import React, { useEffect } from 'react';

import { Field, useFormikContext } from 'formik';
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import { FaMapMarkerAlt } from 'react-icons/fa';
import withFormikSearch from '../../hoc/withFormikSearch';
import useAppTranslation from '../../hook/useAppTranslation';
import InputForm from '../../shared/InputForm';
import Rating from '../../shared/Rating';
import jointStr from '../../utils/jointStr';
import sleep from '../../utils/sleep';
import { bookingSchema } from '../../validations/hotels';
import CarouselTopDetail from '../CarouselTopDetail';
import FormPayment from '../FormPayment';
import BookingErrorsForm from '../booking/BookingErrorsForm';
import styles from './BookInfo.module.scss';

const BookInfo = ({ rate }) => {
  const { t } = useAppTranslation();
  const { values, setValues, setFieldValue, touched, errors, submitCount } =
    useFormikContext();

  const { name, middleName, lastName, isMainGuest } = values ?? {};
  useEffect(() => {
    let didCancel = false;
    const update = async () => {
      await sleep(300);
      if (didCancel || !isMainGuest) return;

      const nameGuest = jointStr({ arr: [name, middleName] });

      setFieldValue('rooms.0.0.name', nameGuest);
      setFieldValue('rooms.0.0.lastName', lastName);
    };

    update();
    return () => {
      didCancel = true;
    };
  }, [name, middleName, lastName, isMainGuest, setFieldValue]);

  const handleMainGuestChange = evt => {
    const newIsMainGuest = evt?.target?.value === 'true';

    const newValues = JSON.parse(JSON.stringify(values));
    newValues.isMainGuest = newIsMainGuest;
    if (!newIsMainGuest) {
      newValues.rooms[0][0].name = '';
      newValues.rooms[0][0].lastName = '';
    }

    setValues(newValues);
  };

  return (
    <Container className={styles.container}>
      <Card>
        <Card.Body>
          <Row xs="1">
            <Col lg="3">
              <CarouselTopDetail photos={rate.hotelPhotos} />
            </Col>

            <Col lg="9">
              <h2>{rate.hotelName}</h2>
              <Rating rating={rate.stars} />
              <span className={`tw-text-secondary ${styles.address}`}>
                <FaMapMarkerAlt className="icon-20 tw-m-0" /> {rate.address}
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <h1>{t('EnterYourData')}</h1>
          <small className="tw-block tw-text-secondary">
            {t('PleaseCheckAllData')}
          </small>

          <BookingErrorsForm
            touched={touched}
            errors={errors}
            submitCount={submitCount}
          />

          <Accordion
            className={`${styles.accordion} tw-mt-3`}
            defaultActiveKey="0"
          >
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                {t('HotelsPrimaryGuestInformation')}
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Row xs="1" lg="3">
                    <Col>
                      <InputForm name="name" label={t('HotelsBookName')} />
                    </Col>

                    <Col>
                      <InputForm
                        name="middleName"
                        label={t('HotelsBookMiddleName')}
                        required={false}
                      />
                    </Col>

                    <Col>
                      <InputForm
                        name="lastName"
                        label={t('HotelsBookLastName')}
                      />
                    </Col>
                  </Row>

                  <Row xs="1" lg="3">
                    <Col>
                      <InputForm
                        type="email"
                        name="email"
                        label={t('HotelsBookEmail')}
                        feedback={
                          <Form.Control.Feedback
                            className="tw-text-gray-500"
                            style={{ display: 'block' }}
                          >
                            {t('CorreoElectronicoParrafo')}
                          </Form.Control.Feedback>
                        }
                      />
                    </Col>

                    <Col>
                      <InputForm
                        type="tel"
                        name="phone"
                        label={t('HotelsBookPhone')}
                      />
                    </Col>
                  </Row>

                  <Row xs="1">
                    <Col>
                      <h6>{t('ParaQuienReserva')}</h6>

                      <Field name="isMainGuest">
                        {({ field, meta }) => {
                          const value = Boolean(field.value);
                          // console.log('🚀 ~ BookInfo.jsx', { value });

                          return (
                            <Form.Group controlId={field.name}>
                              <Form.Check type="radio" id={`${field.name}-yes`}>
                                <div className={styles.check}>
                                  <Form.Check.Input
                                    type="radio"
                                    id={`${field.name}-yes`}
                                    name={`${field.name}-yes`}
                                    // eslint-disable-next-line react/jsx-boolean-value
                                    value={true}
                                    checked={value === true}
                                    onChange={handleMainGuestChange}
                                    onBlur={field.onBlur}
                                    isValid={meta.touched && !meta.error}
                                    isInvalid={meta.touched && !!meta.error}
                                  />

                                  <Form.Check.Label>
                                    {t('HuespedPrincipal')}
                                  </Form.Check.Label>
                                </div>
                              </Form.Check>

                              <Form.Check type="radio" id={`${field.name}-no`}>
                                <div className={styles.check}>
                                  <Form.Check.Input
                                    type="radio"
                                    id={`${field.name}-no`}
                                    name={`${field.name}-no`}
                                    value={false}
                                    checked={value === false}
                                    onChange={handleMainGuestChange}
                                    onBlur={field.onBlur}
                                    isValid={meta.touched && !meta.error}
                                    isInvalid={meta.touched && !!meta.error}
                                  />

                                  <Form.Check.Label>
                                    {t('OtraPersona')}
                                  </Form.Check.Label>
                                </div>

                                {meta.touched && meta.error && (
                                  <Form.Control.Feedback type="invalid">
                                    {t(meta.error)}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Check>
                            </Form.Group>
                          );
                        }}
                      </Field>
                    </Col>
                  </Row>

                  <Accordion
                    className={`${styles.accordionRoom} tw-mt-3`}
                    defaultActiveKey="0"
                  >
                    {values.rooms.map((room, roomIndex) => {
                      // console.log('🚀 ~ BookInfo.jsx', { room });

                      return (
                        <Card key={roomIndex}>
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey={`${roomIndex}`}
                          >
                            {t('HotelsRooms_one')} {roomIndex + 1}
                          </Accordion.Toggle>

                          <Accordion.Collapse eventKey={`${roomIndex}`}>
                            <Card.Body>
                              {room.map((guest, guestIndex) => {
                                const disabled =
                                  values.isMainGuest &&
                                  roomIndex === 0 &&
                                  guestIndex === 0;
                                const prefix = `rooms[${roomIndex}][${guestIndex}]`;
                                const labelName =
                                  guest.type === 'ADULT'
                                    ? 'Adults'
                                    : 'Children';

                                return (
                                  <Row key={`${roomIndex}.${guestIndex}`}>
                                    <Col xs="12">
                                      <label className="tw-font-bold">
                                        {t(labelName, { count: 1 })} {guest.num}
                                      </label>
                                    </Col>

                                    <Col xs="12" lg="6">
                                      <InputForm
                                        name={`${prefix}.name`}
                                        label={t('Nombre')}
                                        placeholder={t('NombrePlaceholder')}
                                        disabled={disabled}
                                      />
                                    </Col>

                                    <Col xs="12" lg="6">
                                      <InputForm
                                        name={`${prefix}.lastName`}
                                        label={t('Apellidos')}
                                        placeholder={t('ApellidosPlaceholder')}
                                        disabled={disabled}
                                      />
                                    </Col>
                                  </Row>
                                );
                              })}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      );
                    })}
                  </Accordion>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Accordion className={styles.accordion} defaultActiveKey="0">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                {t('PaymentInformation')}
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Row xs="1">
                    <Col>
                      <Alert variant="info">
                        {t('HotelsAccommodationRequires')}
                      </Alert>
                    </Col>
                  </Row>

                  <FormPayment />

                  <Row xs="1">
                    <Col>
                      <Field name="policy1">
                        {({ field, meta }) => {
                          return (
                            <Form.Group controlId={field.name}>
                              <Form.Check type="checkbox" id={field.name}>
                                <div className={styles.check}>
                                  <Form.Check.Input
                                    type="checkbox"
                                    name={field.name}
                                    value
                                    checked={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    isValid={meta.touched && !meta.error}
                                    isInvalid={meta.touched && !!meta.error}
                                  />

                                  <Form.Check.Label>
                                    {t('HotelsPolicy1')}
                                  </Form.Check.Label>
                                </div>

                                {meta.touched && meta.error && (
                                  <Form.Control.Feedback type="invalid">
                                    {t(meta.error)}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Check>
                            </Form.Group>
                          );
                        }}
                      </Field>
                    </Col>

                    <Col>
                      <Field name="policy2">
                        {({ field, meta }) => {
                          return (
                            <Form.Group controlId={field.name}>
                              <Form.Check type="checkbox" id={field.name}>
                                <div className={styles.check}>
                                  <Form.Check.Input
                                    type="checkbox"
                                    name={field.name}
                                    value
                                    checked={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    isValid={meta.touched && !meta.error}
                                    isInvalid={meta.touched && !!meta.error}
                                  />

                                  <Form.Check.Label>
                                    {t('HotelsPolicy2')}
                                  </Form.Check.Label>
                                </div>

                                {meta.touched && meta.error && (
                                  <Form.Control.Feedback type="invalid">
                                    {t(meta.error)}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Check>
                            </Form.Group>
                          );
                        }}
                      </Field>
                    </Col>

                    <Col>
                      <Button
                        type="submit"
                        variant="outline-primary"
                        className="tw-mt-3 tw-font-bold"
                      >
                        {t('CompleteReservation')}
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default withFormikSearch(
  {
    schema: bookingSchema,
    onSubmit: ({ values }, { onBookingClick }) => {
      onBookingClick(values);
    },
  },

  BookInfo,
);
