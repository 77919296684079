import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

const MapMarker = forwardRef(function MapMarker(
  { lat, lng, onClick, children }, // eslint-disable-line no-unused-vars
  ref, // eslint-disable-line no-unused-vars
) {
  // console.log('🚀 ~ MapMarker.jsx', { lat, lng, children, ref });

  return <span>Hello World!</span>;
});

MapMarker.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.element.isRequired,
};

MapMarker.defaultProps = {
  onClick: () => {},
};

export default MapMarker;
