/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useMemo } from 'react';

import { Field, Formik } from 'formik';
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InputForm from '../../shared/InputForm';
import jointStr from '../../utils/jointStr';
import sleep from '../../utils/sleep';
import { bookingSchema } from '../../validations/hotels';
import FormPayment from '../FormPayment';
import {
  BookingAccordionCollapse,
  BookingAccordionToggle,
} from './BookingAccordion';
import BookingErrorsForm from './BookingErrorsForm';

const withFormik = Component => props => {
  const { checkout, onBookingClick } = props;

  const initialValues = useMemo(
    () => ({
      name: '',
      middleName: '',
      lastName: '',
      email: '',
      phone: '',
      isMainGuest: null,
      rooms: checkout.rooms.map(it => {
        const acc = {};
        return Array.from({ length: it.guest.adults })
          .fill({ type: 'ADULT' })
          .concat(it.guest.childrenAges.map(age => ({ type: 'CHILD', age })))

          .map(({ type, age }) => {
            acc[type] = acc[type] ?? 1;

            return { num: acc[type]++, type, name: '', lastName: '', age };
          })
          .sort(({ type: a }, { type: b }) => {
            if (a === 'ADULT' && b !== 'ADULT') return -1;
            if (b === 'ADULT' && a !== 'ADULT') return 1;

            return 0;
          });
      }),

      payment: bookingSchema?.fields?.payment?.getDefault?.() ?? {},

      policy1: false,
      policy2: false,
    }),
    [checkout],
  );

  const handleSubmitFormik = values => {
    if (onBookingClick) onBookingClick(values);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={bookingSchema}
      initialValues={initialValues}
      onSubmit={handleSubmitFormik}
    >
      {formikBag => {
        return (
          <form noValidate onSubmit={formikBag.handleSubmit}>
            <Component {...props} formikBag={formikBag} />
          </form>
        );
      }}
    </Formik>
  );
};

const BookingInfo = ({ formikBag }) => {
  const { t } = useTranslation();
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setValues,
    submitCount,
  } = formikBag;

  const { name, middleName, lastName, isMainGuest } = values;
  useEffect(() => {
    let didCancel = false;
    const update = async () => {
      await sleep(300);
      if (didCancel || !isMainGuest) return;

      const nameGuest = jointStr({ arr: [name, middleName] });

      formikBag.setFieldValue('rooms.0.0.name', nameGuest);
      formikBag.setFieldValue('rooms.0.0.lastName', lastName);
    };

    update();
    return () => {
      didCancel = true;
    };
  }, [name, middleName, lastName, isMainGuest]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMainGuestChange = evt => {
    const newIsMainGuest = evt?.target?.value === 'true';

    const newValues = JSON.parse(JSON.stringify(values));

    newValues.isMainGuest = newIsMainGuest;
    if (!newIsMainGuest) {
      newValues.rooms[0][0].name = '';
      newValues.rooms[0][0].lastName = '';
    }

    setValues(newValues);
  };

  return (
    <Card className="tw-shadow">
      <Card.Body>
        <Row className="tw-mb-3">
          <Col xs="12">
            <h1>{t('EnterYourData')}</h1>
            <small className="tw-block tw-text-secondary">
              {t('PleaseCheckAllData')}
            </small>
          </Col>
        </Row>

        <BookingErrorsForm
          touched={touched}
          errors={errors}
          submitCount={submitCount}
        />

        <Row>
          <Col xs="12">
            <Accordion defaultActiveKey="0">
              <Card className="bg-light card-reserva tw-border-0 tw-shadow-none">
                <BookingAccordionToggle open eventKey="0">
                  <h6>{t('HotelsPrimaryGuestInformation')}</h6>
                </BookingAccordionToggle>

                <BookingAccordionCollapse eventKey="0">
                  <Row>
                    <Col xs="12" lg="4">
                      <InputForm
                        name="name"
                        label={t('Nombre')}
                        placeholder={t('NombrePlaceholder')}
                        hideFeedback
                      />
                    </Col>

                    <Col xs="12" lg="4">
                      <InputForm
                        name="middleName"
                        label={t('SegundoNombre')}
                        placeholder={t('SegundoNombrePlaceholder')}
                        hideFeedback
                        required={false}
                      />
                    </Col>

                    <Col xs="12" lg="4">
                      <InputForm
                        name="lastName"
                        label={t('Apellidos')}
                        placeholder={t('ApellidosPlaceholder')}
                        hideFeedback
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12" lg="4">
                      <InputForm
                        type="email"
                        name="email"
                        label={t('CorreoElectronico')}
                        placeholder={t('CorreoElectronicoPlaceholder')}
                        feedback={
                          <Form.Control.Feedback
                            className="tw-text-gray-500"
                            style={{ display: 'block' }}
                          >
                            {t('CorreoElectronicoParrafo')}
                          </Form.Control.Feedback>
                        }
                        hideFeedback
                      />
                    </Col>

                    <Col xs="12" lg="4">
                      <InputForm
                        type="tel"
                        name="phone"
                        label={t('Telefono')}
                        placeholder={t('TelefonoPlaceholder')}
                        hideFeedback
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12" lg="12">
                      <Field name="isMainGuest">
                        {({ field }) => (
                          <Form.Group controlId={field.name}>
                            <Form.Label>{t('ParaQuienReserva')}</Form.Label>

                            <Form.Check
                              type="radio"
                              id="isMainGuestYes"
                              name={field.name}
                              label={t('HuespedPrincipal')}
                              className="tw-flex tw-items-center tw-text-secondary"
                              checked={field.value === true}
                              // eslint-disable-next-line react/jsx-boolean-value
                              value={true}
                              onChange={handleMainGuestChange}
                              onBlur={field.onBlur}
                            />

                            <Form.Check
                              type="radio"
                              id="isMainGuestNo"
                              name={field.name}
                              label={t('OtraPersona')}
                              className="tw-flex tw-items-center tw-text-secondary"
                              checked={field.value === false}
                              value={false}
                              onChange={handleMainGuestChange}
                              onBlur={field.onBlur}
                            />
                          </Form.Group>
                        )}
                      </Field>
                    </Col>

                    <Col xs="12" lg="12">
                      <Accordion>
                        {values.rooms.map((room, roomIndex) => {
                          return (
                            <Card className="tw-shadow-none" key={roomIndex}>
                              <BookingAccordionToggle eventKey={roomIndex}>
                                <h6>
                                  {t('HotelsRooms_one')} {roomIndex + 1}
                                </h6>
                              </BookingAccordionToggle>

                              <BookingAccordionCollapse eventKey={roomIndex}>
                                {room.map((guest, guestIndex) => {
                                  const disabled =
                                    values.isMainGuest &&
                                    roomIndex === 0 &&
                                    guestIndex === 0;
                                  const prefix = `rooms[${roomIndex}][${guestIndex}]`;
                                  const labelName =
                                    guest.type === 'ADULT'
                                      ? 'Adults'
                                      : 'Children';

                                  return (
                                    <Row key={guestIndex}>
                                      <Col xs="12">
                                        <label className="tw-font-bold">
                                          {t(labelName, { count: 1 })}{' '}
                                          {guest.num}
                                        </label>
                                      </Col>

                                      <Col xs="12" lg="6">
                                        <InputForm
                                          name={`${prefix}.name`}
                                          label={t('Nombre')}
                                          placeholder={t('NombrePlaceholder')}
                                          disabled={disabled}
                                          hideFeedback
                                        />
                                      </Col>

                                      <Col xs="12" lg="6">
                                        <InputForm
                                          name={`${prefix}.lastName`}
                                          label={t('Apellidos')}
                                          placeholder={t(
                                            'ApellidosPlaceholder',
                                          )}
                                          disabled={disabled}
                                          hideFeedback
                                        />
                                      </Col>
                                    </Row>
                                  );
                                })}
                              </BookingAccordionCollapse>
                            </Card>
                          );
                        })}
                      </Accordion>
                    </Col>
                  </Row>
                </BookingAccordionCollapse>
              </Card>
            </Accordion>
          </Col>

          <Col xs="12">
            <Accordion>
              <Card className="bg-light card-reserva tw-border-0 tw-shadow-none">
                <BookingAccordionToggle open eventKey="0">
                  <h6>{t('PaymentInformation')}</h6>
                </BookingAccordionToggle>

                <BookingAccordionCollapse eventKey="0">
                  <Row>
                    <Col xs="12">
                      <Alert variant="info">
                        {t('HotelsAccommodationRequires')}
                      </Alert>
                    </Col>
                  </Row>

                  <FormPayment hideFeedback />

                  <Row>
                    <Col xs="12">
                      <Form.Check
                        type="checkbox"
                        name="policy1"
                        label={t('HotelsPolicy1')}
                        className="tw-text-secondary"
                        checked={values.policy1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Col>

                    <Col xs="12">
                      <Form.Check
                        type="checkbox"
                        name="policy2"
                        label={t('HotelsPolicy2')}
                        className="tw-text-secondary"
                        checked={values.policy2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Col>

                    <Col xs="12">
                      <Button
                        type="submit"
                        variant="outline-primary"
                        className="tw-mt-3 tw-font-bold"
                      >
                        {t('CompleteReservation')}
                      </Button>
                    </Col>
                  </Row>
                </BookingAccordionCollapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default withFormik(BookingInfo);
