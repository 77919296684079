import { css } from 'styled-components';
import text from './text';

const containers = css`
  .card,
  .container,
  .container-fluid {
    &.primary,
    &.bg-primary {
      background-color: var(--background-primary) !important;

      ${text}
    }
  }

  .card,
  .container,
  .container-fluid {
    &.bg-secondary {
      background-color: var(--background-secondary) !important;

      ${text}
    }
  }

  section.footer-1,
  section.footer-welcome {
    background-color: var(--background-secondary) !important;

    ${text}
  }

  section.footer-2 {
    background-color: var(--background-footer) !important;

    ${text}
  }
`;

export default containers;
