import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InputRange from 'react-input-range';
import usePriceFormat from '../../hook/usePriceFormat';
import sleep from '../../utils/sleep';
import styles from './FilterSidebar.module.css';

const FormatLabel = value => {
  const format = usePriceFormat({ decimalScale: 2 });

  return <h6 className="tw-font-bold">{format({ value })}</h6>;
};

const FilterSidebar = ({ config, onChange }) => {
  const { t } = useTranslation();
  const [price, setPrice] = useState();
  const [isManual, setIsManual] = useState();
  const [isAutomatic, setIsAutomatic] = useState();
  const [capacity, setCapacity] = useState();
  const [airConditioning, setAirConditioning] = useState();

  const oldFilters = useRef(null);
  useEffect(() => {
    let didCancel = false;
    const update = async () => {
      await sleep(300);
      if (didCancel || !onChange) return;

      const newFilters = {
        price,
        isManual,
        isAutomatic,
        capacity:
          capacity &&
          capacity.map(([expMin, expMax]) => ({
            min: Number.parseInt(expMin.replace('>=', ''), 10),
            max: Number.parseInt(expMax.replace('<=', ''), 10),
          })),
        airConditioning,
      };

      if (oldFilters.current === JSON.stringify(newFilters)) {
        return;
      }

      oldFilters.current = JSON.stringify(newFilters);
      onChange(newFilters);
    };

    update();
    return () => {
      didCancel = true;
    };
  }, [price, isManual, isAutomatic, capacity, onChange, airConditioning]);

  const showPriceRange = useMemo(
    () =>
      Boolean(
        config?.price?.min > 0 &&
          config?.price?.max > 0 &&
          config.price.min < config.price.max,
      ),
    [config],
  );

  const showTransmission = useMemo(
    () => Boolean(config?.isManual && config?.isAutomatic),
    [config],
  );

  const showPassengers = useMemo(
    () => Boolean(config?.capacity?.filter(({ on }) => on).length > 1),
    [config],
  );

  const showAirConditioning = useMemo(
    () => Boolean(config?.airConditioning),
    [config],
  );

  const show = useMemo(() => {
    if (showPriceRange) return true;
    if (showTransmission) return true;
    if (showPassengers) return true;
    if (showAirConditioning) return true;

    return false;
  }, [showPriceRange, showTransmission, showPassengers, showAirConditioning]);

  if (!show) return <div />;

  return (
    <Container>
      <Row xs="1">
        <Col>
          <h4 className="tw-uppercase">{t('FiltersPopular')}</h4>
        </Col>

        {showPriceRange && (
          <Col>
            <Container className="tw-pb-4">
              <Row xs="1" className={styles.containerPrice}>
                <Col>
                  <span className="tw-font-bold tw-text-black">
                    {t('Precio')}
                  </span>
                </Col>

                <Col>
                  <InputRange
                    formatLabel={FormatLabel}
                    minValue={config.price.min}
                    maxValue={config.price.max}
                    value={
                      price ?? {
                        min: config?.price?.min ?? 0,
                        max: config?.price?.max ?? 0,
                      }
                    }
                    onChange={newPrice => {
                      setPrice(newPrice);
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        )}

        {showTransmission && (
          <Col>
            <Container className="tw-pb-4">
              <Row xs="1" className={styles.containerTransmission}>
                <Col>
                  <span className="tw-font-bold tw-text-black">
                    {t('CarsTransmission')}
                  </span>
                </Col>

                <Col>
                  <Form.Check
                    type="checkbox"
                    label={t('CarsTransmissionManual')}
                    className="tw-flex tw-items-center"
                    checked={isManual ?? false}
                    onChange={evt => {
                      setIsManual(evt?.target?.checked ?? false);
                    }}
                  />
                  <Form.Check
                    type="checkbox"
                    label={t('CarsTransmissionAutomatic')}
                    className="tw-flex tw-items-center"
                    checked={isAutomatic ?? false}
                    onChange={evt => {
                      setIsAutomatic(evt?.target?.checked ?? false);
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        )}

        {showPassengers && (
          <Col>
            <Container className="tw-pb-4">
              <Row xs="1" className={styles.containerTransmission}>
                <Col>
                  <span className="tw-font-bold tw-text-black">
                    {t('CarsCapacity')}
                  </span>
                </Col>

                <Col>
                  {config.capacity
                    .filter(({ on }) => on)
                    .map((it, index) => {
                      const expArr = [`>=${it.min}`, `<=${it.max}`];

                      return (
                        <Form.Check
                          key={index}
                          type="checkbox"
                          label={t('CarsCapacityPassenger', {
                            min: it.min,
                            max: Number.isFinite(it.max) ? it.max : '',
                          })}
                          className="tw-flex tw-items-center"
                          checked={(capacity ?? []).some(
                            ([exp1, exp2]) =>
                              expArr.includes(exp1) && expArr.includes(exp2),
                          )}
                          onChange={evt => {
                            const checked = evt?.target?.checked ?? false;
                            const newCapacity = (capacity ?? []).filter(
                              ([exp1, exp2]) =>
                                !expArr.includes(exp1) &&
                                !expArr.includes(exp2),
                            );

                            setCapacity(
                              checked
                                ? newCapacity.concat([expArr])
                                : newCapacity,
                            );
                          }}
                        />
                      );
                    })}
                </Col>
              </Row>
            </Container>
          </Col>
        )}

        {showAirConditioning && (
          <Col>
            <Container className="tw-pb-4">
              <Row xs="1" className={styles.containerTransmission}>
                <Col>
                  <span className="tw-font-bold tw-text-black">
                    {t('CarsSpecifications')}
                  </span>
                </Col>

                <Col>
                  <Form.Check
                    type="checkbox"
                    label={t('CarsAirConditioning')}
                    className="tw-flex tw-items-center"
                    checked={airConditioning ?? false}
                    onChange={evt => {
                      setAirConditioning(evt?.target?.checked ?? false);
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default FilterSidebar;
