import React from 'react';

import moment from 'moment';
import { Card, Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { FaCalendarAlt } from 'react-icons/fa';

const ReservaCardTopEvent = ({ t, item }) => {
  const f1 = moment(item.checkIn, 'DD-MM-YYYY');

  return (
    <Card className="tw-mb-2 tw-text-slate-50">
      <Card.Body>
        <Row>
          <Col xs="12" lg="12">
            <h4>{t('YourReservationDetail')}</h4>
            <div className="tw-flex tw-justify-between">
              <div>
                <label className="tw-mb-0 tw-block">
                  <FaCalendarAlt className="icon-14 tw-mb-1 tw-mr-2" />
                  {t('Fecha')}
                </label>
                <label className="ft-08rem tw-mb-0 tw-block">
                  {moment(f1).format('dd, DD MMM, YYYY')}
                </label>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="tw-mt-4">
          <Col>
            <label className="tw-mb-2 tw-block">{item.sport}</label>
            <label className="tw-mb-2 tw-block">{item.apiName}</label>
            <label className="ft-08rem tw-mb-0  tw-block">
              {item.direccion}
            </label>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default withTranslation()(ReservaCardTopEvent);
