import React from 'react';

import { Card, Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

const FiltersContainer = ({ title, children }) => {
  return (
    <Container fluid>
      <Row className="tw-mb-5 md:tw-justify-center">
        <Col xs="12" md="10" xl="8">
          <h4 className="tw-text-secondary">{title}</h4>

          <CardStyled>
            <Card.Body>{children}</Card.Body>
          </CardStyled>
        </Col>
      </Row>
    </Container>
  );
};

const CardStyled = styled(Card)`
  background-color: var(--background-primary-translucent);
`;

export default FiltersContainer;
