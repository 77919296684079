import React from 'react';

import { OverlayTrigger, Tooltip as TooltipBootstrap } from 'react-bootstrap';

const Tooltip = ({ children, message }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <TooltipBootstrap id="button-tooltip-2">{message}</TooltipBootstrap>
      }
      delay={{ show: 250, hide: 400 }}
    >
      {children}
    </OverlayTrigger>
  );
};

export default Tooltip;
