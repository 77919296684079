import React, { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';
import EmptyItems from '../../components/EmptyItems';
import ErrorServer from '../../components/ErrorServer';
import FilterSidebar from '../../components/Flights/FilterSidebar';
import FormSearch from '../../components/Flights/FormSearch';
import StepperFlights from '../../components/Flights/StepperFlights';
import SectionTop from '../../components/SectionTop';
import useAppParams from '../../hook/useAppParams';
import useAppTranslation from '../../hook/useAppTranslation';
import CargandoOpciones from '../../loader/CargandoOpciones';
import { getLabelByDestinyId } from '../../services/destinations';
import { getFlights } from '../../services/flights';
import FormSidebar from '../../shared/FormSidebar';
import sleep from '../../utils/sleep';

const errorServer = false;
const List = () => {
  const { t, language } = useAppTranslation();
  const { query } = useAppParams();

  const [loading, setLoading] = useState(true);
  const [destination, setDestination] = useState(null);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    airlines: [],
    price: {
      currency: 'MXN',
      min: 0,
      max: 100,
      value: {
        min: 0,
        max: 100,
      },
    },
  });

  useEffect(() => {
    let didCancel = false;
    const set = async () => {
      await sleep(500);
      if (didCancel || !query) return;

      setLoading(true);
      setDestination(null);
      setData([]);

      const paxes = query.persons.children.reduce(
        (acc, age) => {
          if (age >= 0 && age <= 1) {
            acc.infant++;
          } else if (age >= 2 && age <= 17) {
            acc.children++;
          }

          return acc;
        },
        {
          adults: query.persons.adults,
          children: 0,
          infant: 0,
          junior: 0,
        },
      );

      const [labelDestination, flights] = await Promise.all([
        getLabelByDestinyId({
          destinyId: query.destination,
          language,
        }),

        getFlights({
          origin: query.origin,
          destiny: query.destination,
          checkIn: moment(query.startDate),
          returnDate: moment(query.endDate),
          paxes,
          oneWay: query.oneWay,
        }),
      ]);

      if (didCancel) return;

      // console.log('🚀 ~ List.jsx', { labelOrigin, labelDestination, flights });
      setDestination(labelDestination);
      setData(flights.data);
      setFilters({
        airlines: flights.airlines,
        price: {
          currency: flights.currency,
          ...flights.price,
          value: flights.price,
        },
      });
      setLoading(false);
    };

    set();
    return () => {
      didCancel = true;
    };
  }, [query, language]);

  const flights = useMemo(() => {
    const price = filters?.price?.value;
    const airlines = (
      filters?.airlines?.filter(({ checked }) => checked) ?? []
    ).map(({ id }) => id);

    const filteredFlights = data.filter(it => {
      if (!it) return false;

      if (price) {
        if (it.price?.total < price.min || it.price?.total > price.max)
          return false;
      }

      if (airlines.length) {
        if (
          ![
            ...(it?.departing?.segments ?? []),
            ...(it?.returning?.segments ?? []),
          ].some(({ airline }) => airlines.includes(airline.code))
        )
          return false;
      }

      return true;
    });

    return filteredFlights;
  }, [data, filters]);

  if (loading) return <CargandoOpciones />;

  if (errorServer)
    return (
      <Container>
        <ErrorServer />
      </Container>
    );

  if (!data.length)
    return (
      <Container fluid className="tw-my-3">
        <EmptyItems />
      </Container>
    );

  return (
    <>
      <SectionTop
        menu={t('Vuelos', { count: flights.length })}
        destino={destination}
        total={flights.length || 0}
        product={t('Vuelos', {
          count: flights.length,
        })}
      />

      <Container fluid className="tw-my-3">
        <Row>
          <Col xs="12" lg="4">
            <Row className="md:tw-justify-center">
              <Col xs="12">
                <FormSidebar>
                  <FormSearch inline filters={query} />
                </FormSidebar>
              </Col>

              {data.length > 0 && (
                <Col xs="12">
                  <FilterSidebar filters={filters} setFilters={setFilters} />
                </Col>
              )}
            </Row>
          </Col>

          <Col xs="12" lg="8">
            <StepperFlights flights={flights} oneWay={query.oneWay} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default List;
