import axios from 'axios';
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router-dom';
import { encodeURI } from '../utils/getURL';
import useAppParams from './useAppParams';
import useExtraItems from './useExtraItems';

const getURL = (query, id) => {
  const param = query ? encodeURI(query) : null;

  // console.log('🚀 ~ useSportingEvents.js ~ getURL', { query, id, param });

  return `${[param, id].filter(str => !!str && `${str}`.length).join('/')}`;
};

const useSportingEvents = () => {
  const history = useHistory();
  const { id } = useAppParams();
  const extraItems = useExtraItems('booking-hotel');

  const search = newQuery => {
    const param = getURL(newQuery);

    history.push(`/sportingEvents/old/list/${param}`);
  };

  const searchTickets = itm => {
    const param = getURL(itm, nanoid());

    history.push(`/sportingEvents/old/detail/${param}`);
  };

  const warranty = itm => {
    const param = getURL(itm, nanoid());

    history.push(`/sportingEvents/old/warranty/${param}`);
  };

  const makeBook = (item, rate) => {
    if (item && rate) {
      const booking = {
        apiName: item.data.caption,
        checkIn: item.data.date,
        checkOut: item.data.date,
        destino_name: item.data.country,
        stars: '',
        direccion: item.data.venue_address,
        nTickets: item.nTickets,
        unitPrice: Math.round(item.Price * item.dollar),
        total: Math.round(item.Price * item.dollar * item.nTickets),
        ServiceFee: Math.round(item.ServiceFee),
        mealPlan: item.ItemID,
        sport: item.data.sport,
        venue: item.data.venue,
        section: item.Section,
      };
      const iD = extraItems.save(booking);

      history.push(`/sportingEvents/old/booking/${iD}`);
    }
  };

  const getIpClient = async () => {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data;
  };

  return {
    id,
    search,
    searchTickets,
    checkout: {},
    makeBook,
    warranty,
    getIpClient,
  };
};

export default useSportingEvents;
