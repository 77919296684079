import React from 'react';

import { Col } from 'react-bootstrap';

const ColInline = ({
  children,
  className = '',
  inline = false,
  xs,
  sm,
  md,
  lg,
  xl,
}) => {
  return (
    <Col
      className={className}
      xs={inline ? 12 : xs}
      sm={inline ? 12 : sm}
      md={inline ? 12 : md}
      lg={inline ? 12 : lg}
      xl={inline ? 12 : xl}
    >
      {children}
    </Col>
  );
};

export default ColInline;
