import React from 'react';

import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as Yup from 'yup';
import FormProfile from './FormProfile';
import useUser from '../../hook/useUser';

const profileSchema = Yup.object().shape({
  name: Yup.string().required(),
  lastName1: Yup.string().required(),
  lastName2: Yup.string(),
  dateOfBirth: Yup.string().required(),
  email: Yup.string().required(),
  additionalEmail: Yup.string(),
  telephone: Yup.string().required(),
  country: Yup.string().required(),
  state: Yup.string().required(),
});

const EditProfile = () => {
  const { user, setUser } = useUser();
  const history = useHistory();

  return (
    <Formik
      initialValues={user}
      enableReinitialize
      validationSchema={profileSchema}
      onSubmit={values => {
        setUser(values);

        history.push(`${history.location.pathname}`.replace('/edit', ''));
      }}
    >
      {({ handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <FormProfile />
        </Form>
      )}
    </Formik>
  );
};

export default EditProfile;
