import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import useLoaderZustand from '../store/loader';

const useLoader = props => {
  const { isLoading, message } = props ?? {};
  const { show, hide, ...loader } = useLoaderZustand(
    useShallow(state => state),
  );

  useEffect(() => {
    if (isLoading === undefined) {
      return;
    }

    if (isLoading) {
      show(message);
    } else {
      hide();
    }
  }, [isLoading, message, show, hide]);

  return loader;
};

export default useLoader;
