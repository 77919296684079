import React, { useEffect, useMemo, useState } from 'react';

import LightboxComponent from 'react-image-lightbox';
import styled from 'styled-components';
import sleep from '../utils/sleep';

const Lightbox = ({ images = [], index = 0, setIndex = () => {} }) => {
  const [currentIndex, setCurrentIndex] = useState();
  const prevIndex = useMemo(
    () => (currentIndex + images.length - 1) % images.length,
    [currentIndex], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const nextIndex = useMemo(
    () => (currentIndex + 1) % images.length,
    [currentIndex], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    let didCancel = false;
    const set = async () => {
      await sleep(10);
      if (didCancel) return;

      // console.log('🚀 ~ Lightbox.jsx', { index, currentIndex });
      if (index !== currentIndex) setCurrentIndex(index);
    };

    set();
    return () => {
      didCancel = true;
    };
  }, [index]); // eslint-disable-line react-hooks/exhaustive-deps

  if (currentIndex === null || !images.length) return <div />;

  return (
    <Wrapper>
      <LightboxComponent
        mainSrc={images[currentIndex] ?? ''}
        prevSrc={images[prevIndex]}
        nextSrc={images[nextIndex]}
        onCloseRequest={() => setIndex(null)}
        onMovePrevRequest={() => setCurrentIndex(prevIndex)}
        onMoveNextRequest={() => setCurrentIndex(nextIndex)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: red;
`;

export default Lightbox;
