export const encodeURI = query => {
  const str = JSON.stringify(query);
  const param = encodeURIComponent(str);

  return param;
};

const getURL = (query, id) => {
  const param = query ? encodeURI(query) : null;
  // console.log('🚀 ~ getURL.js', { query, id, param });

  return `${[param, id].filter(str => !!str && `${str}`.length).join('/')}`;
};

export default getURL;
