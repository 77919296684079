import React from 'react';

import { useTranslation } from 'react-i18next';
import FormSearch from '../../components/Hotels/FormSearch';
import LayoutHome from '../../layouts/LayoutHome';

const Hotels = () => {
  const { t } = useTranslation();

  return (
    <LayoutHome src="images/hotels/banner.webp" title={t('Hoteles')}>
      <FormSearch />
    </LayoutHome>
  );
};

export default Hotels;
