import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const useTranslationWithA = ({ key, options, onClick }) => {
  const { t } = useTranslation();

  const handleClick = useCallback(
    (...args) => {
      // console.log('🚀 ~ useContent.jsx', { args });

      if (onClick) {
        onClick(...args);
      }
    },
    [onClick],
  );

  const ref = useRef(null);
  const setRef = useCallback(
    node => {
      if (ref.current) {
        const link = ref.current.querySelector('a');
        if (link) {
          link.removeEventListener('click', handleClick);
        }
      }

      if (node) {
        const link = node.querySelector('a');
        if (link) {
          link.addEventListener('click', handleClick);
        }
      }

      ref.current = node;
    },
    [handleClick],
  );

  return (
    <Wrapper
      ref={setRef}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: t(key, options),
      }}
    />
  );
};

const Wrapper = styled.div`
  margin-top: 1rem;

  & a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #19b2e2 !important;
  }
`;

export default useTranslationWithA;
