import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './ListFeatures.module.scss';

const ListFeatures = ({ car }) => {
  const { t } = useTranslation();
  const transmission = car?.isManual
    ? t('CarsTransmissionManual')
    : car?.isAutomatic
    ? t('CarsTransmissionAutomatic')
    : 'N/A';
  const doors = t('CarsDoors', { count: car?.doors });
  const passengers = t('CarsPassengers', { count: car?.passengers });
  const bags = t('CarsBags', { count: car?.bags });
  const airConditioning = car?.airConditioning
    ? t('CarsAirConditioning')
    : t('CarsNotIncluded');

  if (!car) return <ul />;

  return (
    <ul className="tw-m-0 tw-list-none tw-pl-0">
      <li>
        <img
          className={styles.image}
          src="/images/cars/transmission.svg"
          alt={transmission}
        />
        <span>{transmission}</span>
      </li>

      <li>
        <img
          className={styles.image}
          src="/images/cars/doors.svg"
          alt={doors}
        />
        <span>{doors}</span>
      </li>

      <li>
        <img
          className={styles.image}
          src="/images/cars/passengers.svg"
          alt={passengers}
        />
        <span>{passengers}</span>
      </li>

      <li>
        <img className={styles.image} src="/images/cars/bags.svg" alt={bags} />
        <span>{bags}</span>
      </li>

      <li>
        <img
          className={styles.image}
          src="/images/cars/airConditioning.svg"
          alt={airConditioning}
        />
        <span>{airConditioning}</span>
      </li>
    </ul>
  );
};

ListFeatures.propTypes = {
  car: PropTypes.shape().isRequired,
};

export default ListFeatures;
