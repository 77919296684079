import React from 'react';

import { Form } from 'react-bootstrap';
import useAppField from '../../hook/useAppField';
import useAppTranslation from '../../hook/useAppTranslation';
import TimePicker from '../../shared/TimePicker';
import * as formikUtils from '../../utils/formik';

const FilterTimePicker = ({ label, name, disabled = false }) => {
  const { t } = useAppTranslation();
  const fieldDate = useAppField(name);

  const error = formikUtils.getError(fieldDate.meta);
  const isValid = formikUtils.isValid(fieldDate.meta);
  const isInvalid = formikUtils.isInvalid(fieldDate.meta);

  return (
    <Form.Group controlId={fieldDate.field.name}>
      <Form.Label className={`tw-text-white ${disabled || 'required'}`}>
        {label}
      </Form.Label>

      <Form.Control
        as={TimePicker}
        name={fieldDate.field.name}
        value={fieldDate.field.value}
        onChange={fieldDate.field.onChange}
        onBlur={fieldDate.field.onBlur}
        disabled={disabled}
        isValid={isValid}
        isInvalid={isInvalid}
      />

      {isInvalid && (
        <Form.Control.Feedback type="invalid">{t(error)}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default FilterTimePicker;
