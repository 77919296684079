import moment from 'moment';
import { RESTAURANTS_LIST, RESTAURANT_DETAIL } from './Routes';
import instance from './instance';

const LANGUAGE_DEFAULT = 'EN';
const CURRENCY_DEFAULT = 'USD';

export const getRestaurantsByDestiny = async ({
  destiny,
  offset,
  language = LANGUAGE_DEFAULT,
  currency = CURRENCY_DEFAULT,
}) => {
  const body = {
    destiny,
    offset,
    language: `${language}`.toLocaleUpperCase(),
    currency,
  };
  const request = instance.post(RESTAURANTS_LIST, body);
  const response = await request
    .then(res => res.data)
    .then(data => {
      // console.log('🚀 ~ restaurants.js', { data });

      return {
        total: data.countTotal,
        data: data.restaurants.map(it => {
          return {
            id: it.location_id,
            image: it?.photo?.images?.medium?.url ?? null,
            name: it.name,
            isOpen: !it.is_closed,
            rating: parseFloat(it.rating),
            price: it.price,
            cuisine: [...new Set(it.cuisine.map(({ name }) => name))].join(
              ', ',
            ),
          };
        }),
      };
    })
    .catch(() => {
      // console.log('🚀 ~ restaurants.js', { err });
      return {
        total: 0,
        data: [],
      };
    });

  return response;
};

export const getRestaurantById = async ({
  restaurantId,
  language = LANGUAGE_DEFAULT,
  currency = CURRENCY_DEFAULT,
}) => {
  const ret = await instance
    .post(
      RESTAURANT_DETAIL({ restaurantId }),
      {},
      {
        params: {
          currency,
          language: `${language}`.toLocaleUpperCase(),
        },
      },
    )
    .then(res => res.data)
    .then(restaurant => {
      if (!restaurant) return null;

      const cuisine = (restaurant.cuisine ?? []).map(({ name }) => name);
      return {
        name: restaurant.name,
        map: {
          latitude: restaurant.latitude,
          longitude: restaurant.longitude,
          place: restaurant.name,
          raiting: parseFloat(restaurant.rating),
        },

        address: [
          restaurant.address_obj.street1,
          restaurant.address_obj.street2,
          restaurant.address_obj.city,
        ]
          .filter(Boolean)
          .join(', '),
        image: restaurant.photo.images.large.url,
        description: restaurant.description,
        cuisine: [...new Set(cuisine)].join(', '),

        reviews: restaurant.reviews
          .filter(it => it && (it.summary?.trim?.() ?? '').length)
          .map(it => ({
            id: it.review_id,
            description: it.summary.trim(),
            ownerName: it.author,
            publishedDate: moment(it.published_date).format('DD-MM-YYYY'),
            rating: parseFloat(it.rating),
          })),
      };
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ restaurants.js', { err });
      return null;
    });

  // console.log('🚀 ~ restaurants.js ~ getRestaurantById', { restaurant });
  return ret;
};
