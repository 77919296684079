import React, { useMemo } from 'react';

import { Badge, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const COLS = 4;

const ModalAmenities = ({ show = false, onHide, amenities }) => {
  const { t } = useTranslation();

  const handleHide = (...args) => {
    if (onHide) {
      onHide(args);
    }
  };

  const groups = useMemo(() => {
    const size = Math.ceil(amenities.length / COLS);
    const amenitiesGroup = Array.from({ length: COLS }).map((_, index) => {
      const start = index * size;
      const end = start + size;

      return amenities.slice(start, end);
    });

    return amenitiesGroup;
  }, [amenities]);

  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleHide}
      className="tw-z-[2147483001] [&>div]:md:tw-max-w-[80%]"
    >
      <Modal.Header>{t('HotelsAmenities')}</Modal.Header>

      <Modal.Body>
        <Container className="tw-m-0 tw-max-h-full tw-max-w-full tw-p-0">
          <Row
            xs="1"
            lg={COLS}
            className="tw-h-96 tw-overflow-y-scroll md:tw-h-auto md:tw-overflow-y-auto"
          >
            {groups.map((it, index) => {
              return (
                <Col key={index}>
                  <ul className="list-group">
                    {it.map(({ id, name, extraCharge }) => (
                      <li
                        key={id}
                        className={`list-group-item tw-flex tw-flex-col ${
                          extraCharge && 'list-group-item-danger'
                        }`}
                      >
                        <span>{name}</span>

                        {extraCharge && (
                          <div className="tw-self-end">
                            <Badge
                              variant="secondary"
                              className="tw-text-right"
                            >
                              {t('HotelsChargedSeparately')}
                            </Badge>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-primary" onClick={handleHide}>
          {t('Aceptar')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAmenities;
