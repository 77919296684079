import { payment } from './payment';
import yup from './yup';

export const BY = { DESTINATION: 'destination', HOTEL: 'hotel' };
export const PAY_TYPE = {
  CASH: 'cash',
  POINTS: 'points',
};

export const filterSearchSchema = yup.object().shape({
  hotel: yup.string().nullable().default(null),
  hotelId: yup
    .string()
    .nullable()
    .when('by', (by, schema) => {
      if (by !== BY.HOTEL) return schema;

      return schema.required('CampoRequerido');
    })
    .default(null),
  destiny: yup.number().nullable().required('CampoRequerido').default(null),
  checkIn: yup.string().required().isDateMomentValid().default(''),
  checkOut: yup
    .string()
    .required()
    .isDateMomentValid()
    .isDateBeforeAtStartDate({
      startDateName: 'checkIn',
      message: 'ErrorFechaRegreso',
    }),
  rooms: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          adults: yup.number().required(),
          children: yup.object().shape({
            ages: yup.array().of(yup.number()).required(),
          }),
        })
        .required(),
    )
    .default([
      {
        adults: 2,
        children: {
          ages: [],
        },
      },
    ])
    .required(),
  by: yup.string().oneOf(Object.values(BY)).default(BY.DESTINATION),
  pay: yup.string().oneOf(Object.values(PAY_TYPE)).default(PAY_TYPE.CASH),
});

export const bookingSchema = yup.object().shape({
  name: yup
    .string()
    .min(5, 'ErrorFieldMustLeast5')
    .max(20, 'ErrorFieldMustMost20')
    .default('')
    .required('ErrorFieldRequired'),
  middleName: yup
    .string()
    .matches(/.{4,}/, {
      excludeEmptyString: true,
      message: 'ErrorFieldMustLeast4',
    })
    .max(20, 'ErrorFieldMustMost20')
    .default(''),
  lastName: yup
    .string()
    .min(5, 'ErrorFieldMustLeast5')
    .max(20, 'ErrorFieldMustMost20')
    .default('')
    .required('ErrorFieldRequired'),
  email: yup
    .string()
    .email('ErrorFieldMustEmail')
    .min(5, 'ErrorFieldMustLeast5')
    .max(30, 'ErrorFieldMustMost30')
    .default('')
    .required('ErrorFieldRequired'),
  phone: yup
    .string()
    .min(5, 'ErrorFieldMustLeast5')
    .max(20, 'ErrorFieldMustMost20')
    .default('')
    .required('ErrorFieldRequired'),
  isMainGuest: yup.boolean().default(false).required('ErrorFieldRequired'),

  rooms: yup
    .array()
    .of(
      yup.array().of(
        yup.object().shape({
          num: yup.number().required('ErrorFieldRequired'),
          type: yup
            .string()
            .oneOf(['ADULT', 'CHILD'])
            .required('ErrorFieldRequired'),
          name: yup
            .string()
            .min(5, 'ErrorFieldMustLeast5')
            .max(20, 'ErrorFieldMustMost20')
            .required('ErrorFieldRequired'),
          lastName: yup
            .string()
            .min(5, 'ErrorFieldMustLeast5')
            .max(20, 'ErrorFieldMustMost20')
            .required('ErrorFieldRequired'),
          age: yup.number().when(['type'], (type, schemaAge) => {
            if (type === 'ADULT') return schemaAge;

            return schemaAge.required('ErrorFieldRequired');
          }),
        }),
      ),
    )
    .default([
      [
        {
          num: 1,
          type: 'ADULT',
          name: '',
          lastName: '',
          age: null,
        },
      ],
    ]),

  payment: payment.clone(),

  policy1: yup
    .boolean()
    .oneOf([true], 'HotelsBookPolicyAccept')
    .default(false)
    .required('ErrorFieldRequired'),
  policy2: yup
    .boolean()
    .oneOf([true], 'HotelsBookPolicyAccept')
    .default(false)
    .required('ErrorFieldRequired'),
});
