/* eslint-disable no-template-curly-in-string */
import moment from 'moment';
import * as yup from 'yup';

const EMAIL_MESSAGE_INVALID = 'Debe ser un correo válido';

yup.setLocale({
  mixed: {
    required: 'Es un campo obligatorio',
    oneOf: 'Debe ser uno de los siguientes valores: ${values}',
    notNull: 'Es un campo obligatorio',
  },
  string: {
    min: 'Debe tener al menos ${min} carácter',
    max: 'Debe tener como máximo ${max} caracteres',
    email: EMAIL_MESSAGE_INVALID,
    length: 'Debe tener exactamente ${length} caracteres',
  },
  number: {
    min: 'Debe ser mayor o igual a ${min}',
    max: 'Debe ser inferior o igual a ${max}',
    integer: 'Debe ser un número entero',
  },
});

yup.addMethod(
  yup.string,
  'isDateMomentValid',
  function isDateMomentValid({ message = 'CampoRequerido' } = {}) {
    return this.transform((_, originalValue) => {
      if (originalValue.length === 0) return '';
      const date = moment(originalValue);
      return date.isValid() ? date.format() : 'DATE_INVALID';
    }).test('isDateMomentValid', message, value => {
      if (value === '') return true;
      if (value === 'DATE_INVALID') return false;
      return moment(value).isValid();
    });
  },
);

yup.addMethod(
  yup.string,
  'isDateBeforeAtStartDate',
  function isDateBeforeAtStartDate({
    message = 'Error End Date',
    startDateName,
  } = {}) {
    if (!startDateName?.length) return this;

    return this.isDateMomentValid().when(
      [startDateName],
      (startDate, schema) => {
        const mStartDate = startDate && moment(startDate);
        if (!mStartDate?.isValid()) return schema;

        return schema.test('isDateBeforeAtStartDate', message, endDate => {
          const mEndDate = endDate ? moment(endDate) : undefined;
          if (!mEndDate?.isValid()) return true;

          return mStartDate.isSameOrBefore(mEndDate);
        });
      },
    );
  },
);

export default yup;
