import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Go = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col xs="12" lg="12">
            <div className="tw-float-right">
              <h1 className="text-go">GO!</h1>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Go;
