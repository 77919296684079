import axios from 'axios';
import { LOGIN_SUNAPI } from './Routes';

const {
  REACT_APP_SUNAPI_APIKEY,
  REACT_APP_SUNAPI_APIUSER,
  REACT_APP_SUNAPI_ENDPOINT,
} = process.env;

const URL_LOGIN = `${REACT_APP_SUNAPI_ENDPOINT}${LOGIN_SUNAPI}`;
const data = {
  username: REACT_APP_SUNAPI_APIUSER,
  password: REACT_APP_SUNAPI_APIKEY,
};

const login = async ({ config } = {}) => {
  const response = await axios.post(URL_LOGIN, data, config);

  // console.log('🚀 ~ login.js', { response });
  return response;
};

export default login;
