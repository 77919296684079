import React, { useRef, useState } from 'react';

import { Alert, Overlay, Popover, Spinner } from 'react-bootstrap';
import { GoChevronDown } from 'react-icons/go';
import OutsideClickHandler from 'react-outside-click-handler';
import useAppTranslation from '../../hook/useAppTranslation';
import useAuth from '../../hook/useAuth';
import { getPriceComparison } from '../../services/hotels';
import PriceFormat from '../PriceFormat';

const ButtonOtherPrices = ({ hotel, query, setMinOffer, setMaxOffer }) => {
  const { isDemo } = useAuth();
  const { t, language } = useAppTranslation();
  const { checkIn, checkOut, rooms } = query;

  const [loadingPriceComparison, setLoadingPriceComparison] = useState(false);
  const [showPriceComparison, setShowPriceComparison] = useState(false);
  const [anotherPrices, setAnotherPrices] = useState([]);
  const refContainer = useRef(null);
  const refButton = useRef(null);

  const handleToggleCompetence = async () => {
    if (showPriceComparison) {
      setShowPriceComparison(false);
      return;
    }

    setLoadingPriceComparison(true);
    const data = await getPriceComparison({
      id: hotel.id,
      checkIn,
      checkOut,
      rooms,
      language,
      currency: hotel.currency,
      isDemo,
      total: hotel.total,
    });

    setAnotherPrices(data);
    const prices = data.map(({ price }) => price);
    setMinOffer(Math.min(...prices));
    setMaxOffer(Math.max(...prices));
    setShowPriceComparison(true);
    setLoadingPriceComparison(false);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (!showPriceComparison) return;

        setShowPriceComparison(false);
      }}
    >
      <div ref={refContainer}>
        <button
          type="button"
          className="tw-flex tw-items-center tw-border-0 tw-bg-transparent tw-text-primary"
          ref={refButton}
          onClick={handleToggleCompetence}
          disabled={loadingPriceComparison}
        >
          {loadingPriceComparison && (
            <span className="tw-text-sm tw-text-gray-400">
              <Spinner animation="border" variant="outline-primary" size="sm" />{' '}
              {t('HotelsLoadingPriceComparison')}
            </span>
          )}

          {!loadingPriceComparison && (
            <span className="tw-flex tw-items-center tw-justify-center md:tw-flex-col lg:tw-flex-row">
              {t('HotelsOtherPrices')} <GoChevronDown />
            </span>
          )}
        </button>

        <Overlay
          show={showPriceComparison}
          target={refButton}
          placement="bottom"
          container={refContainer.current}
          containerPadding={20}
        >
          <Popover id="popover-contained" className="tw-max-w-none tw-shadow">
            <Popover.Content>
              {anotherPrices.length === 0 && (
                <Alert variant="danger">{t('HotelsNoComparisons')}</Alert>
              )}

              {anotherPrices.length > 0 && (
                <div className="tw-grid tw-w-max tw-gap-x-6 md:tw-grid-cols-2">
                  {anotherPrices.map(offer => {
                    // console.log('🚀 ~ ButtonOtherPrices.jsx', {
                    //   currency: offer.currency,
                    // });

                    return (
                      <div className="tw-flex tw-w-44 tw-items-center tw-justify-between tw-text-base">
                        <span>{offer.vendor}</span>
                        <span>
                          <PriceFormat
                            // currency={offer.currency}
                            value={offer.price}
                            decimalScale={0}
                          />
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </Popover.Content>
          </Popover>
        </Overlay>
      </div>
    </OutsideClickHandler>
  );
};

export default ButtonOtherPrices;
