import { css } from 'styled-components';

const navHeader = css`
  nav.navbar {
    background-color: var(--background-header-translucent);

    & a.dropdown-item {
      &.active,
      &:active {
        background-color: var(--button-primary-active) !important;
      }

      &:focus,
      &:hover {
        background-color: var(--button-primary) !important;
      }
    }
  }
`;

const navSlider = css`
  nav.nav.nav-tabs {
    & > a.nav-item {
      color: var(--color-text) !important;
      background-color: var(--button-primary) !important;

      &.active {
        background-color: var(--button-primary-active) !important;
      }
    }

    & ~ .tab-content {
      background-color: var(--background-primary-translucent) !important;
    }
  }
`;

const nav = css`
  ${navHeader}
  ${navSlider}
`;

export default nav;
