import React, { Suspense } from 'react';

import Switch from 'react-bootstrap/esm/Switch';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { compose } from 'recompose';
import FloatingButtonWhatsApp from './components/FloatingButtonWhatsApp';
import PrivateRoute from './components/PrivateRoute';
import LoadingTranslations from './components/loader/LoadingTranslations';
import withAuth from './hoc/withAuth';
import withQueryClient from './hoc/withQueryClient';
import withTheme from './hoc/withTheme';
import useAppTranslation from './hook/useAppTranslation';
import i18n from './i18n';
import Home from './pages/Home';
import Welcome from './pages/Welcome';

import 'react-toastify/dist/ReactToastify.min.css';

const AppComponent = () => {
  const { ready } = useAppTranslation();

  if (!ready) return <LoadingTranslations />;

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Suspense fallback="loading">
            <I18nextProvider i18n={i18n}>
              <Route path="/welcome" component={Welcome} />
              <PrivateRoute path="/" component={Home} />
            </I18nextProvider>
          </Suspense>
        </Switch>
      </BrowserRouter>

      <FloatingButtonWhatsApp />

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

const App = compose(withAuth, withTheme, withQueryClient)(AppComponent);

export default App;
