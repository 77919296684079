/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';

import { Button, Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai/index';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import '../../css/condos/CondoTarifas.css';
import formatNumber from '../../utils/formatNumber';

const CondoTarifas = ({ t, rooms, searching, condoDetail }) => {
  const history = useHistory();
  const [state, setState] = useState({ nav1: null, nav2: null });
  const slider1 = useRef();
  const slider2 = useRef();
  const { nav1, nav2 } = state;

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    });
  }, []);

  const handleClick = item => {
    const condo = { ...condoDetail, rates: item };

    // guardar en el storage la info del codomino
    localStorage.setItem('condo', JSON.stringify(condo));

    const search = encodeURIComponent(JSON.stringify(searching));

    history.push(`/condos/reservation?searching=${search}`);
  };

  return (
    <div className="slider-content">
      <Slider
        key={1}
        asNavFor={nav2}
        ref={slider => {
          slider1.current = slider;
        }}
        arrows
      >
        {rooms.map((room, i) => (
          <div key={i}>
            <Table bordered>
              <thead>
                <tr className="table-title">
                  <th colSpan="4">
                    {room.roomDescription.length > 1
                      ? t('semanas')
                      : t('semana')}
                    : {room.effectiveDate} {t('al')} {room.expireDate}
                  </th>
                </tr>
                <tr>
                  <th width="40%">{t('HotelsRooms_one')}</th>
                  <th width="30%" className="tw-text-center">
                    {t('Total')}
                  </th>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th width="30%" className="tw-text-center" />
                </tr>
              </thead>
              <tbody>
                {room.roomDescription.map((desc, d) => (
                  <tr key={d}>
                    <td>
                      <div className="room-type">{desc.type}</div>
                      <br />
                      {t('capacidadMaxima')}: {desc.maxCapacity} {t('Personas')}
                    </td>
                    <td>
                      <div className="room-total cell-content">
                        {formatNumber(room.amountDetail.total)}USD
                      </div>
                    </td>
                    <td>
                      <div className="cell-content">
                        <Button
                          variant="outline-primary"
                          className="btn-reserva"
                          onClick={() => handleClick(room)}
                        >
                          {t('Reservar')}
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ))}
      </Slider>

      <Slider
        key={2}
        asNavFor={nav1}
        ref={slider => {
          slider2.current = slider;
        }}
        slidesToShow={6}
        swipeToSlide
        focusOnSelect
        infinite={false}
        nextArrow={<AiFillRightCircle />}
        prevArrow={<AiFillLeftCircle />}
        className="slider-items"
      >
        {rooms.map((room, r) => (
          <div key={r}>
            <div className="item">
              <div className="item-title">{room.roomDescription[0].type}</div>
              <div className="item-costo">
                {formatNumber(room.amountDetail.total)}USD
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default withTranslation()(CondoTarifas);
