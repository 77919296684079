import { useMemo } from 'react';

import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { getCruises, getParams } from '../services/cruises';
import get from '../services/get';
import { encodeURI } from '../utils/getURL';
import useAppParams from './useAppParams';
import useExtraItems from './useExtraItems';

const urlRizCarlton = `voyage-cruises`;

const valueFormat = array => {
  const values = [];
  for (let index = 0; index < array.length; index++) {
    values.push({
      label:
        array[index].text !== undefined ? array[index].text : array[index].name,
      value: array[index].id,
    });
  }

  return values;
};

const valueFormatt = array => {
  const values = [];

  array.forEach(a => {
    const options = [];
    options.push({
      label: a.text !== undefined ? a.text : a.name,
      value: a.id,
    });

    if (a.children !== undefined) {
      a.children.forEach(b => {
        options.push({
          label: b.text !== undefined ? b.text : b.name,
          value: b.id,
        });
        if (b.children !== undefined) {
          b.children.forEach(c => {
            options.push({
              label: c.text !== undefined ? c.text : c.name,
              value: c.id,
            });
          });
        }
      });
    }

    values.push({
      label: a.text !== undefined ? a.text : a.name,
      value: a.id,
      options,
    });
  });

  return values;
};

const Burbuja = price => {
  const lista = price;
  let n = [];
  let i = 0;
  let k = 0;
  let aux = [];
  n = lista.length;

  for (k = 1; k < n; k++) {
    for (i = 0; i < n - k; i++) {
      if (lista[i].fareFrom_USD > lista[i + 1].fareFrom_USD) {
        aux = lista[i];
        lista[i] = lista[i + 1];
        lista[i + 1] = aux;
      }
    }
  }
};

const getURL = (query, id) => {
  const param = query ? encodeURI(query) : null;
  // console.log('🚀 ~ useCruise.js ~ getURL', { query, id, param });

  return `${[param, id].filter(str => !!str && `${str}`.length).join('/')}`;
};

const useCruise = () => {
  const history = useHistory();
  const params = useAppParams();
  const extraItems = useExtraItems('booking-hotel');
  const { get: getLocal } = extraItems;

  const checkout = useMemo(() => {
    try {
      return getLocal(params?.id);
    } catch (err) {
      return null;
    }
  }, [params, getLocal]);

  const searchRiz = () => {
    history.push(`/cruiseShips/listRiz`);
  };

  const searchByCarlton = newQuery => {
    const param = getURL(newQuery);
    history.push(`/ritzCarlton/detailCarlton/${param}`);
  };

  const warranty = (id, apiName, chain) => {
    const data = { ...params?.query, apiName, chain, id };
    const param = getURL(data);
    window.open(`/cruiseShips/warranty/${param}`, '_blank');
  };

  const getRizCarlton = async () => {
    const response = await get(urlRizCarlton);
    if (response?.data?.errors?.length) throw response.data.errors;
    const ritz = response.data.feed.voyage;
    for (let i = 0; i < ritz.length; i++) {
      Burbuja(ritz[i].inventory.suite);
    }

    return ritz;
  };

  const getAreas = async () => {
    const response = await getParams();
    if (response?.errors?.length) throw response.errors;

    return valueFormatt(response.areas);
  };

  const getLines = async area => {
    const response = await getParams({ area });

    if (response?.errors?.length) throw response.errors;
    return valueFormat(response.cruiseLines.online.data);
  };

  const getLength = async (area, cruiseLine) => {
    const response = await getParams({ area, cruiseLine });

    if (response?.errors?.length) throw response.errors;
    return valueFormat(response.durations);
  };

  const getShips = async (area, cruiseLine) => {
    const response = await getParams({ area, cruiseLine });
    const ship = response.ships;
    const arrayShip = ship[`${cruiseLine}`].data;

    if (response?.errors?.length) throw response.errors;
    return valueFormat(arrayShip);
  };

  const getData = async () => {
    const res = await getCruises();
    if (res?.errors?.length) throw res.errors;

    return res;
  };

  const openDetail = (id, apiName, chain) => {
    const data = { ...params?.query, apiName, chain, id };

    const param = getURL(data);
    window.open(`/cruiseShips/detail/${param}`, '_blank');
  };

  const openDetailByHotel = query => {
    const param = getURL(query);
    window.open(`/cruiseShips/detail/${param}`, '_blank');
  };

  const markup = price => {
    return Math.round(price - price * 0.31);
  };

  const makeBook = (item, rate) => {
    if (params?.query && item && rate) {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ useCruise.js', { params, item });
      const booking = {
        apiName: params.query.cruiseArea,
        checkIn: params.query.departureRaw,
        checkOut: params.query.arrivalRaw,
        destino_id: item.masterCruiseId,
        destino_name: item.title,
        hotel_id: params.query.shipID,
        hotel_name: params.query.ship,
        hotel_photos: item.images,
        stars: '',
        direccion: params.query.cruiseArea,
        habitacion_name: 'Inside Cabin',
        habitacion_id: 'IS',
        total: Math.round(markup(params.query.priceUnformatted)),
        mealPlan: params.query.cruiseLineID,
        rooms: 'inside Cabin',
        huespedes: 2,
        huespedes_adults: 1,
        huespedes_children: 0,
      };
      const id = extraItems.save(booking);

      history.push(`/cruiseShips/booking/${id}`);
    }
  };

  const calculateSavings = (priceJustGo, priceAnother) => {
    const justGo = Number.parseFloat(priceJustGo);
    const another = Number.parseFloat(priceAnother);
    const checkIn = moment(params.query.checkIn);
    const checkOut = moment(params.query.checkOut);
    const nights = checkOut.diff(checkIn, 'days');

    const diff = Math.abs(another - justGo);
    const percent = (diff / another) * 100;

    return {
      percent: Number.parseFloat(percent).toFixed(0),
      saving: Number.parseFloat(diff * nights).toFixed(0),
    };
  };

  return {
    query: params?.query,
    getLines,
    getAreas,
    getShips,
    getLength,
    getData,
    openDetail,
    warranty,
    openDetailByHotel,
    checkout,
    makeBook,
    clear: extraItems.clear,
    calculateSavings,
    searchRiz,
    getRizCarlton,
    searchByCarlton,
  };
};

export default useCruise;
