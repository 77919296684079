import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import AdicionalInformation from '../../components/AdicionalInformation';
import CarouselTopDetail from '../../components/CarouselTopDetail';
import DescripcionDetail from '../../components/DescripcionDetail';
import FormSearch from '../../components/Experiences/FormSearch';
import EmptyData from '../../components/Hotels/EmptyData';
import ReviewInformation from '../../components/ReviewInformation';
import SectionTop from '../../components/SectionTop';
import TourDetail from '../../components/TourDetail';
import useAppParams from '../../hook/useAppParams';
import useAppTranslation from '../../hook/useAppTranslation';
import CargandoOpciones from '../../loader/CargandoOpciones';
import { getLabelsByDestinyId } from '../../services/destinations';
import { getExperience } from '../../services/experiences';
import FormSidebar from '../../shared/FormSidebar';
import PriceBottom from '../../shared/PriceBottom';
import { scrollUp } from '../../utils/scroll';
import sleep from '../../utils/sleep';
import { encode } from '../../utils/uriComponent';

const Detail = () => {
  const history = useHistory();
  const { query, activityId } = useAppParams();
  const { t, language } = useAppTranslation();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [destinyLng, setDestinyLng] = useState(null);
  const [, setErrorServer] = useState(false);
  const [, setErrorData] = useState(null);

  useEffect(() => {
    let didCancel = false;
    const fetch = async () => {
      await sleep(500);
      if (didCancel) return;
      if (!query) {
        await sleep(500);
        if (didCancel) return;

        setLoading(false);
        return;
      }

      scrollUp();
      setErrorServer(false);
      setLoading(true);
      setDestinyLng(null);

      getLabelsByDestinyId({
        destinyId: query.destiny,
        language,
      }).then(_destinyLng => {
        setDestinyLng(_destinyLng);
      });

      getExperience({
        id: activityId,
        apiName: query.apiName,
        destiny: query.destiny,
        checkIn: query.checkIn,
        paxes: query.paxes,
        language,
        // currency: null,
      })
        .then(data => {
          // console.log('🚀 ~ Detail.jsx', { data });
          if (
            data.errors !== null &&
            data.error !== undefined &&
            data.errors.length > 0
          ) {
            setErrorData(data.errors);
            setErrorServer(true);
          } else {
            setItem(data);
            const arr = [];
            arr.push(data);
          }
          setLoading(false);
        })
        .catch(() => {
          // console.log(error)
          setErrorServer(true);
          setLoading(false);
        });

      scrollUp();
    };

    fetch();
    return () => {
      didCancel = true;
    };
  }, [language, query, activityId]);

  const onHandleClickReservar = elem => {
    const { paxAmounts } = elem.rateDetail;
    const rate = paxAmounts.reduce(
      (acc, { paxType, amount }) => {
        if (paxType === 'ADULT') {
          acc.adult = Math.ceil(amount * query.paxes.adults);
          acc.total += acc.adult;
        } else if (paxType === 'CHILD') {
          acc.children = Math.ceil(amount * query.paxes.children);
          acc.total += acc.children;
        }

        return acc;
      },
      { total: 0 },
    );

    const d = {
      apiName: query.apiName,
      checkIn: moment(query.checkIn),
      adults: query.paxes.adults,
      children: query.paxes.children,
      destino_id: query.destiny,
      destino_name: destinyLng,
      id: activityId,
      name: item.name,
      rate,
      photos: [],
      address: elem.destination,
      // currency: query.currency,
    };

    const arr = [d.id, d.destino_id, d.checkIn.valueOf(), d.adults, d.children];

    const cacheKey = encode(arr.join('')).replace(/%22/gi, '');
    const cacheValue = JSON.stringify(d);
    if (window.localStorage.getItem(cacheKey) === null) {
      window.localStorage.setItem(cacheKey, cacheValue);
    }

    history.push(`/experiences/reservation/${cacheKey}`);
  };

  if (loading) return <CargandoOpciones hideLooking />;

  if (!item) return <EmptyData />;

  return (
    <>
      <SectionTop
        menu={t('EXPERIENCES_one')}
        destino=""
        nombre={item.name}
        direccion=""
      />

      <Container fluid className="tw-mt-3">
        <Row xs="1">
          <Col lg="3">
            <Row xs="1">
              <Col className="tw-justify-center">
                <FormSidebar>
                  <FormSearch inline filters={query} disabled />
                </FormSidebar>
              </Col>
            </Row>
          </Col>

          <Col lg="9">
            <CarouselTopDetail
              photos={item.photos.map(({ urlPhoto }) => urlPhoto)}
            />
            <DescripcionDetail description={item.description} />
            <TourDetail
              rates={item.rateServices}
              data={query}
              onHandleClickReservar={onHandleClickReservar}
            />
            <AdicionalInformation
              additionalInformation={item.additionalInformation}
            />
            <ReviewInformation reviews={item.reviews} />
          </Col>
        </Row>
      </Container>

      <PriceBottom />
    </>
  );
};

export default Detail;
