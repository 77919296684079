export const getError = meta => {
  const error = `${meta.error ?? ''}`.trim();

  if (error.length === 0) return undefined;

  return error;
};

export const isValid = meta => {
  const error = getError(meta);

  return meta.touched && error === undefined;
};

export const isInvalid = meta => {
  const error = getError(meta);

  return meta.touched && error !== undefined;
};
