import React, { useCallback, useEffect, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import CardExperienciasHorizontal from '../../components/CardExperienciasHorizontal';
import EmptyItems from '../../components/EmptyItems';
import ErrorServer from '../../components/ErrorServer';
import FormSearch from '../../components/Experiences/FormSearch';
import FilterSidebar from '../../components/FilterSidebar';
import Pagination from '../../components/Pagination';
import SectionTop from '../../components/SectionTop';
import useAppParams from '../../hook/useAppParams';
import useAppTranslation from '../../hook/useAppTranslation';
import CargandoOpciones from '../../loader/CargandoOpciones';
import { getLabelByDestinyId } from '../../services/destinations';
import { getExperiences } from '../../services/experiences';
import FormSidebar from '../../shared/FormSidebar';
import getCadena from '../../utils/getCadena';
import getPrecio from '../../utils/getPrecio';
import { scrollUp } from '../../utils/scroll';
import sleep from '../../utils/sleep';
import { encode } from '../../utils/uriComponent';

const List = () => {
  const { t, language } = useAppTranslation();
  const { query } = useAppParams();
  const [loading, setLoading] = useState(true);
  const [destiny, setDestiny] = useState('');
  const [items, setItems] = useState([]);
  const [itemsFilter, setItemsFilter] = useState([]);
  const [minPrecio, setMinPrecio] = useState(0);
  const [maxPrecio, setMaxPrecio] = useState(100);
  const [errorServer, setErrorServer] = useState(false);
  const [errorData, setErrorData] = useState(null);
  const [valuePrecio, setValuePrecio] = useState({ min: 1, max: 100 });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    let didCancel = false;
    const fetch = async () => {
      await sleep(500);
      if (didCancel) return;
      if (!query) {
        await sleep(500);
        if (didCancel) return;

        setLoading(false);
        return;
      }

      setLoading(true);
      setErrorServer(false);
      setDestiny('');
      setItems([]);
      setItemsFilter([]);
      setErrorData(null);
      setMinPrecio(0);
      setMaxPrecio(100);
      setValuePrecio({ min: 0, max: 100 });

      const [label, data] = await Promise.all([
        getLabelByDestinyId({
          destinyId: query.destiny,
          language,
        }),

        getExperiences({
          destiny: query.destiny,
          checkIn: query.checkIn,
          paxes: query.paxes,
          language,
          // currency: null,
        }),
      ]);

      setDestiny(label);
      if (data.errors.length && !data.activities.length) {
        setErrorData(data.errors);
        setErrorServer(true);
      } else {
        const { activities } = data;
        setItems(activities);
        setItemsFilter(activities);

        if (activities.length) {
          const rates = activities.map(({ minRates }) => minRates.adult);
          const min = Math.floor(Math.min(...rates));
          const max = Math.ceil(Math.max(...rates));

          setMinPrecio(min);
          setMaxPrecio(max);
          setValuePrecio({ min, max });
        }
      }

      setLoading(false);
      scrollUp();
    };

    fetch();
    return () => {
      didCancel = true;
    };
  }, [query, language]);

  const searchList = (valor, typeFilter) => {
    if (typeFilter === 'nombre') {
      if (valor !== '') {
        const arr = [...items];
        setItemsFilter(
          arr.filter(elem =>
            getCadena(elem.name).includes(valor.toLowerCase()),
          ),
        );
      } else {
        setItemsFilter(items);
      }
    }
    if (typeFilter === 'precio') {
      const { min } = valor;
      const { max } = valor;
      const arr = [...items];
      setItemsFilter(
        arr.filter(
          elem =>
            getPrecio(elem.minRates.adult) >= min &&
            getPrecio(elem.minRates.adult) <= max,
        ),
      );
    }
  };

  const handleClickCard = useCallback(
    (id, source) => {
      const data = { ...query };
      data.apiName = source;
      data.chain = '';

      const encodedQuery = encode(data);
      window.open(`/experiences/detail/${encodedQuery}/${id}`, '_blank');
    },
    [query],
  );

  const renderCard = useCallback(
    experience => (
      <CardExperienciasHorizontal
        item={experience}
        onHandleClickCard={handleClickCard}
      />
    ),

    [handleClickCard],
  );

  if (loading) return <CargandoOpciones />;

  return (
    <>
      <SectionTop
        menu={t('EXPERIENCES', { count: items.length })}
        destino={destiny}
        total={items.length}
        product={t('EXPERIENCES', { count: items.length })}
      />

      <Container fluid className="tw-my-3">
        <Row>
          <Col xs="12" lg="4">
            <Row>
              <Col xs="12" lg="12">
                <FormSidebar>
                  <FormSearch inline filters={query} />
                </FormSidebar>
              </Col>

              <Col xs="12" lg="12">
                {items.length > 0 && (
                  <FilterSidebar
                    searchList={searchList}
                    mPrecio={minPrecio}
                    mxPrecio={maxPrecio}
                    valuePrecio={valuePrecio}
                    showStars={false}
                  />
                )}
              </Col>
            </Row>
          </Col>

          <Col xs="12" lg="8">
            {itemsFilter.length === 0 && !errorServer && <EmptyItems />}
            {errorServer && <ErrorServer errorData={errorData} />}

            <Pagination
              items={itemsFilter}
              render={renderCard}
              page={currentPage}
              onChange={setCurrentPage}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default List;
