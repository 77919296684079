import React from 'react';

import { Image } from 'react-bootstrap';
import ReactSlick from 'react-slick';

const SectionPromotionNY = () => {
  return (
    <ReactSlick
      dots={false}
      infinite
      autoplay
      speed={2000}
      autoplaySpeed={10000}
    >
      {[
        'https://rh-destinations.com/wp-content/uploads/2018/12/mejores-destinos-para-viajar-2019.jpeg',
        'https://rh-destinations.com/wp-content/uploads/2018/12/mejores-destinos-para-viajar-en-2019-1024x678.jpg',
        'https://rh-destinations.com/wp-content/uploads/2018/12/viajar-a-miami-2019-1024x683.jpg',
        'https://rh-destinations.com/wp-content/uploads/2018/12/viajar-a-punta-cana-2019.jpg',
        'https://rh-destinations.com/wp-content/uploads/2018/12/viajar-a-rio-de-janeiro-2019-1024x683.jpeg',
        'https://rh-destinations.com/wp-content/uploads/2018/12/viajar-a-barcelona-2019-1024x683.jpeg',
        'https://rh-destinations.com/wp-content/uploads/2018/12/viajar-a-puerto-vallarta-royal-holiday-1024x680.jpg',
      ].map((url, index) => (
        <div
          key={index}
          style={{
            background: '#EEE',
            overflow: 'hidden',
            padding: '0 10px',
          }}
        >
          <Image
            src={url}
            fluid
            className="tw-object-cover tw-px-1"
            style={{ width: '100%', aspectRatio: '16 / 3' }}
          />
        </div>
      ))}
    </ReactSlick>
  );
};

export default SectionPromotionNY;
