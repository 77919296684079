import React, { useState } from 'react';

import moment from 'moment';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import withFormikSearch from '../../hoc/withFormikSearch';
import useAppTranslation from '../../hook/useAppTranslation';
import useCruise from '../../hook/useCruise';
import useDev from '../../hook/useDev';
import AsyncSelect from '../../shared/AsyncSelect';
import ColInline from '../../shared/ColInline';
import Select from '../../shared/Select';
import { encode } from '../../utils/uriComponent';
import { filterSearchSchema } from '../../validations/cruises';
import FiltersDateRange from './FiltersDateRange';

const FormGroup = ({ xs, lg, className, showLabel, label, children }) => {
  return (
    <Col xs={xs} lg={lg} className={className}>
      {showLabel ? (
        <Form.Group>
          <Form.Label className="tw-text-white">{label}</Form.Label>

          {children}
        </Form.Group>
      ) : (
        children
      )}
    </Col>
  );
};

const FormSearch = ({ formikBag, inline = false, disabled = false }) => {
  const { t } = useAppTranslation();
  const { isDev } = useDev();
  const { getAreas, getLines, getLength, getShips } = useCruise();
  const [Lineas, setLineas] = useState([]);
  const [Length, setLength] = useState([]);
  const [Ships, setShips] = useState([]);

  const valuesLine = async ({ area }) => {
    const lines = await getLines(area);
    setLineas(lines);
  };

  const valuesLength = async ({ area, line }) => {
    const length = await getLength(area, line);
    setLength(length);
  };

  const valuesShip = async ({ area, line }) => {
    const ship = await getShips(area, line);
    setShips(ship);
  };

  return (
    <Container className="form-padding">
      {/* <pre>{JSON.stringify({ values, errors, touched }, null, 2)}</pre> 
                console.log('V A L U E S', values) */}
      <Row>
        <FormGroup
          label={t('CRUISES_AREAS')}
          xs="12"
          lg={inline ? '12' : '4'}
          showLabel={!inline}
          className={inline && 'tw-mb-2'}
        >
          <AsyncSelect
            groupHeading
            defaultOptions
            defaultValue={formikBag.values.area}
            isMulti={false}
            loadOptions={async () => {
              return getAreas();
            }}
            onChange={value => {
              formikBag.setFieldValue('area', value);
              valuesLine({ area: value.value });
            }}
            onBlur={() => {
              formikBag.setTouched({ ...formikBag.touched, area: true });
            }}
            isValid={formikBag.touched.area && formikBag.errors.area}
          />

          {formikBag.touched.area && formikBag.errors.area && (
            <Form.Control.Feedback type="invalid">
              {t(formikBag.errors.area.label)}
            </Form.Control.Feedback>
          )}
        </FormGroup>

        <FormGroup
          id="cruseline"
          label={t('CRUISES_LINE')}
          name="cruiseLine"
          xs="12"
          lg={inline ? '12' : '4'}
          showLabel={!inline}
          className={inline && 'tw-mb-2'}
        >
          <Select
            defaultValue={formikBag.values.line}
            isDisabled={formikBag.values.area === null}
            options={Lineas}
            placeholder={t('CRUISES_LINE')}
            onChange={value => {
              formikBag.setFieldValue('line', value);
              valuesLength({
                area: formikBag.values.area.value,
                line: value.value,
              });
            }}
          />

          {formikBag.touched.line && formikBag.errors.line && (
            <Form.Control.Feedback type="invalid">
              {t(formikBag.errors.line.label)}
            </Form.Control.Feedback>
          )}
        </FormGroup>

        <FormGroup
          label={t('CRUISES_LENGTH')}
          name="cruiseLength"
          xs="12"
          lg={inline ? '12' : '4'}
          showLabel={!inline}
          className={inline && 'tw-mb-2'}
        >
          <Select
            isDisabled={
              formikBag.values.area === null || formikBag.values.line === null
            }
            options={Length}
            placeholder={t('CRUISES_LENGTH')}
            onChange={value => {
              formikBag.setFieldValue('duration', value);
              valuesShip({
                area: formikBag.values.area.value,
                line: formikBag.values.line.value,
              });
            }}
          />
          {formikBag.touched.duration && formikBag.errors.duration && (
            <Form.Control.Feedback type="invalid">
              {t(formikBag.errors.duration.label)}
            </Form.Control.Feedback>
          )}
        </FormGroup>
      </Row>
      <Row>
        <FormGroup
          label={t('CRUISES_SHIP')}
          name="ships"
          xs="12"
          lg={inline ? '12' : '4'}
          showLabel={!inline}
          className={inline && 'tw-mb-2'}
        >
          <Select
            isDisabled={
              formikBag.values.area === null || formikBag.values.line === null
            }
            options={Ships}
            placeholder={t('CRUISES_SHIP')}
            onChange={value => {
              formikBag.setFieldValue('ship', value);
            }}
          />
          {formikBag.touched.ship && formikBag.errors.ship && (
            <Form.Control.Feedback type="invalid">
              {t(formikBag.errors.ship.label)}
            </Form.Control.Feedback>
          )}
        </FormGroup>

        <ColInline inline={inline} lg="4">
          <FiltersDateRange disabled={disabled} />
        </ColInline>
      </Row>

      <Row>
        <Col xs="12" lg={inline ? 12 : { span: 2, offset: 10 }}>
          <Button
            type="submit"
            block
            variant="outline-primary"
            className="tw-font-bold"
          >
            {t('CRUISES_SEARCH')}
          </Button>
        </Col>
      </Row>

      {isDev && (
        <Row>
          <Col xs="12">
            <pre style={{ color: '#fff' }}>
              {JSON.stringify(
                {
                  values: formikBag.values,
                  errors: formikBag.errors,
                  touched: formikBag.touched,
                },
                null,
                2,
              )}
            </pre>
          </Col>
        </Row>
      )}
    </Container>
  );
};

const FormSearchFormik = withFormikSearch(
  {
    initValues: {
      departing: moment().add(1, 'month').startOf('month').format(),
      returning: moment().add(1, 'month').endOf('month').format(),
    },

    schema: filterSearchSchema,
    onSubmit: ({ values, actions, history }) => {
      actions.setSubmitting(false);

      const query = filterSearchSchema.cast(values, { stripUnknown: true });
      const encodedQuery = encode(query);

      history.push(`/cruiseShips/list/${encodedQuery}`);
    },
  },
  FormSearch,
);

export default FormSearchFormik;
