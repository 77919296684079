const calcTotalHuespedes = (obj, tipo = 'adult') => {
  let total = 0;
  if (tipo === 'adult') {
    total = obj.reduce((accumulator, item) => {
      return accumulator + item.adults;
    }, 0);
  } else if (tipo === 'child') {
    total = obj.reduce((accumulator, item) => {
      return accumulator + item.children.ages.length;
    }, 0);
  } else if (tipo === 'hab') {
    total = obj.length;
  }
  return total;
};

export default calcTotalHuespedes;
