import moment from 'moment';
import * as yup from 'yup';

export const PAYMENT_TYPE = {
  CREDIT_CARD: 'PaymentCardCredit',
  DEBIT_CARD: 'PaymentCardDebit',
};

export const payment = yup.object().shape({
  name: yup
    .string()
    .min(2, 'ErrorFieldMustLeast2')
    .max(100, 'ErrorFieldMustMost100')
    .default('')
    .required('ErrorFieldRequired'),
  paymentType: yup
    .string()
    .oneOf(Object.keys(PAYMENT_TYPE))
    .default('CREDIT_CARD')
    .required('ErrorFieldRequired'),
  creditCard: yup.object().shape({
    number: yup
      .string()
      .matches(/^[0-9]+$/, 'ErrorFieldCardNumberLength')
      .min(16, 'ErrorFieldCardNumberLength')
      .max(16, 'ErrorFieldCardNumberLength')
      .default('')
      .required('ErrorFieldRequired'),
    expireMonth: yup
      .number()
      .oneOf(Array.from({ length: 12 }).map((_, i) => i + 1))
      .default(moment().month() + 1)
      .required('ErrorFieldRequired'),
    expireYear: yup
      .number()
      .oneOf(
        Array.from({ length: 10 })
          .fill(moment().year())
          .map((currentYear, i) => currentYear + i),
      )
      .default(moment().year())
      .required('ErrorFieldRequired'),
    cvv: yup
      .string()
      .min(3, 'ErrorFieldCvvLength')
      .max(3, 'ErrorFieldCvvLength')
      .default('')
      .required('ErrorFieldRequired'),
  }),
});

export default payment;
