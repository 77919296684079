import instance from './instance';

const get = async (url, config) => {
  return instance.get(url, {
    ...config,

    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};

export default get;
