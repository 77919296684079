import React, { Children, cloneElement } from 'react';

import styles from './Timeline.module.css';

const Timeline = ({ children }) => {
  return (
    <ul className={`${styles.timeline} tw-m-0`}>
      {Children.map(children, (it, i) => {
        // console.log('🚀 ~ Timeline.jsx', { it });

        return (
          <li key={it.key ?? i} className={styles.timelineItem}>
            {cloneElement(it)}
          </li>
        );
      })}
    </ul>
  );
};

export default Timeline;
