import React, { useEffect } from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SectionPromotionNY from '../components/SectionPromotionNY';
import Booking from '../components/cruiseShips/Booking';
import Detail from '../components/cruiseShips/Detail';
import DetailCarlton from '../components/cruiseShips/DetailCarlton';
import Home from '../components/cruiseShips/Home';
import List from '../components/cruiseShips/List';
import ListRiz from '../components/cruiseShips/ListRiz';
import Warranty from '../components/cruiseShips/Warranty';
import useHotel from '../hook/useHotel';
import MainContainer from '../shared/MainContainer';
import PriceBottom from '../shared/PriceBottom';

const CruiseShips = () => {
  const { url } = useRouteMatch();
  const matchCheckout = useRouteMatch({
    path: `${url}/booking/:id`,
    exact: true,
  });
  const { clear } = useHotel();

  useEffect(() => {
    if (!matchCheckout) clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchCheckout]);

  return (
    <MainContainer>
      <Switch>
        <Route exact path={url} component={Home} />
        <Route path={`${url}/list/:query`} component={List} />
        <Route path={`${url}/listRiz/`} component={ListRiz} />
        <Route path={`${url}/warranty/:query`} component={Warranty} />

        <Route path={`${url}/detail/:query`} component={Detail} />
        <Route path={`${url}/detailCarlton/:query`} component={DetailCarlton} />

        <Route path={`${url}/detail/`} component={Detail} />
        <Route path={`${url}/booking/:id`} component={Booking} />
      </Switch>

      <SectionPromotionNY />
      <PriceBottom />
    </MainContainer>
  );
};

export default CruiseShips;
