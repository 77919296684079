import React from 'react';

import { Field } from 'formik';
import moment from 'moment';
import { Button, Container, Form, Row } from 'react-bootstrap';
import withFormikSearch from '../../hoc/withFormikSearch';
import useAppTranslation from '../../hook/useAppTranslation';
import useDev from '../../hook/useDev';
import ColInline from '../../shared/ColInline';
import SelectDestination from '../../shared/SelectDestination';
import { encode } from '../../utils/uriComponent';
import { filterSearchSchema } from '../../validations/cars';
import FilterTimePicker from './FilterTimePicker';
import FiltersDateRange from './FiltersDateRange';

const FormSearch = ({ formikBag, inline = false, disabled = false }) => {
  const { t } = useAppTranslation();
  const { isDev } = useDev();

  return (
    <Container className="tw-px-0 tw-py-4 [&_*]:tw-opacity-100">
      <Row xs="1">
        <ColInline inline={inline} lg="4">
          <Field name="pickUp">
            {({ field, meta }) => {
              const { touched, error } = meta;

              return (
                <Form.Group controlId={field.name}>
                  <Form.Label
                    className={`tw-text-white ${disabled || 'required'}`}
                  >
                    {t('CarsPickUp')}
                  </Form.Label>

                  <SelectDestination
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={disabled}
                    isValid={touched && !error}
                    isInvalid={touched && !!error}
                  />

                  {touched && error && (
                    <Form.Control.Feedback type="invalid">
                      {t(error)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              );
            }}
          </Field>
        </ColInline>

        <ColInline inline={inline} lg="4">
          <Field name="dropOff">
            {({ field, meta }) => {
              const { touched, error } = meta;

              return (
                <Form.Group controlId={field.name}>
                  <Form.Label
                    className={`tw-text-white ${disabled || 'required'}`}
                  >
                    {t('CarsDropOff')}
                  </Form.Label>

                  <SelectDestination
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={disabled}
                    isValid={touched && !error}
                    isInvalid={touched && !!error}
                  />

                  {touched && error && (
                    <Form.Control.Feedback type="invalid">
                      {t(error)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              );
            }}
          </Field>
        </ColInline>

        <ColInline inline={inline} lg="4" className="">
          <FiltersDateRange disabled={disabled} />
        </ColInline>
      </Row>

      <Row>
        <ColInline inline={inline} lg="3">
          <FilterTimePicker label={t('CarsPickUpTime')} name="pickUpTime" />
        </ColInline>

        <ColInline inline={inline} lg="3">
          <FilterTimePicker label={t('CarsDropOffTime')} name="dropOffTime" />
        </ColInline>
      </Row>

      {!disabled && (
        <Row xs="1">
          <ColInline inline={inline} lg={{ span: 2, offset: 10 }}>
            <Button
              type="submit"
              block
              variant="outline-primary"
              className="tw-font-bold"
            >
              {t('Buscar')}
            </Button>
          </ColInline>
        </Row>
      )}

      {isDev && (
        <Row className="tw-mt-3">
          <ColInline xs="12">
            <pre style={{ color: '#fff' }}>
              {JSON.stringify(
                {
                  values: formikBag.values,
                  touched: formikBag.touched,
                  errors: formikBag.errors,
                },
                null,
                2,
              )}
            </pre>
          </ColInline>
        </Row>
      )}
    </Container>
  );
};

export default withFormikSearch(
  {
    initValues: {
      pickUpDate: moment().add(1, 'days').startOf('day').format(),
      pickUpTime: moment().hours(12).minutes(0).format(),
      dropOffDate: moment().add(2, 'days').startOf('day').format(),
      dropOffTime: moment().hours(12).minutes(0).format(),
    },
    schema: filterSearchSchema,
    onSubmit: ({ values, actions, history }) => {
      actions.setSubmitting(false);

      const query = filterSearchSchema.cast(values, { stripUnknown: true });
      const newQuery = encode(query);
      history.push(`/cars/list/${newQuery}`);
    },
  },
  FormSearch,
);
