import React from 'react';

import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PriceFormat from '../PriceFormat';
import styles from './CardCar.module.scss';
import ListFeatures from './ListFeatures';

const CardCar = ({ car, onClick }) => {
  // console.log('🚀 ~ CardCar.jsx', { car });
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body>
        <Row xs="1">
          <Col lg="4">
            <Row xs="1">
              <Col>
                <img
                  className={`${styles.imageCar} img-thumbnail`}
                  src={car.picture}
                  alt={car.name}
                />

                <img
                  className={styles.imageCompany}
                  src={car.company.image}
                  alt={car.company.name}
                />
              </Col>
            </Row>
          </Col>

          <Col lg="8" className="tw-flex tw-self-center">
            <Row xs="1" className="tw-w-full">
              <Col lg="12" className="tw-m-0 tw-p-0">
                <h4 className="tw-font-bold">{car.name}</h4>
                <span className="tw-block">{car.vehicleClass}</span>
              </Col>

              <Col xl="6" className="tw-m-0 tw-p-0">
                <ListFeatures car={car} />
              </Col>

              <Col xl="6">
                <Row xs="1" className={styles.containerRight}>
                  {car.pickUpLocation.length > 0 && (
                    <Col className={styles.columnContainerTotal}>
                      <h6 className="tw-font-bold">{t('CarsPickUpPlace')}</h6>
                      <span className="tw-block">{car.pickUpLocation}</span>
                    </Col>
                  )}

                  {car.dropOffLocation.length > 0 && (
                    <Col className={styles.columnContainerTotal}>
                      <h6 className="tw-font-bold">{t('CarsDropOffPlace')}</h6>
                      <span className="tw-block">{car.dropOffLocation}</span>
                    </Col>
                  )}

                  <Col className={styles.columnContainerTotal}>
                    <PriceFormat
                      value={parseFloat(car.price.total)}
                      currency={car.price.currency}
                      prefix={car.price.prefix}
                    >
                      {valueText => (
                        <h4 className="tw-m-0 tw-font-bold tw-text-green-500">
                          {valueText}
                        </h4>
                      )}
                    </PriceFormat>

                    <span className="tw-font-bold tw-text-green-500">
                      {t('JustGoPoints', {
                        count: car.price.justGoPoints,
                      })}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col className={styles.columnContainerButton}>
            <Button
              variant="primary"
              className="tw-font-bold"
              onClick={() => {
                if (typeof onClick === 'function') {
                  onClick(car);
                }
              }}
            >
              {t('CarsViewDetail')}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CardCar;
