import React, { useEffect, useRef, useState } from 'react';

import { Button, Overlay, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import useTranslationWithA from '../../hook/useTranslationWithA';
import sleep from '../../utils/sleep';

const FloatingButton = ({ children, variant, code, tooltip }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const handleClick = async () => {
    if (navigator?.clipboard) {
      await navigator.clipboard.writeText(code);
      setShow(false);
      return true;
    }

    setShow(false);
    return document.execCommand('copy', true, code);
  };

  const handleShowClick = () => {
    setShow(!show);
  };

  const content = useTranslationWithA({
    key: 'DashboardCodeDiscount',
    options: { code },
    onClick: handleClick,
  });

  useEffect(() => {
    if (!show) return undefined;

    let didCancel = false;
    const hide = async () => {
      await sleep(10000);
      if (didCancel) return;

      setShow(false);
    };

    hide();
    return () => {
      didCancel = true;
    };
  }, [show]);

  return (
    <>
      <ButtonStyled ref={target} variant={variant} onClick={handleShowClick}>
        {children}
      </ButtonStyled>

      <Overlay target={target.current} show={show} placement="bottom">
        {props => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Tooltip {...props} id="overlay-example">
            {tooltip || content}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

const ButtonStyled = styled(Button)`
  aspect-ratio: 1;
  border-radius: 50% !important;
  position: absolute;
  top: -33px;
  right: 10px;
`;

export default FloatingButton;
