import React, { useState } from 'react';

import { Button, Modal, Table } from 'react-bootstrap';
import { BsEyeFill } from 'react-icons/bs';
import useAppTranslation from '../hook/useAppTranslation';
import formatNumber from '../utils/formatNumber';
import getTaxName from '../utils/getTaxName';
import PriceFormat from './PriceFormat';

const markUP = 0.31;

const TableCabins = ({ rates, onHandleClickReservar }) => {
  const { t } = useAppTranslation();
  const markup = price => {
    const newprice = price - price * markUP;
    return Math.round(newprice);
  };

  const [show, setShow] = useState(false);
  const [showTaxes, setShowTaxes] = useState(false);
  const [politicas, setPoliticas] = useState([]);
  const [taxes] = useState([]);

  const handleClose = () => setShow(false);
  const handleCloseTaxes = () => setShowTaxes(false);
  const [countShow, setCountShow] = useState(3);
  const [texto, setTexto] = useState(t('VerMas'));

  const onHandleClickPolitica = item => {
    // console.log(item)
    // setPoliticas(item.cancellationPolicies);
    setPoliticas(item.description);

    setShow(true);
  };

  const showAll = () => {
    if (countShow === 1000) {
      setTexto(t('VerMas'));
      setCountShow(3);
    } else {
      setTexto(t('VerMenos'));
      setCountShow(1000);
    }
  };
  const getBeds = array => {
    let bed = '';
    for (let i = 0; i < array.length; i++) {
      if (array[i].indexOf('bed') > 0 || array[i].indexOf('beds') > 0) {
        bed = bed !== '' ? bed : array[i];
      }
    }
    return bed;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('detalles')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>{t('descripcion')}</th>
              </tr>
            </thead>
            <tbody>
              {politicas.map((pol, i) => (
                <tr key={i}>
                  {/* eslint-disable-next-line react/no-danger */}
                  <td dangerouslySetInnerHTML={{ __html: pol }} />
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Modal show={showTaxes} onHide={handleCloseTaxes} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('Impuestos')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>{t('Nombre')}</th>
                <th className="tw-text-center">{t('Incluido')}</th>
                <th className="tw-text-center">{t('Precio')}</th>
                <th>{t('Descripcion')}</th>
              </tr>
            </thead>
            <tbody>
              {taxes.map((tax, i) => (
                <tr
                  key={i}
                  className={
                    tax.included ? 'tw-text-success' : 'tw-text-danger'
                  }
                >
                  <td>{getTaxName(tax.name)}</td>
                  <td className="tw-text-center">
                    {tax.included ? t('Si') : 'No'}
                  </td>
                  <td className="tw-text-center">
                    {formatNumber(Math.round(parseInt(tax.amount, 10)))} USD
                  </td>
                  <td>{tax.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Table hover striped bordered responsive className="tw-mt-3">
        <thead>
          <tr>
            <th>{t('Detalle')}</th>
            <th className="tw-text-center">{t('Precio')}</th>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th />
          </tr>
        </thead>
        <tbody>
          {rates.map((item, i) => {
            // console.log('🚀 ~ TableCabins.js', { item });

            return (
              i < countShow && (
                <tr key={i}>
                  <td width="50%">
                    <label className="tw-mb-0 tw-block tw-font-bold tw-text-secondary">
                      {item.text}
                    </label>
                    <label className="tw-text-secondary">
                      {getBeds(item.description)}
                    </label>
                  </td>

                  <td className="tw-text-center" width="20%">
                    <div className="">
                      <h4 className="tw-m-0 tw-font-bold tw-text-green-500">
                        <PriceFormat
                          value={markup(item.price.total)}
                          currency={item.price.currency}
                          prefix={item.price.prefix}
                        />
                      </h4>

                      <span className="tw-font-bold tw-text-green-500">
                        {t('JustGoPoints', {
                          count: item.price.justGoPoints,
                        })}
                      </span>
                    </div>
                  </td>
                  <td width="30%" className="tw-text-center">
                    <div>
                      <Button
                        variant="outline-primary"
                        className="tw-m-auto tw-block tw-px-5 tw-font-bold"
                        onClick={() => onHandleClickReservar(item)}
                      >
                        {t('Reservar')}
                      </Button>
                      <small>
                        <Button
                          variant="link"
                          size="sm"
                          className="tw-font-bold tw-text-danger"
                          onClick={() => onHandleClickPolitica(item)}
                        >
                          <BsEyeFill /> {t('VerDetalles')}
                        </Button>
                      </small>
                    </div>
                  </td>
                </tr>
              )
            );
          })}
        </tbody>
        {rates.length > 3 && (
          <tfoot>
            <tr>
              <td colSpan="4" className="tw-text-center">
                <Button
                  variant="light"
                  size="sm"
                  className="tw-font-bold"
                  onClick={showAll}
                >
                  {texto}
                </Button>
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
    </>
  );
};

export default TableCabins;
