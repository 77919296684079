import yup from './yup';

const value = yup
  .object()
  .shape({
    label: yup.string().required(),
    value: yup.string().required(),
  })
  .notRequired()
  .nullable()
  .default(null);

export const filterSearchSchema = yup.object().shape({
  area: value.clone(),
  line: value.clone(),
  duration: value.clone(),
  ship: value.clone(),
  departing: yup.string().required().isDateMomentValid().default(''),
  returning: yup
    .string()
    .required()
    .isDateMomentValid()
    .isDateBeforeAtStartDate({
      startDateName: 'departing',
      message: 'ErrorFechaRegreso',
    })
    .default(''),
});

export const bookingSchema = yup.object().shape({});
