import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import BookInfo from '../../components/Cars/BookInfo';
import BookSidebar from '../../components/Cars/BookSidebar';
import SectionTop from '../../components/SectionTop';
import useAppParams from '../../hook/useAppParams';
import useAppTranslation from '../../hook/useAppTranslation';
import useDev from '../../hook/useDev';

const Booking = () => {
  const { t } = useAppTranslation();
  const { isDev } = useDev();
  const { rate } = useAppParams();
  // console.log('🚀 ~ Booking.jsx', { rate });

  if (!rate) return <div />;

  return (
    <>
      <SectionTop
        menu={t('Autos')}
        destino={rate.dropOff}
        nombre={t('DatosReserva')}
        direccion={rate?.car?.name}
      />

      <Container fluid className="tw-my-3">
        <Row xs="1">
          <Col lg="4">
            <BookSidebar rate={rate} />
          </Col>

          <Col lg="8">
            <BookInfo rate={rate} />
          </Col>
        </Row>

        {isDev && (
          <Row xs="1" className="tw-mt-3">
            {Object.entries({
              rate,
            })
              .filter(([, value]) => value)
              .map(([key, value], _, self) => {
                const lg = 12 / self.length;

                return (
                  <Col key={key} lg={lg}>
                    <pre>
                      {JSON.stringify(
                        {
                          [key]: value,
                        },
                        null,
                        2,
                      )}
                    </pre>
                  </Col>
                );
              })}
          </Row>
        )}
      </Container>
    </>
  );
};

export default Booking;
