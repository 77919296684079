import { useShallow } from 'zustand/react/shallow';
import useUserZustand from '../store/user';

const useUser = () => {
  const user = useUserZustand(useShallow(state => state));

  return user;
};

export default useUser;
