/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

const EmptyData = ({ t }) => {
  return (
    <Container>
      <Row className="tw-my-5 tw-justify-center">
        <Col xs="12" lg="7" className="jumbotron tw-px-2 tw-py-4">
          <Row className="tw-items-center">
            <Col className="tw-text-center">
              <h2>{t('HotelsNoInformation')}</h2>
              <h4 className="tw-mb-2">{t('Contactanos')}</h4>

              <h2 className="telef-1 lh-0-7 tw-mb-0 tw-text-primary">
                800 000 0000
              </h2>
              <small>
                {t('LunDom')} (7am - 1am) {t('HoraCiudadMexico')}
              </small>

              <small className="tw-mt-2 tw-block">
                {t('EstadosUnidosCanada')}
              </small>
              <h5 className="telef-1 tw-text-primary">1 (800) 490-0287</h5>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslation()(EmptyData);
