import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import createAppSearchParams from '../utils/createAppSearchParams';
import { decode } from '../utils/uriComponent';

const useSearchParams = () => {
  const history = useHistory();
  const { pathname, search } = history.location;

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const setSearchParams = newSearchParams => {
    history.replace(`${pathname}?${newSearchParams.toString()}`);
  };

  return [searchParams, setSearchParams];
};

const useAppSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const ret = useMemo(() => {
    const keys = [...searchParams.keys()];

    const entries = keys.map(key => {
      const value = searchParams.get(key);

      return [key, value !== null ? decode(value) : value];
    });

    const obj = Object.fromEntries(entries);
    if (keys.length > 0) {
      return obj;
    }

    return undefined;
  }, [searchParams]);

  const set = useCallback(
    params => {
      const newSearchParams = createAppSearchParams(params);

      setSearchParams(newSearchParams);
    },
    [setSearchParams],
  );

  return [ret, set];
};

export default useAppSearchParams;
