import React, { useMemo, useState } from 'react';

import { Field } from 'formik';
import { Button, ButtonGroup, Dropdown, Form } from 'react-bootstrap';
import { MdPeople } from 'react-icons/md';
import useAppTranslation from '../../hook/useAppTranslation';
import Counter from '../../shared/Counter';
import { filterSearchSchema } from '../../validations/hotels';
import styles from './Travelers.module.scss';

const Travelers = ({ inline, form, remove, push, disabled }) => {
  const { t } = useAppTranslation();
  const [show, setShow] = useState(false);
  const [room] = filterSearchSchema?.getDefault?.().rooms ?? [
    { adults: 0, children: { ages: [] } },
  ];

  const rooms = useMemo(() => form?.values?.rooms ?? [], [form?.values?.rooms]);
  const guests = useMemo(() => {
    // console.log('🚀 ~ Travelers.jsx', { rooms });
    const count = rooms.reduce((acc, { adults, children }) => {
      return acc + adults + (children?.ages ?? []).length;
    }, 0);

    return count;
  }, [rooms]);
  const allowAddRoom = useMemo(() => rooms.length < 5, [rooms]);

  const handleDecRoomClick = index => {
    remove(index);
  };

  const handleIncRoomClick = () => {
    push(room);
  };

  const handleDoneClick = () => {
    setShow(false);
  };

  return (
    <Dropdown
      as={ButtonGroup}
      className="filter-traveler tw-m-0 tw-w-full tw-p-0"
      size="lg"
      show={show}
      onToggle={() => setShow(!show)}
    >
      <Dropdown.Toggle className={styles.toggle} disabled={disabled}>
        <span>
          <MdPeople /> {t('HotelsRoomsDescription', { count: rooms.length })},{' '}
          {t('HotelsGuestsDescription', { count: guests })}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className={`tw-w-full tw-p-0 ${
          !inline &&
          '!-tw-ml-4 tw-w-[125%] md:tw-ml-0 md:tw-w-full lg:tw-w-[165%] xl:tw-w-[145%] 2xl:tw-w-[110%]'
        }`}
      >
        <div className="tw-z-10 tw-w-full tw-origin-top-right tw-divide-y tw-rounded-md tw-bg-white tw-text-black tw-shadow-lg tw-ring-1 tw-ring-black/5 focus:tw-outline-none">
          <section className="tw-max-h-60 tw-w-full tw-divide-y tw-overflow-y-scroll">
            {rooms.map(({ adults, children = { ages: [] } }, roomIndex) => {
              const roomKey = `room-${roomIndex}`;
              const fieldAdults = `rooms.${roomIndex}.adults`;
              const fieldChildren = `rooms.${roomIndex}.children.ages`;
              const isLast = rooms.length - 1 === roomIndex;

              const handleAdultClick = newAdults => {
                form.setFieldValue(fieldAdults, newAdults);
              };

              const handleChildClick = newChildren => {
                form.setFieldValue(fieldChildren, newChildren);
              };

              return (
                <article
                  key={roomKey}
                  className={`tw-flex tw-flex-col tw-gap-2 tw-p-4 ${
                    isLast
                      ? ''
                      : 'tw-border-x-0 tw-border-b-2 tw-border-t-0 tw-border-solid tw-border-gray-300'
                  }`}
                >
                  <div className="tw-flex tw-justify-between">
                    <h1 className="tw-m-0 tw-text-base tw-font-semibold tw-text-inherit">
                      {t('HotelsRooms_one')} {roomIndex + 1}
                    </h1>

                    {rooms.length > 1 && (
                      <a
                        href="/"
                        className="tw-text-primary hover:tw-underline"
                        onClick={evt => {
                          evt.preventDefault();
                          evt.stopPropagation();

                          handleDecRoomClick(roomIndex);
                        }}
                      >
                        {t('HotelsDeleteRoom')}
                      </a>
                    )}
                  </div>

                  <div className="tw-grid tw-w-full tw-grid-cols-2">
                    <span className="tw-flex tw-items-center">
                      {t('HotelsAdults', { count: adults })}
                    </span>

                    <Field name={fieldAdults}>
                      {({ field }) => (
                        <Counter
                          name={field.name}
                          value={field.value}
                          min={1}
                          max={6}
                          onChange={handleAdultClick}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="tw-grid tw-w-full tw-grid-cols-2">
                    <span className="tw-flex tw-items-center">
                      {t('HotelsChildren', {
                        count: children.ages.length,
                      })}
                    </span>

                    <Field name={fieldChildren}>
                      {({ field }) => (
                        <Counter
                          name={field.name}
                          value={field.value.length}
                          min={0}
                          max={4}
                          onChange={newValue => {
                            if (newValue < field.value.length) {
                              handleChildClick(field.value.slice(0, -1));
                            } else if (newValue > field.value.length) {
                              handleChildClick([...field.value, 0]);
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  {(children?.ages ?? []).map((age, childIndex) => {
                    const childrenKey = `${roomKey}-child-${childIndex}`;

                    return (
                      <Field
                        key={childrenKey}
                        name={`${fieldChildren}.${childIndex}`}
                      >
                        {({ field }) => (
                          <div className="tw-grid tw-w-full tw-grid-cols-2">
                            <span className="tw-flex tw-items-center">
                              {t('HotelsChildAge')} {childIndex + 1}
                            </span>

                            <Form.Control
                              as="select"
                              name={field.name}
                              value={field.value}
                              onChange={field.onChange}
                              onBlur={field.onBlur}
                            >
                              {Array.from({ length: 18 }).map((_, year) => (
                                <option
                                  key={`${childrenKey}-${year}`}
                                  value={year}
                                >
                                  {t('HotelsChildAgeYears', {
                                    count: year,
                                  })}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                        )}
                      </Field>
                    );
                  })}
                </article>
              );
            })}
          </section>

          <section
            className={`tw-flex tw-items-center tw-border-x-0 tw-border-b-0 tw-border-t-2 tw-border-solid tw-border-gray-300 tw-p-4 ${
              allowAddRoom ? 'tw-justify-between' : 'tw-justify-end'
            }`}
          >
            {allowAddRoom && (
              <a
                href="/"
                onClick={evt => {
                  evt.preventDefault();
                  evt.stopPropagation();

                  handleIncRoomClick();
                }}
                className="tw-text-primary hover:tw-underline"
              >
                + {t('HotelsAddRoom')}
              </a>
            )}

            <Button variant="primary" onClick={handleDoneClick}>
              {t('HotelsDone')}
            </Button>
          </section>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Travelers;
