import React, { useEffect } from 'react';

import { Image } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

const CargandoOpciones = ({ t, hideLooking = false }) => {
  useEffect(() => {
    const { overflow } = window.document.querySelector('body').style;
    window.document.querySelector('body').style.overflow = 'hidden';

    return () => {
      window.document.querySelector('body').style.overflow = overflow;
    };
  }, []);

  return (
    <Wrapper>
      <div>
        <Image
          src="/images/loader.gif"
          alt="Loader"
          className="tw-aspect-square tw-h-64"
        />
      </div>
      <h2 className="">{t('PorFavorEspere')}</h2>
      {!hideLooking && <label>{t('EstamosBuscandoOpciones')}</label>}
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs(() => ({ className: 'jumbotron' }))`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483002;
  width: 100vw;
  height: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #fcfcfc !important;
`;

export default withTranslation()(CargandoOpciones);
