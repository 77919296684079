import React from 'react';

import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs';
import Tooltip from './Tooltip';
import styles from './Rating.module.scss';

const Rating = ({ rating, hideTooltip = false }) => {
  const stars = Math.trunc(rating);
  const isHalf = rating % 1 > 0;

  const getIcon = index => {
    if (index < stars) return BsStarFill;
    if (index < stars + 1 && isHalf) {
      return BsStarHalf;
    }

    return BsStar;
  };

  const ratingComponent = (
    <div>
      {Array.from({ length: 5 })
        .fill(0)
        .map((_, i) => {
          const Icon = getIcon(i);

          return <Icon key={i} className={styles.star} />;
        })}
    </div>
  );
  return (
    <div className={styles.rating}>
      {hideTooltip && ratingComponent}
      {!hideTooltip && <Tooltip message={rating}>{ratingComponent}</Tooltip>}
    </div>
  );
};

export default Rating;
