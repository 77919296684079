import { css } from 'styled-components';

const asyncSelect = css`
  .async-select {
    &.select-container.disabled {
      opacity: 0.5;

      *:has(~ &) {
        opacity: 0.5;
      }
    }

    &.placeholder,
    &.single-value,
    &.clear-indicator,
    &.indicator-separator,
    &.dropdown-indicator,
    &.input {
      color: var(--color-text);
    }

    &.indicator-separator,
    &.dropdown-indicator {
      &.disabled {
        display: none;
      }
    }

    &.option {
      color: #343434;
    }

    &.control,
    &.single-value,
    &.input > input {
      background-color: transparent !important;
      border-color: #ced4da !important;

      border-width: 0 0 2px 0 !important;
      border-radius: 0 !important;
      box-shadow: none !important;
    }

    .form-control.is-valid,
    &.control.is-valid {
      border-color: #28a745 !important;
    }

    .form-control.is-invalid,
    &.control.is-invalid {
      border-color: #dc3545 !important;
    }

    .form-control:focus:not(.is-valid):not(.is-invalid),
    &.select-container:has([id='aria-selection']):not(.is-valid, .is-invalid) {
      box-shadow: 0px 10px 5px -5px #ced4da52 !important;
    }

    .form-control.is-valid:focus,
    &.select-container:has([id='aria-selection']).is-valid {
      box-shadow: 0px 10px 5px -5px #28a74540 !important;
    }

    .form-control.is-invalid:focus,
    &.select-container:has([id='aria-selection']).is-invalid {
      box-shadow: 0px 10px 5px -5px #dc354540 !important;
    }
  }
`;

export default asyncSelect;
