import React, { useEffect } from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SectionPromotionNY from '../components/SectionPromotionNY';
import MainContainer from '../shared/MainContainer';
import PriceBottom from '../shared/PriceBottom';
import CondoDetail from './CondoDetail';
import CondoReserva from './CondoReserva';
import CondosListado from './CondosListado';

const Condos = () => {
  const { url } = useRouteMatch();

  useEffect(() => {
    // console.count('🚀 ~ Home.jsx ~ useEffect(url)');

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [url]);

  return (
    <MainContainer>
      <Switch>
        <Route exact path={url} component={CondosListado} />
        <Route exact path={`${url}/detail`} component={CondoDetail} />
        <Route path={`${url}/reservation`} component={CondoReserva} />
      </Switch>

      <SectionPromotionNY />
      <PriceBottom />
    </MainContainer>
  );
};

export default Condos;
