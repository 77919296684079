import React from 'react';

import { Field } from 'formik';
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import withFormikSearch from '../../hoc/withFormikSearch';
import InputForm from '../../shared/InputForm';
import { bookingSchema } from '../../validations/cars';
import FormPayment from '../FormPayment';
import styles from './BookInfo.module.scss';

const BookInfo = () => {
  const { t } = useTranslation();

  return (
    <Container className="tw-m-0 tw-max-h-full tw-w-full tw-max-w-full tw-p-0">
      <Card>
        <Card.Body>
          <Row xs="1">
            <Col>
              <h1>{t('EnterYourData')}</h1>
              <span>{t('PleaseCheckAllData')}</span>

              <Accordion
                className={`${styles.accordion} tw-mt-3`}
                defaultActiveKey="0"
              >
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    {t('PrimaryInformation')}
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Row xs="1" lg="3">
                        <Col>
                          <InputForm name="name" label={t('CarsBookName')} />
                        </Col>

                        <Col>
                          <InputForm
                            name="middleName"
                            label={t('CarsBookMiddleName')}
                          />
                        </Col>

                        <Col>
                          <InputForm
                            name="lastName"
                            label={t('CarsBookLastName')}
                          />
                        </Col>
                      </Row>

                      <Row xs="1" lg="3">
                        <Col>
                          <InputForm name="email" label={t('CarsBookEmail')} />
                        </Col>

                        <Col>
                          <InputForm name="phone" label={t('CarsBookPhone')} />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <Accordion className={styles.accordion} defaultActiveKey="0">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    {t('PaymentInformation')}
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <FormPayment />

                      <Row xs="1">
                        <Col>
                          <Field name="policy">
                            {({ field, meta }) => {
                              return (
                                <Form.Group controlId={field.name}>
                                  <Form.Check type="checkbox" id={field.name}>
                                    <div className={styles.policy}>
                                      <Form.Check.Input
                                        type="checkbox"
                                        name={field.name}
                                        value
                                        checked={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        isValid={meta.touched && !meta.error}
                                        isInvalid={meta.touched && !!meta.error}
                                      />

                                      <Form.Check.Label>
                                        {t('CarsBookPolicy')}
                                      </Form.Check.Label>
                                    </div>

                                    {meta.touched && meta.error && (
                                      <Form.Control.Feedback type="invalid">
                                        {t(meta.error)}
                                      </Form.Control.Feedback>
                                    )}
                                  </Form.Check>
                                </Form.Group>
                              );
                            }}
                          </Field>
                        </Col>

                        <Col>
                          <Button
                            type="submit"
                            variant="outline-primary"
                            className="tw-mt-3 tw-font-bold"
                          >
                            {t('CompleteReservation')}
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default withFormikSearch(
  {
    schema: bookingSchema,

    // eslint-disable-next-line no-unused-vars
    onSubmit: ({ values, actions, history }) => {
      // console.log('🚀 ~ BookInfo.jsx', { values, actions, history });
    },
  },

  BookInfo,
);
