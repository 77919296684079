import { useMemo } from 'react';
import usePriceFormat from '../hook/usePriceFormat';

const PriceFormat = ({
  currency,
  value,
  children,
  decimalScale = 2,
  prefix,
}) => {
  const format = usePriceFormat({
    decimalScale,
    prefix,
  });

  const formattedValue = useMemo(
    () => format({ value, currency }),
    [format, value, currency],
  );

  if (!children) return formattedValue;

  return children(formattedValue);
};

export default PriceFormat;
