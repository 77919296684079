import { css } from 'styled-components';

const select = css`
  .react-datepicker-wrapper > .react-datepicker__input-container > input,
  select.form-control.filter {
    width: 100%;
    background-color: transparent;
    border-color: #ced4da !important;
    border-width: 0 0 2px 0;
    border-radius: 0;
    color: var(--color-text);

    label:has(+ &) {
      color: var(--color-text);
    }

    &:focus,
    &:focus-visible {
      outline: none;
      box-shadow: 0px 10px 5px -5px #ced4da52 !important;
    }

    &.is-valid {
      border-color: #28a745 !important;

      &:focus {
        box-shadow: 0px 10px 5px -5px #28a74540 !important;
      }
    }

    &.is-invalid {
      border-color: #dc3545 !important;

      &:focus {
        box-shadow: 0px 10px 5px -5px #dc354540 !important;
      }
    }

    &:disabled {
      opacity: 0.5;

      *:has(+ &) {
        opacity: 0.5;
      }

      & + * {
        opacity: 0.5;
      }
    }
  }
`;

export default select;
