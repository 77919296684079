/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';

import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ModalAmenities = ({ show = false, onClose, amenities = [] }) => {
  const { t } = useTranslation();
  const handleClose = () => onClose && onClose();

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('Comodidades')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tw-mb-3">
          <span className="badge badge-pill badge-info">
            {t('NoCargoExtra')}
          </span>
          <span className="badge badge-pill badge-danger">
            {t('TienenCargoExtra')}
          </span>
        </div>

        <ul className="list-inline">
          {amenities.length === 0 ? (
            <li className="list-inline-item">
              {t('NoDisponiblesComodidades')}
            </li>
          ) : (
            amenities.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <li className="list-inline-item" key={i}>
                <span
                  className={`badge badge-pill ${
                    item.extraCharge ? 'badge-danger' : 'badge-info'
                  }`}
                >
                  {item.name}
                </span>
              </li>
            ))
          )}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={handleClose}>
          {t('Aceptar')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAmenities;
