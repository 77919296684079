import React from 'react';

import { Button, Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IoMdRestaurant } from 'react-icons/io';
import ReviewInformation from '../ReviewInformation';
import styles from './RestaurantDetail.module.css';

const RestaurantDetail = ({ restaurant, onBookingClick }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col xs="12">
        <Image src={restaurant.image} className={styles['image-header']} />
      </Col>

      <Col xs="12" className="tw-mt-3">
        <Button variant="outline-primary" onClick={onBookingClick}>
          <IoMdRestaurant /> {t('RestaurantBook')}
        </Button>
      </Col>

      {restaurant.description?.length > 0 && (
        <>
          <Col xs="12" className="tw-mt-3">
            <h2>{t('RestaurantAboutUs')}</h2>
          </Col>

          <Col xs="12">
            <span>{restaurant.description}</span>
          </Col>
        </>
      )}

      <Col xs="12" className="tw-mt-3">
        <h2>{t('RestaurantTypesOfFood')}</h2>
      </Col>

      <Col xs="12">
        <span>{restaurant.cuisine}</span>
      </Col>

      <Col xs="12">
        <ReviewInformation reviews={restaurant.reviews} />
      </Col>
    </Row>
  );
};

export default RestaurantDetail;
