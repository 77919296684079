import { create } from 'zustand';

const store = set => ({
  message: null,
  type: null,

  add: ({ message, type }) => {
    set({ message, type }, false, 'ADD_MESSAGE');
  },
  clear: () => {
    set({ message: null, type: null }, false, 'CLEAR_MESSAGE');
  },
});

const useMessageZustand = create(store);

export default useMessageZustand;
