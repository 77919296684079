import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

const useQuery = name => {
  const { search } = useLocation();

  return useMemo(() => {
    const query = new URLSearchParams(search);

    if (name) {
      const data = query?.get(name);
      return data ? JSON.parse(decodeURIComponent(data)) : {};
    }

    return query;
  }, [search, name]);
};

export default useQuery;
