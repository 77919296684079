import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import useAppField from '../../hook/useAppField';
import useAppTranslation from '../../hook/useAppTranslation';
import useInputFormHidden from '../../hook/useInputFormHidden';
import Select from '../../shared/Select';
import * as formikUtils from '../../utils/formik';

const FiltersSelect = ({ name, label, options, disabled, required }) => {
  const { t } = useAppTranslation();
  const select = useAppField(name);
  const input = useInputFormHidden(select.field);

  const error = formikUtils.getError(select.meta);
  const isValid = formikUtils.isValid(select.meta);
  const isInvalid = formikUtils.isInvalid(select.meta);

  const valueOpt = useMemo(() => {
    const opt = options.find(it => `${it.value}` === select.field.value);
    if (!opt) return undefined;

    return opt;
  }, [options, select]);

  return (
    <Form.Group controlId={select.field.name}>
      <Form.Label
        className={`tw-text-white ${disabled || (required && 'required')}`}
      >
        {label}
      </Form.Label>

      <Select
        name={select.field.name}
        value={valueOpt}
        isClearable
        options={options}
        onInputChange={(_, actionMeta) => {
          if (actionMeta.action === 'input-blur') {
            input.handleBlur();
          }
        }}
        onChange={(newValue, actionMeta) => {
          if (actionMeta.action === 'clear') {
            input.handleChange('');
            return;
          }

          if (actionMeta.action === 'select-option') {
            const value = newValue !== null ? newValue.value : '';
            input.handleChange(value.toString());
            return;
          }

          // eslint-disable-next-line no-console
          console.log('🚀 ~ FiltersSelect.jsx ~ onChange', {
            action: actionMeta.action,
            newValue,
          });
        }}
        isDisabled={disabled}
        isValid={isValid}
        isInvalid={isInvalid}
      />

      {isInvalid && (
        <Form.Control.Feedback type="invalid">{t(error)}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

FiltersSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

FiltersSelect.defaultProps = {
  disabled: false,
  required: false,
};

export default FiltersSelect;
