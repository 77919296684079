import React, { useEffect } from 'react';

import { Button, Container, Form } from 'react-bootstrap';
import useAppTranslation from '../../hook/useAppTranslation';
import useCurrency from '../../hook/useCurrency';
import PriceFormat from '../PriceFormat';

const CardPreOrder = ({ ticket, setTicket, quantity, setQuantity }) => {
  const { t } = useAppTranslation({ keyPrefix: 'SportingEvents' });
  const { convertTo } = useCurrency();
  const price = convertTo({
    value: (ticket?.Price ?? 0) + (ticket?.ServiceFee ?? 0),
    currency: ticket?.Currency ?? '',
  });

  useEffect(() => {
    setQuantity(Math.min(...(ticket?.available_selling_quantities ?? [0])));
  }, [ticket, setQuantity]);

  return (
    <div
      className={`tw-fixed tw-inset-x-0 tw-z-[2147483002] tw-h-28 tw-border-x-0 tw-border-b-0 tw-border-t-2 tw-border-solid tw-border-zinc-200 tw-bg-white tw-transition-all tw-duration-500 ${
        ticket ? 'tw-bottom-0' : '-tw-bottom-full'
      }`}
    >
      {ticket && (
        <div className="tw-relative tw-h-full tw-w-full tw-bg-white">
          <button
            type="button"
            className="tw-absolute tw-right-2 tw-top-0 tw-rounded-full tw-border-0 tw-bg-transparent"
            onClick={() => {
              setTicket(null);
            }}
          >
            X
          </button>

          <Container className="tw-inset-0 tw-h-full">
            <div className="tw-flex tw-h-full tw-items-center tw-justify-end tw-gap-4">
              <div className="tw-w-28">
                <Form.Group>
                  <Form.Label>{t('Quantity')}</Form.Label>

                  <Form.Control
                    as="select"
                    value={quantity}
                    onChange={evt => {
                      setQuantity(evt.target.value);
                    }}
                  >
                    {(ticket?.available_selling_quantities ?? []).map(it => (
                      <option value={it}>{it}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>

              <div>
                <h3>Total</h3>
                <PriceFormat
                  value={price.value * quantity}
                  currency={price.currency}
                  prefix={price.prefix}
                >
                  {valueText => <h3 className="tw-text-price">{valueText}</h3>}
                </PriceFormat>
              </div>

              <div>
                <Button
                  variant="outline-primary"
                  className="tw-ml-auto tw-block tw-rounded-full"
                  onClick={() => {}}
                >
                  {t('PlaceOrder')}
                </Button>
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default CardPreOrder;
