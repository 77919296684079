import React from 'react';

import { Field } from 'formik';
import styled, { css } from 'styled-components';

const RadioButton = ({ name = 'rbTest', value, size = '25', children }) => {
  return (
    <Wrapper>
      <Radio size={size}>
        <Input name={name} value={value} />
        <CheckMark />
      </Radio>

      {children}
    </Wrapper>
  );
};

const Wrapper = styled.label`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;

  & > *:last-child {
    margin-left: 1rem;
  }
`;

const CheckMark = styled.span`
  box-sizing: content-box;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border-color: var(--button-primary) !important;

  &:after {
    content: '';
    position: absolute;
    display: none;

    border-radius: 50%;
    background-color: var(--button-primary) !important;
  }
`;

const Input = styled(Field).attrs(() => ({
  type: 'radio',
}))`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const Radio = styled.div`
  display: block;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;
  margin-right: 0.5rem;

  &&&& {
    ${({ size }) => {
      const sizeBorder = 2;
      const sizeWrapper = Number(size);
      const sizeRadio = sizeWrapper - sizeBorder * 2;
      const sizeCheckMark = sizeRadio / 2;

      return css`
        height: ${sizeWrapper}px;
        width: ${sizeWrapper}px;

        & > ${CheckMark} {
          height: ${sizeRadio}px;
          width: ${sizeRadio}px;
          border: ${sizeBorder}px solid;

          &:after {
            height: ${sizeCheckMark}px;
            width: ${sizeCheckMark}px;
            top: ${sizeCheckMark / 2}px;
            left: ${sizeCheckMark / 2}px;
          }
        }
      `;
    }}
  }

  & > ${Input}:checked ~ ${CheckMark}::after {
    display: block;
  }
`;

export default RadioButton;
