import moment from 'moment';

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
const monthsShort = months.map(it => `${it.substring(0, 3)}`);
const weekdays = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
];
const weekdaysMin = weekdays.map(it => `${it.substring(0, 2)}`);
const weekdaysShort = weekdays.map(it => `${it.substring(0, 3)}`);

moment.locale('es', {
  months,
  monthsShort,
  monthsParseExact: true,
  weekdays,
  weekdaysMin,
  weekdaysShort,
  weekdaysParseExact: true,
});
