/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useState } from 'react';

import { Card, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ErrorServer from '../components/ErrorServer';
import SectionTop from '../components/SectionTop';
import CondoAmenities from '../components/condos/CondosAmenities';
import CondosFormSideBar from '../components/condos/CondosFormSideBar';
import CondosFormTopBar from '../components/condos/CondosFormTopBar';
import CondoGaleria from '../components/condos/CondosGaleria';
import CondoTarifas from '../components/condos/CondosTarifas';
import '../css/condos/CondosDetail.css';
import useQuery from '../hook/useQuery';
import CargandoOpciones from '../loader/CargandoOpciones';
import { CONDOS_DETAIL } from '../services/Routes';
import post from '../services/post';

const CondoDetail = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const data = JSON.parse(query.get('search'));
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [, setDataError] = useState(false);
  const [positions, setPositions] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [descriptionCondo, setDescriptionCondo] = useState(null);
  const [condoName, setCondoName] = useState('');
  const [condoAddress, setCondoAddress] = useState('');
  const [center, setCenter] = useState(null);
  const [position, setPosition] = useState(-1);
  const [items, setItems] = useState([]);
  const [condoDetail, setCondoDetail] = useState();

  useEffect(() => {
    const urlService = `${CONDOS_DETAIL}`;

    post({
      url: urlService,
      data,
    })
      .then(resp => {
        if (resp.data === null) setDataError(true);
        else {
          const arr = [];
          arr.push(resp.data);
          setItems(arr);
          if (
            resp.data.address != null &&
            resp.data.address.geolocation != null
          ) {
            const arrP = [
              {
                id: resp.data.id,
                place: resp.data.name,
                raiting: resp.data.stars,
                latitude: resp.data.address.geolocation.latitude,
                longitude: resp.data.address.geolocation.longitude,
              },
            ];
            setPositions(arrP);
            setCenter({
              latitude: resp.data.address.geolocation.latitude,
              longitude: resp.data.address.geolocation.longitude,
            });
          }

          setCondoDetail(resp.data);
          setPhotos(resp.data.photos);
          setAmenities(resp.data.amenities);
          setRooms(resp.data.rates);
          setDescriptionCondo(resp.data.description);
          setCondoName(resp.data.name);
          if (resp.data.address != null) {
            setCondoAddress(
              resp.data.address.addressName
                ? resp.data.address.addressName
                : resp.data.address.city,
            );
          }
        }

        setLoading(false);
      })
      .catch(() => {
        setServerError(true);
        // console.log('Error en la Peticion', error);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SectionTop
        menu={t('Condominios')}
        destino={data.destino_name}
        nombre={condoName}
        direccion={condoAddress}
      />

      {loading ? (
        <CargandoOpciones />
      ) : serverError ? (
        <Container fluid className="tw-mt-3">
          <Row>
            <Col xs="12" lg="3">
              <CondosFormSideBar searching={data} />
            </Col>
            <Col xs="12" lg="9">
              <ErrorServer errorData={null} />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid className="content">
          <CondosFormTopBar searching={data} />

          {/* CONTENIDO DEL DETALLE */}
          <div className="taps">
            <Tabs defaultActiveKey="tarifas" id="uncontrolled-tab-example">
              <Tab
                eventKey="tarifas"
                title={t('tarifas')}
                className="internalItem"
              >
                <CondoTarifas
                  rooms={rooms}
                  searching={data}
                  condoDetail={condoDetail}
                />
              </Tab>
              <Tab eventKey="detalle" title={t('Detalle')}>
                <CondoAmenities
                  positions={positions}
                  amenities={amenities}
                  detail={descriptionCondo}
                  items={items}
                  position={position}
                  setPosition={setPosition}
                  center={center}
                  setCenter={setCenter}
                />
              </Tab>
              <Tab eventKey="galeria" title={t('galeria')}>
                <Card>
                  <Card.Body>
                    <CondoGaleria photos={photos} />
                  </Card.Body>
                </Card>
              </Tab>
            </Tabs>
          </div>
        </Container>
      )}
    </>
  );
};

export default CondoDetail;
