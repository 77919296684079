import React, { useEffect } from 'react';

import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { scrollUp } from '../../utils/scroll';
import Booking from './Booking';
import Detail from './Detail';
import Hotels from './Hotels';
import List from './List';
import Voucher from './Voucher';
import Warranty from './Warranty';

const Home = () => {
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  useEffect(() => {
    scrollUp();
  }, [pathname]);

  return (
    <Switch>
      <Route exact path={path} component={Hotels} />
      <Route exact path={`${path}/list/:query`} component={List} />
      <Route exact path={`${path}/detail/:query/:hotelId`} component={Detail} />
      <Route exact path={`${path}/booking/:rate`} component={Booking} />
      <Route
        exact
        path={`${path}/booking/voucher/:booking`}
        component={Voucher}
      />
      <Route path={`${path}/warranty/:query`} component={Warranty} />
    </Switch>
  );
};

export default Home;
