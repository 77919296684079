import { prepareConvertCurrency } from '../utils/currency';
import { isFlightValid, mapItineraries } from '../utils/flights';
import { FLIGHT_CHECK_PRICE, FLIGHT_LIST } from './Routes';
import instance from './instance';

const LANGUAGE_DEFAULT = 'es';
const CURRENCY_DEFAULT = 'MXN';
const sources = process.env.REACT_APP_SUNAPI_API_NAME_FLIGHT ?? '';

export const getFlights = async ({
  origin,
  destiny,
  checkIn,
  returnDate,
  paxes,
  oneWay,
  currency = CURRENCY_DEFAULT,
  language = LANGUAGE_DEFAULT,
}) => {
  const body = {
    currency,
    language,
    origin,
    destiny,
    checkIn: checkIn.format('DD-MM-YYYY'),
    returnDate: returnDate.format('DD-MM-YYYY'),
    paxes,
    oneWay,
  };

  const request = instance.post(FLIGHT_LIST, body, { params: { sources } });
  const response = await request
    .then(async res => {
      const data = res?.data ?? {};
      // console.log('🚀 ~ flights.js', { data });

      const convertCurrency = await prepareConvertCurrency();
      const dataResponse = (data.flightsSearch ?? []).reduce(
        (acc, flight) => {
          if (!isFlightValid({ flight })) return acc;

          // eslint-disable-next-line no-param-reassign
          flight.price = convertCurrency(flight.price);
          const { departing, returning } = mapItineraries(flight);
          acc.data.push({
            id: flight.id,
            price: flight.price,
            departing,
            returning,
          });

          flight.itineraries.forEach(({ flightSegments }) => {
            flightSegments.forEach(({ airline }) => {
              acc.airlines[airline.codeIata] = acc.airlines[
                airline.codeIata
              ] ?? {
                id: airline.codeIata,
                label: airline.name,
                checked: false,
              };
            });
          });

          if (flight.price?.total) {
            acc.price.min = Math.min(acc.price.min, flight.price.total);
            acc.price.max = Math.max(acc.price.max, flight.price.total);
          }

          return acc;
        },
        {
          data: [],
          currency: data.currency,
          airlines: {},
          price: {
            min: Number.POSITIVE_INFINITY,
            max: Number.NEGATIVE_INFINITY,
          },
        },
      );

      dataResponse.airlines = Object.values(dataResponse.airlines).sort(
        ({ label: a }, { label: b }) => a.localeCompare(b),
      );

      // console.log('🚀 ~ flights.js', { dataResponse });
      return dataResponse;
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ flights.js', { err });

      return { currency, data: [] };
    });

  return response;
};

export const checkPrice = async () => {
  const body = {};
  const request = instance.post(FLIGHT_CHECK_PRICE, body, {});

  await request;
};
