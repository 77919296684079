import React from 'react';

import styled from 'styled-components';
import { AiTwotoneBulb } from 'react-icons/ai';

const Title = ({ title }) => {
  return (
    <Wrapper>
      <Icon>
        <AiTwotoneBulb />
      </Icon>
      <h4 className="tw-ml-3">{title}</h4>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > h4 {
    margin-block: 0;
  }
`;

const Icon = styled.div`
  /* background-color: gray; */
  width: 65px;
  height: 82px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  & path {
    stroke: var(--button-primary);
    color: var(--button-primary);
  }
`;

export default Title;
