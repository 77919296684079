import { create } from 'zustand';

const USER_BLACK = {
  name: 'María',
  lastName1: 'Bárcenas',
  lastName2: 'Martínez',
  dateOfBirth: '',
  email: 'Mar@gmail.com',
  additionalEmail: '',
  telephone: '55 5500 2020',
  country: 'México',
  state: 'Estado Ciudad de México',
};

const USER_PLATINUM = {
  name: 'Alejandra',
  lastName1: 'Mateos',
  lastName2: '',
  dateOfBirth: '',
  email: 'alexmat@gmail.com',
  additionalEmail: '',
  telephone: '55 5500 2010',
  country: 'México',
  state: 'Estado Ciudad de México',
};

const store = set => ({
  user: {},
  setBlack: () => {
    set({ user: USER_BLACK }, false, 'SET_BLACK');
  },
  setPlatinum: () => {
    set({ user: USER_PLATINUM }, false, 'SET_PLATINUM');
  },
  setUser: user => {
    set({ user }, false, 'SET_USER');
  },
});

const useUserZustand = create(store);

export default useUserZustand;
