/* eslint-disable react/no-danger */
import React from 'react';

import { Button } from 'react-bootstrap';
import useAppTranslation from '../../hook/useAppTranslation';

const ListItem = ({ title, subtitle, prefixParagraph, btnTitle, onClick }) => {
  const { t } = useAppTranslation();

  return (
    <li className="tw-grow md:tw-w-1/3">
      <article className="tw-flex tw-h-full tw-flex-col tw-border tw-border-solid tw-border-[#d8dde5] tw-text-black">
        <header className="tw-bg-primary tw-py-2.5 tw-text-center tw-text-2xl tw-font-medium tw-text-white">
          {title}
        </header>

        <main className="tw-flex tw-flex-1 tw-flex-col tw-p-3.5 [&>div]:tw-flex [&>div]:tw-flex-col [&>div]:tw-gap-2.5 [&>div]:tw-py-5">
          <div className="tw-items-center tw-border-0 tw-border-b tw-border-solid">
            <h2 className="tw-text-base tw-text-inherit">
              {t('dwYouReceive')}
            </h2>
            <h3 className="tw-text-xl tw-font-medium tw-text-inherit">
              {subtitle}
            </h3>
          </div>

          <div className="tw-flex-1 tw-justify-between tw-px-6">
            {Array.from({ length: 5 }).map((_, index) => (
              <p
                className={`tw-m-0 [&_a]:tw-text-primary [&_a]:tw-underline ${
                  index === 4 ? 'tw-px-0' : 'tw-px-4'
                }`}
                dangerouslySetInnerHTML={{
                  __html: t(`${prefixParagraph}${index + 1}`),
                }}
              />
            ))}

            <div className="tw-mx-auto tw-py-4">
              <Button
                variant="primary"
                size="sm"
                className="tw-font-bold"
                onClick={onClick}
              >
                {btnTitle}
              </Button>
            </div>
          </div>
        </main>
      </article>
    </li>
  );
};

export default ListItem;
