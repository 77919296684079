import React from 'react';

import { withTranslation } from 'react-i18next';

const GetTextHuespedes = ({ t, guest }) => {
  return (
    <>
      {t('Para')}
      <span className="tw-lowercase">
        {` ${guest.adults} ${t('Adults', { count: guest.adults })} ${
          guest.children > 0
            ? ` + ${guest.children} ${t('Children', { count: guest.children })}`
            : ''
        }`}
      </span>
    </>
  );
};

export default withTranslation()(GetTextHuespedes);
