import React from 'react';

import { Card, Col, Row } from 'react-bootstrap';
import useAppTranslation from '../../hook/useAppTranslation';
import PriceFormat from '../PriceFormat';

const BookingCardTopWhiteExp = ({ item }) => {
  const { t } = useAppTranslation();
  const { name, adults, children, rate } = item;

  return (
    <Card className="tw-shadow-none">
      <Card.Body>
        <Row>
          <Col xs="12" lg="12">
            <h5 className="tw-text-info">
              {t('EXPERIENCES_RESERVATION_DETAIL')}
            </h5>
            <hr className="tw-bg-info" />
            <h6>{name}</h6>
          </Col>
        </Row>
        <hr />

        <Row xs="2">
          <Col lg="7">
            <label className="tw-capitalize">
              {adults} {t('EXPERIENCES_ADULTS', { count: adults })}
            </label>
          </Col>

          <Col lg="5" className="tw-flex tw-items-center tw-justify-end">
            <PriceFormat currency="USD" value={rate.adult} decimalScale={2}>
              {price => <span>{price}</span>}
            </PriceFormat>
          </Col>
        </Row>

        <Row xs="2">
          <Col lg="7">
            <label className="tw-capitalize">
              {children} {t('EXPERIENCES_CHILDREN', { count: children })}
            </label>
          </Col>

          <Col lg="5" className="tw-flex tw-items-center tw-justify-end">
            <PriceFormat currency="USD" value={rate.children} decimalScale={2}>
              {price => <span>{price}</span>}
            </PriceFormat>
          </Col>
        </Row>

        <hr />
        <Row xs="2">
          <Col lg="7">
            <h6 className="tw-text-success">{t('EXPERIENCES_TOTAL')}</h6>
          </Col>

          <Col lg="5" className="tw-flex tw-items-center tw-justify-end">
            <PriceFormat currency="USD" value={rate.total} decimalScale={2}>
              {price => <h6 className="tw-text-success">{price}</h6>}
            </PriceFormat>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default BookingCardTopWhiteExp;
