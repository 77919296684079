import React from 'react';

import { Field } from 'formik';
import moment from 'moment';
import { Button, Container, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import withFormikSearch from '../../hoc/withFormikSearch';
import useAppTranslation from '../../hook/useAppTranslation';
import useDev from '../../hook/useDev';
import ColInline from '../../shared/ColInline';
import SelectDestination from '../../shared/SelectDestination';
import { encode } from '../../utils/uriComponent';
import { filterSearchSchema } from '../../validations/experiences';
import Travelers from './Travelers';

const FormSearch = ({ formikBag, inline = false, disabled = false }) => {
  const { t, dateFormat } = useAppTranslation();
  const { isDev } = useDev();

  return (
    <Container className="tw-px-0 tw-py-4">
      <Row xs="1" lg="4">
        <ColInline inline={inline} className="">
          <Field name="destiny">
            {({ field, meta }) => {
              const { touched, error } = meta;

              return (
                <Form.Group controlId={field.name}>
                  <Form.Label
                    className={`tw-text-white ${disabled || 'required'}`}
                  >
                    {t('EXPERIENCES_DESTINY')}
                  </Form.Label>

                  <SelectDestination
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={disabled}
                    isValid={touched && !error}
                    isInvalid={touched && !!error}
                  />

                  {touched && error && (
                    <Form.Control.Feedback type="invalid">
                      {t(error)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              );
            }}
          </Field>
        </ColInline>

        <ColInline inline={inline} className="">
          <Field name="checkIn">
            {({ field, meta }) => {
              const { name } = field;
              const { touched, error } = meta;
              const mCheckIn = moment(field.value);
              const checkIn = mCheckIn.isValid() ? mCheckIn.toDate() : null;

              return (
                <Form.Group controlId={name}>
                  <Form.Label
                    className={`tw-text-white ${disabled || 'required'}`}
                  >
                    {t('EXPERIENCES_CHECK_IN')}
                  </Form.Label>

                  <Form.Control
                    as={DatePicker}
                    name={name}
                    selected={checkIn}
                    onBlur={field.onBlur}
                    onChange={date => {
                      const mDate = moment(date);
                      formikBag.setFieldValue(
                        name,
                        mDate.isValid() ? mDate : null,
                      );
                    }}
                    minDate={new Date()}
                    dateFormat={dateFormat}
                    disabled={disabled}
                    isValid={touched && !error}
                    isInvalid={touched && !!error}
                  />

                  {touched && error && (
                    <Form.Control.Feedback type="invalid">
                      {t(error)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              );
            }}
          </Field>
        </ColInline>

        <ColInline inline={inline} className="">
          <Field name="paxes">
            {({ field }) => {
              return (
                <Form.Group controlId={field.name}>
                  <Form.Label
                    className={`tw-text-white ${disabled || 'required'}`}
                  >
                    {t('EXPERIENCES_TRAVELERS_zero')}
                  </Form.Label>

                  <Form.Control
                    as={Travelers}
                    inline={inline}
                    name={field.name}
                    value={field.value}
                    disabled={disabled}
                  />
                </Form.Group>
              );
            }}
          </Field>
        </ColInline>
      </Row>

      {!disabled && (
        <Row xs="1">
          <ColInline inline={inline} lg={{ span: 2, offset: 10 }}>
            <Button
              type="submit"
              block
              variant="outline-primary"
              className="tw-font-bold"
            >
              {t('EXPERIENCES_SEARCH')}
            </Button>
          </ColInline>
        </Row>
      )}

      {isDev && (
        <Row className="tw-mt-3" xs="1">
          <ColInline>
            <pre style={{ color: '#fff' }}>
              {JSON.stringify(
                {
                  values: formikBag.values,
                  touched: formikBag.touched,
                  errors: formikBag.errors,
                },
                null,
                2,
              )}
            </pre>
          </ColInline>
        </Row>
      )}
    </Container>
  );
};

export default withFormikSearch(
  {
    schema: filterSearchSchema,
    onSubmit: ({ values, actions, history }) => {
      actions.setSubmitting(false);

      const query = filterSearchSchema.cast(values, { stripUnknown: true });
      const newQuery = encode(query);
      history.push(`/experiences/list/${newQuery}`);
    },
  },
  FormSearch,
);
