import { BOOKING, PAYMENT } from './Routes';
import instance from './instance';

export const pay = async ({ payment, total, currency, source }) => {
  const { name, paymentType, creditCard } = payment;
  const [firstName, lastName] = name.split(/\s+/);
  const expireYear = `${creditCard.expireYear}`.padStart(4, '0');

  const body = {
    currency,
    source,
    amount: total,
    paymentType,

    client: {
      name: firstName,
      lastname: lastName,
    },

    creditCard: {
      number: creditCard.number,
      expireMonth: `${creditCard.expireMonth}`.padStart(2, '0'),
      expireYear: expireYear.substring(expireYear.length - 2),
      type: {
        name: 'VI',
      },
    },

    transactionType: 'PURCHASE',
    merchant: true,
  };

  const request = instance.post(PAYMENT, body);

  const response = await request
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err.response.data;
    });

  return response;
};

export const makeBooking = async data => {
  const response = await instance
    .post(BOOKING, data)
    .then(res => res.data)
    .catch(err => {
      throw err.response.data;
    });

  return response;
};

export default makeBooking;
