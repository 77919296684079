import React, { useEffect, useMemo, useState } from 'react';

import { nanoid } from 'nanoid';
import PaginationComponent from '../shared/Pagination';
import EmptyItems from './EmptyItems';

const Pagination = ({
  items,
  component: Component,
  render,
  children,
  itemsPerPage = 20,
  page = 1,
  onChange,
  disabledScroll = false,
}) => {
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    if (page !== currentPage) setCurrentPage(page);
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const itemsRender = useMemo(() => {
    const first = (currentPage - 1) * itemsPerPage;
    const last = first + itemsPerPage;
    const slice = items.slice(first, last);

    return slice.map(item => {
      const id = item?.id || nanoid();
      return (
        <React.Fragment key={id}>
          {Component ? (
            <Component {...item} />
          ) : render ? (
            render(item)
          ) : children ? (
            children(item)
          ) : (
            item
          )}
        </React.Fragment>
      );
    });
  }, [currentPage, itemsPerPage, items, Component, render, children]);

  const handleChange = newPage => {
    setCurrentPage(newPage);

    if (onChange) onChange(newPage);

    if (!disabledScroll)
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  if (!items?.length) return <EmptyItems />;

  return (
    <>
      {itemsRender}

      {items.length > itemsPerPage && (
        <PaginationComponent
          current={currentPage}
          onChange={handleChange}
          total={items.length}
          pageSize={itemsPerPage}
        />
      )}
    </>
  );
};

export default Pagination;
