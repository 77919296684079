import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import moment from 'moment';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useSportingEvents from '../../hook/useSportingEvents';
import {
  getCities,
  getCountries,
  getSports,
} from '../../services/sportingEvents';
import Select from '../../shared/Select';
import FiltersDateRange from './FiltersDateRange';

const FormSearch = ({ query, inline = false }) => {
  const { t } = useTranslation();
  const { search } = useSportingEvents();
  const [sports, setSports] = useState(null);
  const [countries, setCountries] = useState(null);
  const [cities, setCities] = useState([]);

  const getListSport = async () => {
    const resultCountry = await getSports();
    setSports(resultCountry);
  };

  const getListCountries = async () => {
    const resultCountry = await getCountries();
    setCountries(resultCountry);
  };

  useEffect(() => {
    getListCountries();
    getListSport();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const valuesContrie = async Id => {
    const data = await getCities(Id);
    setCities(data);
  };

  return (
    <Formik
      initialValues={
        query || {
          event: null,
          startDate: moment().add(1, 'day').startOf('day').hour(12).format(),
          endDate: moment().add(4, 'day').startOf('day').hour(12).format(),
          place: null,
          sport: null,
          country: null,
          city: null,
        }
      }
      onSubmit={values => {
        search(values);
      }}
    >
      {({ errors, touched, setFieldValue, setTouched, handleSubmit }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Container className="form-padding">
              <Row>
                <Form.Group
                  as={Col}
                  xs="12"
                  lg={inline ? '12' : '4'}
                  className={inline && 'tw-mb-2'}
                >
                  <Form.Label className="tw-text-white">
                    {' '}
                    {t('Deporte')}
                  </Form.Label>

                  <Select
                    groupHeading
                    defaultOptions
                    isMulti={false}
                    options={sports}
                    onChange={value => {
                      setFieldValue('sport', value);
                    }}
                    onBlur={() => {
                      setTouched({ ...touched, sport: true });
                    }}
                    isClearable
                  />
                  {touched.sport && errors.sport && (
                    <Form.Control.Feedback type="invalid">
                      {t(errors.sport)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group
                  as={Col}
                  xs="12"
                  lg={inline ? '12' : '4'}
                  className={inline && 'tw-mb-2'}
                >
                  <Form.Label className="tw-text-white">{t('Pais')}</Form.Label>

                  <Select
                    groupHeading
                    defaultOptions
                    isMulti={false}
                    options={countries}
                    onChange={value => {
                      setFieldValue('country', value);
                      valuesContrie(value.value);
                    }}
                    onBlur={() => {
                      setTouched({ ...touched, country: true });
                    }}
                    isClearable
                  />
                  {touched.country && errors.country && (
                    <Form.Control.Feedback type="invalid">
                      {t(errors.country)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group
                  as={Col}
                  xs="12"
                  lg={inline ? '12' : '4'}
                  className={inline && 'tw-mb-2'}
                >
                  <Form.Label className="tw-text-white">
                    {t('Ciudad')}
                  </Form.Label>

                  <Select
                    groupHeading
                    options={cities}
                    defaultOptions
                    isMulti={false}
                    onChange={value => {
                      setFieldValue('city', value);
                    }}
                    onBlur={() => {
                      setTouched({ ...touched, city: true });
                    }}
                    isClearable
                  />
                  {touched.city && errors.city && (
                    <Form.Control.Feedback type="invalid">
                      {t(errors.city)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Col
                  xs="12"
                  lg={inline ? '12' : '4'}
                  className={inline && 'tw-mb-2'}
                >
                  <FiltersDateRange />
                </Col>
              </Row>

              <Row>
                <Col xs="12" lg={inline ? 12 : { span: 2, offset: 10 }}>
                  <Button
                    type="submit"
                    block
                    variant={inline ? 'light' : 'outline-primary'}
                    className="tw-font-bold"
                  >
                    {t('Buscar')}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormSearch;
