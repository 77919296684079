import React, { forwardRef, useState } from 'react';

import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { FaSlidersH } from 'react-icons/fa';
import OutsideClickHandler from 'react-outside-click-handler';
import useAppTranslation from '../../hook/useAppTranslation';
import Rating from '../../shared/Rating';

const ButtonApply = ({ className, onClick }) => {
  const { t } = useAppTranslation();

  return (
    <div className={`${className} tw-ml-auto`}>
      <Button variant="primary" className="tw-px-2 tw-py-1" onClick={onClick}>
        {t('HotelsApply')}
      </Button>
    </div>
  );
};

const FilterToggle = forwardRef(
  ({ children, onClick, isApplied = false }, ref) => {
    return (
      <button
        type="button"
        className={`tw-rounded-md tw-border tw-border-solid tw-border-zinc-200 tw-px-4 tw-py-1 hover:tw-shadow ${
          isApplied
            ? 'tw-bg-primary tw-text-white'
            : 'tw-bg-transparent tw-text-black'
        } tw-hidden xl:tw-inline-block`}
        ref={ref}
        onClick={evt => {
          evt.preventDefault();

          onClick(evt);
        }}
      >
        {children}
      </button>
    );
  },
);

const FilterMenu = forwardRef(
  (
    { children, style, className, 'aria-labelledby': labeledBy, onApplyClick },
    ref,
  ) => {
    // console.log('🚀 ~ Filters.jsx', { opacity: style.opacity });
    // console.log('🚀 ~ Filters.jsx', { className });

    return (
      <>
        <div className="xl:tw-hidden">{children}</div>

        <div
          ref={ref}
          style={style}
          className={`${className} tw-hidden tw-w-max tw-flex-col tw-gap-2 tw-p-3 xl:tw-flex`}
          aria-labelledby={labeledBy}
        >
          {children}

          <ButtonApply onClick={onApplyClick} />
        </div>
      </>
    );
  },
);

const DropdownName = ({ name, setName, onChange, isApplied }) => {
  const { t } = useAppTranslation();
  const [show, setShow] = useState(false);
  // const [name, setName] = useState(value);

  const handleChange = e => {
    setName(e.target.value);
  };

  const handleClick = () => {
    onChange({ name });
    setShow(false);
  };

  return (
    <Dropdown
      show={show}
      onToggle={() => {
        setShow(prev => !prev);
      }}
    >
      <Dropdown.Toggle as={FilterToggle} isApplied={isApplied}>
        {t('HotelsName')}
      </Dropdown.Toggle>

      <Dropdown.Menu as={FilterMenu} onApplyClick={handleClick} renderOnMount>
        <span className="tw-m-0 tw-text-xl tw-text-black">
          {t('HotelsName')}
        </span>
        <Form.Control
          type="text"
          className="tw-w-96"
          value={name}
          onChange={handleChange}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const DropdownRating = ({ ratings, setRatings, onChange, isApplied }) => {
  const { t } = useAppTranslation();
  const [show, setShow] = useState(false);
  // const [ratings, setRatings] = useState(value);

  const handleChange = evt => {
    setRatings(prev => {
      const { value: rating, checked } = evt.target;

      const newRatings = [...prev, rating];
      if (checked) {
        return newRatings;
      }

      return newRatings.filter(it => it !== rating);
    });
  };

  const handleClick = () => {
    onChange({ stars: ratings });
    setShow(false);
  };

  return (
    <Dropdown
      show={show}
      onToggle={() => {
        setShow(prev => !prev);
      }}
    >
      <Dropdown.Toggle
        as={FilterToggle}
        isApplied={isApplied}
        className="tw-hidden"
      >
        {t('HotelsStarRating')}
      </Dropdown.Toggle>

      <Dropdown.Menu as={FilterMenu} onApplyClick={handleClick} renderOnMount>
        <span className="tw-m-0 tw-text-xl tw-text-black">
          {t('HotelsStarRating')}
        </span>
        {Array.from({ length: 5 }).map((_, index) => {
          const rating = index + 1;

          return (
            <Form.Check
              type="checkbox"
              key={`rating-${rating}`}
              id={`rating-${rating}`}
              className="tw-flex tw-items-center"
            >
              <Form.Check.Input
                value={rating}
                checked={ratings.includes(`${rating}`)}
                type="checkbox"
                onChange={handleChange}
              />
              <Form.Check.Label>
                <Rating rating={rating} hideTooltip />
              </Form.Check.Label>
            </Form.Check>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const DropdownSortedBy = ({ value, onChange }) => {
  const { t } = useAppTranslation();

  const handleChange = evt => {
    onChange({ sort: evt.target.value });
  };

  return (
    <Form.Control
      as="select"
      className="tw-w-1/2 xl:tw-w-1/4"
      value={value}
      onChange={handleChange}
    >
      <option value="name;asc">{t('HotelsName')}</option>
      <option value="star;asc">
        {t('HotelsStarRating')}: {t('HotelsLowToHigh')}
      </option>
      <option value="star;desc">
        {t('HotelsStarRating')}: {t('HotelsHighToLow')}
      </option>
    </Form.Control>
  );
};

const Filters = ({ filters, setFilters, countHotelsFiltered = 0 }) => {
  const { t } = useAppTranslation();
  const [showSidebar, setShowSidebar] = useState(false);
  const [name, setName] = useState(filters?.name ?? '');
  const [ratings, setRatings] = useState(filters?.stars ?? []);

  const handleChange = value => {
    setFilters(prev => ({ ...prev, ...value }));
  };

  const onApplyClick = () => {
    setFilters(prev => ({ ...prev, name, stars: ratings }));
    setShowSidebar(false);
  };

  return (
    <>
      <div className="tw-fixed tw-bottom-0 tw-left-0 tw-z-50 tw-h-12 tw-w-screen tw-border-x-0 tw-border-b-0 tw-border-t-2 tw-border-solid tw-border-primary tw-bg-white xl:tw-hidden">
        <Row className="tw-h-full tw-w-full">
          <Col className="tw-m-0 tw-grid tw-p-0">
            <button
              type="button"
              className="tw-border-0 tw-bg-transparent tw-p-0"
              onClick={() => {
                setShowSidebar(true);
              }}
            >
              <FaSlidersH /> {t('HotelsFilters')}
            </button>
          </Col>
        </Row>
      </div>

      <section
        className={`tw-fixed tw-top-0 tw-z-[2147483002] tw-h-screen tw-w-full tw-bg-transparent ${
          showSidebar ? 'tw-left-0' : '-tw-left-full'
        } tw-transition-all tw-duration-500 xl:tw-static xl:tw-mb-4 xl:tw-h-auto xl:tw-transition-none`}
      >
        <div className="tw-h-full tw-w-5/6 tw-border-y-0 tw-border-l-0 tw-border-r-2 tw-border-solid tw-border-zinc-200 tw-bg-white md:tw-w-3/6 lg:tw-w-2/6 xl:tw-w-full xl:tw-border-0 xl:tw-bg-transparent [&>div]:tw-h-full [&>div]:tw-w-full [&>div]:tw-p-4 [&>div]:xl:tw-p-0">
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowSidebar(false);
            }}
          >
            <div className="tw-flex tw-h-full tw-flex-col tw-justify-between tw-gap-2 xl:tw-flex-row xl:tw-gap-0">
              <div className="tw-flex tw-flex-col tw-gap-2 xl:tw-flex-row">
                <DropdownName
                  name={name}
                  setName={setName}
                  onChange={handleChange}
                  isApplied={filters?.name?.length > 0}
                />

                <DropdownRating
                  ratings={ratings}
                  setRatings={setRatings}
                  onChange={handleChange}
                  isApplied={filters?.stars?.length > 0}
                />

                <ButtonApply className="xl:tw-hidden" onClick={onApplyClick} />
              </div>

              <div className="tw-flex tw-items-center tw-gap-2 xl:tw-flex-1 xl:tw-justify-end">
                <span className="tw-text-black">
                  {t('HotelsItemsSortedBy', { count: countHotelsFiltered })}
                </span>

                <DropdownSortedBy
                  value={filters?.sort}
                  onChange={handleChange}
                />
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </section>
    </>
  );
};

export default Filters;
