import React, { useState } from 'react';

import { Markup } from 'interweave';
import moment from 'moment';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import '../css/TourDetail.css';
import useAppTranslation from '../hook/useAppTranslation';
import PriceFormat from './PriceFormat';

const TourDetail = ({ rates, data, onHandleClickReservar }) => {
  const { t } = useAppTranslation();
  const [tours] = useState(rates);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [politicas, setPoliticas] = useState('');
  const onHandleClickPolitica = item => {
    // console.log(item)
    setPoliticas(item.rateDetail.cancellationPolicy);
    setShow(true);
  };

  const participants = Object.values(data.paxes).reduce(
    (acc, it) => acc + (it ?? 0),
    0,
  );

  return (
    <Row className="tw-mt-4">
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('PoliticaCancelacion')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Markup content={politicas.description} />
        </Modal.Body>
      </Modal>

      <Col xs="12" lg="12">
        <h2>{t('MiraOpcionesExperiencia')}</h2>
      </Col>

      <Col>
        {tours.map((tour, indexTour) => (
          <Card key={indexTour} className="tw-my-4">
            <Card.Body>
              <Row>
                <Col xs="12" lg="8">
                  <span className="title-tour-rate tw-block tw-bg-primary">
                    {tour.name}
                  </span>
                  <label>
                    <Markup content={tour.description} />
                  </label>
                  <Row>
                    <Col xs="12" lg="6">
                      <label className="tw-text-info">{t('QueIncluye')}</label>
                      <ul className="tour-list">
                        {tour.includes.map(({ name }, indexInclude) => {
                          return <li key={indexInclude}> {name}</li>;
                        })}
                      </ul>
                    </Col>
                    <Col xs="12" lg="6">
                      <label className="tw-text-danger">
                        {t('QueNoIncluye')}
                      </label>
                      <ul className="tour-list">
                        {tour.notIncludes.map(({ name }, indexNotInclude) => {
                          return <li key={indexNotInclude}> {name}</li>;
                        })}
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <div className="line-price tw-border-primary" />
                <Col xs="12" lg="4">
                  <div className="tw-mb-4 tw-text-right">
                    <span className="ft-12 lh-03 tw-block tw-font-bold tw-text-secondary">
                      {moment(data.checkIn).format('DD MMMM YYYY')}
                    </span>
                    <span className="ft-12 tw-font-bold tw-text-secondary">{`${participants} ${
                      participants > 1 ? t('personas') : t('persona')
                    }`}</span>
                  </div>

                  {tour.rateDetail.paxAmounts.map((rate, i) => (
                    <Row className="tw-text-center" key={i}>
                      <Col className="tw-flex tw-flex-col">
                        <div>
                          <span style={{ textTransform: 'tw-capitalize' }}>
                            {rate.paxType}
                          </span>{' '}
                          <span className="tw-text-green-500">
                            <PriceFormat
                              value={rate.price.total}
                              currency={rate.price.currency}
                              prefix={rate.price.prefix}
                            />
                          </span>
                        </div>

                        <span className="tw-text-green-500">
                          {t('JustGoPoints', {
                            count: rate.price.justGoPoints,
                          })}
                        </span>
                      </Col>
                    </Row>
                  ))}

                  <Row className="tw-justify-center">
                    <Col xs="10" lg="8">
                      <Button
                        block
                        variant="outline-primary"
                        className="tw-my-3"
                        onClick={() => onHandleClickReservar(tour)}
                      >
                        {t('Reservar')}
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        block
                        onClick={() => onHandleClickPolitica(tour)}
                      >
                        {t('PoliticaCancelacion')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </Col>
    </Row>
  );
};

export default TourDetail;
