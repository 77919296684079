import moment from 'moment';
import { prepareConvertCurrency } from '../utils/currency';
import { toDateTime } from '../utils/date';
import { CAR_DETAIL, CAR_LIST, CAR_OFFICES_LIST } from './Routes';
import instance from './instance';

// SIPP - Standard Interline Passenger Procedure -> https://www.amoita.com/node/2512
// ccrc

const sources = process.env.REACT_APP_SUNAPI_API_NAME_CAR;
const LANGUAGE_DEFAULT = 'es';
const CURRENCY_DEFAULT = 'MXN';

export const getOffices = async ({ id }) => {
  const body = {
    location: {
      id,
    },
  };

  const request = instance.post(CAR_OFFICES_LIST, body);
  const response = await request
    .then(res => {
      // console.log('🚀 ~ cars.js', { res });
      const { data } = res;

      return data
        .sort(({ company_name: a }, { company_name: b }) => a.localeCompare(b))
        .map(
          ({
            office_code: officeCode,
            lng,
            lat,
            company_name: companyName,
            company_logo: companyLogo,
            address,
          }) => {
            return {
              officeCode,
              lng: parseFloat(lng),
              lat: parseFloat(lat),
              company: {
                name: companyName,
                logo: `https://cdn.rentingcarz.com${companyLogo}`,
              },

              address,
            };
          },
        );
    })
    .catch(() => []);

  return response;
};

export const getCars = async ({
  pickUpDate,
  pickUpTime,
  pickUpLocation,
  pickUpOffice,
  dropOffDate,
  dropOffTime,
  dropOffLocation,
  dropOffOffice,
  language = LANGUAGE_DEFAULT,
  currency = CURRENCY_DEFAULT,
}) => {
  const body = {
    pickupDate: toDateTime(pickUpDate, pickUpTime, 'DD-MM-YYYY HH:mm'),
    pickupLocation: {
      id: pickUpLocation,
      code: pickUpOffice,
    },
    dropoffDate: toDateTime(dropOffDate, dropOffTime, 'DD-MM-YYYY HH:mm'),
    dropoffLocation: {
      id: dropOffLocation,
      code: dropOffOffice,
    },
    language,
    currency,
    includeLocationForVehicle: true,
    testCaseUrl: '?list=1',
    searchOffice: true,
  };

  if (!body.pickupLocation.code && !body.dropoffLocation.code) {
    body.searchOffice = false;
  }

  const request = instance.post(CAR_LIST, body, { params: { sources } });
  const response = await request
    .then(async res => {
      const { data } = res;

      if (data?.errors?.length) {
        return {
          errors: data.errors,
        };
      }

      const convertCurrency = await prepareConvertCurrency();
      const dataResponse = data.vehicles
        // .filter(({ vehicleRate }) => vehicleRate?.currencyCode !== 'USD')
        .reduce(
          (acc, vehicle) => {
            const {
              makeModels: [model],
              company,
              vehicleClass,
              transmission,
              doors,
              passengers,
              bags,
              airConditioning,
              pickUpAddress,
              dropOffAddress,
              vehicleRate,
              sippCode,
              ccrc,
            } = vehicle;

            const total = Math.ceil(vehicleRate?.estimatedTotalAmount ?? 0);
            const car = {
              name: model?.name ?? null,
              vehicleClass: vehicleClass ?? null,
              picture: model?.picture ?? null,

              company: {
                code: company?.code ?? null,
                name: company?.name ?? null,
                image: company?.img ?? null,
              },
              isManual: transmission === 'Manual',
              isAutomatic: ['Automático', 'Automatic'].includes(transmission),
              doors: parseInt(doors ?? 0, 10),
              passengers: parseInt(passengers ?? 0, 10),
              bags: parseFloat(bags ?? 0.0),
              airConditioning,
              pickUpLocation: pickUpAddress?.trim?.() ?? '',
              dropOffLocation: dropOffAddress?.trim?.() ?? '',
              price: convertCurrency({
                total,
                currency: vehicleRate?.currencyCode,
              }),

              sippCode,
              ccrc,
            };

            acc.filters.price.min = Math.min(
              acc.filters.price.min,
              car.price.total,
            );
            acc.filters.price.max = Math.max(
              acc.filters.price.max,
              car.price.total,
            );

            if (!acc.filters.isManual && car.isManual) {
              acc.filters.isManual = true;
            }

            if (!acc.filters.isAutomatic && car.isAutomatic) {
              acc.filters.isAutomatic = true;
            }

            acc.filters.capacity = acc.filters.capacity.map(it => {
              if (!it.on) {
                // console.log({ passengers: car.passengers, it });
                if (car.passengers >= it.min && car.passengers <= it.max) {
                  return { ...it, on: true };
                }
              }

              return it;
            });

            if (!acc.filters.airConditioning && car.airConditioning) {
              acc.filters.airConditioning = true;
            }

            acc.vehicles.push(car);

            return acc;
          },
          {
            errors: [],
            vehicles: [],
            filters: {
              price: {
                currency: body.currency,
                min: Number.POSITIVE_INFINITY,
                max: Number.NEGATIVE_INFINITY,
              },
              isManual: false,
              isAutomatic: false,
              capacity: [
                { min: 1, max: 2, on: false },
                { min: 3, max: 4, on: false },
                { min: 5, max: 6, on: false },
                { min: 7, max: Number.POSITIVE_INFINITY, on: false },
              ],
              airConditioning: false,
            },
          },
        );

      dataResponse.filters.price.min = Math.floor(
        dataResponse.filters.price.min,
      );
      dataResponse.filters.price.max = Math.ceil(
        dataResponse.filters.price.max,
      );

      // console.log('🚀 ~ cars.js', { dataResponse });
      return dataResponse;
    })
    .catch(errors => {
      return {
        errors,
      };
    });

  return response;
};

export const getCar = async ({
  pickUpDate,
  pickUpTime,
  pickUpAddress,
  pickUpLocation,
  pickUpOffice,
  dropOffDate,
  dropOffTime,
  dropOffAddress,
  dropOffLocation,
  dropOffOffice,
  sippCode,
  ccrc,
  companyCode,
  language = LANGUAGE_DEFAULT,
  currency = CURRENCY_DEFAULT,
}) => {
  const body = {
    apiName: sources,
    pickupDate: toDateTime(pickUpDate, pickUpTime, 'DD-MM-YYYY HH:mm'),
    pickUpAddress,
    pickupLocation: {
      id: pickUpLocation,
      code: pickUpOffice,
    },
    dropOffAddress,
    dropoffDate: toDateTime(dropOffDate, dropOffTime, 'DD-MM-YYYY HH:mm'),
    dropoffLocation: {
      id: dropOffLocation,
      code: dropOffOffice,
    },
    includeLocationForVehicle: true,
    testCaseUrl: '?list=1',
    sippCode,
    ccrc,
    companyCode,
    language,
    currency,
  };

  const request = instance.post(CAR_DETAIL, body);
  const response = await request
    .then(async ({ data }) => {
      if (data?.errors?.length) {
        return {
          errors: data.errors,
        };
      }

      const convertCurrency = await prepareConvertCurrency();
      return data.vehicleRates.map(
        ({ id, rateInfo, estimatedTotalAmount }, index) => {
          const { url } = data.images[index] ?? data.images[0] ?? {};
          const total = Math.ceil(estimatedTotalAmount ?? 0);

          return {
            id: `${data.id}-${id}`,
            pickUp: pickUpAddress,
            pickUpDate: moment(data.pickupDate),
            dropOff: dropOffAddress,
            dropOffDate: moment(data.dropoffDate),
            picture: url,
            description: rateInfo.description,
            isManual: data.transmission === 'Manual',
            isAutomatic: ['Automático', 'Automatic'].includes(
              data.transmission,
            ),
            doors: parseInt(data.doors, 10),
            passengers: parseInt(data.passengerCount, 10),
            bags: parseFloat(data.bagCount),
            airConditioning: data.airConditioner,
            price: convertCurrency({
              total,
              currency,
            }),
          };
        },
      );
    })
    .catch(errors => {
      return {
        errors: [{ message: errors?.response?.data?.developerMessage }],
      };
    });

  return response;
};
