/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import CardHotel from '../../components/Hotels/CardHotel';
import Filters from '../../components/Hotels/Filters';
import FormSearch from '../../components/Hotels/FormSearch';
import Pagination from '../../components/Pagination';
import useAppParams from '../../hook/useAppParams';
import useAppTranslation from '../../hook/useAppTranslation';
import useAuth from '../../hook/useAuth';
import useDev from '../../hook/useDev';
import CargandoOpciones from '../../loader/CargandoOpciones';
import { getLabelByDestinyId } from '../../services/destinations';
import { getHotels } from '../../services/hotels';
import Error from '../../shared/Error';
import normalizeStr from '../../utils/normalizeStr';
import { scrollUp } from '../../utils/scroll';
import sleep from '../../utils/sleep';
import { encode } from '../../utils/uriComponent';
import { BY } from '../../validations/hotels';

const List = () => {
  const { language } = useAppTranslation();
  const { isDev } = useDev();
  const { query } = useAppParams();
  const { isDemo } = useAuth();

  const [loading, setLoading] = useState(true);
  const [hotels, setHotels] = useState([]);
  const [destiny, setDestiny] = useState(null);
  const [errors, setErrors] = useState([]);
  const [allAmenities, setAllAmenities] = useState([]);
  const [minPrecio, setMinPrecio] = useState(0);
  const [maxPrecio, setMaxPrecio] = useState(100);
  const [valuePrecio, setValuePrecio] = useState({ min: 1, max: 100 });
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({ sort: 'name;asc' });
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    let didCancel = false;
    const fetch = async () => {
      await sleep(500);
      if (didCancel || !query) return;

      setLoading(true);
      setDestiny(null);
      setErrors(false);
      setHotels([]);
      setFilters({ sort: 'name;asc' });

      if (query.by === BY.DESTINATION) {
        const [labelDestiny, data] = await Promise.all([
          getLabelByDestinyId({
            destinyId: query.destiny,
            language,
          }),

          getHotels({
            destiny: query.destiny,
            checkIn: query.checkIn,
            checkOut: query.checkOut,
            rooms: query.rooms,
            language,
            // currency: null,
            isDemo,
          }),
        ]);

        setDestiny(labelDestiny);
        if (data.errors?.length) {
          // console.log('🚀 ~ List.jsx', { data });
          setErrors(data.errors);
        } else if (data.hotelsValid?.length) {
          setHotels(data.hotelsValid);
          const offset = data.min === data.max ? 1 : 0;
          setMinPrecio(data.min - offset);
          setMaxPrecio(data.max);
          setValuePrecio({ min: data.min - offset, max: data.max });
          setAllAmenities(data.amenities);
        }

        scrollUp();
      } else if (query.by === BY.HOTEL) {
        // console.log('🚀 ~ List.jsx', { query });
      }

      setLoading(false);
    };

    fetch();
    return () => {
      didCancel = true;
    };
  }, [isDemo, query, language]);

  const hotelsFiltered = useMemo(() => {
    if (!hotels?.length) return [];

    if (filters) {
      let fnBySort = () => 0;
      const { sort } = filters;
      if (sort.includes('name')) {
        if (sort.includes('asc')) {
          fnBySort = ({ name: a }, { name: b }) => a.localeCompare(b);
        } else {
          fnBySort = ({ name: a }, { name: b }) => b.localeCompare(a);
        }
      } else if (sort.includes('star')) {
        if (sort.includes('asc')) {
          fnBySort = ({ stars: a }, { stars: b }) => a - b;
        } else {
          fnBySort = ({ stars: a }, { stars: b }) => b - a;
        }
      }

      return hotels
        .filter(({ name, total, stars, amenities }) => {
          if (filters.name?.length) {
            const str1 = normalizeStr(name).toLocaleLowerCase();
            const str2 = normalizeStr(filters.name).toLocaleLowerCase();

            if (!str1.includes(str2)) return false;
          }

          if (filters.price && filters.price.min && filters.price.max) {
            const price = Number(total) || 0;
            if (price < filters.price.min || price > filters.price.max) {
              return false;
            }
          }

          if (filters.stars?.length && !filters.stars.includes('todas')) {
            const hotelStars = Number(stars);

            if (
              !filters.stars.some(star => {
                const value = Number(star.trim());

                return hotelStars === value;
              })
            ) {
              return false;
            }
          }

          if (filters?.amenities?.length) {
            if (
              amenities.every(
                amenity =>
                  !filters.amenities.includes(amenity.name.toLocaleLowerCase()),
              )
            ) {
              return false;
            }
          }

          return true;
        })
        .sort(fnBySort);
    }

    return hotels;
  }, [hotels, filters]);

  const handleHotelClick = useCallback(
    hotel => {
      const encodedQuery = encode(query);

      window.open(`/hotels/detail/${encodedQuery}/${hotel.id}`, '_blank');
    },
    [query],
  );

  const handlePriceGuaranteeClick = useCallback(
    hotel => {
      const encodedQuery = encode({
        checkIn: query.checkIn,
        checkOut: query.checkOut,
        hotel: {
          id: hotel.id,
          name: hotel.name,
        },
      });

      window.open(`/price-guarantee/${encodedQuery}`, '_blank');
    },
    [query],
  );

  const handleMouseOverCard = useCallback(
    hotel => {
      setCurrent(hotel);
    },

    [],
  );

  const handleMouseOutCard = useCallback(() => {
    setCurrent(null);
  }, []);

  const renderCard = useCallback(
    hotel => (
      <CardHotel
        hotel={hotel}
        query={query}
        onSeeMoreClick={handleHotelClick}
        onPriceGuaranteeClick={handlePriceGuaranteeClick}
        onMouseOver={handleMouseOverCard}
        onMouseOut={handleMouseOutCard}
      />
    ),
    [
      query,
      handleHotelClick,
      handlePriceGuaranteeClick,
      handleMouseOverCard,
      handleMouseOutCard,
    ],
  );

  if (loading) return <CargandoOpciones />;

  return (
    <section>
      <header className="tw-bg-gray-400 tw-p-3">
        <FormSearch filters={query} />
      </header>

      <main className="tw-my-4">
        <Container>
          <Row xs="1">
            {errors && (
              <Col>
                <Error errors={errors} />
              </Col>
            )}

            {!errors && (
              <>
                <Col>
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    countHotelsFiltered={hotelsFiltered?.length}
                  />
                </Col>

                <Col className="tw-flex tw-flex-col tw-gap-4">
                  <Pagination
                    items={hotelsFiltered}
                    render={renderCard}
                    page={currentPage}
                    onChange={newPage => {
                      setCurrentPage(newPage);
                      scrollUp();
                    }}
                  />
                </Col>
              </>
            )}
          </Row>

          {isDev && (
            <Row xs="1" className="tw-mt-3">
              {Object.entries({
                query,
              })
                .filter(([, value]) => value)
                .map(([key, value], _, self) => {
                  const lg = 12 / self.length;

                  return (
                    <Col lg={lg}>
                      <pre>
                        {JSON.stringify(
                          {
                            [key]: value,
                          },
                          null,
                          2,
                        )}
                      </pre>
                    </Col>
                  );
                })}
            </Row>
          )}
        </Container>
      </main>
    </section>
  );
};

export default List;
