import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import storage from '../services/storage';
import useAuthZustand from '../store/auth';

const useAuth = () => {
  const { loading, isPlatinum, isDemo, data, setAuthData } = useAuthZustand(
    useShallow(state => state),
  );

  useEffect(() => {
    const authFromStorage = storage.get();

    setAuthData(authFromStorage);
  }, [setAuthData]);

  useEffect(() => {
    storage.set(data);
  }, [data]);

  return { loading, isPlatinum, isDemo, data, setAuthData };
};

export default useAuth;
