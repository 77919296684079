import React from 'react';

import { Button, Container } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import useAppTranslation from '../../hook/useAppTranslation';
import useCurrency from '../../hook/useCurrency';
import PriceFormat from '../PriceFormat';

const CardTicket = ({ ticket, setTicket }) => {
  const { t } = useAppTranslation({ keyPrefix: 'SportingEvents' });
  const { convertTo } = useCurrency();
  const noTickets = Math.max(...ticket.available_selling_quantities);
  const price = convertTo({ value: ticket.Price, currency: ticket.Currency });
  const serviceFee = convertTo({
    value: ticket.ServiceFee,
    currency: ticket.Currency,
  });

  return (
    <Container className="tw-rounded-lg tw-border-[1px] tw-border-solid tw-border-black/20 tw-p-4 tw-shadow-lg">
      <div className="tw-flex tw-justify-between">
        <div className="tw-flex tw-flex-col">
          <h1 className="tw-m-0 tw-text-3xl tw-font-bold">{ticket.Section}</h1>
          {ticket.immediate_confirmation && (
            <span className="tw-text-green-800">
              {t('ImmediateConfirmation')}
            </span>
          )}

          {noTickets > 0 && (
            <span>
              {t('NoOfTickets', {
                count: noTickets,
              })}
            </span>
          )}
        </div>

        <div className="tw-flex tw-flex-col tw-items-end">
          <PriceFormat
            value={price.value}
            currency={price.currency}
            prefix={price.prefix}
          >
            {valueText => (
              <span className="tw-text-xl tw-text-price">{valueText}</span>
            )}
          </PriceFormat>

          <PriceFormat
            value={serviceFee.value}
            currency={serviceFee.currency}
            prefix={serviceFee.prefix}
          >
            {valueText => (
              <small className="tw-text-lg">
                <Trans
                  t={t}
                  i18nKey="ServiceFee"
                  values={{
                    serviceFee: valueText,
                  }}
                  components={[<span className="tw-text-price" />]}
                />
              </small>
            )}
          </PriceFormat>
        </div>
      </div>

      <Button
        variant="outline-primary"
        className="tw-ml-auto tw-block tw-rounded-full"
        onClick={() => {
          setTicket?.(ticket);
        }}
      >
        {t('Book')}
      </Button>
    </Container>
  );
};

export default CardTicket;
