import { css } from 'styled-components';

const a = css`
  a {
    text-decoration: none !important;
  }

  a.primary {
    color: var(--button-primary) !important;
    cursor: pointer;

    &:hover {
      color: var(--button-primary-active) !important;
    }
  }
`;

export default a;
