import React from 'react';

import { Image } from 'react-bootstrap';

export default (t, amenity) => {
  switch (amenity) {
    case 'double':
      return (
        <span>
          <Image
            src="/images/hotels/amenities/doble.svg"
            fluid
            className="icon-20"
          />
          {t('Doble')}
        </span>
      );
    case 'non-smoking':
      return (
        <span>
          <Image
            src="/images/hotels/amenities/nonSmoking.svg"
            fluid
            className="icon-20"
          />
          {t('NoFumar')}
        </span>
      );
    case 'private-bathroom':
      return (
        <span>
          <Image
            src="/images/hotels/amenities/privateBathroom.svg"
            fluid
            className="icon-20"
          />
          {t('BanoPrivado')}
        </span>
      );
    case 'window':
      return (
        <span>
          <Image
            src="/images/hotels/amenities/ventana.svg"
            fluid
            className="icon-20"
          />
          {t('Ventana')}
        </span>
      );
    default:
      return amenity;
  }
};
