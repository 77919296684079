/* eslint-disable react/no-array-index-key */
import React from 'react';

import { Card } from 'react-bootstrap';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import styled from 'styled-components';
import markerImg from '../img/round-pushpin_1f4cd.png';
import Rating from '../shared/Rating';
import PriceFormat from './PriceFormat';

const FormMaps = ({
  center,
  items,
  onClick,
  onMarkerClick,
  position,
  positions,
  setPosition,
}) => {
  const handlePositionChange = newPosition => {
    if (setPosition) setPosition(newPosition);
  };

  return (
    <GoogleMap
      defaultZoom={11}
      defaultCenter={{
        lat: parseFloat(center.latitude),
        lng: parseFloat(center.longitude),
      }}
      onClick={onClick}
    >
      {positions.map((item, i) => (
        <Marker
          icon={markerImg}
          key={i}
          position={{
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          }}
          onClick={args => {
            if (onMarkerClick) {
              const itemFound = items.find(({ id }) => id === item.id);
              // console.log('🚀 ~ FormMaps.js', { item: itemFound });

              onMarkerClick({ ...args, item: itemFound ?? null });
            }
          }}
          onMouseOver={() => {
            const indexFound = items.findIndex(el => el.id === item.id);
            // console.log('🚀 ~ FormMaps.js', { index: indexFound });

            handlePositionChange(indexFound);
          }}
          onMouseOut={() => handlePositionChange(-1)}
        >
          {position === i && (
            <InfoWindow>
              <Card className="tw-border-0">
                <Body className="tw-p-1">
                  <label className="tw-font-bold">{item.place}</label>
                  {item.price && (
                    <PriceFormat
                      currency="USD"
                      value={Math.round(item.price)}
                      decimalScale={0}
                    />
                  )}
                  {item.raiting && <Rating rating={item.raiting} />}
                </Body>
              </Card>
            </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  );
};

const Body = styled(Card.Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormMapsInt = withScriptjs(withGoogleMap(FormMaps));

export default FormMapsInt;
