import React from 'react';

import PropTypes from 'prop-types';

const LayoutHome = ({ children, src, title }) => {
  return (
    <div
      style={{
        backgroundImage: `url('${src}')`,
      }}
      className="tw-mb-4 tw-flex tw-items-center tw-justify-center lg:tw-bg-[length:100%_430px] lg:tw-bg-no-repeat lg:tw-pb-28 lg:tw-pt-12"
    >
      <article className="tw-mx-0 tw-w-full tw-rounded tw-bg-gray-400/90 tw-p-3 lg:tw-mx-20 xl:tw-mx-60">
        <h4 className="tw-text-white">{title}</h4>

        {children}
      </article>
    </div>
  );
};

LayoutHome.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

LayoutHome.defaultProps = {};

export default LayoutHome;
