import moment from 'moment';
import yup from './yup';

export const filterSearchSchema = yup.object().shape({
  destiny: yup.number().nullable().required('CampoRequerido').default(null),
  checkIn: yup
    .string()
    .isDateMomentValid()
    .nullable()
    .required()
    .default(moment().add(1, 'day').startOf('day').hour(12)),
  paxes: yup.object().shape({
    adults: yup.number().default(1),
    children: yup.number().default(0),
    infant: yup.number().default(0),
    junior: yup.number().default(0),
  }),
});

export const bookingSchema = yup.object().shape({});
