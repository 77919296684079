import React from 'react';

import { Col, Row } from 'react-bootstrap';
import FlightTimeDetail from '../FlightTimeDetail';
import styles from './CardItineraryBody.module.css';

const CardItineraryBody = ({ itinerary }) => {
  return (
    <Row xs="1" className="tw-m-0">
      <Col lg="4" className="tw-px-1">
        <img
          className={`${styles.image} tw-py-2`}
          src={itinerary.airline.image}
          alt={itinerary.airline.name}
        />
      </Col>

      <Col lg="8">
        <FlightTimeDetail itinerary={itinerary} />
      </Col>
    </Row>
  );
};

export default CardItineraryBody;
