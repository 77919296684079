import React, { useEffect, useState } from 'react';

import { Alert, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import Switch from 'react-bootstrap/esm/Switch';
import { useTranslation } from 'react-i18next';
import { Route, useRouteMatch } from 'react-router-dom';
import '../../css/HotelReserva.css';
import useCruise from '../../hook/useCruise';
import LoaderReservar from '../../loader/LoaderReservar';
import buildPaxesInformationRerverva from '../../utils/buildPaxesInformationRerverva';
import PromoCondos from '../PromoCondos';
import ReservaCardTop from '../ReservaCardTop';
import ReservaCardTopWhite from '../ReservaCardTopWhite';
import ReservaTop from '../ReservaTop';
import ReservacionConcluida from '../ReservacionConcluida';
import SectionTop from '../SectionTop';
import Voucher from './Voucher';

const Booking = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { checkout } = useCruise();
  const [, setRoomsHuespedes] = useState([]);

  const [, setHasItem] = useState(true);
  const [, setPayment] = useState(null);
  const [sendindReserva] = useState(false);
  const [infoReserva] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (checkout !== null) {
      setPayment({
        currency: 'USD',
        source: checkout ? checkout.apiName : '',
        amount: checkout ? checkout.total : 0,
        paymentType: 'CREDIT_CARD',
        creditCard: {
          number: '',
          expireMonth: '01',
          expireYear: '2022',
          type: {
            name: 'VI',
          },
        },
      });

      const arrroomHuespedes = [];

      arrroomHuespedes.push({
        id: checkout.habitacion_id,
        roomType: '', // fijo
        planId: '', // fijo
        price: checkout.total,
        paxes: {
          adults: checkout.huespedes,
          children: checkout.huespedes_children,
          infant: 0, // fijo
        },
      });

      arrroomHuespedes.forEach((elem, index) => {
        const paxesInformation = buildPaxesInformationRerverva(elem);
        arrroomHuespedes[index].paxesInformation = paxesInformation;
      });
      setRoomsHuespedes(arrroomHuespedes);
    } else {
      setHasItem(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!checkout) return <div />;
  // console.log('🚀 ~ Booking.js', { checkout });

  return (
    <Switch>
      <Route exact path={path}>
        {checkout && (
          <>
            {sendindReserva && <LoaderReservar />}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton />
              <Modal.Body>
                <Row>
                  <Col xs="12" lg="12">
                    <h6>{t('LoSentimos')}!!!</h6>
                    <label>{t('NoEfectuarReservación')}</label>
                  </Col>
                </Row>
                {infoReserva && infoReserva.httpStatusCode && (
                  <Row>
                    <Col xs="12" lg="12">
                      <Alert variant="outline-primary">
                        {`${infoReserva.httpStatusCode}`}
                        <br />
                        {infoReserva.message}
                      </Alert>
                    </Col>
                  </Row>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={handleClose}>
                  {t('Aceptar')}
                </Button>
              </Modal.Footer>
            </Modal>
            <SectionTop
              menu={t('Crucero')}
              destino={checkout.direccion}
              nombre={t('DatosReserva')}
              direccion={checkout.hotel_name}
            />
            <Container fluid>
              <Row>
                <Col xs="12" lg="3" className="tw-my-3">
                  <Row className="tw-justify-center">
                    <Col xs="12" lg="12">
                      <ReservaCardTop rate={checkout} />
                    </Col>
                    <Col xs="12" lg="12">
                      <ReservaCardTopWhite item={checkout} />
                    </Col>
                    <Col xs="12" lg="10">
                      <h1 className="text-go ft-6em tw-text-right">GO!</h1>
                    </Col>
                    <Col xs="12" lg="12">
                      <PromoCondos />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="9">
                  <ReservaTop
                    hotelPhotos={checkout.hotel_photos}
                    hotelName={checkout.hotel_name}
                    hotel_stars={checkout.stars}
                    hotel_direccion={checkout.direccion}
                  />
                </Col>
              </Row>
            </Container>
          </>
        )}
      </Route>
      <Route path={`${path}/voucher`}>
        {checkout ? (
          <Voucher infoReserva={infoReserva} checkout={checkout} />
        ) : (
          <ReservacionConcluida />
        )}
      </Route>
    </Switch>
  );
};

export default Booking;
