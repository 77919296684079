import React, { useState } from 'react';

import moment from 'moment';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SelectDestination from '../../shared/SelectDestination';
import { toString } from '../../utils/date';
import FiltersDateRange from './FiltersDateRange';

const CondosFormTopBar = ({ searching }) => {
  const { t } = useTranslation();
  const [destino, setDestino] = useState(searching.destiny);
  const [startDate, setStartDate] = useState(
    toString(searching.checkIn, { in: 'DD/MM/YYYY' }),
  );
  const [endDate, setEndDate] = useState(
    toString(searching.checkOut, { in: 'DD/MM/YYYY' }),
  );
  const [errorDestino, setErrorDestino] = useState(false);
  const history = useHistory();

  const onHandleClickBuscar = () => {
    if (destino === null) setErrorDestino(true);
    else {
      const data = {
        destiny: destino,
        destino_name: null,
        checkIn: moment(startDate).startOf('month').format('DD-MM-YYYY'),
        checkOut: moment(endDate).endOf('month').format('DD-MM-YYYY'),
        currency: 'USD',
        language: 'es',
        rooms: [
          {
            adults: 1,
            children: {
              ages: [],
            },
          },
        ],
      };

      history.push(
        `/condos?search=${encodeURIComponent(JSON.stringify(data))}`,
      );
    }
  };

  return (
    <Card className="tw-bg-gray-400">
      <Card.Body>
        <Row>
          <Col xs="12" sm="6" md="4" lg="4" xl="4">
            <SelectDestination
              value={destino}
              onChange={evt => {
                const { value } = evt?.target ?? {};
                if (value === null) setErrorDestino(true);
                else setErrorDestino(false);

                setDestino(value);
              }}
            />

            {errorDestino && (
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            )}
          </Col>

          <Col xs="12" sm="6" md="4" lg="4" xl="4">
            <FiltersDateRange
              hideLabel
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </Col>

          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Button
              block
              variant="light"
              onClick={onHandleClickBuscar}
              className="tw-font-bold"
            >
              {t('Buscar')}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CondosFormTopBar;
