/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import { Button, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { MdPeople } from 'react-icons/md';
import calcTotalHuespedes from '../utils/calcTotalHuespedes';

const ExtraInformation = ({ setHuespedesSend, huespedesSend }) => {
  const { t } = useTranslation();

  const [hTextP, setTextP] = useState(t('HotelsTravelers').toLocaleLowerCase());
  const [hTextS, setTextS] = useState(t('HotelsTravelers_one'));
  const [huespedes, setHuespedes] = useState(huespedesSend ?? []);
  const [show, setShow] = useState(false);
  const [texto, setTexto] = useState(
    `${calcTotalHuespedes(huespedes, 'hab')} hab., ${
      calcTotalHuespedes(huespedes, 'adult') +
      calcTotalHuespedes(huespedes, 'child')
    } ${hTextP}`,
  );
  const proveedor = process.env.REACT_APP_SUNAPI_API_NAME_HOTEL;

  useEffect(() => {
    setTextP(t('HotelsTravelers').toLocaleLowerCase());
    setTextS(t('HotelsTravelers_one'));
    let cad = `${texto.split(',')[0]},${texto.split(',')[1].substring(0, 3)}`;
    const count = texto.split(',')[1].substring(1, 3);
    if (parseInt(count, 10) > 1) {
      cad += t('HotelsTravelers').toLocaleLowerCase();
    } else {
      cad += t('HotelsTravelers_one');
    }
    setTexto(cad);
  }, [t, texto]);

  const onHandleClickAdultMinus = i => {
    // console.log(i)
    const obj = [...huespedes];
    let adult = obj[i].adults;
    if (proveedor === 'RateHawk-Temp') {
      if (adult > 1) {
        adult--;
        obj.forEach(elem => {
          // eslint-disable-next-line no-param-reassign
          elem.adults = adult;
        });
        setHuespedes(obj);
        setTexto(
          `${calcTotalHuespedes(obj, 'hab')} hab., ${
            calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child')
          } ${
            calcTotalHuespedes(obj, 'adult') +
              calcTotalHuespedes(obj, 'child') >
            1
              ? hTextP
              : hTextS
          }`,
        );
        setHuespedesSend(obj);
      }
    } else if (adult > 1) {
      adult--;
      obj[i].adults = adult;
      setHuespedes(obj);
      setTexto(
        `${calcTotalHuespedes(obj, 'hab')} hab., ${
          calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child')
        } ${
          calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child') >
          1
            ? hTextP
            : hTextS
        }`,
      );
      setHuespedesSend(obj);
    }
  };

  const onHandleClickAdultPlus = i => {
    // console.log(i)
    const obj = [...huespedes];
    let adult = obj[i].adults;
    if (proveedor === 'RateHawk-Temp') {
      if (adult < 6) {
        adult++;
        obj.forEach(elem => {
          // eslint-disable-next-line no-param-reassign
          elem.adults = adult;
        });
        setHuespedes(obj);
        setTexto(
          `${calcTotalHuespedes(obj, 'hab')} hab., ${
            calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child')
          } ${
            calcTotalHuespedes(obj, 'adult') +
              calcTotalHuespedes(obj, 'child') >
            1
              ? hTextP
              : hTextS
          }`,
        );
        setHuespedesSend(obj);
      }
    } else if (adult < 6) {
      adult++;
      obj[i].adults = adult;
      setHuespedes(obj);
      setTexto(
        `${calcTotalHuespedes(obj, 'hab')} hab., ${
          calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child')
        } ${
          calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child') >
          1
            ? hTextP
            : hTextS
        }`,
      );
      setHuespedesSend(obj);
    }
  };

  const onHandleClickChildMinus = i => {
    const obj = [...huespedes];
    const child = obj[i].children.ages.length;
    if (proveedor === 'RateHawk-Temp') {
      if (child >= 1) {
        obj.forEach(ele => {
          ele.children.ages.pop();
        });
        setHuespedes(obj);
        setTexto(
          `${calcTotalHuespedes(obj, 'hab')} hab., ${
            calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child')
          } ${
            calcTotalHuespedes(obj, 'adult') +
              calcTotalHuespedes(obj, 'child') >
            1
              ? hTextP
              : hTextS
          }`,
        );
        setHuespedesSend(obj);
      }
    } else if (child >= 1) {
      obj[i].children.ages.pop();
      setHuespedes(obj);
      setTexto(
        `${calcTotalHuespedes(obj, 'hab')} hab., ${
          calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child')
        } ${
          calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child') >
          1
            ? hTextP
            : hTextS
        }`,
      );
      setHuespedesSend(obj);
    }
  };

  const onHandleClickChildPlus = i => {
    const obj = [...huespedes];
    const child = obj[i].children.ages.length;
    if (proveedor === 'RateHawk-Temp') {
      if (child < 4) {
        obj.forEach(ele => {
          ele.children.ages.push(0);
        });
        setHuespedes(obj);
        setTexto(
          `${calcTotalHuespedes(obj, 'hab')} hab., ${
            calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child')
          } ${
            calcTotalHuespedes(obj, 'adult') +
              calcTotalHuespedes(obj, 'child') >
            1
              ? hTextP
              : hTextS
          }`,
        );
        setHuespedesSend(obj);
      }
    } else if (child < 4) {
      obj[i].children.ages.push(0);
      setHuespedes(obj);
      setTexto(
        `${calcTotalHuespedes(obj, 'hab')} hab., ${
          calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child')
        } ${
          calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child') >
          1
            ? hTextP
            : hTextS
        }`,
      );
      setHuespedesSend(obj);
    }
  };

  const onHandleChangeAge = (indexParent, indexChild, value) => {
    const obj = [...huespedes];
    obj[indexParent].children.ages[indexChild] = parseInt(value, 10);
    setHuespedes(obj);
    setHuespedesSend(obj);
  };

  const onHandleClickAddHabitacion = () => {
    const obj = [...huespedes];
    const hab = obj.length;

    if (hab < 5) {
      if (proveedor === 'RateHawk-Temp') {
        const newObj = {
          adults: obj[0].adults,
          children: {
            ages: obj[0].children.ages.map(() => {
              return 0;
            }),
          },
        };
        obj.push(newObj);
        setHuespedes(obj);
        setTexto(
          `${calcTotalHuespedes(obj, 'hab')} hab., ${
            calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child')
          } ${
            calcTotalHuespedes(obj, 'adult') +
              calcTotalHuespedes(obj, 'child') >
            1
              ? hTextP
              : hTextS
          }`,
        );
        setHuespedesSend(obj);
      } else {
        const newObj = {
          adults: 2,
          children: {
            ages: [],
          },
        };
        obj.push(newObj);
        setHuespedes(obj);
        setTexto(
          `${calcTotalHuespedes(obj, 'hab')} hab., ${
            calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child')
          } ${
            calcTotalHuespedes(obj, 'adult') +
              calcTotalHuespedes(obj, 'child') >
            1
              ? hTextP
              : hTextS
          }`,
        );
        setHuespedesSend(obj);
      }
    }
  };

  const onHandleClickDelHabitacion = () => {
    const obj = [...huespedes];
    const hab = obj.length;
    if (hab > 1) {
      obj.pop();
      setHuespedes(obj);
      setTexto(
        `${calcTotalHuespedes(obj, 'hab')} hab., ${
          calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child')
        } ${
          calcTotalHuespedes(obj, 'adult') + calcTotalHuespedes(obj, 'child') >
          1
            ? hTextP
            : hTextS
        }`,
      );
      setHuespedesSend(obj);
    }
  };

  return (
    <Dropdown
      className="tw-text-black"
      show={show}
      onToggle={() => setShow(!show)}
      drop="down"
    >
      <Dropdown.Toggle variant="success" className="tw-shadow-none">
        <MdPeople className="icon-24 tw-mr-2 tw-text-white" />{' '}
        <span>{texto}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="tw-rounded-0 tw-w-full" flip={false}>
        <div className="tw-px-3 tw-py-1">
          {huespedes.map((item, i) => (
            <div key={i}>
              <h6 className={`${i > 0 && 'tw-mt-3'}`}>
                {t('HotelsRooms_one')} {i + 1}
              </h6>
              <div className="tw-flex tw-items-center tw-justify-between">
                <span>{t('Adults')}</span>
                <div className="tw-flex tw-justify-between">
                  {proveedor !== 'RateHawk-Temp' || i === 0 ? (
                    <span
                      className={`btn-h btn-minus ${
                        item.adults === 1 ? 'disabled' : ''
                      }`}
                      onClick={() => onHandleClickAdultMinus(i)}
                    >
                      <FaMinus />
                    </span>
                  ) : (
                    <span className="btn-h btn-minus disabled">
                      <FaMinus />
                    </span>
                  )}
                  <span className="adult-number">{item.adults}</span>
                  {proveedor !== 'RateHawk-Temp' || i === 0 ? (
                    <span
                      className={`btn-h btn-plus ${
                        item.adults === 6 ? 'disabled' : ''
                      }`}
                      onClick={() => onHandleClickAdultPlus(i)}
                    >
                      <FaPlus />
                    </span>
                  ) : (
                    <span className="btn-h btn-plus disabled">
                      <FaPlus />
                    </span>
                  )}
                </div>
              </div>
              <div className="tw-flex tw-items-center tw-justify-between">
                <span className="lh-1">
                  {t('Ninos')}
                  <small className="tw-block">{t('NinosRangoEdad')}</small>
                </span>
                <div className="tw-flex tw-justify-between">
                  {proveedor !== 'RateHawk-Temp' || i === 0 ? (
                    <span
                      className={`btn-h btn-minus ${
                        item.children.ages.length === 0 ? 'disabled' : ''
                      }`}
                      onClick={() => onHandleClickChildMinus(i)}
                    >
                      <FaMinus />
                    </span>
                  ) : (
                    <span className="btn-h btn-minus disabled">
                      <FaMinus />
                    </span>
                  )}
                  <span className="adult-number">
                    {item.children.ages.length}
                  </span>
                  {proveedor !== 'RateHawk-Temp' || i === 0 ? (
                    <span
                      className={`btn-h btn-plus ${
                        item.children.ages.length === 4 ? 'disabled' : ''
                      }`}
                      onClick={() => onHandleClickChildPlus(i)}
                    >
                      <FaPlus />
                    </span>
                  ) : (
                    <span className="btn-h btn-plus disabled">
                      <FaPlus />
                    </span>
                  )}
                </div>
              </div>
              {item.children.ages.map((itemChild, idx) => (
                <div
                  className="tw-flex tw-items-center tw-justify-between"
                  key={idx}
                >
                  <span className="ft-08rem">{`${t('HotelsChildAge')} ${
                    idx + 1
                  }`}</span>
                  <select
                    className="ft-08rem"
                    onChange={e => onHandleChangeAge(i, idx, e.target.value)}
                    defaultValue={itemChild}
                  >
                    <option value="0">{t('NinosMenosDe')} 1</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                </div>
              ))}
              {huespedes.length > 1 && (
                <div className="tw-mt-1 tw-text-right">
                  <Button
                    variant="link"
                    size="sm"
                    className="tw-p-0 tw-shadow-none"
                    onClick={() => onHandleClickDelHabitacion(i)}
                  >
                    {t('HotelsDeleteRoom')}
                  </Button>
                </div>
              )}
            </div>
          ))}

          {huespedes.length < 5 && (
            <div className="tw-mt-1 tw-text-right">
              <Button
                variant="link"
                size="sm"
                className="tw-p-0 tw-shadow-none"
                onClick={onHandleClickAddHabitacion}
              >
                {t('HotelsAddRoom')}
              </Button>
            </div>
          )}
          <Button
            block
            variant="outline-primary"
            className="tw-mt-2 tw-shadow-none"
            onClick={() => setShow(false)}
          >
            {t('Aceptar')}
            <small className="tw-block">{texto}</small>
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ExtraInformation;
