import React from 'react';

import { Markup } from 'interweave';
import {
  Card,
  Col,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import useAppTranslation from '../hook/useAppTranslation';
import Rating from '../shared/Rating';
import PriceFormat from './PriceFormat';

const CardExperienciasHorizontal = ({ item, onHandleClickCard }) => {
  const { t } = useAppTranslation();

  return (
    <Card
      className="tw-mb-3 tw-cursor-pointer tw-shadow"
      onClick={() => onHandleClickCard(item.id, item.source)}
    >
      <Card.Body className="tw-py-1">
        <Row className="tw-items-center">
          <Col xs="12" lg="3">
            <div className="tw-text-center">
              <Image
                src={
                  item.mainPhoto && item.source === 'Viator'
                    ? item.mainPhoto.urlPhoto
                    : '/images/no-image.jpg'
                }
                fluid
                className="card-img-max-height"
              />
            </div>
          </Col>
          <Col xs="12" lg="9">
            <div className="">
              <Row className="tw-mb-2">
                <div className="">
                  <span className="tw-text-xl tw-font-bold tw-leading-6 tw-text-zinc-500">
                    {item.name}
                  </span>
                </div>
                {item.description && (
                  <label className="tw-mt-2 tw-leading-[1.1] tw-text-gray-500">
                    {item.description.length > 170 ? (
                      <Markup
                        content={`${item.description.substr(0, 170)}...`}
                      />
                    ) : (
                      <Markup content={item.description} />
                    )}
                  </label>
                )}
              </Row>
              <Rating rating={item.rating} />
              <span>
                {item.reviews.length} {t('Resenas')}
              </span>
              <Row>
                <Col>
                  <ul className="list-inline tw-flex tw-gap-3">
                    <li className="list-inline-item">
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">{t('Traslado')}</Tooltip>
                        }
                      >
                        <Image
                          src="/images/experiences/transfer.svg"
                          fluid
                          className="tw-h-5 tw-w-5"
                        />
                      </OverlayTrigger>
                    </li>
                    <li className="list-inline-item">
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">{t('Comida')}</Tooltip>
                        }
                      >
                        <Image
                          src="/images/experiences/food.svg"
                          fluid
                          className="tw-h-5 tw-w-5"
                        />
                      </OverlayTrigger>
                    </li>
                    <li className="list-inline-item">
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">{t('Bebida')}</Tooltip>
                        }
                      >
                        <Image
                          src="/images/experiences/drink.svg"
                          fluid
                          className="tw-h-5 tw-w-5"
                        />
                      </OverlayTrigger>
                    </li>
                  </ul>
                </Col>
              </Row>

              <Row xs="2">
                <Col>
                  <div>
                    <span className="tw-text-2xl tw-font-semibold tw-text-green-500">
                      <PriceFormat
                        value={item.minRates.adult.total}
                        currency={item.minRates.adult.currency}
                        prefix={item.minRates.adult.prefix}
                      />
                    </span>{' '}
                    <small className="tw-text-xs tw-font-normal tw-text-black">
                      {t('PrecioAdulto')}
                    </small>
                  </div>

                  <span className="tw-font-bold tw-text-green-500">
                    {t('JustGoPoints', {
                      count: item.minRates.adult.justGoPoints,
                    })}
                  </span>
                </Col>

                <Col className="tw-flex tw-flex-col">
                  <div className="tw-flex tw-items-baseline tw-justify-end tw-gap-1">
                    <span className="tw-text-2xl tw-font-semibold tw-text-green-500">
                      <PriceFormat
                        value={item.minRates.child.total}
                        currency={item.minRates.child.currency}
                        prefix={item.minRates.child.prefix}
                      />
                    </span>{' '}
                    <small className="tw-text-xs tw-font-normal tw-text-black">
                      {t('PrecioMenor')}
                    </small>
                  </div>

                  <span className="tw-w-full tw-text-right tw-font-bold tw-text-green-500">
                    {t('JustGoPoints', {
                      count: item.minRates.child.justGoPoints,
                    })}
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CardExperienciasHorizontal;
