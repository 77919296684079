import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const DATE_FORMAT = {
  es: 'dd/MM/yyyy',
  en: 'MM/dd/yyyy',
};

const TIME_FORMAT = {
  es: `h:mm aa`,
  en: `HH:mm`,
};

const DATE_TIME_FORMAT = {
  es: `${DATE_FORMAT.es} ${TIME_FORMAT.es}`,
  en: `${DATE_FORMAT.en} ${TIME_FORMAT.en}`,
};

const DATE_FORMAT_MOMENT = {
  es: 'DD/MM/YYYY',
  en: 'MM/DD/YYYY',
};

const TIME_FORMAT_MOMENT = {
  es: 'hh:mm a',
  en: 'HH:mm',
};

const DATE_TIME_FORMAT_MOMENT = {
  es: `${DATE_FORMAT_MOMENT.es} ${TIME_FORMAT_MOMENT.es}`,
  en: `${DATE_FORMAT_MOMENT.en} ${TIME_FORMAT_MOMENT.en}`,
};

const useAppTranslation = ({ ns = 'translation', keyPrefix } = {}) => {
  const translation = useTranslation(ns, { keyPrefix });

  const ready = useMemo(() => translation?.ready, [translation?.ready]);

  const t = useMemo(() => {
    if (!ready) return () => 'Loading...';

    return translation.t;
  }, [ready, translation.t]);

  const changeLanguage = useMemo(() => {
    return translation.i18n.changeLanguage;
  }, [translation.i18n.changeLanguage]);

  const language = useMemo(
    () => translation.i18n.language,
    [translation.i18n.language],
  );

  const languages = useMemo(
    () => translation?.i18n?.languages?.sort?.() ?? [],
    [translation.i18n.languages],
  );

  const dateFormat = useMemo(() => {
    return DATE_FORMAT[language] ?? null;
  }, [language]);

  const timeFormat = useMemo(() => {
    return TIME_FORMAT[language] ?? null;
  }, [language]);

  const dateTimeFormat = useMemo(() => {
    return DATE_TIME_FORMAT[language] ?? null;
  }, [language]);

  const dateFormatMoment = useMemo(() => {
    return DATE_FORMAT_MOMENT[language] ?? null;
  }, [language]);

  const timeFormatMoment = useMemo(() => {
    return TIME_FORMAT_MOMENT[language] ?? null;
  }, [language]);

  const dateTimeFormatMoment = useMemo(() => {
    return DATE_TIME_FORMAT_MOMENT[language] ?? null;
  }, [language]);

  return {
    ready,
    t,
    changeLanguage,
    languages,
    language,
    dateFormat,
    timeFormat,
    dateTimeFormat,
    dateFormatMoment,
    timeFormatMoment,
    dateTimeFormatMoment,
  };
};

export default useAppTranslation;
