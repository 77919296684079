import React from 'react';

import { Field } from 'formik';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import useAppTranslation from '../hook/useAppTranslation';

const InputForm = ({
  type,
  as,
  name,
  label,
  placeholder,
  feedback,
  disabled,
  hideFeedback,
  children,
  required = true,
}) => {
  const { t } = useAppTranslation();

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <Form.Group controlId={field.name}>
          <Form.Label className={required && 'required'}>{label}</Form.Label>

          <Form.Control
            type={type}
            as={as}
            placeholder={placeholder}
            name={field.name}
            disabled={disabled}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            isValid={meta.touched && !meta.error}
            isInvalid={meta.touched && !!meta.error}
          >
            {children}
          </Form.Control>

          {feedback}
          {!hideFeedback && meta.touched && meta.error && (
            <Form.Control.Feedback type="invalid">
              {t(meta.error, { path: label }).replace(
                new RegExp(`^${name}`),
                label,
              )}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      )}
    </Field>
  );
};

InputForm.propTypes = {
  type: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  feedback: PropTypes.node,
  disabled: PropTypes.bool,
  hideFeedback: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  required: PropTypes.bool,
};

InputForm.defaultProps = {
  type: 'text',
  as: undefined,
  placeholder: undefined,
  feedback: undefined,
  disabled: false,
  hideFeedback: false,
  children: undefined,
  required: true,
};

export default InputForm;
