import React, { useMemo, useState } from 'react';

import { Button, Col, Jumbotron, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const MAX_AMENITIES = 16;

const AmenitiesDetail = ({ amenities }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleShowMoreClick = () => setShow(prev => !prev);

  const content = useMemo(() => {
    if (!amenities?.length)
      return (
        <Jumbotron>
          <p>{t('NoInformacionMostrar')}</p>
        </Jumbotron>
      );

    return (
      <>
        <Row>
          {amenities
            .slice(0, show ? amenities.length : MAX_AMENITIES)
            .map(item => (
              <Col key={item.name} xs="6" md="3" className="tw-text-secondary">
                {item.name}
              </Col>
            ))}
        </Row>

        {amenities.length > MAX_AMENITIES && (
          <Button
            variant="link"
            className="tw-px-0 tw-text-success tw-shadow-none"
            onClick={handleShowMoreClick}
          >
            {t(!show ? 'VerMas' : 'VerMenos')}
          </Button>
        )}
      </>
    );
  }, [amenities, show]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row className="tw-mt-4">
      <Col>
        <h2>{t('ServiciosHotel')}</h2>
        {content}
      </Col>
    </Row>
  );
};

export default AmenitiesDetail;
