import React, { useMemo } from 'react';

import { Field, FieldArray } from 'formik';
import moment from 'moment';
import { Button, Container, Form, Row } from 'react-bootstrap';
import withFormikSearch from '../../hoc/withFormikSearch';
import useAppTranslation from '../../hook/useAppTranslation';
import useDev from '../../hook/useDev';
import ColInline from '../../shared/ColInline';
import SelectDestination from '../../shared/SelectDestination';
import { encode } from '../../utils/uriComponent';
import { BY, PAY_TYPE, filterSearchSchema } from '../../validations/hotels';
import Checkbox from '../Checkbox';
import FiltersDateRange from './FiltersDateRange';
import SelectHotel from './SelectHotel';
import Travelers from './Travelers';

const FormSearch = ({ formikBag, inline = false, disabled = false }) => {
  const { t } = useAppTranslation();
  const { isDev } = useDev();
  const by = useMemo(() => formikBag?.values?.by, [formikBag?.values?.by]);
  // console.log('🚀 ~ FormSearch.jsx', { by });

  return (
    <Container className="tw-flex tw-flex-col tw-gap-4">
      <Row xs="1">
        {by === BY.DESTINATION && (
          <ColInline inline={inline} lg="4">
            <Field name="destiny">
              {({ field, meta }) => {
                // console.log('🚀 ~ FormSearch.jsx', { field, meta });

                return (
                  <Form.Group controlId={field.name}>
                    <Form.Label
                      className={`tw-text-white ${disabled || 'required'}`}
                    >
                      {t('HotelsDestination')}
                    </Form.Label>

                    <SelectDestination
                      service="HOTEL"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      disabled={disabled}
                      isValid={meta.touched && !meta.error}
                      isInvalid={meta.touched && !!meta.error}
                    />

                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {t(meta.error)}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            </Field>
          </ColInline>
        )}

        {by === BY.HOTEL && (
          <ColInline inline={inline} lg="5">
            <Field name="hotelId">
              {({ field, meta }) => {
                // console.log('🚀 ~ FormSearch.jsx', { field, meta });

                return (
                  <Form.Group controlId={field.name}>
                    <Form.Label
                      className={`tw-text-white ${disabled || 'required'}`}
                    >
                      {t('Hoteles_one')}
                    </Form.Label>

                    <SelectHotel
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      disabled={disabled}
                      isValid={meta.touched && !meta.error}
                      isInvalid={meta.touched && !!meta.error}
                    />

                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {t(meta.error)}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            </Field>
          </ColInline>
        )}

        <ColInline
          inline={inline}
          lg={by === BY.DESTINATION ? 8 : 7}
          className="tw-p-0"
        >
          <Row>
            <ColInline inline={inline} lg="6">
              <FiltersDateRange disabled={disabled} />
            </ColInline>

            <ColInline inline={inline} lg="6">
              <FieldArray name="rooms">
                {arrayHelpers => {
                  // const { touched, error } = meta;
                  // console.log('🚀 ~ FormSearch.jsx', { field, form, meta });

                  return (
                    <Form.Group controlId={arrayHelpers.name}>
                      <Form.Label
                        className={`tw-text-white ${disabled || 'required'}`}
                      >
                        {t('HotelsTravelers')}
                      </Form.Label>

                      <Form.Control
                        as={Travelers}
                        inline={inline}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...arrayHelpers}
                        disabled={disabled}
                      />
                    </Form.Group>
                  );
                }}
              </FieldArray>
            </ColInline>
          </Row>
        </ColInline>

        <ColInline
          inline={inline}
          lg="3"
          className="tw-flex tw-flex-col tw-justify-center"
        >
          <Field name="by">
            {({ field, form }) => {
              return (
                <Checkbox
                  name={field.name}
                  checked={field.value === BY.HOTEL}
                  disabled={disabled}
                  onChange={value => {
                    const newValue = value ? BY.HOTEL : BY.DESTINATION;
                    form.setFieldValue(field.name, newValue);
                  }}
                >
                  <span className="tw-text-base tw-leading-6 tw-tracking-normal tw-text-white">
                    {t('HotelsSearchBy')}
                  </span>
                </Checkbox>
              );
            }}
          </Field>
        </ColInline>

        <ColInline
          inline={inline}
          lg="4"
          className="tw-flex tw-flex-col tw-justify-center"
        >
          <Field name="pay">
            {({ field }) => {
              return (
                <Form.Group controlId={field.name} className="tw-m-0 tw-p-0">
                  <Form.Check
                    type="checkbox"
                    id={field.name}
                    inline
                    className="tw-flex tw-w-full tw-justify-between"
                  >
                    <Row
                      xs="1"
                      className="tw-m-0 tw-max-h-full tw-w-full tw-p-0"
                    >
                      <ColInline
                        inline={inline}
                        lg="6"
                        className="tw-m-0 tw-p-0"
                      >
                        <div className="tw-flex tw-items-center tw-text-base tw-leading-6 tw-tracking-normal tw-text-white">
                          <Form.Check.Input
                            type="radio"
                            className="tw-my-0"
                            id={PAY_TYPE.CASH}
                            name={field.name}
                            value={PAY_TYPE.CASH}
                            checked={field.value === PAY_TYPE.CASH}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            disabled={disabled}
                          />

                          <Form.Check.Label htmlFor={PAY_TYPE.CASH}>
                            {t('HotelsCash')}
                          </Form.Check.Label>
                        </div>
                      </ColInline>

                      <ColInline
                        inline={inline}
                        lg="6"
                        className="tw-m-0 tw-p-0"
                      >
                        <div className="tw-flex tw-items-center tw-text-base tw-leading-6 tw-tracking-normal tw-text-white">
                          <Form.Check.Input
                            type="radio"
                            className="tw-my-0"
                            id={PAY_TYPE.POINTS}
                            name={field.name}
                            value={PAY_TYPE.POINTS}
                            checked={field.value === PAY_TYPE.POINTS}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            disabled={disabled}
                          />

                          <Form.Check.Label htmlFor={PAY_TYPE.POINTS}>
                            {t('JustGoPointsTitle')}
                          </Form.Check.Label>
                        </div>
                      </ColInline>
                    </Row>
                  </Form.Check>
                </Form.Group>
              );
            }}
          </Field>
        </ColInline>
      </Row>

      {!disabled && (
        <Row xs="1">
          <ColInline inline={inline} lg={{ span: 2, offset: 10 }}>
            <Button
              type="submit"
              block
              variant="outline-primary"
              className="tw-font-bold"
            >
              {t('Buscar')}
            </Button>
          </ColInline>
        </Row>
      )}

      {isDev && (
        <Row>
          <ColInline xs="12">
            <pre className="tw-text-gray-800">
              {JSON.stringify(
                {
                  values: formikBag.values,
                  touched: formikBag.touched,
                  errors: formikBag.errors,
                },
                null,
                2,
              )}
            </pre>
          </ColInline>
        </Row>
      )}
    </Container>
  );
};

const FormikSearch = withFormikSearch(
  {
    initValues: {
      checkIn: moment().add(2, 'day').startOf('day').hour(12).format(),
      checkOut: moment().add(4, 'day').startOf('day').hour(12).format(),
    },
    schema: filterSearchSchema,
    onSubmit: ({ values, actions, history }) => {
      actions.setSubmitting(false);

      const query = filterSearchSchema.cast(values, { stripUnknown: true });
      const encodedQuery = encode(query);

      if (query.by === BY.DESTINATION)
        history.push(`/hotels/list/${encodedQuery}`);
      else history.push(`/hotels/detail/${encodedQuery}/${query.hotelId}`);
    },
  },

  FormSearch,
);

export default FormikSearch;
