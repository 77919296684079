import React, { useEffect } from 'react';

import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import SectionPromotionNY from '../../components/SectionPromotionNY';
import MainContainer from '../../shared/MainContainer';
import PriceBottom from '../../shared/PriceBottom';
import Detail from './Detail';
import List from './List';
import Restaurants from './Restaurants';

const Home = () => {
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  useEffect(() => {
    // console.log('🚀 ~ Home.jsx ~ useEffect', { pathname });

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    <MainContainer>
      <Switch>
        <Route exact path={path} component={Restaurants} />
        <Route path={`${path}/list/:query`} component={List} />
        <Route path={`${path}/detail/:query/:id`} component={Detail} />
      </Switch>

      <SectionPromotionNY />
      <PriceBottom />
    </MainContainer>
  );
};

export default Home;
