import React from 'react';

import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Stops from './Stops';

const FlightTimeDetail = ({ itinerary }) => {
  const { t } = useTranslation();

  return (
    <Row className="tw-h-full" lg="4">
      <Col xs="4" className="tw-flex tw-items-center tw-justify-center">
        <h6 className="tw-mb-0 tw-font-bold tw-text-black">
          {t('Tiempo', {
            time: moment(itinerary.departingAt).format('HHmm'),
          })}
        </h6>
      </Col>

      <Col xs="4" className="tw-flex tw-items-center tw-justify-center">
        <Stops itinerary={itinerary} />
      </Col>

      <Col xs="4" className="tw-flex tw-items-center tw-justify-center">
        <h6 className="tw-mb-0 tw-font-bold tw-text-black">
          {t('Tiempo', {
            time: moment(itinerary.arrivingAt).format('HHmm'),
          })}
        </h6>
      </Col>

      <Col
        xs="12"
        className="tw-flex tw-items-center tw-justify-center tw-py-3"
      >
        <h6 className="tw-mb-0 tw-font-bold tw-text-black">
          {t('DuracionCorta', {
            duration: itinerary.duration,
          })}
        </h6>
      </Col>
    </Row>
  );
};

export default FlightTimeDetail;
